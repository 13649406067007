export const parseJwt = (token) => {
	if (token && token !== 'undefined') {
		return JSON.parse(atob(token.split('.')[1]));
	}
	return null;
};

export const checkTokenIsExpired = (token) => {
	if (token) {
		const decodedJwt = parseJwt(token);
		if (decodedJwt) {
			if (decodedJwt.exp * 1000 < Date.now()) {
				return true;
			}
		}
	}

	return false;
};
