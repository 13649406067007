import React, {memo, useCallback, useEffect, useState} from "react";
import {Button, Checkbox, DatePicker, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {checkMembershipItemStatus, createJoinMembershipItem, createPaymentMethodAndPay, createStripeItemOnetimePayment, createStripePaymentIntent} from "../../../services/payment_method";
import {getCode} from "country-list";
import {getAuthUserInfo} from "../../../states/general/actions";
import {getSpaceItemData} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import routes from "../../../routes";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import {getDomainUrl, setAccessToken} from '../../../utils/common';
import { Alert } from 'antd';
import {
    VALIDATE_MESSAGES,
    MAGIC_CONNECT_KEY,
    MAGIC_CONNECT_NETWORK, API_DOMAIN, ROOT_DOMAIN
} from '../../../constants/common';
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {MEMBERSHIP_TYPES, SELECT_PAYMENT_METHOD, SELECT_ADD_NEW_CARD} from "../../../constants/space";
import DropdownWithCards from "../../../components/commons/DropdownWithCards";
import DropdownWithCountries from "../../../components/commons/DropdownWithCountryRegion/DropdownWithCountries";
import DropdownWithRegions from "../../../components/commons/DropdownWithCountryRegion/DropdownWithRegions";
import {autoRenewParser, DateDayCondition, getButtonPaymentText, getExpirationDate, getExpirationMonthlyStart, getIncludedMonths, getMembershipItemPrice, membershipItemImage, Recurring} from "../../../components/commons/helpers/MembershipItemHelpers";
import '../../SpaceBuyMembership/SpaceBuyMembership.scss'
import {getPaymentType, MembershipItemUtils, PaymentType, RenewalOptions} from "../../../utils/MembershipItemUtils";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import FormFieldStacked from "../../../components/commons/FormFieldStacked";
import {checkMfaEnabled, checkSmsEnabled, emailSignUp, registerMagic} from '../../../services/user';
import { login, magic_login } from '../../../services/auth';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import {getLocalStorage, removeLocalStorage} from "../../../utils/storage";
import {createFullRoute} from "../../../utils/redirects";
import web3 from "web3";

const ItemPaymentMethod = () => {
    const [itemPaymentMethodForm] = Form.useForm();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [searchParams, setSearchParams] = useSearchParams();
    const itemId = searchParams.get('id');
    const [hasMfaEnabled, setHasMfaEnabled] = useState(false);
    const [form] = Form.useForm();
    const [loginForm] = Form.useForm();
    // const [selectedCard, setSelectedCard] = useState('default');
    // const [isAddCard, setAddCard] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const [agreementChecked1, setAgreementChecked1] = useState(false);
    const [agreementChecked2, setAgreementChecked2] = useState(false);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [loginFormIsOnFocus, setLoginFormIsOnFocus] = useState(false);
    const [signupFormIsOnFocus, setSignUpFormIsOnFocus] = useState(false);
    const [alert, setAlert] = useState(null);
    const [alert2, setAlert2] = useState(null);
    const currentLocation = useLocation();
    const [isItemLoaded, setItemLoaded] = useState(false);
    const [item, setItem] = useState(null);
    const [isPaid, setIsPaid] = useState(false);
    const [loadItemOnce, setLoadItemOnce] = useState(false);
    const [expirationDate, setExpirationDate] = useState('No Expiration');
    const [expirationDateDeductOneDay, setExpirationDateDeductOneDay] = useState('');
    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
    const [spaceName, setSpaceName] = useState(null);
    const [isSubscription, setSubscription] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [displayDropDownError, setDisplayDropDownError] = useState(false);
    const [addCardForm] = Form.useForm();
    const [submittedAddCardForm, setSubmittedAddCardForm] = useState(false);
    const [showAddCardForm, setShowAddCardForm] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('default');
    const [autoMonthlyPrice, setAutoMonthlyPrice] = useState([{}]);
    const [selectedMonthlyPrice, setSelectedMonthlyPrice] = useState(RenewalOptions.renewal_none);
    const [isParseOnlyOnce, setParseOnlyOnce] = useState(false);
    const [hidePaymentMethod, setHidePaymentMethod] = useState(false);
    const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);

    const [isSignedUp, setIsSignedUp] = useState(false);
    const [isMagicLink, setIsMagicLink] = useState(false);

    let spaceUsername = null;
    const magic = new Magic(MAGIC_CONNECT_KEY, {
        network: MAGIC_CONNECT_NETWORK,
        locale: 'en_US',
        extensions: [new ConnectExtension()]
    });
    const path = currentLocation.pathname;
    if (path && path.startsWith('/@') && path.length > 3) {
        const clean_path = path.replace('/@', '');
        const path_exp = clean_path.split('/');
        spaceUsername = path_exp[0];
    }

    const handleSignUpFormChanges = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSignUpFormOnBlur = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleLoginFormChanges = useCallback(async () => {
        await loginForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleLoginFormOnBlur = useCallback(async () => {
        await loginForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSignup = useCallback(async (values) => {
        try {
            console.log(values)
            if (isMagicLink) {
                await web3Login(values);
            } else {
                await registerUser(values);
            }
        } catch (error) {
            setAlert({ variant: 'danger', message: error.message });
        }
    },)

    const registerUser = async (values) => {
        // setSuccessSignUp(false);
        // setSubmitted(true);
        values.email = values.email.toLowerCase();
        values.space_id = spaceInfo.id;
        values.space_path = path + '?id=' + itemId;
        // Pass the current api domain to make the api_domain dynamic base on the server domain
        values.api_domain = getDomainUrl();
        const result = await emailSignUp(values);
        if (result) {
            console.log(result)
            if (result.result) {
                form.resetFields();
                if(result.message !== "Email already verified."){
                    setIsSignedUp(true);
                    setAlert({
                        variant: 'success',
                        message: 'Registered successfully!'
                    });
                }else{
                    setAlert({
                        variant: 'danger',
                        message: result.message
                    });
                }
            } else {
                if (result.message) {
                    setAlert({ variant: 'danger', message: result.message });
                } else {
                    setAlert({
                        variant: 'danger',
                        message: 'Something went wrong'
                    });
                }
            }
            setSubmitted(false);
        }
    };

    const web3Login = async () => {
        web3.eth
            .getAccounts()
            .then((accounts) => {
                magic.connect
                    .requestUserInfo({ isResponseRequired: false })
                    .then((user_account) => {
                        let values = {
                            email: user_account.email ?? 'no-email',
                            user_public_address: accounts?.[0]
                        };
                        // getWalletInfo(values);
                    });
            })
            .catch((error) => {
                // setSubmitted(false);
                setIsMagicLink(false);
                // console.log(error);
            });
    };

    const magicLogin = async () => {
        // setSubmitted(true);
        setIsMagicLink(true);
        await magic.connect.disconnect().catch((e) => {
            // console.log(e);
        });
        await web3Login();
    };
    const loginUser = async (values) => {
        console.log(values)
        const result = await login(values);
        await processLogin(result);
    };

    useEffect(() => {
        if (loading && loading === 'done' && authUserInfo) {
            // window.location.replace(routes.dashboard());
            setSubmitted(false);
            checkHas2FA().then(() => {});
        }
    }, [authUserInfo, loading, hasMfaEnabled, isMagicLink]);

    const checkHas2FA = async () => {
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const mfa_async = checkMfaEnabled(values);
            const sms_async = checkSmsEnabled(values);
            const mfa_res = await mfa_async;
            const sms_res = await sms_async;
            if (mfa_res.result || sms_res.result) {
                setHasMfaEnabled(true);
            } else {
                setHasMfaEnabled(false);
                // window.location.replace(routes.dashboard());
            }
            console.log(mfa_res.message);
            console.log(sms_res.message);
        } catch (error) {
            console.log(error);
        }
    };

    const processLogin = async (result) => {
        // console.log("processLogin");
        if (result) {
            if (result.result) {
                if (result.access_token) {
                    setAccessToken(result);
                }
                dispatch(getAuthUserInfo());
            } else {
                if (result.message) {
                    setAlert2({ variant: 'danger', message: result.message });
                } else {
                    setAlert2({
                        variant: 'danger',
                        message: 'User not found.Please try again.'
                    });
                }
            }
        }
        // setLoginBtn(false);
        // setSubmitted(false);
        // setIsMagicLink(false);
    };
    const handleLogin = useCallback(
        async (values) => {
            try {
                // setLoginBtn(true);
                console.log(values);
                if (hasMfaEnabled) {
                    console.log('MFA Enabled');
                    values.user_id = authUserInfo.id;
                    await handleOtp(values);
                } else {
                    console.log('MFA Not Enabled');
                    await loginUser(values);
                }
            } catch (error) {
                console.log(error);
            }
        },[])


    const handleOtp = async (values) => {
        try {
            let values = {
                user_id: authUserInfo.id
            };
            let result = await dualCheck2FA(values);
            if (result.result) {
                form.resetFields();
                setAlert({
                    variant: 'success',
                    message: 'Log in successful!'
                });
                // setLoginBtn(false);
                window.location.replace(routes.dashboard());
            } else {
                setAlert({
                    variant: 'danger',
                    message: 'OTP Code is incorrect.'
                });
                // setLoginBtn(false);
            }
        } catch (error) {
            // setLoginBtn(false);
            console.log(error);
        }
    };

    useEffect(() => {
        // console.log(isSignedUp)
    }, [isSignedUp])

    const handleFormChanges = useCallback(async () => {
        await itemPaymentMethodForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleFormOnBlur = useCallback(async () => {
        await itemPaymentMethodForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const onChangeAgreement1 = (e) => {
        setAgreementChecked1(e.target.checked);
        console.log(e)
    };

    const onChangeAgreement2 = (e) => {
        setAgreementChecked2(e.target.checked);
        console.log(e)
    };

    const handleSubmit = useCallback(async (values) => {
            setSubmitted(true);

            if (!authUserInfo) {
                setSubmitted(false);
                return;
            }

            if (!spaceInfo) {
                setSubmitted(false);
                return;
            }

            if (!item) {
                setSubmitted(false);
                return;
            }

            const data = values;
            data.item_id = itemId;
            data.space_id = spaceInfo.id;
            data.user_id = authUserInfo.id;
            data.payment_type = 'free';
            data.is_renew = false;
            data.space_owner_id = spaceInfo.owner;
            data.is_subscription_enable = item?.enable_expiration_and_subscription;
            // if (itemDetails.enable_expiration_and_subscription && itemDetails.first_benefit) {
            //     data.amount = parseFloat(itemDetails?.first_benefit?.monthly_price);
            //     data.currency = itemDetails?.first_benefit?.currency.toLowerCase();
            //     data.included_months = itemDetails?.first_benefit?.included_months;
            // } else {
            data.amount = parseFloat(item?.price);
            data.currency = item?.currency.toLowerCase();
            // }

            // Pass the current api domain to make the api_domain dynamic base on the server domain
            data.api_domain = getDomainUrl();

            if (hidePaymentMethod) {
                const response = await createJoinMembershipItem(data);
                if (response && response.result) {
                    setSubmitted(false);
                    await proceedPaymentCompleted();
                } else {
                    setSubmitted(false);
                }
            } else {
                try {
                    setDisplayDropDownError(true);
                    const response = await createStripeItemOnetimePayment(data);
                    if (response && response.payout) {
                        if (response.payout.url && response.payout.status === 'open') {
                            window.location.href = response.payout.url;
                        } else if (response.payout.status === 'complete') {
                            console.log(response.payout)
                            let url = routes.spaceItemPaymentCompleted();
                            const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
                            navigate(fin_url);
                        }
                    }

                    setSubmitted(false);

                    // if (selectedCard.value !== 'default' && selectedCard.value !== 'add') {
                    //     data.item_id = itemId;
                    //     data.space_id = spaceInfo.id;
                    //     data.user_id = authUserInfo.id;
                    //     // data.payment_type = 'free';
                    //     data.is_renew = false;
                    //
                    //     data.space_owner_id = spaceInfo.owner;
                    //     data.payment_name = 'test paymnet';
                    //
                    //     const response = await createStripeItemOnetimePayment(data);
                    //     window.location.href = response.payout.url;
                    //     setSubmitted(false);
                    //
                    //     // const response = await createStripeCustomerSessionPaymentIntent(data);
                    //     // window.open(response.payout.url, '_blank', 'noreferrer');
                    //
                    //     // data.user_id = authUserInfo.id;
                    //     // data.stripe_id = selectedCard.cardObject.id;
                    //
                    //     // window.open(response.payout.url, '_blank', 'noreferrer');
                    //     // if (response && response.result) {
                    //     //     setSubmitted(false);
                    //     //     await proceedPaymentCompleted();
                    //     // } else {
                    //     //     setSubmitted(false);
                    //     // }
                    // } else {
                    //     if (!selectedCountry || selectedCountry === '') {
                    //         setSubmitted(false);
                    //         return;
                    //     }
                    //
                    //     //TODO: change package this is only a temporary fix
                    //     let countryCode = getCode(selectedCountry);
                    //     if (!countryCode) {
                    //         if (selectedCountry === 'United States') {
                    //             countryCode = getCode('United States of America');
                    //         }
                    //     }
                    //
                    //     data.country = countryCode;
                    //     data.state = selectedRegion;
                    //     data.expiry_mm = values.expiry.format('MM');
                    //     data.expiry_yy = values.expiry.format('YYYY');
                    //
                    //     delete data.expiry;
                    //
                    //     if (authUserInfo) {
                    //         setSubmittedAddCardForm(true);
                    //         data.user_id = authUserInfo.id;
                    //
                    //         const response = await createPaymentMethodAndPay(data);
                    //         if (response && response.result) {
                    //             setShowAddCardForm(false);
                    //             dispatch(getAuthUserInfo());
                    //             setSubmitted(false);
                    //             await proceedPaymentCompleted();
                    //         } else {
                    //             setSubmitted(false);
                    //         }
                    //         setSubmittedAddCardForm(false);
                    //     } else {
                    //         setSubmitted(false);
                    //     }
                    // }
                } catch (error) {
                    setSubmitted(false);
                    console.log(error);
                }
            }

        },
        [
            authUserInfo,
            selectedCountry,
            selectedRegion,
            // selectedCard,
            item,
            hidePaymentMethod,
            spaceInfo
        ]
    );

    const checkStatus = useCallback(async (space_id, item_id) => {
        if (!item_id) return;
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await checkMembershipItemStatus(data);
        if (response && response.result) {
            const transaction = response.stripe_transaction;
            const sessionStatus = response.session_status;
            if (transaction) {
                if (transaction.status === 'inactive') {
                    if (sessionStatus === 'complete') {
                        let url = routes.spaceItemPaymentCompleted();
                        const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + item_id;
                        navigate(fin_url);
                    }
                } else if (transaction.status === 'active' || transaction.status === 'expired') {
                    let url = routes.spaceItemBuy();
                    const fin_url =
                        url.replace(':space_username', '@' + spaceUsername) + '?id=' + item_id;
                    navigate(fin_url);
                }
            }
            setTimeout(()=> {
                setCheckStatusLoaded(true);
            },2000)
        }
    },[authUserInfo])

    const getItem = useCallback(async (space_id, item_id) => {
        if (!item_id) {
            return;
        }

        const data = {
            space_id: space_id,
            item_id: item_id
        }

        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }

        const response = await getSpaceItemData(data);
        if (response && response.result) {
            const itemData = response.item;

            if (itemData != null) {
                if (itemData?.creator === authUserInfo?.id) {
                    window.location.replace(routes.viewUserSpace() + spaceUsername );
                }
            }

            setIsPaid(itemData.is_paid);
            setItem(itemData);
            setItemLoaded(true);
            if (itemData.photo || itemData.video) {
                if (itemData.photo) {
                    const bg = {
                        backgroundImage: 'url(' + itemData.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (itemData.video) {
                    const bg = {
                        backgroundImage: 'none',
                    };
                    setCollectionAssetBGImage(bg)
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [authUserInfo]);

    const proceedPaymentCompleted = useCallback(async () => {
        // const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
        if (spaceUsername) {
            let url = routes.spaceItemPaymentCompleted();
            //redirect to wallet connect for item when the collection is NFT and no subscription
            // if (itemDetails.collection_type === 'membership') {
            //     if (isSubscription) {
            //         const isSubscription = itemDetails.enable_expiration_and_subscription;
            //         // if (!isSpaceMember) {
            //         if (itemDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
            //             url = routes.spaceItemWalletConnect();
            //         }
            //         // }
            //
            //     } else {
            //         if (isSpaceMember) {
            //             // Membership Ready
            //             //to
            //             //Wallet Connect
            //         } else {
            //             // EXISTING USER/NEW TO SPACE CLAIM NOW FLOW where User
            //             // must own an existing NFT to claim Membership/Item NO SUBSCRIPTION
            //             if (itemDetails.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
            //                 url = routes.spaceItemWalletConnect();
            //             }
            //         }
            //     }
            // }
            const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
            navigate(fin_url);
        }
    }, [spaceInfo, item, isSubscription, spaceUsername, itemId]);


    useEffect(() => {
        if (item == null) {
            setItemLoaded(false);
        }

        if (spaceUsername && !isItemLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getItem(spaceInfo.id, itemId).then((r) => {
                    });
                    setSpaceName(spaceInfo.name);
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(spaceUsername));
                }
                if (spaceInfoLoading === 'failed') {
                    navigate(routes.createSpace());
                }
            }
        }

        if (item != null) {
            setHidePaymentMethod(!isSubscription && (item?.price == null || (parseFloat(item?.price) === 0)));
            if (!isParseOnlyOnce) {
                setParseOnlyOnce(true);
                setAutoMonthlyPrice(autoRenewParser(item));
            }

            setSubscription(item?.enable_expiration_and_subscription);
            if (autoMonthlyPrice.length > 0 && item?.enable_expiration_and_subscription && item?.first_benefit) {
                const included_months = item?.first_benefit?.included_months;
                // const date_created = item?.first_benefit?.created_on;
                const selectedMonths = autoMonthlyPrice.filter(monthly => monthly.value === selectedMonthlyPrice);
                let redemptionCount = included_months;
                if (selectedMonths.length > 0 ) {
                    redemptionCount = included_months + selectedMonths[0].months
                }
                setExpirationDate(getExpirationDate(included_months,DateDayCondition.default));
                setExpirationDateDeductOneDay(getExpirationDate(redemptionCount,DateDayCondition.deductOneDay));
            }

            if (authUserInfo != null && !loadItemOnce) {
                setLoadItemOnce(true);
                checkStatus(spaceInfo.id, itemId).then(r =>{});
                getItem(spaceInfo.id, itemId).then((r) => {});
            }
        }
        // setAddCard(selectedCard.value === 'add');

        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);
    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        spaceUsername,
        itemId,
        item,
        // selectedCard,
        hidePaymentMethod,
        isPaid,
        isCheckStatusLoaded,
        selectedMonthlyPrice
    ])

    useEffect(() => {
    },[
        expirationDateDeductOneDay,
        expirationDate,
        isSubscription,
        autoMonthlyPrice
    ]);

    return (
        <>
            <main id="cs-space-main" className="cs-main main-theme">
                {(!spaceInfo || !item || item?.creator === authUserInfo?.id|| (authUserInfo && !isCheckStatusLoaded)) ? (
                    <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                        <div className="centerframe-flex frame-modal">
                            <div className="block-lg modal-block">
                                <i className="fa-regular fa-solar-system"></i>
                            </div>
                        </div>
                    </section>
                ) : (
                    <section id="cs-space-checkout-c1" className="py-0 px-0 lg:py-section-safeview theme-transparent">
                        <div className="cs-centerframe-split-lg">
                            <div className="cs-centerframe-split-left-lg order-2 lg:order-1">
                                <div className="p-section-safeview lg:pr-gutter">
                                    <div className="cs-stack-checkout-form stack-col-base gap-gutterxx lg:max-w-half-breakpoint-less-gutter">
                                        <div className="cs-checkout-start stack-col">
                                            <hgroup className="heading">
                                                <h2>
                                                    {item?.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (
                                                        <>
                                                            Wallet Connected! <br/>
                                                            Review Item Details.
                                                        </>
                                                    ) : (
                                                        <>
                                                            {getLocalStorage('redirect_link') ?
                                                                <>
                                                                  Email Confirmed!
                                                                </>
                                                                :
                                                                <>
                                                                    {authUserInfo && (
                                                                        <>Review Item</>
                                                                    )}
                                                                </>
                                                            }
                                                        </>
                                                    )}
                                                </h2>
                                                {authUserInfo && (
                                                    <p>Review and continue below to purchase this item?.</p>
                                                    // <p> To { hidePaymentMethod ?'get':'purchase' } this item and join the community,
                                                    //     complete the following information:
                                                    // </p>
                                                )}
                                            </hgroup>
                                            <hgroup className="title">
                                                <h1>{item ? item?.name : ''}</h1>
                                                <p>{item ? item?.summary : ''}</p>
                                            </hgroup>

                                            <div className="cs-stack-checkout-product-info">
                                                <div className="stack-row">
                                                    <p>Item Price</p>
                                                    <p>${(item?.price != null ? parseFloat(item?.price).toFixed(2) : 0) + ' ' + (item?.currency != null ? item?.currency : 'USD') }</p>
                                                </div>
                                                {getPaymentType(item) !== PaymentType.onetime &&
                                                    <div className="stack-row">
                                                        <p>Expiration Date</p>
                                                        <p>{expirationDateDeductOneDay.trim() !== ''  ? expirationDateDeductOneDay : 'No Expiration'}</p>
                                                    </div>
                                                }
                                            </div>
                                            {!authUserInfo && (
                                                <>
                                                    <hgroup className="heading">

                                                        <h3>{isSignedUp ? 'Confirm your Account' : 'Sign Up or Log In to continue'}</h3>
                                                        {!isSignedUp ? (
                                                            (spaceInfo && spaceInfo?.plan === 'pro') ?
                                                                (
                                                                    <p>
                                                                        Enter your email to create a free account or continue below if you already have an account
                                                                        . By signing up, you agree to our <a href={routes.viewUserSpace() + spaceInfo.username + '/terms-services'} target='_blank'>Terms of Service</a>
                                                                        , <a href={routes.viewUserSpace() + spaceInfo.username + '/privacy-policy'} target='_blank'>Privacy Policy</a>
                                                                        , and  <a href={routes.viewUserSpace() + spaceInfo.username + '/community-guidelines'} target='_blank'>Community Guidelines</a>.
                                                                    </p>
                                                                ) : (
                                                                    <p>
                                                                        Enter your email to create a free account or continue below if you already have an account
                                                                        . By signing up, you agree to our {getSitewideLink('terms_of_service_sign_up', "social-links list-unstyled")}
                                                                        , {getSitewideLink('privacy_policy_sign_up', "social-links list-unstyled")}
                                                                        , and  {getSitewideLink('community_guideline_sign_up', "social-links list-unstyled")}.
                                                                    </p>
                                                                )
                                                        ) : (
                                                            <p>Check your email to continue.</p>
                                                        )}
                                                    </hgroup>
                                                    {!isSignedUp ? (
                                                        <>
                                                            <div className="w-full">
                                                                {alert && (
                                                                    <div className="alert callout warning items-center">
                                                                        <div className="action">
                                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                                        </div>
                                                                        <div className="content">
                                                                            <div>
                                                                                <h6>{alert.message}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <Form
                                                                    form={form}
                                                                    name="signupForm"
                                                                    validateMessages={VALIDATE_MESSAGES}
                                                                    onFocus={() => {
                                                                        setSignUpFormIsOnFocus(true);
                                                                    }}
                                                                    onBlur={() => {
                                                                        setSignUpFormIsOnFocus(false);
                                                                        handleSignUpFormOnBlur().then(() =>{});
                                                                    }}
                                                                    autoComplete="off"
                                                                    onChange={handleSignUpFormChanges}
                                                                    onFinish={handleSignup}
                                                                    className={signupFormIsOnFocus ? 'hide-antd-error-messages' : 'relative z-0'}
                                                                >
                                                                    <Form.Item
                                                                        name="email"
                                                                        validateTrigger="onBlur"
                                                                        rules={[{ required: true, type: 'email' }]}
                                                                    >
                                                                        <div className="form-input-combo">
                                                                            <label htmlFor="email">Email</label>
                                                                            <input id="email" name="email" type="email" autoComplete="email" required placeholder="Email"/>
                                                                            <button   disabled={submitted || formHasError} type="submit" className="secondary-gradient">
                                                                    <span>
                                                                        <p>
                                                                            {submitted && (
                                                                                <i className="fa-solid fa-spinner fa-spin  mr-2"></i>
                                                                            )}
                                                                            Sign Up
                                                                        </p>
                                                                    </span>
                                                                            </button>
                                                                        </div>

                                                                    </Form.Item>

                                                                </Form>
                                                            </div>

                                                            <hgroup className="heading">
                                                                <h4>Already have an Account?</h4>
                                                                <p>Log in to continue.</p>
                                                            </hgroup>

                                                            {/* <form className="w-full stack-form-col">

                                                        <div className="form-input-combo">
                                                            <label htmlFor="email">Email or Username</label>
                                                            <input id="email" name="email" type="email" autoComplete="email" required placeholder="Email or Username"/>
                                                        </div>
                                                        <div className="form-input-combo">
                                                            <label htmlFor="password">Password</label>
                                                            <input id="password" name="password" type="password" autoComplete="current-password" required placeholder="Password"/>
                                                            <a href="fam-space-checkout-review.html" type="submit" className="button-secondary-gradient"><span><p>Login</p></span></a>
                                                        </div>
                                                    </form>  */}
                                                            <Form
                                                                form={loginForm}
                                                                name="loginForm"
                                                                validateMessages={VALIDATE_MESSAGES}
                                                                onFocus={() => {
                                                                    setLoginFormIsOnFocus(true);
                                                                }}
                                                                onBlur={() => {
                                                                    setLoginFormIsOnFocus(false);
                                                                    handleLoginFormOnBlur().then(() => {});
                                                                }}
                                                                autoComplete="off"
                                                                onChange={handleLoginFormChanges}
                                                                onFinish={handleLogin}
                                                                className={(loginFormIsOnFocus? 'hide-antd-error-messages z-0': 'otp relative z-0') +' w-full stack-form-col !p-0 !m-0'}
                                                            >
                                                                {!hasMfaEnabled && (
                                                                    <>
                                                                        {alert2 && (
                                                                            <div className="alert callout warning items-center">
                                                                                <div className="action">
                                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                                </div>
                                                                                <div className="content">
                                                                                    <div>
                                                                                        <h6>{alert2.message}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <Form.Item
                                                                            name="email"
                                                                            className={`w-full stack-form-col !p-0 !m-0`}
                                                                            validateTrigger="onBlur"
                                                                            rules={[{ required: true }]}
                                                                        >
                                                                            <div className="form-input unlabeled !p-0 !rounded-full">
                                                                                <input id="email" name="email" type="text" autoComplete="email" required placeholder="Email or Username"/>
                                                                            </div>

                                                                        </Form.Item>

                                                                    </>
                                                                )}
                                                                {!hasMfaEnabled && (
                                                                    <>
                                                                        <Form.Item
                                                                            name="password"
                                                                            className={`w-full stack-form-col !m-0`}
                                                                            validateTrigger="onBlur"
                                                                            rules={[{ required: true }]}
                                                                        >
                                                                            <div className="form-input-combo">
                                                                                <label htmlFor="password">Password</label>
                                                                                <input id="password" name="password" type="password" autoComplete="current-password" required placeholder="Password"/>
                                                                                <button  disabled={submitted || formHasError} type="submit" className="secondary-gradient">
                                                                    <span><p>
                                                                        Login</p></span>
                                                                                </button>
                                                                            </div>

                                                                        </Form.Item>

                                                                        {(loginForm.getFieldsError()[1]?.errors.length > 0) && (
                                                                            <div className="alert callout warning !m-0">
                                                                                <div className="action">
                                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                                </div>
                                                                                <div className="content">
                                                                                    <div>
                                                                                        <h6>{loginForm.getFieldsError()[1]?.errors}</h6>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}

                                                            </Form>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <a>
                                                                <div className="block-callout card stack-col">
                                                                    <hgroup className="heading">
                                                                        <h3>Thank you for signing up!</h3>
                                                                        <p>We’ve sent an email with a link to confirm your account. Please click it to continue. If you do not see it within 5 minutes, please check your spam folder.</p>
                                                                    </hgroup>
                                                                </div>
                                                            </a>
                                                            <p>No email? <a>Re-enter your email here.</a></p>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {authUserInfo && (
                                                <>
                                                    <div className="cs-checkout-review-pricing stack-col">
                                                        <hgroup className="heading">
                                                            <h2>Pricing & Terms</h2>
                                                            <p>By continuing below, you are authorizing <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                                <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                            </a> to charge your Payment Method for</p>
                                                        </hgroup>
                                                        <div className="cs-checkout-total stack-row">
                                                            <h3>
                                                                {!isSubscription && item &&
                                                                    <p className="body-text--big">
                                                                        ${(item?.price != null ? parseFloat(item?.price).toFixed(2) : 0) + ' ' + (item?.currency != null ? item?.currency : 'USD') }
                                                                    </p>
                                                                }

                                                                {isSubscription && item &&
                                                                    <>
                                                                        <p className="body-text--big">
                                                                            ${(item?.price != null ? parseFloat(item?.price) : 0).toFixed(2) + ' ' + (item?.currency != null ? item?.currency : 'USD') }
                                                                            + <br/>
                                                                        </p>
                                                                        <p className="body-text--big">
                                                                            {getExpirationMonthlyStart(selectedMonthlyPrice, autoMonthlyPrice, item?.first_benefit?.currency, expirationDate, item?.first_benefit?.included_months) ?? 'selected renew option'}
                                                                        </p>
                                                                    </>
                                                                }
                                                            </h3>
                                                            <p>One-time charge</p>
                                                        </div>
                                                        <form className="cs-checkout-form-promo stack-col">
                                                            <div className="form-input-combo">
                                                                <label htmlFor="promo">Promo Code</label>
                                                                <input id="promo" name="promo" type="text" placeholder="Promo Code (optional)"/>
                                                                <a href="#" type="submit" className="button-secondary-gradient"><span><p>Redeem</p></span></a>
                                                            </div>
                                                        </form>
                                                        <hgroup className="title">
                                                            <h3>Payment Details</h3>
                                                            <p>All major debit cards, credit cards, Apple Pay, Google Pay, and wallets are accepted. Your full card number is never accessible and only the last 4 digits are used for identification.</p>
                                                        </hgroup>
                                                        <Form
                                                            form={itemPaymentMethodForm}
                                                            name="itemPaymentMethod"
                                                            id="itemPaymentMethod"
                                                            validateMessages={VALIDATE_MESSAGES}
                                                            onFocus={() => {
                                                                setFormIsOnFocus(true);
                                                                setAlert(null);
                                                            }}
                                                            onBlur={() => {
                                                                // setTimeout(() => {
                                                                setFormIsOnFocus(false);
                                                                handleFormOnBlur().then(r => {});
                                                                // }, 200);
                                                            }}
                                                            autoComplete="off"
                                                            onChange={handleFormChanges}
                                                            onFinish={handleSubmit}
                                                            className={formIsOnFocus ? 'hide-antd-error-messages cs-checkout-terms stack-col' : ' cs-checkout-terms stack-col'}
                                                        >
                                                            {/*<div className="grid grid-cols-12 mt-5">*/}
                                                            {/*        <div className="col-span-4">*/}
                                                            {/*            <p className="m-0 body-text--small gradient-color-txt">*/}
                                                            {/*                Item Price*/}
                                                            {/*            </p>*/}
                                                            {/*        </div>*/}
                                                            {/*        <div className="col-span-8">*/}
                                                            {/*            {itemDetails != null &&*/}
                                                            {/*                <p className="m-0 body-text--small gradient-color-txt text-center">*/}
                                                            {/*                    {getMembershipItemPrice(isSubscription, itemDetails.type, itemDetails)}*/}
                                                            {/*                </p>*/}
                                                            {/*            }*/}
                                                            {/*        </div>*/}
                                                            {/*    </div>*/}
                                                            { item && (
                                                                <>
                                                                    { isSubscription &&
                                                                        <div className="grid grid-cols-12 mt-5">
                                                                            <div className="col-span-4">
                                                                                <p className="m-0 body-text--small gradient-color-txt">
                                                                                    Included Months
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-span-8">
                                                                                <p className="m-0 body-text--small gradient-color-txt text-center">
                                                                                    {getIncludedMonths(item?.first_benefit?.included_months)}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {expirationDateDeductOneDay &&
                                                                        <div className="grid grid-cols-12 mt-5">
                                                                            <div className="col-span-4">
                                                                                <p className="m-0 body-text--small gradient-color-txt">
                                                                                    Expiration Date
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-span-8">
                                                                                <p className="m-0 body-text--small gradient-color-txt text-center">
                                                                                    {expirationDateDeductOneDay.trim() !== '' ? expirationDateDeductOneDay : 'No Expiration'}

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    { isSubscription &&
                                                                        <div className="grid grid-cols-12 mt-5 flex items-center">
                                                                            <div className="col-span-4">
                                                                                <p className="m-0 body-text--small gradient-color-txt">
                                                                                    Renewal
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-span-8">
                                                                                <div className="input-group-com">
                                                                                    <DropdownWithItems
                                                                                        dropDownclassName="w-full"
                                                                                        customButtonclassName="btn-lg btn-secondary dropdown-primary"
                                                                                        title="Credit Card"
                                                                                        selectedValue={selectedMonthlyPrice}
                                                                                        listItems={autoMonthlyPrice}
                                                                                        onDropdownChange={(value) =>
                                                                                            setSelectedMonthlyPrice(value)
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )}
                                                            <fieldset>
                                                                <legend className="sr-only">Terms & Agreement</legend>

                                                                <div className="form-input-checkbox gradient md rounded row">
                                                                    <div className="checkbox">
                                                                        <input id="checkbox-space-agreement" onChange={onChangeAgreement1} aria-describedby="checkbox-space-agreement-description" name="checkbox-space-agreement" type="checkbox"/>
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="checkbox-space-agreement" className="sr-only">Space Terms</label>
                                                                        <p id="checkbox-space-agreement-description">By joining, you agree to {spaceInfo ? spaceInfo.name : ''}  <a href={routes.viewUserSpace() + spaceInfo?.username + '/terms-services'} target="_blank">Terms of Service</a>, <a href={routes.viewUserSpace() + spaceInfo?.username + '/privacy-policy'} target="_blank">Privacy Policy</a>, and <a href={routes.viewUserSpace() + spaceInfo?.username + '/community-guidelines'} target="_blank">Community Guidelines.</a></p>
                                                                    </div>
                                                                </div>

                                                                <div className="form-input-checkbox gradient md rounded row">
                                                                    <div className="checkbox">
                                                                        <input id="checkbox-cs-agreement" onChange={onChangeAgreement2} aria-describedby="checkbox-cs-agreement-description" name="checkbox-cs-agreement" type="checkbox"/>
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="checkbox-cs-agreement" className="sr-only">Space Terms</label>
                                                                        <p id="checkbox-cs-agreement-description">By continuing, you agree to the Commonspace Platforms <a href={createFullRoute(ROOT_DOMAIN, '/terms-of-use')} target="_blank">Terms of Service</a> and <a href={createFullRoute(ROOT_DOMAIN, '/privacy-policy')} target="_blank">Privacy Policy</a>.</p>
                                                                    </div>

                                                                </div>
                                                            </fieldset>

                                                            {hidePaymentMethod && item != null &&
                                                                <Form.Item>
                                                                    {/* <Button
                                                                    className="btn btn-primary btn-lg"
                                                                    type="submit"
                                                                    disabled={!agreementChecked1 || !agreementChecked2}
                                                                >
                                                                    {submitted && (
                                                                        <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                    )}
                                                                    {getButtonPaymentText(isSubscription, itemDetails.type, selectedMonthlyPrice)}
                                                                </Button> */}
                                                                    <button type="submit" className="primary-gradient w-full" disabled={!agreementChecked1 || !agreementChecked2}>
                                                                <span><p>
                                                                {submitted && (
                                                                    <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                )}
                                                                    <i className="fa-regular fa-wallet"/>{' '}
                                                                    {getButtonPaymentText(isSubscription, item?.type, selectedMonthlyPrice, item)}
                                                                </p></span></button>

                                                                </Form.Item>

                                                            }

                                                            {!hidePaymentMethod && item != null &&
                                                                <Form.Item>
                                                                    <button
                                                                        type="submit"
                                                                        className="primary-gradient w-full"
                                                                        disabled={submitted ||
                                                                            formHasError ||
                                                                            !agreementChecked1 ||
                                                                            !agreementChecked2}
                                                                    >
                                                                <span><p>
                                                                {submitted && (
                                                                    <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                                )}
                                                                    <i className="fa-regular fa-wallet"/>{' '}
                                                                    {getButtonPaymentText(isSubscription, item?.type, selectedMonthlyPrice, item)}
                                                                </p></span>
                                                                    </button>
                                                                </Form.Item>
                                                            }
                                                            <div className="stack-button-info">
                                                                <p><span>AES 256-Encrypted</span> All Payment Information is securely managed and processed by Stripe.</p>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cs-centerframe-split-right-lg order-1 lg:order-2 lg:pt-gutter lg:pb-gutter">
                                <div className="cs-checkout-product-hero">
                                    {membershipItemImage(collectionAssetType, item)}
                                </div>
                            </div>
                        </div>
                    </section>
                )}

            </main>
        </>
    );
}
export default memo(ItemPaymentMethod);