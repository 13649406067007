import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import space_ex1 from '../../../assets/images/space_ex1.png';
import space_ex2 from '../../../assets/images/space_ex2.png';
import space_ex3 from '../../../assets/images/space_ex3.png';
import space_ex4 from '../../../assets/images/space_ex4.png';
import {updateLastStepLink} from "../../../services/space";

const LaunchCheckList = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const saveContinue = () => {
		const url = routes.createSpaceAddBusiness();
		navigate(url.replace(':space_username', space_username));
	};

	const handleUpdateLastStepLink = useCallback(async () => {
			const data = {};
			// include last step link data for continuing the space creation
			const url = routes.createSpaceAddBusiness();
			data.last_step_link = url.replace(':space_username', space_username);
			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					const response = await updateLastStepLink(data);
					if (response && response.result) {
						saveContinue();
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		[space_username, authUserInfo, spaceInfo]
	);

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	useEffect(()=>{
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},[])

	return (
		<>
			<main id="cs-platform-main" className="cs-main main-theme">
				<section id="cs-platform-create-start-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="block-xl">
							<hgroup className="heading">
								<h1>Launching Your Space</h1>
								<p>
									Once your Space is set up, you can launch it for people to access
									and for Members to join. Your Space is a site for content, commerce,
									and community, and requires certain steps to be completed to comply
									with privacy and payment regulations. By completing the steps below,
									you’ll be able to launch your Space and start inviting Members,
									transacting, and growing your community and business.
								</p>
							</hgroup>
							<hgroup className="heading">
								<h2>Launch Steps</h2>
								<p>
									Here are the{' '}
									{getSitewideLink('steps_complete_launch_space')}
									:
								</p>
							</hgroup>
							<ol>
								<li>
									<p>
										{getSitewideLink('complete_business_details')} {' '}Provide your business information
										and locale settings.
									</p>
								</li>
								<li>
									<p>
										{getSitewideLink('add_information_pages')} {' '} Create your About and Support pages to
										help Members, and add your Privacy and Terms of Service that
										users will agree to.
									</p>
								</li>
								<li>
									<p>
										{getSitewideLink('set_up_payout_methods')} {' '} Select and set up your Payout Methods to
										accept credit card and other payments from sales of Memberships
										and Items.
									</p>
								</li>
								<li>
									<p>
										{getSitewideLink('add_billing_details')} {' '} Enter your payment details for selected
										Commonspace Plan.
									</p>
								</li>
								<li>
									<p>
										{getSitewideLink('review_launch_space')} {' '} Preview and check your Space before
										making it live.
									</p>
								</li>
							</ol>

							<p>
								Once you’ve completed these steps, you’ll be able to launch your
								Space!
							</p>
							<h3>Ready to complete and launch your Space?</h3>
							<a
								className="button-primary-gradient"
								onClick={handleUpdateLastStepLink}
							>
								<span><p>Let's go!</p></span>
							</a>

							<p><i>Need help?</i> {getSitewideLink('get_support', '')}</p>
						</article>

						<aside className="block-md">
							<div className="stack-row">

								<div className="stack-col">
									<div className="cs-aside-image">
										<img src={space_ex1}/>
									</div>
									<div className="cs-aside-image">
										<img src={space_ex3}/>
									</div>
								</div>

								<div className="stack-col">
									<div className="cs-aside-image">
										<img src={space_ex2}/>
									</div>
									<div className="cs-aside-image">
										<img src={space_ex4}/>
									</div>
								</div>
							</div>
						</aside>
					</div>
				</section>
			</main>
			{/*<main className="dashboard-wrapper flex">*/}
			{/*	<div className="container !py-20">*/}
			{/*		<div className="grid grid-cols-1">*/}
			{/*			<div className="col-span-1">*/}
			{/*				<h1 className="header-large">Launching Your Space</h1>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-1 grid grid-cols-1 md:grid-cols-12 gap-3 mt-4">*/}
			{/*				<div className="md:col-span-7 order-last md:order-first">*/}
			{/*					<p className="body-text--reg">*/}
			{/*						Once your Space is set up, you can launch it for people to access*/}
			{/*						and for Members to join. Your Space is a site for content, commerce,*/}
			{/*						and community, and requires certain steps to be completed to comply*/}
			{/*						with privacy and payment regulations. By completing the steps below,*/}
			{/*						you’ll be able to launch your Space and start inviting Members,*/}
			{/*						transacting, and growing your community and business.*/}
			{/*					</p>*/}
			{/*					<h3 className="header-medium mt-5">Launch Steps</h3>*/}
			{/*					<p className="body-text--reg">*/}
			{/*						Here are the{' '}*/}
			{/*						<a className="gradient-color-txt body-txtitalic--reg">*/}
			{/*							steps to complete and launch your Space*/}
			{/*						</a>*/}
			{/*						:*/}
			{/*					</p>*/}
			{/*					<ol className="mt-5">*/}
			{/*						<li className="body-text--reg">*/}
			{/*							Complete your Business Details Provide your business information*/}
			{/*							and locale settings.*/}
			{/*						</li>*/}
			{/*						<li className="body-text--reg">*/}
			{/*							Add Information Pages Create your About and Support pages to*/}
			{/*							help Members, and add your Privacy and Terms of Service that*/}
			{/*							users will agree to.*/}
			{/*						</li>*/}
			{/*						<li className="body-text--reg">*/}
			{/*							Set up Payout Methods Select and set up your Payout Methods to*/}
			{/*							accept credit card and other payments from sales of Memberships*/}
			{/*							and Items.*/}
			{/*						</li>*/}
			{/*						<li className="body-text--reg">*/}
			{/*							Add Billing Details Enter your payment details for selected*/}
			{/*							Commonspace Plan.*/}
			{/*						</li>*/}
			{/*						<li className="body-text--reg">*/}
			{/*							Review and Launch Your Space Preview and check your Space before*/}
			{/*							making it live.*/}
			{/*						</li>*/}
			{/*					</ol>*/}
			{/*					<p className="body-text--reg mt-5">*/}
			{/*						Once you’ve completed these steps, you’ll be able to launch your*/}
			{/*						Space!*/}
			{/*					</p>*/}
			{/*					<h5 className="header-small">*/}
			{/*						Ready to complete and launch your Space?*/}
			{/*					</h5>*/}
			{/*					<Button*/}
			{/*						onClick={saveContinue}*/}
			{/*						className="btn btn-lg btn-primary mt-5"*/}
			{/*						type="submit"*/}
			{/*					>*/}
			{/*						Let's Go*/}
			{/*					</Button>*/}
			{/*					<p className="body-text--reg mt-5">*/}
			{/*						Need help?{' '}*/}
			{/*						/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*						/!*	Get support*!/*/}
			{/*						/!*</Link>*!/*/}
			{/*						{getSitewideLink(*/}
			{/*							'get_support',*/}
			{/*							'gradient-color-txt body-txtitalic--reg'*/}
			{/*						)}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-5 order-first md:order-last">*/}
			{/*					<div className="grid grid-cols-2 gap-3">*/}
			{/*						<div className="col-span-1">*/}
			{/*							<img className="img-fluid mx-auto" src={space_ex1} />*/}
			{/*						</div>*/}
			{/*						<div className="col-span-1 text-end">*/}
			{/*							<img className="img-fluid mx-auto" src={space_ex2} />*/}
			{/*							<img className="img-fluid mt-5 mx-auto" src={space_ex3} />*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-2">*/}
			{/*						<div className="w-full">*/}
			{/*							<img className="img-fluid mt-5 mx-auto" src={space_ex4} />*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</main>*/}
		</>
	);
};

export default memo(LaunchCheckList);
