import { memo, useEffect, useState, useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import { createSpaceBenefit, getSpaceAccessGroups } from '../../../../services/space';
import { useDispatch, useSelector } from 'react-redux';
import AccessGroupModal from "../../../../components/commons/Modals/AccessGroupModal/AccessGroupModal";
import DropdownWithItems from "../../../../components/commons/DropdownWithItems"
import CreateAccessGroupModal
    from "../../../../components/commons/Modals/CreateAccessGroupModal/CreateAccessGroupModal";
import {getSitewideLink} from "../../../../utils/sitewideLinks";
import routes from "../../../../routes";
import {useNavigate} from "react-router-dom";
import {setBenefit} from "../../../../states/benefitsSlice";



const CreateAccessBenefit = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [createBenefitForm] = Form.useForm();
    const [formHasError, setFormHasError] = useState(true);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [accessGroupList, setAccessGroupList] = useState([]);
    const [accessGroupModalOpen, setAccessGroupModalOpen] = useState(false);
    const [selectedAccessGroups, setSelectedAccessGroups] = useState([]);
    const [selectedAccessGroup, setSelectedAccessGroup] = useState([]);
    const [createAccessGroupModalOpen, setCreateAccessGroupModalOpen] = useState(false);
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [selectedButtonSetting, setSelectedButtonSetting] = useState('default');
    const [filteredAccessGroupList, setFilteredAccessGroupList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [alert, setAlert] = useState(null);

    const dummyButtonOptions = [
        { label: 'Default', value: 'default' },
        // { label: 'Benefits Post', value: 'benefits_post' },
        { label: 'Custom URL', value: 'custom_url' }
    ];

    const handleFormOnBlur = useCallback(async () => {
        await createBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const updateAccessGroup = (group) => {
        getSpaceAccessGroupsRequest().then(r => {});
        selectedAccessGroups.push({id: group.id, label: group.name, value: group.id});
    }

    const handleSubmit = useCallback(
        async (values) => {
            const data = { ...createBenefitForm.getFieldsValue(), space_id: spaceInfo.id };
            data['type'] = 'access';

            const response = await createSpaceBenefit(data);
            if (response) {
                if (response.result) {
                    dispatch(setBenefit(response.benefit));
                    redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@');
                } else {
                    setAlert({ variant: 'danger', message: response.message });
                }
            }
        });

    const handleFormChanges = useCallback(async () => {
        await createBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const getSpaceAccessGroupsRequest = useCallback(
        async () => {
            try {
                if (spaceInfo) {
                    const space_param = {}
                    space_param.space_id = spaceInfo.id;

                    const responseGetSpaceAccessGroups = await getSpaceAccessGroups(space_param);
                    if (responseGetSpaceAccessGroups && responseGetSpaceAccessGroups.result) {
                        let groupArray = [];
                        const groupList = responseGetSpaceAccessGroups.access_groups;
                        if (groupList && groupList.length > 0) {
                            groupList.map((group) => {
                                groupArray.push({id: group.id, label: group.name, value: group.id})
                            })
                        }
                        setAccessGroupList(groupArray);
                    }
                }
            } catch (error) {
            }
        },
        [spaceInfo]
    );

    const redirectToSpaceLink = (route, spaceUsername, prepend = '') => {
        setTimeout(()=>{
            navigate(route.replace(':space_username', prepend + spaceUsername));
        },[1500])
    };

    useEffect(() => {
        let groupList = [
            { label: 'Select Access Group', value: 'default', disabled: true },
            { label: 'Create New Access Group', value: 'create' }
        ];
        if (accessGroupList && accessGroupList.length > 0) {
            accessGroupList.map((accessGroup) => {
                groupList.push(accessGroup);
            })
        }
        // if (selectedAccessGroups && selectedAccessGroups.length > 0) {
        //     selectedAccessGroups.map((selectedAccessGroup) => {
        //         groupList.map((gList) => {
        //             if (selectedAccessGroup.value === gList.value)
        //                 groupList.shift(accessGroup);
        //         })
        //     })
        // }
        setFilteredAccessGroupList(groupList);
    }, [accessGroupList]);

    const filterDropdownChange = (value) => {
        if (value === 'create') {
            setCreateAccessGroupModalOpen(true);
            setModalOpen(false);
        } else {
            let selected = selectedAccessGroups;
            const selectedGroup = selectedAccessGroups.find((access) => access.value === value);
            const access = accessGroupList.find((access) => access.value === value);
            setSelectedAccessGroup(access.id)
            setSelectedAccessGroups(selected);
            createBenefitForm.setFieldsValue({ access_settings: access.id });
        }
    };

    const handleButtonSettingChange = (value) => {
        setSelectedButtonSetting(value)
        createBenefitForm.setFieldsValue({ button_settings: value });
    };

    useEffect(()=>{
        if (createBenefitForm) {
            createBenefitForm.setFieldsValue({ button_settings: 'default' });
        }
    },[])

    useEffect(() =>{
        if (spaceInfo) {
            getSpaceAccessGroupsRequest().then(r => {});
        }
    },[spaceInfo]);

    useEffect(() =>{},[accessGroupList, createBenefitForm])


    return (
        <div className="cs-dashframe section-flex">
            <div className="cs-dash-card block-full card">
                <hgroup className="heading">
                    <h2>Create New Access Benefit</h2>
                    <p>An <a href="#link-via-admin" target="_blank">Access Benefit</a> is a type of Benefit which allows you to provide access to specific content on your Space. You can apply this Benefit to any Memberships or Items and Members with that specific Membership or Item will gain access to the specified content. This is different than standard Members Only access, which provides access to associated content to Members with <span className="italic">any</span> Membership. <a href="#link-via-admin" target="_blank">Learn more about the Access Benefit Type.</a></p>
                    {/*<p>An <a href="#link-via-admin" target="_blank">Access</a> Benefit is a type of Benefit which allows you to provide access to specific content on your Space. You can apply this Benefit to any Memberships or Items and Members with that specific Membership or Item will gain access to the specified content. This is different than standard Members Only access, which provides access to associated content to Members with any Membership. {getSitewideLink('learn_more_benefits')}</p>*/}
                </hgroup>
            </div>
            <div className="cs-dash-card block-full card">
                <Form
                    form={createBenefitForm}
                    name="createBenefitForm"
                    id="createBenefitForm"
                    validateMessages={VALIDATE_MESSAGES}
                    onFocus={() => {
                        setFormIsOnFocus(true);
                    }}
                    onBlur={() => {
                        // setTimeout(() => {
                        setFormIsOnFocus(false);
                        handleFormOnBlur().then(r => {});
                        // }, 200);
                    }}
                    onChange={handleFormChanges}
                    onFinish={handleSubmit}
                    className={formIsOnFocus ? 'hide-antd-error-messages stack-col' : 'stack-col'}
                >
                    <>
                        {alert && (
                            <div className="grid grid-cols-12 gap-8 mt-5">
                                <div className="col-span-8">
                                    <Alert
                                        key={alert.variant}
                                        variant={alert.variant}
                                        className={
                                            'custom-alert ' +
                                            (alert.variant === 'danger'
                                                ? 'custom-alert-danger'
                                                : 'custom-alert-success')
                                        }
                                    >
                                        {alert.message}
                                    </Alert>
                                </div>
                            </div>
                        )}
                    </>
                    {/* <form className="stack-col"> */}
                    <div className="formrow title">
                        <h3>Details</h3>
                        <p>Provide Benefit information</p>
                    </div>
                    <Form.Item
                        label="Name"
                        name="name"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable"><span><label htmlFor="name">Name</label>
                                        <textarea id="name" name="name" maxLength={32} placeholder="Up to 32 characters."/></span>
                            </div>
                            <div><p className='text-sm text-color-secondary italic'>This will be listed on the Product page of Memberships or Items with this Benefit.</p></div>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="Summary"
                        name="details"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable"><span><label htmlFor="details">Details</label>
                                    <textarea id="details" name="details" maxLength={160} placeholder="Up to 160 characters."/></span></div>
                            <div><p className='text-sm text-color-secondary italic'>This Summary text with display next to the Benefit Name and should communicate what the Benefit provides.</p></div>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="Note"
                        name="note"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable"><span><label htmlFor="note">Note</label>
                                <textarea id="note" name="note" maxLength={160} placeholder="Optional, up to 160 characters."/></span></div>
                            <div><p className='text-sm text-color-secondary italic'>This private text is shown to Members as a interstitial, pop-up, or content before the Benefit is accessed.</p></div>
                        </div>
                    </Form.Item>

                    <div className="formrow title">
                        <h4>Settings</h4>
                        <p>Configure the functions for this Benefit type</p>
                    </div>
                    <Form.Item
                        label="Access Settings"
                        name="access_settings"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            {/* <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-key"></i><p>Select Access Group</p><i className="fa-solid fa-caret-down"></i></span></button> */}
                            <DropdownWithItems
                                title="Select Access Group"
                                customButtonClass="button gradient secondary dropdown w-full"
                                selectedValue={selectedAccessGroup}
                                listItems={filteredAccessGroupList}
                                onDropdownChange={(value) => { filterDropdownChange(value) }}
                            />
                            <div><p  className='text-sm text-color-secondary italic'>Memberships or Items with this Benefit will be added to the specified Benefit Group.</p></div>
                        </div>
                        {accessGroupModalOpen && (
                            <AccessGroupModal
                                modalOpen={accessGroupModalOpen}
                                setModalOpen={setAccessGroupModalOpen}
                                accessGroupList={accessGroupList}
                                selectedAccessGroups={selectedAccessGroups}
                                setSelectedAccessGroups={setSelectedAccessGroups}
                                setCreateAccessGroupModalOpen={setCreateAccessGroupModalOpen}
                                header_title={''}
                                spaceID={spaceInfo.id}
                                userID={authUserInfo.id}
                            />
                        )}
                        {createAccessGroupModalOpen && (
                            <CreateAccessGroupModal
                                modalOpen={createAccessGroupModalOpen}
                                setModalOpen={setCreateAccessGroupModalOpen}
                                spaceMembership={spaceInfo.memberships}
                                spaceItems={spaceInfo.items}
                                updateAccessGroupList={updateAccessGroup}
                                setAccessGroupModalOpen={setAccessGroupModalOpen}
                                spaceID={spaceInfo.id}
                                userID={authUserInfo.id}
                            />
                        )}
                    </Form.Item>

                    <div className="formrow title">
                        <h4>Button</h4>
                        <p>Set the button behavior for this Benefit</p>
                    </div>
                    <Form.Item
                        label="Button Settings"
                        name="button_settings"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            {/* <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-key"></i><p>Default</p><i className="fa-solid fa-caret-down"></i></span></button> */}
                            <DropdownWithItems
                                title="Select Button Setting"
                                customButtonClass="button gradient secondary dropdown w-full"
                                selectedValue={selectedButtonSetting}
                                listItems={dummyButtonOptions}
                                onDropdownChange={(value) => { handleButtonSettingChange(value) }}
                            />
                            <div><p className='text-sm text-color-secondary italic'>Default directly accesses the Benefit. Benefits Post links to a selected post. Custom URL opens a specified link.</p></div>
                        </div>
                    </Form.Item>

                    {selectedButtonSetting === 'custom_url' && (
                        <Form.Item
                            label="Custom URL"
                            name="custom_url"
                            className="cs-stack-controlrows stack-form-col"
                            validateTrigger="onBlur"
                            rules={[{ required: true }]}
                        >
                            <div className="formrow split">
                                <div className="form-input gradient"><span><label htmlFor="name">Custom URL</label>
                                        <input id="custom_url" name="text" type="text" required=""/></span>
                                </div>
                                <div><p className='text-sm text-color-secondary italic'>Redirect URL</p></div>
                            </div>
                        </Form.Item>
                    )}

                    {/* <Form.Item className="stack-buttons col">
                            <button
                                className="primary-gradient w-full"
                                onClick={handleSubmit}
                            >
                                <span><p>Create Benefit</p></span>
                            </button>
                            <a href="#"  onClick={()=>onCancelCreate(false)} className="button gradient secondary"><span><p>Cancel</p></span></a>
                        </Form.Item> */}
                    <div className="stack-buttons col">
                        <button type={"submit"} className="button gradient"><span><p>Create Benefit</p></span></button>
                        <a onClick={()=>{redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@')}} className="button gradient secondary"><span><p>Cancel</p></span></a>
                    </div>
                </Form>
            </div>
        </div>
    )
};

export default memo(CreateAccessBenefit);