import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import routes from '../../../routes';

import space_ex1 from '../../../../src/assets/images/space_ex1.png';
import space_ex2 from '../../../../src/assets/images/space_ex2.png';
import space_ex3 from '../../../../src/assets/images/space_ex3.png';
import space_ex4 from '../../../../src/assets/images/space_ex4.png';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {getSitewideLink} from "../../../utils/sitewideLinks";
import {createSpaceAddBenefit, updateLastStepLink} from "../../../services/space";

const SpaceCreateBasicCompleteView = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const continueNextPage = () => {
		const url = routes.createSpaceAddFirstItemPage();
		navigate(url.replace(':space_username', space_username));
	};

	const handleUpdateLastStepLink = useCallback(async () => {
			const data = {};
			// include last step link data for continuing the space creation
			const url = routes.createSpaceAddFirstItemPage();
			data.last_step_link = url.replace(':space_username', space_username);
			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					const response = await updateLastStepLink(data);
					if (response && response.result) {
						continueNextPage();
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		[space_username, authUserInfo, spaceInfo]
	);

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(()=>{
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},[])

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
		<>
			<main  id="cs-platform-main" className="cs-main main-theme">
				<section id="cs-platform-create-start-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<article className="block-xl">
							<hgroup className="heading">
								<h1>Basic Space Complete!</h1>
								<p>Congrats! Your{' '}
									{getSitewideLink('basic_space_complete')}
									{' '}is created and ready to finalize and launch! Before you do, we
									recommend you add some additional content and configure your
									community features first. You can also choose to add your own domain
									and custom branding by upgrading to a{' '}
									{getSitewideLink('pro_space')}
									{' '}as well. You can preview your Space then check out the optional
									steps below!
								</p>
							</hgroup>
							<a href={routes.viewUserSpace() + space_username} className="button-secondary-gradient w-full "><span><p>Preview Your Space <i
								className="fa-regular fa-arrow-up-right-from-square i-r"></i></p></span></a>

							<hgroup className="heading">
								<h2>Recommended Steps</h2>
								<p>The following optional steps guide you through adding additional
									content and customizing your Space:</p>
							</hgroup>
							<ul>
								<li>
									{getSitewideLink('pro_create_item', ' ')}
									{' '}Offer a free or paid digital Item that gives Members additional
									Benefits or upgraded access.
								</li>
								<li>
									{getSitewideLink('post_update_basic_complete', ' ')}
									{' '}Welcome visitors with a public post or make a Members Only post
									to guide your first Members.
								</li>
								<li>
									{getSitewideLink('enable_community_features_basic_complete', ' ')}
									{' '}Set up and customize your Community Channels, Points System, and
									Leaderboard.
								</li>
								<li>
									{getSitewideLink('upgrade_pro_basic_complete', ' ')}
									{' '}Connect your domain, get custom branding, and other Pro features
									by upgrading your Space.
								</li>
								<li>
									{getSitewideLink('invite_team_basic_complete', ' ')}
									{' '}Give your team special access to your Space to manage, moderate,
									and post.
								</li>
							</ul>

							<p><i>Each additional step can be skipped and you can always access them
								later from your Dashboard.</i></p>

							<button className="primary-gradient w-full" onClick={handleUpdateLastStepLink}><span><p>Continue</p></span></button>

							<p><i>Need help?</i> {getSitewideLink('get_support', ' ')}</p>
						</article>

						<aside className="block-md">
							<div className="stack-row">

								<div className="stack-col">
									<div className="cs-aside-image">
										<img src={space_ex1}/>
									</div>
									<div className="cs-aside-image">
										<img src={space_ex3}/>
									</div>
								</div>

								<div className="stack-col">
									<div className="cs-aside-image">
										<img src={space_ex2}/>
									</div>
									<div className="cs-aside-image">
										<img src={space_ex4}/>
									</div>
								</div>
							</div>
						</aside>
					</div>
				</section>
			</main>

			{/*<main className="dashboard-wrapper flex">*/}
			{/*	<div className="container !py-20">*/}
			{/*		<div className="grid grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">*/}
			{/*			<div className="col-span-12">*/}
			{/*				<h1 className="header-large">Basic Space Complete!</h1>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12  grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5">*/}
			{/*				<div className="md:col-span-7 grid max-[768px]:order-last">*/}
			{/*					<p className="body-text--reg">*/}
			{/*						Congrats! Your{' '}*/}
			{/*						/!*<Link to="#" className="gradient-color-txt">*!/*/}
			{/*						/!*	Basic Space*!/*/}
			{/*						/!*</Link>*!/*/}
			{/*						{getSitewideLink('basic_space_complete')}*/}
			{/*						{' '}is created and ready to finalize and launch! Before you do, we*/}
			{/*						recommend you add some additional content and configure your*/}
			{/*						community features first. You can also choose to add your own domain*/}
			{/*						and custom branding by upgrading to a{' '}*/}
			{/*						/!*<Link to="#" className="gradient-color-txt">*!/*/}
			{/*						/!*	Pro Space*!/*/}
			{/*						/!*</Link>*!/*/}
			{/*						{getSitewideLink('pro_space')}*/}
			{/*						{' '}as well. You can preview your Space then check out the optional*/}
			{/*						steps below!*/}
			{/*					</p>*/}
			{/*					<Link*/}
			{/*						to={routes.viewUserSpace() + space_username}*/}
			{/*						target="_blank"*/}
			{/*						className="btn btn-lg btn-secondary mt-5"*/}
			{/*					>*/}
			{/*						Preview Your Space{' '}*/}
			{/*						<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*/}
			{/*					</Link>*/}
			{/*					<h3 className="header-medium mt-5">Recommended Steps</h3>*/}
			{/*					<p className="body-text--reg">*/}
			{/*						The following optional steps guide you through adding additional*/}
			{/*						content and customizing your Space:{' '}*/}
			{/*					</p>*/}
			{/*					<ul className="mt-5">*/}
			{/*						<li className="body-text--reg">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	Pro Create Your First Item*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink('pro_create_item', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*							{' '}Offer a free or paid digital Item that gives Members additional*/}
			{/*							Benefits or upgraded access.*/}
			{/*						</li>*/}
			{/*						<li className="body-text--reg">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	Post Your First Update*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink('post_update_basic_complete', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*							{' '}Welcome visitors with a public post or make a Members Only post*/}
			{/*							to guide your first Members.*/}
			{/*						</li>*/}
			{/*						<li className="body-text--reg">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	Enable Community Features*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink('enable_community_features_basic_complete', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*							{' '}Set up and customize your Community Channels, Points System, and*/}
			{/*							Leaderboard.*/}
			{/*						</li>*/}
			{/*						<li className="body-text--reg">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	Upgrade to Pro*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink('upgrade_pro_basic_complete', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*							{' '}Connect your domain, get custom branding, and other Pro features*/}
			{/*							by upgrading your Space.*/}
			{/*						</li>*/}
			{/*						<li className="body-text--reg">*/}
			{/*							/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*							/!*	Invite Your Team*!/*/}
			{/*							/!*</Link>*!/*/}
			{/*							{getSitewideLink('invite_team_basic_complete', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*							{' '}Give your team special access to your Space to manage, moderate,*/}
			{/*							and post.*/}
			{/*						</li>*/}
			{/*					</ul>*/}
			{/*					<p className="body-text--reg mt-5">*/}
			{/*						Each additional step can be skipped and you can always access them*/}
			{/*						later from your Dashboard.*/}
			{/*					</p>*/}
			{/*					<Button*/}
			{/*						type="submit"*/}
			{/*						className="btn btn-lg btn-primary mt-5"*/}
			{/*						onClick={continueNextPage}*/}
			{/*					>*/}
			{/*						Continue*/}
			{/*					</Button>*/}
			{/*					<p className="body-text--reg mt-5">*/}
			{/*						Need help?{' '}*/}
			{/*						/!*<Link to="#" className="gradient-color-txt body-txtitalic--reg">*!/*/}
			{/*						/!*	Get support*!/*/}
			{/*						/!*</Link>*!/*/}
			{/*						{getSitewideLink('get_support', 'gradient-color-txt body-txtitalic--reg')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-5 max-[768px]:order-first">*/}
			{/*					<div className="grid grid-cols-12  gap-y-8 md:gap-y-0 gap-2  md:gap-8">*/}
			{/*						<div className="col-span-12 md:col-span-6">*/}
			{/*							<img className="img-fluid mx-auto" src={space_ex1} />*/}
			{/*						</div>*/}
			{/*						<div className="col-span-12 md:col-span-6 text-end">*/}
			{/*							<img className="img-fluid mx-auto" src={space_ex2} />*/}
			{/*							<img className="img-fluid mt-5 mx-auto" src={space_ex3} />*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="grid grid-cols-12  gap-y-8 md:gap-y-0  gap-2   md:gap-8">*/}
			{/*						<div className="col-span-12">*/}
			{/*							<img className="img-fluid mt-5 mx-auto" src={space_ex4} />*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</main>*/}
		</>
	);
};

export default memo(SpaceCreateBasicCompleteView);
