
/************************************
 *                                  *
 *                                  *
 *        Membership and Item       *
 *                                  *
 *             Utilities            *
 *                                  *
 *                                  *
 ************************************/


import React from "react";
import {getLocalStorage} from "./storage";
import {STORAGE_KEYS} from "../constants/common";
import routes from "../routes";

/** TODO-MJ: Update this enumeration for checking the checkout flow
 * Enumeration of checkout flow
 * @type {{claimNFTWithNoSubscriptionExistingUserMemberToSpace: number,
 * claimNFTWithSubscriptionExistingUserNewToSpace: number,
 * freeWithSubscription: number, paidWithNoSubscription: number,
 * paidWithSubscription: number, freeWithNoSubscription: number,
 * claimNFTFreeNewUser: number, claimNFTWithNoSubscriptionExistingUserNewToSpace: number}}
 */
export const checkoutFlow = {
    paidWithNoSubscription: 0,
    paidWithSubscription: 1,
    freeWithSubscription: 2,
    freeWithNoSubscription: 3,
    claimNFTWithNoSubscriptionExistingUserNewToSpace: 4,
    claimNFTWithNoSubscriptionExistingUserMemberToSpace: 5,
    claimNFTWithSubscriptionExistingUserNewToSpace: 6,
    claimNFTFreeNewUser: 7,
}


/**
 * Enumeration of types of payment
 * @type {{recurring: string, onetime: string}}
 */
export const PaymentType = {
    free: 'free',
    onetime: 'one_time',
    subscription: 'subscription'
}

/**
 * Enumeration of types of renewal
 * @type {{recurring: value}}
 */
export const RenewalOptions = {
    monthly: 'renewal_monthly',
    quarterly: 'renewal_quarterly',
    biannually: 'renewal_biannually',
    annually: 'renewal_annually',
    renewal_none: 'renewal_none'
}

/**
 * Enumeration of currencies
 * @type {{USD: string}}
 */
export const CurrencyType = {
    USD : 'USD'
}

/**
 *
 * Membership/Item Utility class
 *
 */
export class MembershipItemUtils extends React.Component {

    /**
     * Redirect user to login page if not authenticated
     * @param {string} loading
     * @param {object} authUserInfo
     */
    static notAuthRedirect = (loading, authUserInfo) => {
        const accessToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)));
        const refreshToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN)));
        if (accessToken && refreshToken) {
            if (loading === 'done' && !authUserInfo) {
                // window.location.replace(routes.login());
            }
        } else {
            // window.location.replace(routes.login());
        }
    }

    /**
     * Returns true if the user is authenticated
     * @param {string} loading
     * @param {object} authUserInfo
     * @returns {boolean}
     */
    static isOAuth = (loading, authUserInfo) => {
        const accessToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)));
        const refreshToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN)));
        if (accessToken && refreshToken) {
            if (loading === 'done' && !authUserInfo)
                return false;
        } else {
           return false;
        }
        return true;
    }
}

/**
 * getPaymentType function will return the type string of Payment Type for Membership and Items
 * @param itemMembershipObj
 * @returns {string}(free, onetime, subscription)
 */
export const getPaymentType = (itemMembershipObj = {}) => {
    if (itemMembershipObj) {
        let price = itemMembershipObj.price ?? 0;
        let isSubscription = itemMembershipObj.enable_expiration_and_subscription;
        // Check if the price is a string
        if (typeof price === 'string') {
            // Convert the string to a float
            price = parseFloat(price);
        }
        if (isSubscription) {
            return PaymentType.subscription;
        }
        if (price === 0) {
            return PaymentType.free;
        } else {
            return PaymentType.onetime;
        }
    } else {
        // return default free set a default value
        return PaymentType.free;
    }
}

/***
 * Returns the lowest existing monthly price in the membership
 * @param membership
 * @returns {number}
 */

export const getMembershipMonthlyDisplay = (membership) => {
    let benefit = membership?.first_benefit;
    let displayPrice = 0;
    let monthCount = 0;
    if (benefit) {
        const monthly = benefit.monthly_price;
        const threeMonthRenewal = benefit.three_month_renewal;
        const sixMonthRenewal = benefit.six_month_renewal;
        const twelveMonthRenewal = benefit.twelve_month_renewal;
        if (twelveMonthRenewal) {
            displayPrice = twelveMonthRenewal;
            monthCount = 12;
        }
        if (sixMonthRenewal) {
            displayPrice = sixMonthRenewal;
            monthCount = 6;
        }
        if (threeMonthRenewal) {
            displayPrice = threeMonthRenewal;
            monthCount = 3;
        }
        if (monthly) {
            displayPrice = monthly;
            monthCount = 1;
        }
    }
    return benefit ? '+ $'+ (parseFloat(displayPrice).toFixed(2) ?? 0) + `/ ${monthCount} month${monthCount > 1 ? 's': ''} or less` : '';
}


