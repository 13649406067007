import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import ReactQuill from 'react-quill';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES, IMAGE_LARGE_MAX_HEIGHT, IMAGE_LARGE_MAX_WIDTH, IMAGE_LARGE_MIN_HEIGHT, IMAGE_LARGE_MIN_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH, IMAGE_VIDEO_FILE_SIZE_LIMIT,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	ADD_CREATE_BENEFIT,
	CONTENT_TYPES_LIST_WITH_DEFAULT, MEMBERSHIP_ITEM_VISIBILITY,
	MEMBERSHIP_TYPES, PRICING_ITEM_TYPES_LIST, PRICING_TYPES_LIST, SELECT_CURRENCIES,
	UPDATES_VISIBILITY
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { getListIcon } from '../../../../utils/list';
import TextareaAutosize from 'react-textarea-autosize';
import { useSelector } from 'react-redux';
import {checkItemShortCodeAvailability, checkMembershipShortCodeAvailability, updateSpaceItem} from '../../../../services/space';
import routes from '../../../../routes';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import ExplicitContent from '../../../../components/commons/Modals/ExplicitContent';
import { getSitewideLink } from '../../../../utils/sitewideLinks';
import { CustomValidators } from '../../../../utils/CustomValidators';
import UploadDraggerV2 from "../../../../components/commons/UploadDraggerV2";
import {RenewalOptions} from "../../../../utils/MembershipItemUtils";

const EditItemCommunity = (props) => {

	const { itemData, benefitData, getItemDataRequest } = props;
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [editItemForm] = Form.useForm();
	// const [selectedVisibility, setSelectedVisibility] = useState('members');

	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [enableGiftingChecked, setGiftingChecked] = useState(false);
	const [enableAddNumericChecked, setAddNumericChecked] = useState(false);
	const [itemImage, setItemImage] = useState(null);
	const [itemImageBlobPreview, setItemImageBlobPreview] = useState(null);
	const [itemVideo, setItemVideo] = useState(null);
	const [itemVideoBlobPreview, setItemVideoBlobPreview] = useState(null);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);

	const [formIsOnFocusItem, setformIsOnFocusItem] = useState(false);
	const [formHasErrorItem, setFormHasErrorItem] = useState(true);
	// const [addCreateBenefit, setAddCreateBenefit] = useState('default');

	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);

	const [itemImageError, setItemImageError] = useState(null);
	const [itemVideoError, setItemVideoError] = useState(null);

	const [enableNumericCount, setEnableNumericCount] = useState(false);
	const [itemName, setItemName] = useState(null);
	const [itemSummary, setItemSummary] = useState(null);
	const [itemListingText, setItemListingText] = useState(null);
	const [itemDescription, setItemDescription] = useState(null);
	const [itemSKUShortcode, setItemSKUShortcode] = useState(null);
	const [itemIndividualTitling, setItemIndividualTitling] = useState(null);
	const [itemPurchasePrice, setItemPurchasePrice] = useState(null);
	const [itemTotalAvailable, setItemTotalAvailable] = useState(null);
	const [itemAccountLimit, setItemAccountLimit] = useState(null);
	const [itemIncludedMonths, setItemIncludedMonths] = useState(null);
	const [itemOneMonth, setItemOneMonth] = useState(null);
	const [itemThreeMonths, setItemThreeMonths] = useState(null);
	const [itemSixMonths, setItemSixMonths] = useState(null);
	const [itemTwelveMonths, setItemTwelveMonths] = useState(null);
	const [itemPurchasePassword, setItemPurchasePassword] = useState(null);

	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [selectedPricingType, setSelectedPricingType] = useState('default');
	const [selectedCurrencyType, setSelectedCurrencyType] = useState('USD');
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedVisibilityType, setSelectedVisibilityType] = useState('public');

	const [blurredField, setBlurredField] = useState(null);
	const [isSubmitClicked, setSubmitClicked] = useState(false);

	const debounceTimeoutRef = useRef(null);
	const [forceRender, setForceRender] = useState(false); // State to trigger re-render

	const handleReRender = () => {
		setForceRender(!forceRender); // Toggle the state to trigger re-render
	};

	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		[{size: ['small', false, 'large', 'huge']}], // custom dropdown
		[{font: []}],
		[{header: 1}, {header: 2}], // custom button values
		[{header: [1, 2, 3, 4, 5, 6, false]}],
		['blockquote', 'code-block'],
		['link', 'image', 'video', 'formula'],
		[{list: 'ordered'}, {list: 'bullet'}],
		[{script: 'sub'}, {script: 'super'}], // superscript/subscript
		[{indent: '-1'}, {indent: '+1'}], // outdent/indent
		[{direction: 'rtl'}], // text direction
		[{color: []}, {background: []}], // dropdown with defaults from theme
		[{align: []}],
		['clean'] // remove formatting button
	];


	const setImageUploadErrors = (error) => {
		setItemImageError(error?.message)
	}
	const setVideoUploadErrors = (error) => {
		setItemVideoError(error?.message)
	}

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};

	const onChangeEnableGifting = (e) => {
		setGiftingChecked(e.target.checked);
	};

	const onChangeEnableAddNumeric = (e) => {
		setAddNumericChecked(e.target.checked);
	};

	const handleItemImageUploadFileChange = (file, blobPreview, error = false) => {
		if(!error){
			setItemImageError(null);
		}
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setItemImage(file);
		setItemImageBlobPreview(blobPreview);
	};

	const handleItemVideoUploadFileChange = (file, blobPreview, error = false) => {
		if(!error){
			setItemVideoError(null);
		}
		if (!file) {
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
		}

		setItemVideo(file);
		setItemVideoBlobPreview(blobPreview);
	};

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	// const handleSubmit = useCallback(
	// 	async (values) => {
	// 		setItemImageError(null);
	//
	// 		let hasError = false;
	//
	// 		if (selectedContentType === 'default' || selectedAccessType === 'default') {
	// 			hasError = true;
	// 		}
	//
	// 		if (!itemImageBlobPreview) {
	// 			setItemImageError('Item Image is required.');
	// 			hasError = true;
	// 		}
	//
	// 		if (hasError) {
	// 			window.scrollTo(0, 0);
	// 			return;
	// 		}
	//
	// 		setSubmitted(true);
	// 		const data = values;
	//
	// 		data.item_id = itemData.id;
	// 		data.currency = 'USD';
	// 		data.type = MEMBERSHIP_TYPES.COMMUNITY;
	// 		data.expiration_subscription_enabled = enableExpirationChecked;
	// 		data.enable_add_numeric_count = enableAddNumericChecked;
	// 		data.enable_gifting = enableGiftingChecked;
	// 		data.content_type = selectedContentType;
	// 		data.content_access = selectedAccessType;
	// 		data.visibility = selectedVisibility;
	// 		data.explicit_content = JSON.stringify(selectedExplicitContent);
	// 		data.explicit_access = JSON.stringify(selectedExplicitAccess);
	//
	// 		if (itemImage) {
	// 			data.item_image = itemImage;
	// 		} else {
	// 			if (photoIsRemoved) {
	// 				data.photo_is_empty = true;
	// 			}
	// 		}
	//
	// 		if (itemVideo) {
	// 			data.item_video = itemVideo;
	// 		} else {
	// 			if (videoIsRemoved) {
	// 				data.video_is_empty = true;
	// 			}
	// 		}
	//
	// 		try {
	// 			if (authUserInfo) {
	// 				data.user_id = authUserInfo.id;
	// 				data.space_id = spaceInfo.id;
	//
	// 				const response = await updateSpaceItem(data);
	// 				if (response && response.result) {
	// 					setAlert({
	// 						variant: 'success',
	// 						message: response.message
	// 					});
	// 					setDisplayDropDownError(false);
	// 					setSubmitted(false);
	//
	// 					// if (enableExpirationChecked) {
	// 					// 	const route = routes.addItemMonthBenefit();
	// 					// 	const url = route.replace(':space_username', '@' + spaceInfo.username);
	// 					// 	const fin_url = url.replace(':item_id', response.item.id);
	// 					// 	window.location.replace(fin_url);
	// 					// } else {
	// 					// 	const route = routes.manageItem();
	// 					// 	const url = route.replace(':space_username', '@' + spaceInfo.username);
	// 					// 	const fin_url = url.replace(':item_id', response.item.id);
	// 					// 	window.location.replace(fin_url);
	// 					// }
	// 				} else {
	// 					setAlert({
	// 						variant: 'danger',
	// 						message: response.message
	// 					});
	// 					setSubmitted(false);
	// 				}
	//
	// 				setSubmitted(false);
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 			setSubmitted(false);
	// 		}
	// 	},
	// 	[
	// 		authUserInfo,
	// 		spaceInfo,
	// 		enableExpirationChecked,
	// 		enableAddNumericChecked,
	// 		enableGiftingChecked,
	// 		selectedContentType,
	// 		selectedAccessType,
	// 		selectedVisibility,
	// 		itemImage,
	// 		itemVideo,
	// 		photoIsRemoved,
	// 		videoIsRemoved,
	// 		selectedExplicitAccess,
	// 		selectedExplicitContent,
	// 		itemImageBlobPreview
	// 	]
	// );

	const handleSubmit = useCallback(async (values) => {
			handleItemFormOnBlur().then(() => {});

			setSubmitted(true);
			setAlert(null);
			setDisplayDropDownError(true);
			setItemImageError(null);

			let hasError = false;

			if (!selectedContentType || selectedContentType === 'default' ||
				!selectedAccessType || selectedAccessType === 'default' ||
				!selectedPricingType || selectedPricingType === 'default' ||
				!selectedCurrencyType || selectedCurrencyType === 'default') {
				hasError = true;
			}

			if (!itemDescription || itemDescription ==='default') {
				hasError = true;
			}

			if (!itemImageBlobPreview) {
				window.scrollTo(0, 0);
				setItemImageError('Item Image is required.');
				hasError = true;
			}

			if (formHasErrorItem || hasError) {
				setSubmitted(false);
				setTimeout(() => {
					setBlurredField('all');
				}, 1000);
				return;
			}

			const data = values;
			// data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
			data.type = MEMBERSHIP_TYPES.COMMUNITY;
			// data.collection_contract_address = contractAddress;
			data.name = itemName;
			data.listing_text = itemListingText;
			data.summary = itemSummary;
			data.description = itemDescription;
			data.sku_shortcode = itemSKUShortcode;
			data.individual_title = itemIndividualTitling;
			data.max_per_account = itemAccountLimit;
			data.max_supply = itemTotalAvailable;
			data.expiration_subscription_enabled = selectedPricingType === 'recurring';
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);
			data.visibility = selectedVisibilityType;
			data.currency = selectedCurrencyType;
			data.is_include_benefit = selectedPricingType === 'recurring';
			data.enable_add_numeric_count = enableNumericCount;
			data.pricing_type = selectedPricingType;

			data.price = selectedPricingType === 'free' ? null : itemPurchasePrice;

			const isNotRecurring = selectedPricingType === 'one-time' || selectedPricingType === 'free';

			data.monthly_price = isNotRecurring ? null : (!itemOneMonth ? null : itemOneMonth);
			data.three_month_renewal = isNotRecurring ? null : (!itemThreeMonths ? null : itemThreeMonths);
			data.six_month_renewal = isNotRecurring ? null : (!itemSixMonths ? null : itemSixMonths);
			data.twelve_month_renewal = isNotRecurring ? null : (!itemTwelveMonths ? null : itemTwelveMonths);
			data.included_months = isNotRecurring ? null : (itemIncludedMonths);
			data.password = itemPurchasePassword;

			if (itemImage) {
				data.item_image = itemImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (itemVideo) {
				data.item_video = itemVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					if (itemData) {
						data.item_id = itemData.id;
					}

					const response = await updateSpaceItem(data);
					if (response && response.result) {

						if (getItemDataRequest) {
							getItemDataRequest(itemData.id);
						}

						if (selectedPricingType === 'one-time') {
							setItemPurchasePrice(null);
						}

						setAlert({
							variant: 'success',
							message: response.message
						});
						setDisplayDropDownError(false);
						setSubmitted(false);

						// if (enableExpirationChecked) {
						// 	const route = routes.addItemMonthBenefit();
						// 	const url = route.replace(':space_username', '@' + spaceInfo.username);
						// 	const fin_url = url.replace(':item_id', response.item.id);
						// 	window.location.replace(fin_url);
						// } else {
						// 	const route = routes.manageItem();
						// 	const url = route.replace(':space_username', '@' + spaceInfo.username);
						// 	const fin_url = url.replace(':item_id', response.item.id);
						// 	window.location.replace(fin_url);
						// }
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						setSubmitted(false);
					}

					setSubmitted(false);
				}
			} catch (error) {
				console.log(error);
				setSubmitted(false);
			}
		},
		[
			formHasErrorItem,
			authUserInfo,
			spaceInfo,
			enableExpirationChecked,
			itemData,
			itemImage,
			itemVideo,
			selectedContentType,
			selectedAccessType,
			selectedPricingType,
			selectedCurrencyType,
			enableNumericCount,
			selectedVisibilityType,
			photoIsRemoved,
			videoIsRemoved,
			selectedExplicitAccess,
			selectedExplicitContent,
			itemName,
			itemSummary,
			itemListingText,
			itemDescription,
			itemSKUShortcode,
			itemIndividualTitling,
			itemTotalAvailable,
			itemAccountLimit,
			itemImageBlobPreview,
			itemPurchasePrice,
			itemDescription,
			itemOneMonth,
			itemThreeMonths,
			itemSixMonths,
			itemTwelveMonths,
			itemIncludedMonths,
			itemPurchasePassword,
			displayDropDownError
		]
	);

	const handleItemFormOnBlur = useCallback(async () => {
		await editItemForm
			.validateFields()
			.then(async () => {
				setFormHasErrorItem(false);
			})
			.catch((errors) => {
				console.error('Validation errors:', errors);
				setFormHasErrorItem(true);
			});
	}, []);

	const redirectToItemUpdateBenefitLink = useCallback(
		(item) => {
			if (spaceInfo) {
				if (item.no_first_benefit) {
					const route = routes.addItemMonthBenefit();
					const url = route.replace(':space_username', '@' + spaceInfo.username);
					return url.replace(':item_id', item.id);
				} else {
					const route = routes.editItemMonthBenefit();
					let url = route.replace(':space_username', '@' + spaceInfo.username);
					url = url.replace(':benefit_id', item.first_benefit.id);
					return url.replace(':item_id', item.id);
				}
			}
			return '';
		},
		[spaceInfo]
	);

	const onChangePricingType = useCallback(
		async (value) => {
			setSelectedPricingType(value);
			if (value === 'free') {
				setItemPurchasePrice('0.00');
				editItemForm.setFieldValue('price', '0.00');
			} else {
				setItemPurchasePrice('');
				editItemForm.setFieldValue('price', '');
				if (itemData) {
					let purchasePrice = '';
					if (value === 'one-time') {
						purchasePrice = itemData && itemData?.price !== null && itemData.price > 0 ? parseInt(itemData.price, 10) : '';
					} else if (value === 'recurring') {
						purchasePrice = itemData && itemData?.price !== null && itemData.price >= 0 ? parseInt(itemData.price, 10) : '';
					}
					setItemPurchasePrice(!purchasePrice ? '' : purchasePrice);
					editItemForm.setFieldValue('price', purchasePrice);
				}
			}
			handleItemFormOnBlur().then(() => {});
		},
		[itemPurchasePrice, itemData, selectedPricingType, displayDropDownError]
	);

	const onChangeCurrencyType = useCallback(
		async (value) => {
			setSelectedCurrencyType(value);
		},
		[]
	);

	const onChangeEnableNumericCount = (e) => {
		setEnableNumericCount(e.target.checked);
	};

	const handleTotalAvailableChange = (e) => {
		const value = e.target.value;
		if (!isNaN(value) && value >= 0) {
			setItemTotalAvailable(value);
		}
	};

	const preventNegativeInputRegex = (input) => { const pattern = /^(0|[1-9][0-9]*)$/; return pattern.test(input);}

	const preventNegativeInput = (e) => {
		const inputValue = e.target.value;
		const nextValue = inputValue + e.key;
		if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) { return; }
		if (!preventNegativeInputRegex(nextValue)) { e.preventDefault(); }
	}

	const handleMonthlyPriceOnBlur = (e, monthlyType) => {
		const inputValue = e.target.value;
		// This regex pattern matches numbers that end with a period.
		// ^       : Asserts the start of the string
		// \d+     : Matches one or more digits (0-9)
		// \.      : Matches a literal period (dot)
		// $       : Asserts the end of the string
		const isValid = /^\d+\.$/.test(inputValue);
		if (isValid) {
			// Append '00' to make it a valid decimal number with two decimal places
			const correctedValue = inputValue + '00';

			if (monthlyType === RenewalOptions.monthly) {
				setItemOneMonth(correctedValue);
				editItemForm.setFieldValue('one_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.quarterly) {
				setItemThreeMonths(correctedValue);
				editItemForm.setFieldValue('three_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.biannually) {
				setItemSixMonths(correctedValue);
				editItemForm.setFieldValue('six_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.annually) {
				setItemTwelveMonths(correctedValue);
				editItemForm.setFieldValue('twelve_month_renewal', correctedValue);
			}

		}
	};

	const handlePurchasePriceOnBlur = (e) => {
		handleOnblurField('price').then(() => {});
		const inputValue = e.target.value;
		// This regex pattern matches numbers that end with a period.
		// ^       : Asserts the start of the string
		// \d+     : Matches one or more digits (0-9)
		// \.      : Matches a literal period (dot)
		// $       : Asserts the end of the string
		const isValid = /^\d+\.$/.test(inputValue);
		if (isValid) {
			// Append '00' to make it a valid decimal number with two decimal places
			const correctedValue = inputValue + '00';
			setItemPurchasePrice(correctedValue);
			editItemForm.setFieldValue('price', correctedValue);
		}
	};

	const preventPurchasePriceNegativeAndZeroInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if ((!selectedPricingType || selectedPricingType === 'default' || selectedPricingType === 'one-time') && !isValidNotAllowZeroNumber) {
			const isCurrentValueValid = /^[1-9]\d*(\.\d{0,2})?$/.test(e.key);
			if (!isCurrentValueValid) {
				e.preventDefault();
			}
		}
		// This regex validates input values to allow zero, positive whole numbers, and floating-point numbers
		// with up to two decimal places.
		// Explanation:
		// ^                  : Start of the string
		// (0|[1-9]\d*)       : Matches zero or positive whole numbers excluding leading zeros
		// (\.\d{0,2})?       : Optionally matches a decimal point followed by zero, one, or two digits
		// $                  : End of the string
		const isValidAllowZeroNumber = /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(inputValue);
		if (selectedPricingType === 'recurring' && !isValidAllowZeroNumber) {
			const isValidAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(e.key);
			if (!isValidAllowZeroNumber) {
				e.preventDefault();
			}
			const currentValue = e.target.value;
			if (currentValue <= 0) {
				setItemPurchasePrice('');
				editItemForm.setFieldValue('price', '');
			}
		}
	}

	const handlePurchasePriceNegativeAndZeroInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value;
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if (selectedPricingType === 'one-time'){
			if (isValidNotAllowZeroNumber) {
				setItemPurchasePrice(inputValue);
				editItemForm.setFieldValue('price', inputValue);
			} else {
				setItemPurchasePrice('');
				editItemForm.setFieldValue('price', '');
			}
		} else {
			setItemPurchasePrice(inputValue);
			editItemForm.setFieldValue('price', inputValue);
		}
		handleItemFormOnBlur().then(() => {});
	}


	const preventMonthlyPriceNegativeInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if (!isValidNotAllowZeroNumber) {
			e.preventDefault();
		}
	}

	const preventSpaceInput = (e) => {
		// Get the current input value
		// const inputValue = e.target.innerText;
		const inputValue = e.target.innerHTML;
		const maxWords = 2400;
		// Count the current number of words
		if (inputValue) {
			const wordCount = inputValue.split(/\s+/).filter(word => word.length > 0).length;
			// console.log(e.key)
			// console.log(wordCount)
			// Prevent specific keys and space if word count is 2400 or more
			if (wordCount > maxWords && e.key !== 'Backspace') {
				e.preventDefault();
			}
		}
	};

	const preventIncludedMonthsInvalidInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow numbers from 1 to 23 inclusive,
		// but it does not allow zero or numbers outside this range.
		// ^          : Asserts the start of the string
		// 1?[1-9]    : Matches numbers 1-9 and 10-19
		//    1?      : Matches an optional '1' (for numbers 1-19)
		//    [1-9]   : Matches digits 1 through 9 (for 1-9 and 11-19)
		// 2[0-3]     : Matches numbers 20-23
		//    2       : Matches the digit '2'
		//    [0-3]   : Matches digits 0 through 3
		// $          : Asserts the end of the string
		const isValidNotAllowZeroNumber = /^(1?[1-9]|2[0-3])$/.test(inputValue);
		if (!isValidNotAllowZeroNumber) {
			e.preventDefault();
		}
	}

	const handleDescriptionChange = (content, delta, source, editor) => {
		const maxWords = 2400;
		const innerText = editor.getText(); // Get plain text from the editor
		// const words = innerText.split(/\s+/).filter(word => word.length > 0);
		const words = content.split(/\s+/).filter(word => word.length > 0);
		const wordCount = words.length;
		// console.log(content);
		// console.log(innerText);
		// console.log(wordCount);
		if ('<p><br></p>' === content) {
			// Allow clearing the editor
			editItemForm.setFieldValue('description', '');
			setItemDescription(null);
		} else {
			if (wordCount <= maxWords) {
				setItemDescription(content);
				editItemForm.setFieldValue('description', content);
			}
		}
		handleFormItemChanges().then(r => {});
	};

	const handleAccountLimitChange = (e) => {
		const value = e.target.value;
		if (!isNaN(value) && value >= 0) {
			setItemAccountLimit(value);
		}
	};

	const handleShortcodeValidation = (value) => {
		if (debounceTimeoutRef.current) {
			clearTimeout(debounceTimeoutRef.current);
		}

		debounceTimeoutRef.current = setTimeout(async () => {
			const data = {
				shortcode: value
			};

			let available = false;
			if (spaceInfo && itemData.id) {
				data['space_id'] = spaceInfo.id;
				data['item_id'] = itemData.id;
				const response = await checkItemShortCodeAvailability(data);
				if (response && response.result) {
					available = response.availability;
				}
			}
			if (!available) {
				editItemForm.setFields([
					{
						name: 'sku_shortcode', // Name of the field you want to set the error for
						errors: ['Shortcode not available.'], // Error message you want to display
					},
				]);
				handleReRender();
			}
		}, 1000);
	};

	const setFormData = useCallback(async (itemData) => {
			if (itemData) {

				let description = itemData.description;
				if ('<p><br></p>' === description) {
					setItemDescription(null);
					description = null;
				} else {
					setItemDescription(description);
				}
				let purchasePrice = '0.00';
				if (itemData?.pricing_type === 'one-time') {
					purchasePrice = itemData.price && itemData.price > 0 ? parseInt(itemData.price, 10) : '';
				} else if (itemData?.pricing_type === 'recurring') {
					purchasePrice = itemData.price && itemData.price >= 0 ? parseInt(itemData.price, 10) : '';
				}
				editItemForm.setFieldsValue({
					name: itemData.name,
					listing_text: itemData.listing_text,
					summary: itemData.summary,
					sku_shortcode: itemData.sku_shortcode,
					individual_title: itemData.individual_title,
					description: description,
					// price: firstItem.benefits.length > 0 ? firstItem.benefits[0].price : null,
					price: purchasePrice,
					max_supply: itemData.max_supply,
					max_per_account: itemData.max_per_account,
					royalty_percentage: itemData.royalty_percentage,
				});
				setItemName(itemData.name);
				setItemListingText(
					itemData.listing_text ? itemData.listing_text : ''
				);
				setItemSummary(itemData.summary ? itemData.summary : '');
				setItemSKUShortcode(itemData.sku_shortcode);
				setItemTotalAvailable(itemData.max_supply);
				setExpirationChecked(itemData.enable_expiration_and_subscription);
				setEnableNumericCount(itemData.enable_add_numeric_count);
				setItemIndividualTitling(itemData.individual_title);
				setItemAccountLimit(itemData.max_per_account);

				setSelectedContentType(itemData.content_type ?? 'has_no_explicit');
				setSelectedAccessType(itemData.content_access ?? 'has_no_explicit');
				setSelectedExplicitContent(JSON.parse(itemData.explicit_content));
				setSelectedExplicitAccess(JSON.parse(itemData.explicit_access));
				setSelectedVisibilityType(itemData.visibility);
				setSelectedPricingType(itemData?.pricing_type ? itemData?.pricing_type : 'default');
				setSelectedCurrencyType(itemData.currency ?  itemData.currency : 'USD');
				setItemPurchasePrice(purchasePrice);

				// setItemPurchasePassword(firstItem.password);

				if (itemData.photo) {
					if (!photoIsRemoved) {
						setItemImageBlobPreview(itemData.photo);
					}
				}
				if (itemData.video) {
					if (!videoIsRemoved) {
						setItemVideoBlobPreview(itemData.video);
					}
				}

				// await editItemForm
				// 	.validateFields()
				// 	.then(async () => {
				// 		setFormHasErrorItem(false);
				// 	})
				// 	.catch((errors) => {
				// 		setFormHasErrorItem(true);
				// 	});
			}
		// editItemForm.setFieldsValue({
		// 	name: data.name,
		// 	sku_shortcode: data.sku_shortcode,
		// 	max_per_account: data.max_per_account,
		// 	max_supply: data.max_supply,
		// 	summary: data.summary,
		// 	listing_text: data.listing_text,
		// 	price: data.price,
		// 	individual_title: data.individual_title,
		// 	description: data.description,
		// 	royalty_percentage: data.royalty_percentage
		// });
		//
		// if (data.visibility) {
		// 	setSelectedVisibility(data.visibility);
		// } else {
		// 	setSelectedVisibility('custom');
		// }
		//
		// let content_type = data.content_type;
		// if (!data.content_type) {
		// 	content_type = 'default';
		// }
		// setSelectedContentType(content_type);
		//
		// let content_access = data.content_access;
		// if (!data.content_access) {
		// 	content_access = 'default';
		// }
		// setSelectedAccessType(content_access);
		//
		// if (data.explicit_content !== null) {
		// 	setSelectedExplicitContent(JSON.parse(data.explicit_content));
		// }
		// if (data.explicit_access !== null) {
		// 	setSelectedExplicitAccess(JSON.parse(data.explicit_access));
		// }
		//
		// setExpirationChecked(data.enable_expiration_and_subscription);
		// setAddNumericChecked(data.enable_add_numeric_count);
		// setGiftingChecked(data.enable_gifting);
		// if (data.photo) {
		// 	setItemImageBlobPreview(data.photo);
		// }
		// if (data.video) {
		// 	setItemVideoBlobPreview(data.video);
		// }
		// handleFormItemChanges();
		},
		[
			photoIsRemoved,
			videoIsRemoved,
			itemName,
			itemSummary,
			itemListingText,
			itemDescription,
			itemSKUShortcode,
			itemIndividualTitling,
			itemPurchasePrice,
			itemTotalAvailable,
			itemAccountLimit,
			itemOneMonth,
			itemThreeMonths,
			itemSixMonths,
			itemTwelveMonths,
			itemPurchasePassword,
			// itemIncludedMonths
		]
	);

	const handleFinishFailed = useCallback(async (errorInfo) => {
		setSubmitClicked(true);
		// Set the blurred field to the first field with an error
		if (errorInfo.errorFields.length > 0) {
			setBlurredField('all');
		}
		if (!selectedPricingType || selectedPricingType === 'default') {
			setSelectedPricingType(null);
		}
		if (!itemImageBlobPreview) {
			window.scrollTo(0, 0);
			setItemImageError('Item Image is required.');
		}
	}, [blurredField, isSubmitClicked, selectedPricingType, itemImageBlobPreview]);

	const handleOnblurField = useCallback(async (field) => {
		if (!isSubmitClicked) {
			setBlurredField(field);
		}
		setSubmitClicked(false);
	}, [blurredField, isSubmitClicked]);

	const handleFormItemChanges = useCallback(async () => {
		setItemName(editItemForm.getFieldValue('name'));
		setItemSummary(editItemForm.getFieldValue('summary'));
		setItemListingText(editItemForm.getFieldValue('listing_text'));
		// setItemSKUShortcode(editItemForm.getFieldValue('sku_shortcode'));
		setItemIndividualTitling(editItemForm.getFieldValue('individual_title'));
		// setItemPurchasePrice(editItemForm.getFieldValue('price'));
		setItemTotalAvailable(editItemForm.getFieldValue('max_supply'));
		setItemAccountLimit(editItemForm.getFieldValue('max_per_account'));
		setItemPurchasePassword(editItemForm.getFieldValue('password'));
		// setItemIncludedMonths(editItemForm.getFieldValue('included_months'));
		handleItemFormOnBlur().then(r => {});
	}, [
		itemName,
		itemSummary,
		itemListingText,
		itemDescription,
		itemSKUShortcode,
		itemIndividualTitling,
		itemPurchasePrice,
		itemTotalAvailable,
		itemAccountLimit,
		itemOneMonth,
		itemThreeMonths,
		itemSixMonths,
		itemTwelveMonths,
		itemPurchasePassword,
		itemIncludedMonths
	]);

	useEffect(() => {
		editItemForm.setFieldValue('currency', 'USD');
	});

	useEffect(() => {}, [itemImageError, formHasErrorItem]);

	useEffect(() => {
		if (itemData) {
			setFormData(itemData).then((r) => {});
		}
	}, [authUserInfo, spaceInfo, spaceInfoLoading, itemData, benefitData]);

	return (
		<>
			<div className="block-full card">
				<div className="items">
					<div className="dashboard-wrapper">
						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
							<div className="col-span-12">
								<h2 className="header-medium">Editing {itemData.name}</h2>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-y-8 md:gap-y-0 md:gap-8 mt-5">
							<div className="col-span-12">
								<p className="body-text--small">
									You can edit your Item Details here, however most changes may
									only apply to new sales and certain details may not be modifiable
									depending on your Item Type and status.{' '}
									<a className="body-txtitalic--small gradient-color-txt">
										Learn more about Item Types and editing limits.
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>


			<main id="cs-platform-main" className="cs-main main-theme">
				<section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<div className="block-full">
							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h3>Item Product Details</h3>
									</div>
									<div>
										<p>Item Details can be changed but some changes may only affect to new sales.</p>
									</div>
								</div>

								<Form
									form={editItemForm}
									name="editItemForm"
									id="editItemForm"
									validateMessages={VALIDATE_MESSAGES}
									onFocus={() => {
										setformIsOnFocusItem(true);
									}}
									onBlur={() => {
										setformIsOnFocusItem(false);
										handleItemFormOnBlur().then(r => {});
									}}
									onChange={handleFormItemChanges}
									onFinish={handleSubmit}
									onFinishFailed={handleFinishFailed}
									className='cs-stack-form-guided'
								>

									<div className="formrow media">
										<div className="cs-stack-form-media stack-row">
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setItemImageError}
													cropperAspectRatio={4 / 3}
													shape="square"
													hideDragIcon="true"
													hideText="true"
													uploadFileTypes={IMAGE_FILETYPES.toString()}
													uploadedFilePreview={itemImageBlobPreview}
													onUploadFileChange={
														handleItemImageUploadFileChange
													}
													minHeight={IMAGE_LARGE_MIN_HEIGHT}
													minWidth={IMAGE_LARGE_MIN_WIDTH}
													maxHeight={IMAGE_LARGE_MAX_HEIGHT}
													maxWidth={IMAGE_LARGE_MAX_WIDTH}
													uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
													// uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
													disableCropper={true}
												/>
												<p className="cs-label">Item Image</p>
												<p className="cs-helper">Up to 3840px JPEG, PNG, or GIF</p>
												{itemImageError && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{itemImageError}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setItemVideoError}
													hideDragIcon="true"
													hideText="true"
													shape="square"
													uploadFileTypes={VIDEO_FILETYPES.toString()}
													uploadedFilePreview={itemVideoBlobPreview}
													onUploadFileChange={
														handleItemVideoUploadFileChange
													}
													minHeight={VIDEO_STANDARD_MIN_HEIGHT}
													minWidth={VIDEO_STANDARD_MIN_WIDTH}
													maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
													maxWidth={VIDEO_STANDARD_MAX_WIDTH}
													uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
													// uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
												/>
												<p className="cs-label">Item Video</p>
												<p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
												{itemVideoError && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{itemVideoError}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
										</div>
										<div className="cs-formrow-helper-split">
											<p>Your Item Image (up to 4MB) is used on Product Pages and previews. A square format is preferred.</p>
											<p>You can add an optional Item Video (up to 12MB) which appears in limited pages. Optional.</p>
											{/*<p>Your Item Image (up to 8MB) should be as high*/}
											{/*	resolution as possible, up to 3840px height and width. This*/}
											{/*	will also be used for any thumbnails and previews.</p>*/}
											{/*<p>You can add a Item Video (up to 12MB). This will be*/}
											{/*	viewable by Members when viewing their purchased Item.*/}
											{/*	Optional.</p>*/}
										</div>
									</div>

									<Form.Item
										name="name"
										label="Item Product Name"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Item Product Name</label>
												<input id="name"
													   name="name"
													   type="text"
													   value={itemName}
													   onBlur={()=>handleOnblurField('name')}
													   maxLength={32}
													   placeholder="Up to 32 characters, including spaces."
												/>
												{((blurredField === 'name' || blurredField === 'all') && editItemForm.getFieldsError()[0]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editItemForm.getFieldsError()[0]?.errors[0]}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>This shows on the Item Product Page and listing which people can purchase.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="summary"
										label="Summary"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="text">Summary</label>
												<textarea id="summary" name="summary" maxLength={128}
														  value={itemSummary}
														  onBlur={()=>handleOnblurField('summary')}
														  placeholder="Up to 128 characters, including spaces."></textarea>
												{ ((blurredField === 'summary' || blurredField === 'all') && editItemForm.getFieldsError()[1]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editItemForm.getFieldsError()[1]?.errors[0]}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This should be 1 to 2 sentences long and is shown on the Product page and purchased Items.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="listing_text"
										label="Listing Text"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="text">Listing Text</label>
												<textarea id="listing_text" name="listing_text"
														  value={itemListingText}
														  onBlur={()=>handleOnblurField('listing_text')}
														  maxLength={128} placeholder="Up to 128 characters, including spaces."></textarea>
												{ ((blurredField === 'listing_text' || blurredField === 'all') && editItemForm.getFieldsError()[2]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editItemForm.getFieldsError()[2]?.errors[0]}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This is marketing text for your Item, it should be 1 to 2 sentences summarizing and promoting the Item.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="description"
										label="Full Description"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{ validator: (_, value) => {
													const tempDiv = document.createElement('div');
													tempDiv.innerHTML = value;
													const innerText = tempDiv.innerText ;
													// console.log(innerText)
													// Count the words
													const wordCount = innerText ? innerText.split(/\s+/).filter(word => word.length > 0).length : 0;
													// console.log(wordCount);
													if (wordCount < 2) {
														return Promise.reject(new Error('Must be minimum 2 words.'));
													} else if (wordCount > 2400) {
														return Promise.reject(new Error('Must not exceed 2400 words.'));
													} return Promise.resolve();
												},
											},
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="description">Full Description</label>
												{/*<textarea id="description" name="description" rows="7"*/}
												{/*		  value={itemDescription}*/}
												{/*		  required/>*/}
												<ReactQuill
													theme="snow"
													modules={{
														toolbar: toolbarOptions
													}}
													value={itemDescription}
													onBlur={()=>handleOnblurField('description')}
													onKeyDown={preventSpaceInput}
													onChange={handleDescriptionChange}
												/>
												{((blurredField === 'description' || blurredField === 'all') && editItemForm.getFieldsError()[3]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editItemForm.getFieldsError()[3]?.errors[0]}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This displays on your Product page and on the details page of a purchased Item. It should provides the full details about the Item. You can format and add up to approximately 2,400 words.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="sku_shortcode"
										label="Shortcode"
										validateTrigger="onBlur"
										rules={[
											{ required: true},
											{
												min: 2,
												message: 'Must be a minimum of 2 characters.'
											},
											{
												max: 8,
												message: 'Must not exceed 8 characters.'
											},
											{
												pattern: /^[a-zA-Z0-9]+$/, // Example for alphanumeric characters
												message: 'Only alphanumeric characters are allowed.'
											},
											{
												pattern: /^(?!.*[`\!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\s]).*$/,
												message: 'Spaces and special characters are not allowed.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Shortcode</label>
												<input id="sku_shortcode"
													   name="sku_shortcode"
													   value={itemSKUShortcode}
													   onBlur={(e)=> {
														   handleShortcodeValidation(e.target.value);
														   handleOnblurField('sku_shortcode').then(() => {});
													   }}
													   onChange={(e)=> {
														   handleShortcodeValidation(e.target.value);
														   setItemSKUShortcode(e.target.value);
													   }}
													   type="text" placeholder="2 to 8 characters. Letters and numbers only, no spaces."
												/>
												{ ((blurredField === 'sku_shortcode' || blurredField === 'all') && editItemForm.getFieldsError()[4]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editItemForm.getFieldsError()[4]?.errors[0]}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>Choose a unique ID based the Item Name. This is used in transactions, support, and other areas.</p>
											</div>
										</div>
									</Form.Item>


									<div className="formrow title">
										<div>
											<h3>Individual Item Details</h3>
										</div>
										<div>
											<p>Set details for individual Items when purchased.</p>
										</div>
									</div>

									<Form.Item
										name="individual_title"
										label="Individual Titling"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Individual Titling</label>
												<input
													id="individual_title"
													name="individual_title"
													type="text"
													maxLength={32}
													onBlur={()=>handleOnblurField('individual_title')}
													placeholder="Up to 32 characters, including spaces."
													value={itemIndividualTitling}
												/>
												{((blurredField === 'individual_title' || blurredField === 'all') && editItemForm.getFieldsError()[5]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editItemForm.getFieldsError()[5]?.errors[0]}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>This is applied to individual Items when purchased. It can be the same or a variation of your Item Product Name.</p>
											</div>
										</div>
									</Form.Item>

									<div className="formrow">
										<div className="form-input-checkbox gradient md rounded row w-full">
											<div className="checkbox">
												<input
													id="checkbox-cs-agreement"
													aria-describedby="checkbox-cs-agreement-description"
													name="checkbox-cs-agreement"
													type="checkbox"
													// required
													checked={enableNumericCount}
													onChange={onChangeEnableNumericCount}
												/>
											</div>
											<div>
												<label htmlFor="checkbox-cs-agreement" className="sr-only">Add Numeric Count</label>
												<p>Add Numeric Count to the Individual Title of each purchased Item</p>
											</div>
										</div>
										<div>
											<p>Add a numeric count to each purchased Item. For example, the first "Club Item" sold would be “Club Item #1”, the second “Club Item #2”, and so on.</p>
										</div>
									</div>

									<div className="formrow title">
										<div>
											<h3>Pricing & Availability</h3>
										</div>
										<div>
											<p>Specify the type, pricing, and supply for this Item.</p>
										</div>
									</div>

									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">
											{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
											<DropdownWithItems
												title="Select Pricing Type"
												customButtonclassName="icon-dropdown-gradient-alt secondary"
												displayError={!selectedPricingType || selectedPricingType === 'default'}
												icon="fa-light fa-dollar"
												selectedValue={selectedPricingType}
												listItems={PRICING_ITEM_TYPES_LIST}
												onDropdownChange={(value) => onChangePricingType(value)}
											/>
											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}

										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Items can be Free, One-time Purchase, or Recurring Subscription. Any Items pricing changes will only apply to future purchases.</p>
										</div>
									</div>

									{/*CSJS Only display Currency Dropdown Row if One-time Purchase or Recurring Subscription option is chosen in previous dropdown.*/}
									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">

											{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
											<DropdownWithItems
												title="Select Currency"
												customButtonclassName="icon-dropdown-gradient-alt secondary"
												displayError={displayDropDownError && selectedCurrencyType === 'default'}
												icon="fa-light fa-dollar"
												selectedValue={selectedCurrencyType}
												listItems={SELECT_CURRENCIES}
												onDropdownChange={(value) => onChangeCurrencyType(value)}
											/>
											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}
										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Select the Currency for your Item Price. Any purchases in a different currency will be converted based on market pricing at the time of purchase. <a href="link-via-admin" target="_blank">See officially supported major currencies and cryptocurrencies.</a></p>
										</div>
									</div>


									{/*CSJS Only display Purchase Price Form Row if One-time Purchase or Recurring Subscription option is chosen in previous dropdown.*/}
									{/*{selectedPricingType !== 'free' &&*/}
									<Form.Item
										name="price"
										label="Purchase Price"
										validateTrigger="onBlur"
										rules={[
											{required: true},
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Purchase Price</label>
												<input
													id="price"
													name="price"
													type="text"
													// required
													disabled={selectedPricingType === 'free'}
													placeholder="Enter numbers and a decimal only, up to 2 decimal places."
													value={itemPurchasePrice}
													onChange={handlePurchasePriceNegativeAndZeroInput}
													onKeyDown={preventPurchasePriceNegativeAndZeroInput}
													onBlur={(e) => handlePurchasePriceOnBlur(e)}
													className={selectedPricingType === 'free' ? 'disabled-input' : ''}

												/>
												{((blurredField === 'price' || blurredField === 'all') && editItemForm.getFieldsError()[6]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editItemForm.getFieldsError()[6]?.errors[0]}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>Set the upfront purchase price for your Item. Paid Items have a $1 USD/¥100 JPY Minimum.</p>
											</div>
										</div>
									</Form.Item>
									{/*}*/}


									<div className="formrow">
										<div className="cs-stack-form-inputs stack-row">
											<Form.Item
												name="max_supply"
												label="Total Available"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
												]}
											>
												<div className="form-input air">
													<label htmlFor="text">Total Available</label>
													<input
														id="max_supply"
														name="max_supply"
														type="text"
														// required
														onChange={handleTotalAvailableChange}
														placeholder="Enter 0 for unlimited."
														value={itemTotalAvailable}
														onKeyDown={preventNegativeInput}
													/>
													{editItemForm.getFieldsError()[7]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{editItemForm.getFieldsError()[7]?.errors[0]}</h6>
																</div>
															</div>

														</div>
													)}
												</div>
											</Form.Item>

											<Form.Item
												name="max_per_account"
												label="Account Limit"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
												]}
											>
												<div className="form-input air">
													<label htmlFor="text">Account Limit</label>
													<input
														id="max_per_account"
														name="max_per_account"
														type="text"
														// required
														placeholder="1 is recommended."
														value={itemAccountLimit}
														onChange={handleAccountLimitChange}
														onKeyDown={preventNegativeInput}
													/>
													{editItemForm.getFieldsError()[8]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{editItemForm.getFieldsError()[8]?.errors[0]}</h6>
																</div>
															</div>

														</div>
													)}
												</div>
											</Form.Item>
										</div>
										<div className="cs-formrow-helper-split">
											<p>Enter “0” to offer unlimited availability for this Item or any number to limit availability.</p>
											<p>Enter any number to set a limit on how many of this Item can be purchased by a single account. Enter “0” for unlimited.</p>
										</div>
									</div>

									{/*CSJS Only display Subscription Details Title and Renewal Form Row if Recurring Subscription option is chosen in earlier dropdown.*/}

									{/*{(selectedPricingType === 'recurring') &&*/}
									{/*	<>*/}
									{/*		<div className="formrow title">*/}
									{/*			<div>*/}
									{/*				<h3>Subscription Details</h3>*/}
									{/*			</div>*/}
									{/*			<div>*/}
									{/*				<p>Configure your Subscription Pricing and Included Months here.</p>*/}
									{/*			</div>*/}
									{/*		</div>*/}


									{/*		<Form.Item*/}
									{/*			name="included_months"*/}
									{/*			label="Months included with Purchase Price"*/}
									{/*			validateTrigger="onBlur"*/}
									{/*			rules={*/}
									{/*				[*/}
									{/*					{ required: true, message: 'Months included with Purchase Price is required.' },*/}
									{/*					// {*/}
									{/*					// 	type: 'number',*/}
									{/*					// 	min: 1, max: 23,*/}
									{/*					// 	message: 'Please enter a number between 1 and 23.'*/}
									{/*					// },*/}
									{/*				]}*/}
									{/*		>*/}
									{/*			<div className="formrow">*/}
									{/*				<div className="form-input air">*/}
									{/*					<label htmlFor="text">Months included with Purchase Price</label>*/}
									{/*					<input*/}
									{/*						id="included_months"*/}
									{/*						name="included_months"*/}
									{/*						type="text"*/}
									{/*						placeholder="Enter 1 or more."*/}
									{/*						value={itemIncludedMonths}*/}
									{/*						onKeyDown={preventIncludedMonthsInvalidInput}*/}
									{/*					/>*/}
									{/*					{editItemForm.getFieldsError()[9]?.errors.length > 0 && (*/}
									{/*						<div className="alert callout warning !w-full items-center">*/}
									{/*							<div className="action">*/}
									{/*								<i className="fa-solid fa-triangle-exclamation"></i>*/}
									{/*							</div>*/}
									{/*							<div className="content">*/}
									{/*								<div>*/}
									{/*									<h6>{editItemForm.getFieldsError()[9]?.errors[0]}</h6>*/}
									{/*								</div>*/}
									{/*							</div>*/}

									{/*						</div>*/}
									{/*					)}*/}
									{/*				</div>*/}
									{/*				<div className="cs-formrow-helper-end">*/}
									{/*					<p>Item with Subscriptions must include at least 1 month included, after which it must be renewed.</p>*/}
									{/*				</div>*/}
									{/*			</div>*/}
									{/*		</Form.Item>*/}


									{/*		<div className="formrow">*/}
									{/*			<div className="cs-stack-form-inputs stack-row cs-stack-form-input-quarters">*/}
									{/*				<div className="stack-row">*/}
									{/*					<Form.Item*/}
									{/*						name="one_month_renewal"*/}
									{/*						label="1 Month Renewal Price"*/}
									{/*						validateTrigger="onBlur"*/}

									{/*					>*/}
									{/*						<div className="form-input air">*/}
									{/*							<label htmlFor="text">1 Month Renewal Price</label>*/}
									{/*							<input*/}
									{/*								id="one_month_renewal"*/}
									{/*								name="one_month_renewal"*/}
									{/*								type="text"*/}
									{/*								onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.monthly)}*/}
									{/*								placeholder="Enter numbers and a decimal only, up to 2 decimal places."*/}
									{/*								value={itemOneMonth}*/}
									{/*								onKeyDown={preventMonthlyPriceNegativeInput}*/}
									{/*								onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.monthly)}*/}
									{/*							/>*/}
									{/*							/!*{editItemForm.getFieldsError()[3]?.errors.length > 0 && (*!/*/}
									{/*							/!*	<div className="alert callout warning !w-full items-center">*!/*/}
									{/*							/!*		<div className="action">*!/*/}
									{/*							/!*			<i className="fa-solid fa-triangle-exclamation"></i>*!/*/}
									{/*							/!*		</div>*!/*/}
									{/*							/!*		<div className="content">*!/*/}
									{/*							/!*			<div>*!/*/}
									{/*							/!*				<h6>{editItemForm.getFieldsError()[3]?.errors[0]}</h6>*!/*/}
									{/*							/!*			</div>*!/*/}
									{/*							/!*		</div>*!/*/}

									{/*							/!*	</div>*!/*/}
									{/*							/!*)}*!/*/}
									{/*						</div>*/}
									{/*					</Form.Item>*/}
									{/*					<Form.Item*/}
									{/*						name="three_month_renewal"*/}
									{/*						label="3 Month Renewal Price"*/}
									{/*						validateTrigger="onBlur"*/}
									{/*					>*/}
									{/*						<div className="form-input air">*/}
									{/*							<label htmlFor="text">3 Month Renewal Price</label>*/}
									{/*							<input*/}
									{/*								id="three_month_renewal"*/}
									{/*								name="three_month_renewal"*/}
									{/*								type="text"*/}
									{/*								value={itemThreeMonths}*/}
									{/*								onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.quarterly)}*/}
									{/*								placeholder="Enter numbers and a decimal only, up to 2 decimal places."*/}
									{/*								onKeyDown={preventMonthlyPriceNegativeInput}*/}
									{/*								onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.quarterly)}*/}
									{/*							/>*/}
									{/*							/!*{editItemForm.getFieldsError()[3]?.errors.length > 0 && (*!/*/}
									{/*							/!*	<div className="alert callout warning !w-full items-center">*!/*/}
									{/*							/!*		<div className="action">*!/*/}
									{/*							/!*			<i className="fa-solid fa-triangle-exclamation"></i>*!/*/}
									{/*							/!*		</div>*!/*/}
									{/*							/!*		<div className="content">*!/*/}
									{/*							/!*			<div>*!/*/}
									{/*							/!*				<h6>{editItemForm.getFieldsError()[3]?.errors[0]}</h6>*!/*/}
									{/*							/!*			</div>*!/*/}
									{/*							/!*		</div>*!/*/}

									{/*							/!*	</div>*!/*/}
									{/*							/!*)}*!/*/}
									{/*						</div>*/}
									{/*					</Form.Item>*/}
									{/*				</div>*/}
									{/*				<div className="stack-row">*/}
									{/*					<Form.Item*/}
									{/*						name="six_month_renewal"*/}
									{/*						label="6 Month Renewal Price"*/}
									{/*						validateTrigger="onBlur"*/}
									{/*					>*/}
									{/*						<div className="form-input air">*/}
									{/*							<label htmlFor="text">6 Month Renewal Price</label>*/}
									{/*							<input*/}
									{/*								id="six_month_renewal"*/}
									{/*								name="six_month_renewal"*/}
									{/*								type="text"*/}
									{/*								value={itemSixMonths}*/}
									{/*								onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.biannually)}*/}
									{/*								placeholder="Enter numbers and a decimal only, up to 2 decimal places."*/}
									{/*								onKeyDown={preventMonthlyPriceNegativeInput}*/}
									{/*								onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.biannually)}*/}
									{/*							/>*/}
									{/*							/!*{editItemForm.getFieldsError()[3]?.errors.length > 0 && (*!/*/}
									{/*							/!*	<div className="alert callout warning !w-full items-center">*!/*/}
									{/*							/!*		<div className="action">*!/*/}
									{/*							/!*			<i className="fa-solid fa-triangle-exclamation"></i>*!/*/}
									{/*							/!*		</div>*!/*/}
									{/*							/!*		<div className="content">*!/*/}
									{/*							/!*			<div>*!/*/}
									{/*							/!*				<h6>{editItemForm.getFieldsError()[3]?.errors[0]}</h6>*!/*/}
									{/*							/!*			</div>*!/*/}
									{/*							/!*		</div>*!/*/}

									{/*							/!*	</div>*!/*/}
									{/*							/!*)}*!/*/}
									{/*						</div>*/}
									{/*					</Form.Item>*/}
									{/*					<Form.Item*/}
									{/*						name="twelve_month_renewal"*/}
									{/*						label="12 Month Renewal Price"*/}
									{/*						validateTrigger="onBlur"*/}
									{/*					>*/}
									{/*						<div className="form-input air">*/}
									{/*							<label htmlFor="text">12 Month Renewal Price</label>*/}
									{/*							<input*/}
									{/*								id="twelve_month_renewal"*/}
									{/*								name="twelve_month_renewal"*/}
									{/*								type="text"*/}
									{/*								onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.annually)}*/}
									{/*								placeholder="Enter numbers and a decimal only, up to 2 decimal places."*/}
									{/*								value={itemTwelveMonths}*/}
									{/*								onKeyDown={preventMonthlyPriceNegativeInput}*/}
									{/*								onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.annually)}*/}
									{/*							/>*/}
									{/*							/!*{editItemForm.getFieldsError()[3]?.errors.length > 0 && (*!/*/}
									{/*							/!*	<div className="alert callout warning !w-full items-center">*!/*/}
									{/*							/!*		<div className="action">*!/*/}
									{/*							/!*			<i className="fa-solid fa-triangle-exclamation"></i>*!/*/}
									{/*							/!*		</div>*!/*/}
									{/*							/!*		<div className="content">*!/*/}
									{/*							/!*			<div>*!/*/}
									{/*							/!*				<h6>{editItemForm.getFieldsError()[3]?.errors[0]}</h6>*!/*/}
									{/*							/!*			</div>*!/*/}
									{/*							/!*		</div>*!/*/}

									{/*							/!*	</div>*!/*/}
									{/*							/!*)}*!/*/}
									{/*						</div>*/}
									{/*					</Form.Item>*/}
									{/*				</div>*/}
									{/*			</div>*/}
									{/*			<div className="cs-formrow-helper-end">*/}
									{/*				<p>Set your Renewal Pricing for one or more time options. To hide an option, leave it blank.</p>*/}
									{/*			</div>*/}
									{/*		</div>*/}
									{/*	</>*/}
									{/*}*/}


									<div className="formrow title">
										<div>
											<h3>Visibility & Content</h3>
										</div>
										<div>
											<p>Set Visibility. Content, and Linked Access. </p>
										</div>
									</div>

									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">

											<DropdownWithItems
												title="Update Visibility"
												displayError={!selectedVisibilityType || selectedVisibilityType === 'default'}
												dropDownClass="dropdown-bg"
												customButtonClass="icon-dropdown-gradient-alt secondary"
												selectedValue={selectedVisibilityType}
												listItems={MEMBERSHIP_ITEM_VISIBILITY}
												icon="fa-light fa-eye"
												// icon={getListIcon(
												// 	UPDATES_VISIBILITY,
												// 	selectedVisibilityType
												// )}
												onDropdownChange={(value) => setSelectedVisibilityType(value)}
											/>


										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Items can be visible to Public, Members Only (any members), Password Required, Hidden, or Custom Access (based on owning a specific Item/Item).</p>
										</div>
									</div>

									{/*CSJS Only display Password Form Row if Password Required option is chosen in previous dropdown.*/}
									{selectedVisibilityType === 'password' &&
										<Form.Item
											name="password"
											label="Choose Password"
											validateTrigger="onBlur"
										>
											<div className="formrow">
												<div className="form-input air">
													<label htmlFor="text">Choose Password</label>
													<input
														id="password"
														name="password"
														type="password"
														placeholder="1+ letters, numbers, and symbols."
														value={itemPurchasePassword}
													/>
													{/*{createSpaceAddItemForm.getFieldsError()[3]?.errors.length > 0 && (*/}
													{/*	<div className="alert callout warning !w-full items-center">*/}
													{/*		<div className="action">*/}
													{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
													{/*		</div>*/}
													{/*		<div className="content">*/}
													{/*			<div>*/}
													{/*				<h6>{createSpaceAddItemForm.getFieldsError()[3]?.errors[0]}</h6>*/}
													{/*			</div>*/}
													{/*		</div>*/}

													{/*	</div>*/}
													{/*)}*/}
												</div>
												<div className="cs-formrow-helper-end">
													<p>Choose a case-sensitive password required to purchase this Item.</p>
												</div>
											</div>
										</Form.Item>
									}

									<div className="formrow">
										<div className="cs-stack-form-inputs stack-row">
											<div className="cs-stack-form-buttons">
												<DropdownWithItems
													title="No Sensitive Content"
													customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
													displayError={!selectedContentType || selectedContentType === 'default'}
													icon={getListIcon(
														CONTENT_TYPES_LIST_WITH_DEFAULT,
														selectedContentType
													)}
													selectedValue={selectedContentType}
													listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
													onDropdownChange={(value) => openContentTypeModal(value)}
												/>
												<ExplicitContent
													modalOpen={modalOpenExplicitContent}
													setModalOpen={setModalOpenExplicitContent}
													selectedItems={selectedExplicitContent}
													isExplicitContent={true}
													onSelectedItems={(value) => setSelectedExplicitContent(value)}
												/>
											</div>

											{/*/!*DROPDOWN MENU GRADIENT SET - START*!/*/}
											{/*<div className="dropdown-menu-set">*/}
											{/*	/!*DROPDOWN MENU GRADIENT BUTTON - START*!/*/}
											{/*	<button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true" id="dropdown-button-3"><span><i className="fa-light fa-photo-film-music"></i><p>No Sensitive Content</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
											{/*	/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*	/!*DROPDOWN MENU GRADIENT BUTTON - END*!/*/}
											{/*	/!*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*!/*/}
											{/*	<ul className="dropdown-menu-gradient hide" aria-orientation="vertical" role="listbox" id="dropdown-menu-3">*/}
											{/*		/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*	</ul>*/}
											{/*	/!*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*!/*/}
											{/*</div>*/}
											{/*DROPDOWN MENU GRADIENT SET - END*/}

											{/*DROPDOWN MENU GRADIENT SET - START*/}
											<div className="dropdown-menu-set">
												{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
												<DropdownWithItems
													title="No Sensitive Access"
													customButtonclassName="icon-dropdown-gradient-alt secondary active"
													displayError={!selectedAccessType || selectedAccessType === 'default'}
													icon={getListIcon(
														ACCESS_TYPES_LIST_WITH_DEFAULT,
														selectedAccessType
													)}
													selectedValue={selectedAccessType}
													listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
													onDropdownChange={(value) => openAccessTypeModal(value)}
												/>
												{/*<button type="button" className="icon-dropdown-gradient-alt secondary active" aria-expanded="false" aria-haspopup="true" id="dropdown-button-4"><span><i className="fa-light fa-globe-pointer"></i><p>No Sensitive Access</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
												{/*CSJS ID is for DEMO Purposes only.*/}
												{/*DROPDOWN MENU GRADIENT BUTTON - END*/}
												{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*/}
												<ExplicitContent
													modalOpen={modalOpenExplicitAccess}
													setModalOpen={setModalOpenExplicitAccess}
													selectedItems={selectedExplicitAccess}
													isExplicitContent={false}
													onSelectedItems={(value) => setSelectedExplicitAccess(value)}
												/>
												{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}
											</div>
											{/*DROPDOWN MENU GRADIENT SET - END*/}

										</div>
										<div className="cs-formrow-helper-end">
											<p>Specify if your Item contains or provides access to sensitive content. {getSitewideLink('view_our_content_guideline_items', 'gradient-color-txt body-txtitalic--reg')}</p>
										</div>
									</div>
									<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">
										<div className="col-span-12">
											<div className="dialog-box-secondary">
												<div className="content">
													<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">
														<div className="col-span-12 md:col-span-2">
															<p className="header-small-title color-lightest text-white">
																IMPORTANT NOTICE BASED ON YOUR CHANGES.
																<br />
																<br />
																PLEASE REVIEW BEFORE SAVING.
															</p>
														</div>
														<div className="col-span-12 md:col-span-10">
															<ul className="list-none">
																<li className="body-text--small color-lightest text-white">
																	Your Image and Video change will apply to
																	ALL Existing AND New Item sales.
																</li>
																<li className="body-text--small color-lightest text-white">
																	Your Name and Individual Title change will
																	only apply to New Item sales and may
																	cause new Items to display as a new
																	collection on external sites and
																	marketplaces.
																</li>
																<li className="body-text--small color-lightest text-white">
																	Your Description, Price, and Royalty
																	Percentage change will only apply to new
																	Item sales.
																</li>
																{/*<li className="body-text--small color-lightest text-white">*/}
																{/*	Your Monthly Subscription change will apply*/}
																{/*	to ALL Existing AND New Items sales.*/}
																{/*</li>*/}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<Form.Item>
										<div className="formrow">
											<button
												disabled={submitted}
												type="submit" className="w-full secondary-gradient">
												<span><p>
													{submitted && (
														<i className="fa-solid fa-spinner fa-spin"></i>
													)}
													Save
													{/*Save Item & Continue*/}
												</p></span>
											</button>
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>
									</Form.Item>
								</Form>

								<div className="formrow">
									{alert && (
										<section className={"alert banner px-section-safeview" + (alert.variant === 'success' ? ' success' : ' error')}>
											<div className="action">
												<i className="fa-solid fa-circle-check"></i>
											</div>
											<div className="content">
												<hgroup>
													<h6></h6>
													<p>{alert.message}</p>
												</hgroup>
												{/*<a href="#alertlink" class="button">Link Button</a>*/}
											</div>
											<div className="action">
												<a onClick={() => {setAlert(null)}}>
													<i className="fa-solid fa-xmark"></i>
												</a>
											</div>
										</section>
									)}
									<div className="cs-formrow-helper-none">
										<p></p>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>
			</main>

			{/*<div className="block-full card">*/}
			{/*	<div className="items">*/}
			{/*		<div className="dashboard-wrapper">*/}
			{/*			<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*				<div className="col-span-12 md:col-span-6">*/}
			{/*					<h3 className="header-small">Item Details</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-12 md:col-span-6 hidden">*/}
			{/*					/!*<p className="body-text--small gradient-color-txt text-end">*!/*/}
			{/*					/!*	Remove Item*!/*/}
			{/*					/!*</p>*!/*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={editItemForm}*/}
			{/*				name="editItemForm"*/}
			{/*				id="editItemForm"*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*				onFocus={() => {*/}
			{/*					setformIsOnFocusItem(true);*/}
			{/*				}}*/}
			{/*				onBlur={() => {*/}
			{/*					// setTimeout(() => {*/}
			{/*					setformIsOnFocusItem(false);*/}
			{/*					handleItemFormOnBlur().then(r => {});*/}
			{/*					// }, 200);*/}
			{/*				}}*/}
			{/*				onChange={handleFormItemChanges}*/}
			{/*				onFinish={handleSubmit}*/}
			{/*				className={formIsOnFocusItem ? 'hide-antd-error-messages' : ''}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">*/}
			{/*					<div className="col-span-4">*/}
			{/*						<div className="cs-stack-form-media-item">*/}
			{/*							<UploadDraggerV2*/}
			{/*								onError={setImageUploadErrors}*/}
			{/*								cropperAspectRatio={4 / 3}*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={itemImageBlobPreview}*/}
			{/*								onUploadFileChange={handleItemImageUploadFileChange}*/}
			{/*								minHeight={IMAGE_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={IMAGE_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={IMAGE_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={IMAGE_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*								disableCropper={true}*/}
			{/*								shape="aspect-cs-preview"*/}
			{/*							/>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Item Image**/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest">*/}
			{/*							Up to 3840px square or wide. <br />*/}
			{/*							JPEG, PNG, or GIF*/}
			{/*						</p>*/}
			{/*						{itemImageError && (*/}
			{/*							<div className="alert callout warning items-center">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{itemImageError}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}

			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<div className="cs-stack-form-media-item">*/}
			{/*							<UploadDraggerV2*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								onError={setVideoUploadErrors}*/}
			{/*								uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={itemVideoBlobPreview}*/}
			{/*								onUploadFileChange={handleItemVideoUploadFileChange}*/}
			{/*								minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*								shape="aspect-cs-preview"*/}
			{/*							/>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Item Video*/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest">*/}
			{/*							Up to 1920px/1080px, square or wide. <br />*/}
			{/*							MOV or MP4*/}
			{/*						</p>*/}
			{/*						{itemVideoError && (*/}
			{/*							<div className="alert callout warning items-center">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{itemVideoError}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}

			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Your Item Image (up to 8MB) should be as high resolution*/}
			{/*							as possible, up to 3840px height and width. This will also be*/}
			{/*							used for any thumbnails and previews.*/}
			{/*						</p>*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Your Item Image (up to 8MB) should be as high resolution*/}
			{/*							as possible, up to 3840px height and width. This will also be*/}
			{/*							used for any thumbnails and previews.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Name</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Name"*/}
			{/*								name="name"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 32,*/}
			{/*										message: 'Must not exceed 32 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Up to 32 characters, including spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={32}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[0]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[0]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}

			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is the displayed Name of the Item and is what people*/}
			{/*							will see when browsing your Space and viewing their purchased*/}
			{/*							Item, as well as the Collection Name when viewing the*/}
			{/*							artwork.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Sku Short Code</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Sku Short Code"*/}
			{/*								name="sku_shortcode"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										min: 2,*/}
			{/*										message: 'Must be minimum 2 characters.'*/}
			{/*									},*/}
			{/*									{*/}
			{/*										max: 8,*/}
			{/*										message: 'Must not exceed 8 characters.'*/}
			{/*									},*/}
			{/*									({ getFieldValue }) => ({*/}
			{/*										async validator(_, value) {*/}
			{/*											if (value) {*/}
			{/*												const data = {*/}
			{/*													shortcode: value*/}
			{/*												};*/}

			{/*												if (spaceInfo) {*/}
			{/*													data['space_id'] = spaceInfo.id;*/}
			{/*												}*/}

			{/*												if (itemData) {*/}
			{/*													data['item_id'] = itemData.id;*/}
			{/*												}*/}

			{/*												let available = false;*/}
			{/*												const response =*/}
			{/*													await checkItemShortCodeAvailability(*/}
			{/*														data*/}
			{/*													);*/}
			{/*												if (response && response.result) {*/}
			{/*													available = response.availability;*/}
			{/*												}*/}

			{/*												if (!available && value.length <= 8) {*/}
			{/*													return Promise.reject(*/}
			{/*														new Error(*/}
			{/*															'Shortcode not available.'*/}
			{/*														)*/}
			{/*													);*/}
			{/*												}*/}
			{/*											}*/}

			{/*											if (*/}
			{/*												(value && value.match(/\ /g)) ||*/}
			{/*												/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(*/}
			{/*													value*/}
			{/*												)*/}
			{/*											) {*/}
			{/*												return Promise.reject(*/}
			{/*													new Error('Invalid Input')*/}
			{/*												);*/}
			{/*											}*/}

			{/*											return Promise.resolve();*/}
			{/*										}*/}
			{/*									})*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="2 to 8 characters. Letters and numbers only, no spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={8}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[1]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[1]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Choose a unique SKU Shortcode based the Item Name. This is*/}
			{/*							must be unique within your Space and is used in transactions,*/}
			{/*							support, and other areas.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Max Supply</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Max Supply"*/}
			{/*								name="max_supply"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									({ getFieldValue }) => ({*/}
			{/*										validator(_, value) {*/}
			{/*											if (typeof value === 'number') {*/}
			{/*												value = value.toString();*/}
			{/*											}*/}
			{/*											if (*/}
			{/*												isNaN(value) ||*/}
			{/*												value < 0 ||*/}
			{/*												value.match(/\./g)*/}
			{/*											) {*/}
			{/*												return Promise.reject(*/}
			{/*													new Error('Invalid value.')*/}
			{/*												);*/}
			{/*											}*/}

			{/*											return Promise.resolve();*/}
			{/*										}*/}
			{/*									})*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Enter “0” for unlimited."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[2]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[2]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Your Item can have a limited or unlimited Supply. Enter*/}
			{/*							“0” to set the Supply to unlimited, “1” to make it a unique*/}
			{/*							1-of-1, or any larger number to set a higher limit.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Max Per Account</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Max Per Account"*/}
			{/*								name="max_per_account"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{ validator: CustomValidators.maxPerCount }*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Enter “0” for unlimited."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[3]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[3]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							You can put a limit how many Items can be purchased by a*/}
			{/*							single account. Enter “0” to set this to unlimited.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Currency</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Currency"*/}
			{/*								name="currency"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[{ required: true }]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Start typing your currency and select from the list."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[4]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[4]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							You can offer your Item for free or sell it at any price*/}
			{/*							you choose. Denominated in your selected Currency. Enter “0” to*/}
			{/*							set the Price as free.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Price</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Price"*/}
			{/*								name="price"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										validator: CustomValidators.priceMinmax(*/}
			{/*											itemData.currency*/}
			{/*										)*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Enter “0” if free. "*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[5]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[5]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Set the Currency for your Item Price. Any purchases in a*/}
			{/*							different currency will be converted based on market pricing at*/}
			{/*							the time of purchase.{' '}*/}
			{/*							<a className="gradient-color-txt">*/}
			{/*								{' '}{getSitewideLink('see_supported_currencies_and_cryptocurrencies')}*/}
			{/*								/!*See officially supported major currencies and cryptocurrencies.*!/*/}
			{/*							</a>*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				/!*<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4  flex items-center">*!/*/}
			{/*				/!*	<div className="col-span-8">*!/*/}
			{/*				/!*		<div className="form-check ms-5">*!/*/}
			{/*				/!*			<label className="form-check-label body-text--small gradient-color-txt">*!/*/}
			{/*				/!*				<Checkbox*!/*/}
			{/*				/!*					className="form-check-input"*!/*/}
			{/*				/!*					checked={enableExpirationChecked}*!/*/}
			{/*				/!*					onChange={onChangeEnableExpiration}*!/*/}
			{/*				/!*				/>*!/*/}
			{/*				/!*				Enable Expiration Date and add Monthly Subscription for*!/*/}
			{/*				/!*				continued access*!/*/}
			{/*				/!*			</label>*!/*/}
			{/*				/!*		</div>*!/*/}
			{/*				/!*	</div>*!/*/}
			{/*				/!*	<div className="col-span-4">*!/*/}
			{/*				/!*		<p className="body-txtitalic--smallest">*!/*/}
			{/*				/!*			Items have a one-time upfront Price. This option adds an*!/*/}
			{/*				/!*			Expiration after a set number of months. After that, Members*!/*/}
			{/*				/!*			must pay a Monthly Subscription to continue access. Manage*!/*/}
			{/*				/!*			details in the Benefits Section.*!/*/}
			{/*				/!*		</p>*!/*/}
			{/*				/!*	</div>*!/*/}
			{/*				/!*</div>*!/*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Summary</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Summary"*/}
			{/*								name="summary"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 128,*/}
			{/*										message: 'Must not exceed 128 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<TextareaAutosize*/}
			{/*									minRows={3}*/}
			{/*									placeholder="Up to 128 characters, including spaces."*/}
			{/*									className={"w-full bg-transparent border-none no-scrollbar"}*/}
			{/*									maxLength={128}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[6]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[6]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This Summary shows when Members view their Item. It should*/}
			{/*							be 1 to 2 sentences summarizing the Item and its details.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Listing Text</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Listing Text"*/}
			{/*								name="listing_text"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 128,*/}
			{/*										message: 'Must not exceed 128 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<TextareaAutosize*/}
			{/*									minRows={3}*/}
			{/*									placeholder="Up to 128 characters, including spaces."*/}
			{/*									className={"w-full bg-transparent border-none no-scrollbar"}*/}
			{/*									maxLength={128}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[7]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[7]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							The Listing Text is used when listing your Item for*/}
			{/*							purchase on your Space. It should be 1 to 2 sentences*/}
			{/*							summarizing the Item and why people should join.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Individual Title</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Individual Title"*/}
			{/*								name="individual_title"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 32,*/}
			{/*										message: 'Must not exceed 32 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									maxLength={32}*/}
			{/*									placeholder="Up to 32 characters, including spaces. Usually this is the same or similar to the Name."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[8]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[8]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is the Title given to an individual Item when*/}
			{/*							purchased by someone. For example, if your Item Name is*/}
			{/*							“Club All Access Item” then you could make this “Club*/}
			{/*							Item”.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4  flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-check ms-5">*/}
			{/*							<label className="form-check-label body-text--small gradient-color-txt">*/}
			{/*								<Checkbox*/}
			{/*									className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
			{/*									checked={enableAddNumericChecked}*/}
			{/*									onChange={onChangeEnableAddNumeric}*/}
			{/*								/>*/}
			{/*								Add Numeric Count to the Individual Title of each purchased*/}
			{/*								Item*/}
			{/*							</label>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This adds a numeric count to the Individual Title of each*/}
			{/*							Item sold. For example, the first Item sold would*/}
			{/*							have the Title of “Club Item #1”, and the second would be*/}
			{/*							“Club Item #2”, and so on.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4">*/}
			{/*					<div className="col-span-12 md:col-span-8 items-center">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Description</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Description"*/}
			{/*								name="description"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 512,*/}
			{/*										message: 'Must not exceed 512 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<TextareaAutosize*/}
			{/*									minRows={6}*/}
			{/*									placeholder="Up to 512 characters, including spaces."*/}
			{/*									className={"w-full bg-transparent border-none no-scrollbar"}*/}
			{/*									maxLength={512}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editItemForm.getFieldsError()[9]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editItemForm.getFieldsError()[9]?.errors[0]}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4 flex align-top">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is a full Description of your Item. You can use{' '}*/}
			{/*							{getSitewideLink('markdown_syntax')} to add basic formatting.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4  flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-check ms-5">*/}
			{/*							<label className="form-check-label body-text--small gradient-color-txt">*/}
			{/*								<Checkbox*/}
			{/*									className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
			{/*									checked={enableGiftingChecked}*/}
			{/*									onChange={onChangeEnableGifting}*/}
			{/*								/>*/}
			{/*								Enable gifting to accounts and external transfers on*/}
			{/*								marketplaces*/}
			{/*							</label>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Enabling gifting and transfers allows Members to gift a*/}
			{/*							purchased Item to another account. Free gifting is*/}
			{/*							supported within your Space. External transfers and sales can be*/}
			{/*							done on marketplaces like OpenSea.{' '}*/}
			{/*							<a className="gradient-color-txt">{getSitewideLink('learn_more_enable_gifting')}</a>*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				{enableGiftingChecked && (*/}
			{/*					<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*						<div className="col-span-12 md:col-span-8">*/}
			{/*							<div className="form-input w-full mt-2">*/}
			{/*								<label htmlFor="text">Royalty Percentage</label>*/}
			{/*								<Form.Item*/}
			{/*									label="Royalty Percentage"*/}
			{/*									name="royalty_percentage"*/}
			{/*									className="mb-0 hidden-label w-full"*/}
			{/*									validateTrigger="onBlur"*/}
			{/*									rules={[*/}
			{/*										{ required: true },*/}
			{/*										{ validator: CustomValidators.royaltyPercentage }*/}
			{/*									]}*/}
			{/*								>*/}
			{/*									<Input*/}
			{/*										placeholder="Whole numbers only, 0% to 20%."*/}
			{/*										className="input-md body-text--small color-reg"*/}
			{/*									/>*/}
			{/*								</Form.Item>*/}
			{/*							</div>*/}
			{/*							{editItemForm.getFieldsError()[10]?.errors.length > 0 && (*/}
			{/*								<div className="alert callout warning items-center !w-full">*/}
			{/*									<div className="action">*/}
			{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*									</div>*/}
			{/*									<div className="content">*/}
			{/*										<div>*/}
			{/*											<h6>{editItemForm.getFieldsError()[10]?.errors[0]}</h6>*/}
			{/*										</div>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							)}*/}
			{/*						</div>*/}
			{/*						<div className="col-span-12 md:col-span-4">*/}
			{/*							<p className="body-txtitalic--smallest">*/}
			{/*								With Gifting and Transfers enabled, when a Item is sold on*/}
			{/*								an external marketplace, you can specify that a percentage of*/}
			{/*								the sale price be paid to you as a royalty.*/}
			{/*								<a className="gradient-color-txt"> Learn more.</a>*/}
			{/*							</p>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				)}*/}

			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Select Content Type"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							customButtonClass="button gradient secondary distribute w-full"*/}
			{/*							displayError={*/}
			{/*								displayDropDownError && selectedContentType === 'default'*/}
			{/*							}*/}
			{/*							icon={getListIcon(*/}
			{/*								CONTENT_TYPES_LIST_WITH_DEFAULT,*/}
			{/*								selectedContentType*/}
			{/*							)}*/}
			{/*							selectedValue={selectedContentType}*/}
			{/*							listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}*/}
			{/*							onDropdownChange={(value) => openContentTypeModal(value)}*/}
			{/*						/>*/}
			{/*						<ExplicitContent*/}
			{/*							modalOpen={modalOpenExplicitContent}*/}
			{/*							setModalOpen={setModalOpenExplicitContent}*/}
			{/*							selectedItems={selectedExplicitContent}*/}
			{/*							isExplicitContent={true}*/}
			{/*							onSelectedItems={(value) => setSelectedExplicitContent(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Change this setting if your Item Details, Image, or Video*/}
			{/*							contains explicit or sensitive content. If it does, viewers may*/}
			{/*							need to confirm their age before accessing content. View our*/}
			{/*							Content Guidelines.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Select Access Type"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							customButtonClass="button gradient secondary distribute w-full"*/}
			{/*							displayError={*/}
			{/*								displayDropDownError && selectedAccessType === 'default'*/}
			{/*							}*/}
			{/*							icon={getListIcon(*/}
			{/*								ACCESS_TYPES_LIST_WITH_DEFAULT,*/}
			{/*								selectedAccessType*/}
			{/*							)}*/}
			{/*							selectedValue={selectedAccessType}*/}
			{/*							listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}*/}
			{/*							onDropdownChange={(value) => openAccessTypeModal(value)}*/}
			{/*						/>*/}
			{/*						<ExplicitContent*/}
			{/*							modalOpen={modalOpenExplicitAccess}*/}
			{/*							setModalOpen={setModalOpenExplicitAccess}*/}
			{/*							selectedItems={selectedExplicitAccess}*/}
			{/*							isExplicitContent={false}*/}
			{/*							onSelectedItems={(value) => setSelectedExplicitAccess(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Change this setting if this Item will provide Members with*/}
			{/*							access to explicit or sensitive content, either directly on your*/}
			{/*							Space or via an external site or other source. View our Content*/}
			{/*							Guidelines.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Update Visibility"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							customButtonClass="button gradient secondary distribute w-full"*/}
			{/*							selectedValue={selectedVisibility}*/}
			{/*							listItems={UPDATES_VISIBILITY}*/}
			{/*							onDropdownChange={(value) => setSelectedVisibility(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							You can set the Visibility of this Item to viewable to the*/}
			{/*							Public, Members Only (any item), or Custom Access (based*/}
			{/*							on owning a specific Item or Item).*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12">*/}
			{/*						<div className="dialog-box-secondary">*/}
			{/*							<div className="content">*/}
			{/*								<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*									<div className="col-span-12 md:col-span-2">*/}
			{/*										<p className="header-small-title color-lightest text-white">*/}
			{/*											IMPORTANT NOTICE BASED ON YOUR CHANGES.*/}
			{/*											<br />*/}
			{/*											<br />*/}
			{/*											PLEASE REVIEW BEFORE SAVING.*/}
			{/*										</p>*/}
			{/*									</div>*/}
			{/*									<div className="col-span-12 md:col-span-10">*/}
			{/*										<ul className="list-none">*/}
			{/*											<li className="body-text--small color-lightest text-white">*/}
			{/*												Your Image and Video change will apply to*/}
			{/*												ALL Existing AND New Item sales.*/}
			{/*											</li>*/}
			{/*											<li className="body-text--small color-lightest text-white">*/}
			{/*												Your Name and Individual Title change will*/}
			{/*												only apply to New Item sales and may*/}
			{/*												cause new Items to display as a new*/}
			{/*												collection on external sites and*/}
			{/*												marketplaces.*/}
			{/*											</li>*/}
			{/*											<li className="body-text--small color-lightest text-white">*/}
			{/*												Your Description, Price, and Royalty*/}
			{/*												Percentage change will only apply to new*/}
			{/*												Item sales.*/}
			{/*											</li>*/}
			{/*											<li className="body-text--small color-lightest text-white">*/}
			{/*												Your Monthly Subscription change will apply*/}
			{/*												to ALL Existing AND New Items sales.*/}
			{/*											</li>*/}
			{/*										</ul>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<>*/}
			{/*						{alert && (*/}
			{/*							<div className="col-span-12">*/}
			{/*								<Alert*/}
			{/*									key={alert.variant}*/}
			{/*									variant={alert.variant}*/}
			{/*									className={*/}
			{/*										'custom-alert ' +*/}
			{/*										(alert.variant === 'danger'*/}
			{/*											? 'custom-alert-danger'*/}
			{/*											: 'custom-alert-success')*/}
			{/*									}*/}
			{/*								>*/}
			{/*									{alert.message}*/}
			{/*								</Alert>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12">*/}
			{/*						<Form.Item className="mb-0">*/}
			{/*							<button*/}
			{/*								className="primary-gradient w-full"*/}
			{/*								type="submit"*/}
			{/*								disabled={submitted || formHasErrorItem}*/}
			{/*							>*/}
			{/*							<span><p>{submitted && (*/}
			{/*								<i className="fa-solid fa-spinner fa-spin me-2"></i>*/}
			{/*							)}*/}
			{/*								Save</p></span>*/}
			{/*							</button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}
			{/*		/!*<div className="dashboard-wrapper mt-5">*!/*/}
			{/*		/!*	<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*!/*/}
			{/*		/!*		<div className="col-span-12">*!/*/}
			{/*		/!*			<h3 className="header-small">Item Benefits</h3>*!/*/}
			{/*		/!*		</div>*!/*/}
			{/*		/!*	</div>*!/*/}
			{/*		/!*	<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">*!/*/}
			{/*		/!*		<div className="col-span-4">*!/*/}
			{/*		/!*			<p className="body-text--small gradient-color-txt">Member Access</p>*!/*/}
			{/*		/!*		</div>*!/*/}
			{/*		/!*		<div className="col-span-4">*!/*/}
			{/*		/!*			<p className="body-txtitalic--smallest">*!/*/}
			{/*		/!*				The Member Access Benefit provides basic “Members Only” access to*!/*/}
			{/*		/!*				Updates and Members sections in your Space. This is included in*!/*/}
			{/*		/!*				every Item you create and cannot be edited.*!/*/}
			{/*		/!*			</p>*!/*/}
			{/*		/!*		</div>*!/*/}
			{/*		/!*		<div className="col-span-4">*!/*/}
			{/*		/!*			<p className="body-text--small">Included Benefit</p>*!/*/}
			{/*		/!*		</div>*!/*/}
			{/*		/!*	</div>*!/*/}
			{/*		/!*	{enableExpirationChecked && (*!/*/}
			{/*		/!*		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">*!/*/}
			{/*		/!*			<div className="col-span-4">*!/*/}
			{/*		/!*				<p className="body-text--small gradient-color-txt">*!/*/}
			{/*		/!*					Monthly Subscription*!/*/}
			{/*		/!*				</p>*!/*/}
			{/*		/!*			</div>*!/*/}
			{/*		/!*			<div className="col-span-4">*!/*/}
			{/*		/!*				<p className="body-txtitalic--smallest">*!/*/}
			{/*		/!*					The Monthly Subscription Benefit is required when the Expiration*!/*/}
			{/*		/!*					Date option is enabled.*!/*/}
			{/*		/!*				</p>*!/*/}
			{/*		/!*			</div>*!/*/}
			{/*		/!*			<div className="col-span-4 flexjustify-between">*!/*/}
			{/*		/!*				<Link className="btn btn-secondary btn-md m-2">*!/*/}
			{/*		/!*					<i className="fa-light fa-arrow-up-right-from-square"></i>*!/*/}
			{/*		/!*				</Link>*!/*/}
			{/*		/!*				<Link*!/*/}
			{/*		/!*					className="btn btn-secondary btn-md m-2"*!/*/}
			{/*		/!*					to={redirectToItemUpdateBenefitLink(itemData)}*!/*/}
			{/*		/!*				>*!/*/}
			{/*		/!*					<i className="fa-light fa-pen-to-square"></i>*!/*/}
			{/*		/!*				</Link>*!/*/}
			{/*		/!*			</div>*!/*/}
			{/*		/!*		</div>*!/*/}
			{/*		/!*	)}*!/*/}
			{/*		/!*	<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">*!/*/}
			{/*		/!*		<div className="col-span-12">*!/*/}
			{/*		/!*			<DropdownWithItems*!/*/}
			{/*		/!*				title="Add or Create Benefit"*!/*/}
			{/*		/!*				dropDownClass="dropdown-bg"*!/*/}
			{/*		/!*				customButtonClass="btn-md btn-secondary mt-2"*!/*/}
			{/*		/!*				icon="fa-solid fa-gifts"*!/*/}
			{/*		/!*				selectedValue={addCreateBenefit}*!/*/}
			{/*		/!*				listItems={ADD_CREATE_BENEFIT}*!/*/}
			{/*		/!*				onDropdownChange={(value) => setAddCreateBenefit(value)}*!/*/}
			{/*		/!*			/>*!/*/}
			{/*		/!*		</div>*!/*/}
			{/*		/!*	</div>*!/*/}
			{/*		/!*	<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">*!/*/}
			{/*		/!*		<div className="col-span-12">*!/*/}
			{/*		/!*			<Button className="btn btn-primary btn-md">Save</Button>*!/*/}
			{/*		/!*		</div>*!/*/}
			{/*		/!*	</div>*!/*/}
			{/*		/!*</div>*!/*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
};

export default memo(EditItemCommunity);
