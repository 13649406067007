import { memo, useCallback } from 'react';
import { shortenNumber } from '../../../../utils/number';
import routes from '../../../../routes';
import { getSitewideLink } from '../../../../utils/sitewideLinks';
import moment from "moment/moment";
import {ROOT_DOMAIN} from "../../../../constants/common";
import {getDomainUrl} from "../../../../utils/common";

const SpaceItem = (parentProps) => {
	const { data, type, currentUser } = parentProps;
	const backgroundImage = {
		backgroundImage: 'url(' + data.background_photo + ')'
	};
	// const userImageBorder = type !== 'expired' ? 'img-thumb-square me-4' : 'img-thumb-expired me-4';
	const userImageBorder = 'img-thumb-square me-4';
	const textColor =
		type === 'expired'
			? 'expired body-text--smallest'
			: 'gradient-color-txt body-text--smallest';
	const updatesSingularText = type === 'manage' ? ' Pending Update' : ' New Update';
	const benefitsSingularText = type === 'manage' ? ' Pending Benefit' : ' New Benefit';
	const postsSingularText = ' New Post';
	const membersSingularText = ' New Member';
	const updatesPluralText = type === 'manage' ? ' Pending Updates' : ' New Updates';
	const benefitsPluralText = type === 'manage' ? ' Pending Benefits' : ' New Benefits';
	const postsPluralText = ' New Posts';
	const membersPluralText = ' New Members';

	const goToSpace = useCallback(async () => {
		if (ROOT_DOMAIN !== window.location.hostname) {
			if (data.domain_name && data.domain_name === window.location.hostname) {
				window.location.replace('/');
			} else {
				const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
				const protocol = isLocal ? 'http://' : 'https://';
				window.location.href = `${protocol}${ROOT_DOMAIN}${routes.viewUserSpace() + data.username}`;
			}
		} else {
			window.location.replace(routes.viewUserSpace() + data.username);
		}
	}, [data]);

	const redirectToSpaceLink = (route, spaceUsername, prepend = '') => {
		const url = route.replace(':space_username', prepend + spaceUsername);
		window.location.replace(url);
	};

	const redirectToCollectionManageMembership = (route, spaceUsername, mebershipID) => {
		const url = route.replace(':space_username', spaceUsername).replace(':membership_id', mebershipID);
		window.location.replace(url);
	};

	const redirectToSpaceMembershipLink = (route, spaceUsername, membershipId, prepend = '') => {
		const url = route
			.replace(':space_username', prepend + spaceUsername)
			.replace(':membership_id', membershipId);
		window.location.replace(url);
	};

	const showTeamMembers = () => {
		if (type === 'expired') {
			return false;
		} else if (currentUser.profile.username !== data.owner.username) {
			return false;
		} else {
			return true;
		}
	}

	const getImage = (data) => {
		let photo = '';
		if (type === 'manage') {
			photo = data.owner?.photo_small;
			// if (data.photo_small)
			// 	photo = data.photo_small;
			// else
			// 	photo = data.owner?.photo_small;
		} else {
			// if (data.membership && data.memberships && data.memberships.length > 0) {
			if (data.membership) {
				photo = data?.membership?.photo_small;
			} else {
				photo = data.owner?.photo_small;
			}

		}
		return photo;
	}

	return (
		<div className="cs-dash-card cs-dash-card-space block-full card">
			<div className="cs-dash-card-header-banner rounded-t-card"
				 style={backgroundImage}>
				<div className="cs-banner-card-shader p-card rounded-t-card stack-col">
					<div className="formrow">
						<div className="cs-banner-card-info stack-col">
							<h3><i className="fa-regular fa-solar-system"></i> {data.name}</h3>
						</div>
						<div className="cs-banner-card-controls stack-col">
							<a
								onClick={() => goToSpace()}
								className="button secondary distribute"
							>
									<span>
										<i className="fa-light fa-solar-system"></i>
										<p>Access Space</p>
										<i className="fa-light fa-arrow-up-right-from-square"></i>
									</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="datarow p-card">
				<div className="cs-stack-product-data stack-col">
					<div className="stack-row">
						<div className={`avatar gradient md shrink-0 ${type === 'manage' ? 'rounded-full' : 'cs-avatar-membership'}`}>
							<img src={getImage(data)}  alt={''}/>
						</div>
						<hgroup>
							<h4>{type === 'manage' ? currentUser.profile.display_name : data?.membership?.individual_title}</h4>
							{type === 'manage' ? (
								<>
									<p>Owner</p>
									<p>Role granted</p>
								</>
							) : (
								data.membership && (
									<>
										<p>{data?.membership?.summary}</p>
										{data?.membership?.stripe_transaction && (
											<p>
												{"Joined " + moment(data?.membership?.stripe_transaction.created_on).format('MMMM YYYY')}
											</p>
										)}
									</>
								)
							)}
						</hgroup>
					</div>
					{/*<div className="data-item">*/}
					{/*	{data && data?.items && (*/}
					{/*		<div className="stack-avatar md">*/}
					{/*			{data?.items.length > 0 &&*/}
					{/*				data?.items.slice(0, 3).map((item, index) => (*/}
					{/*					<div*/}
					{/*						className="avatar gradient md cs-avatar-item"*/}
					{/*						key={index}*/}
					{/*					>*/}
					{/*						<img src={item.photo} alt="member-thumb"/>*/}
					{/*					</div>*/}
					{/*				))}*/}
					{/*		</div>*/}
					{/*	)}*/}
					{/*	{data && data?.items && data?.items.length > 3 && (*/}
					{/*		<p className="data-count">+{shortenNumber(data?.items.length - 3)}</p>*/}
					{/*	)}*/}
					{/*</div>*/}
				</div>
				<div className="cs-stack-activity">
					{type === 'manage' ?
						(
							<a className="cursor-default">
								<i className="fa-regular fa-newspaper"></i> {shortenNumber(data && data.pending_updates_count ? data.pending_updates_count : 0 )}
								{data && data.pending_updates_count && data.pending_updates_count > 1 ? updatesPluralText : updatesSingularText}
							</a>
						) : (
							<a className="cursor-default">
								<i className="fa-regular fa-newspaper"></i> {shortenNumber(data && data.new_updates_count ? data.new_updates_count : 0 )}
								{data && data.new_updates_count && data.new_updates_count > 1 ? updatesPluralText : updatesSingularText}
							</a>
						)}
					<a className="hidden">
						<i className="fa-regular fa-gifts"></i> {shortenNumber(data && data.metadata && data.metadata.new_benefits_count ? data.metadata.new_benefits_count : 0)}
						{data && data.metadata && data.metadata.new_benefits_count && data.metadata.new_benefits_count > 1 ? benefitsPluralText : benefitsSingularText}
					</a>
					<a className="cursor-default">
						<i className="fa-regular fa-comments"></i> {shortenNumber(data && data.new_posts_count ? data.new_posts_count : 0)}
						{data && data.new_posts_count && data.new_posts_count > 1 ? postsPluralText : postsSingularText}
					</a>
					{type === 'manage' &&
						<a className="cursor-default">
							<i className="fa-solid fa-user"></i> {shortenNumber(data && data.new_members_count ? data.new_members_count : 0 )}
							{data && data.new_members_count > 1 ? membersPluralText : membersSingularText}
						</a>
					}
					<a className="hidden"><i className="fa-regular fa-ranking-star"></i> #1 Rank</a>
					<a className="hidden"><i className="fa-regular fa-star"></i> 20.1k Points</a>
				</div>

				<div className="stack-col">
					{type === 'active' && (
						<a
							onClick={() => {
								redirectToSpaceMembershipLink(
									routes.manageSpaceMembership(),
									data.username,
									data?.membership?.id
								);
								// if (currentUser.profile.username !== data.owner.username) {
								// 	redirectToCollectionManageMembership(
								// 		routes.viewCollectionManageMembership(),
								// 		data.username,
								// 		data?.membership?.id
								// 	);
								// } else {
								// 	redirectToSpaceMembershipLink(
								// 		routes.manageSpaceMembership(),
								// 		data.username,
								// 		data?.membership?.id
								// 	);
								// }
							}}
							className="button gradient secondary"
						>
								<span>
									<p>
										Membership
									</p>
								</span>
						</a>
					)}
					{type === 'expired' && (
						<a
							onClick={() => {
								redirectToSpaceMembershipLink(
									routes.manageSpaceMembershipExpired(),
									data.username,
									data?.membership?.id
								);
							}}
							className="button gradient secondary"
						>
								<span>
									<p>
										{data.membership &&
										data?.membership?.stripe_transaction &&
										data?.membership?.stripe_transaction.status ===
										'expired' ? (
											<>Renew Membership</>
										) : (
											<>Activate</>
										)}
									</p>
								</span>
						</a>
					)}
					{type === 'manage' && (
						<a
							onClick={() => {
								redirectToSpaceLink(
									routes.spaceDashboard(),
									data.username,
									'@'
								);
							}}
							className="button gradient secondary"
						>
								<span>
									<p>
										Manage Space
									</p>
								</span>
						</a>
					)}
					<a href="#" className="button gradient secondary"><span><p>Transactions</p></span></a>
					{getSitewideLink('get_support', 'button gradient secondary', true)}
				</div>
			</div>
		</div>
		// <>
		// 	<div className="grid grid-cols-12 md:gap-8">
		// 		<div className="col-span-12">
		// 			<div className="spaces-container">
		// 				<div className="spaces-header" style={backgroundImage}>
		// 					<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 items-center">
		// 						<div className="col-span-12 md:col-span-4 text-center md:text-left">
		// 							<h5>{data.name}</h5>
		// 						</div>
		//
		// 						<div className="lg:hidden inline col-span-12 md:col-span-2 items-center">
		// 							<Link
		// 								className="btn btn-tertiary btn-md"
		// 								onClick={() => goToSpace()}
		// 							>
		// 								<i className="fa-light fa-arrow-up-right-from-square"></i>
		// 							</Link>
		// 						</div>
		//
		// 						<div className="col-span-12 md:col-span-5 ">
		// 							<i className="fa-light fa-users me-3"></i>
		// 							<label className="body-text--smallest">
		// 								{shortenNumber(data && data.metadata && data.metadata.members_count ? data.metadata.members_count : 0)} Members
		// 							</label>
		// 						</div>
		// 						<div className="hidden lg:inline lg:col-span-3 items-center flex justify-end">
		// 							<Link
		// 								className="btn btn-tertiary btn-md"
		// 								onClick={() => goToSpace()}
		// 							>
		// 								<i className="fa-light fa-solar-system"></i>
		// 								<p className="w-3/4 two-line-ellipsis text-center">{data.name}</p>
		// 								<i className="fa-light fa-arrow-up-right-from-square"></i>
		// 							</Link>
		// 						</div>
		// 					</div>
		// 					<div className="bg-overlay"></div>
		// 				</div>
		// 				<div className="spaces-content">
		// 					<div className="grid grid-cols-12 md:gap-8">
		// 						<div className="col-span-12 lg:col-span-6">
		// 							<div className="flex-col md:flex ">
		// 								<img
		// 									className={userImageBorder}
		// 									src={data && data.memberships && data?.memberships[0] ? data?.memberships[0]?.photo_small : data.owner.photo_small}
		// 								/>
		// 								<div>
		// 									<h6
		// 										className={
		// 											'header-title ' +
		// 											(type === 'expired' ? 'expired' : '')
		// 										}
		// 									>
		// 										{type === 'manage'
		// 											? currentUser.profile.full_name
		// 											: '@' +
		// 											  currentUser.profile.username +
		// 											  '’s Membership'}
		// 									</h6>
		// 									<p className="body-text--smallest">
		// 										{type === 'manage' ? 'Owner' : data.space_title}
		// 									</p>
		// 									{data.membership && (
		// 										<>
		// 											{data?.membership?.name}
		// 											<p className="body-txtitalic--smallest">
		// 												{/*Expired {format(new Date(data?.membership?.stripe_transaction.stripe_date_cancellation), 'MMMM d, yyyy')}*/}
		// 											</p>
		// 										</>
		// 									)}
		// 									{!data.membership && (
		// 										<p className="body-txtitalic--smallest">
		// 											{type === 'manage'
		// 												? 'Role granted '
		// 												: data.join_date ? 'Member since ' + data.join_date : ''}
		// 										</p>
		// 									)}
		// 								</div>
		// 							</div>
		// 							{/* {(showTeamMembers()) && (
		// 								<div className="flex items-center justify-content-start mt-4">
		// 									{(!data.members || data.members.length === 0) && (
		// 										<p className="body-text--smallest expired mt-5">
		// 											No additional Team Members.
		// 										</p>
		// 									)}
		// 									{data.members && (
		// 										<ul className="polygon-membership">
		// 											{data.members.length > 0 &&
		// 												data.members
		// 													.slice(0, 5)
		// 													.map((member, index) => (
		// 														<li key={index}>
		// 															<Link
		// 																to="#"
		// 																className={
		// 																	type !== 'manage'
		// 																		? 'hex'
		// 																		: ''
		// 																}
		// 															>
		// 																<img
		// 																	className={
		// 																		type === 'manage'
		// 																			? 'img-thumb-circle'
		// 																			: ''
		// 																	}
		// 																	alt="member-thumb"
		// 																	src={member.photo}
		// 																/>
		// 															</Link>
		// 														</li>
		// 													))}
		// 										</ul>
		// 									)}
		// 									{data.members && data.members.length > 4 && (
		// 										<Link
		// 											to="#"
		// 											className="gradient-color-txt body-text--smallest ms-4"
		// 										>
		// 											+{shortenNumber(data.members.length - 5)} more
		// 										</Link>
		// 									)}
		// 								</div>
		// 							)} */}
		// 							<div className="grid grid-cols-12 flex items-center pt-5 gap-4">
		// 								<div className="col-span-12 grid md:flex items-center justify-start overflow-hidden ">
		// 									{data && data?.items && (
		// 										<ul className="polygon-membership grid grid-flow-col auto-cols-min ">
		// 											{data?.items.length > 0 &&
		// 												data?.items.slice(0, 10).map((item, index) => (
		// 													<li key={index} >
		// 														<Link to="#" className="hex">
		// 															<img alt="member-thumb" className='object-cover' src={item.photo} />
		// 														</Link>
		// 													</li>
		// 												))}
		// 										</ul>
		// 									)}
		// 									{data && data?.items && data?.items.length > 10 ? (
		// 										<Link to="#" className="gradient-color-txt body-text--smallest ms-4 max-[768px]:col-span-12">
		// 											<i>+{shortenNumber(data?.items.length - 9)} more</i>
		// 										</Link>
		// 									) : data.items && data.items.length > 0 ?(
		// 										<Link to="#" className="gradient-color-txt body-text--smallest ms-4  max-[768px]:col-span-12">
		// 											<i>{data && data?.items ? data?.items.length : '0'} Items owned</i>
		// 										</Link>
		// 									) : (
		// 										<></>
		// 									)}
		// 								</div>
		// 							</div>
		// 						</div>
		// 						<div className="col-span-12 lg:col-span-3">
		// 							<ul className="spaces-menu">
		// 								<li>
		// 									<Link to="#" className={textColor}>
		// 										<i className="fa-solid fa-newspaper"></i>{' '}
		// 										{shortenNumber(data && data.metadata && data.metadata.new_updates_count ? data.metadata.new_updates_count : 0 )}
		// 										{data && data.metadata && data.metadata.new_updates_count && data.metadata.new_updates_count > 1
		// 											? updatesPluralText
		// 											: updatesSingularText}
		// 									</Link>
		// 								</li>
		// 								<li>
		// 									<Link to="#" className={textColor}>
		// 										<i className="fa-solid fa-gifts"></i>{' '}
		// 										{shortenNumber(data && data.metadata && data.metadata.new_benefits_count ? data.metadata.new_benefits_count : 0)}
		// 										{data && data.metadata && data.metadata.new_benefits_count && data.metadata.new_benefits_count > 1
		// 											? benefitsPluralText
		// 											: benefitsSingularText}
		// 									</Link>
		// 								</li>
		// 								<li>
		// 									<Link to="#" className={textColor}>
		// 										<i className="fa-solid fa-comments"></i>{' '}
		// 										{shortenNumber(data && data.metadata && data.metadata.new_posts_count ? data.metadata.new_posts_count : 0)}
		// 										{data && data.metadata && data.metadata.new_posts_count && data.metadata.new_posts_count > 1
		// 											? postsPluralText
		// 											: postsSingularText}
		// 									</Link>
		// 								</li>
		// 								<li>
		// 									{type === 'manage' ? (
		// 										<Link to="#" className={textColor}>
		// 											<i className="fa-solid fa-user"></i>
		// 											{shortenNumber(data && data.new_members_count ? data.new_members_count : 0 )}{' '}
		// 											{data && data.new_members_count > 1
		// 												? membersPluralText
		// 												: membersSingularText}
		// 										</Link>
		// 									) : (
		// 										<>
		// 										</>
		// 										// <Link to="#" className={textColor}>
		// 										// 	<i className="fa-solid fa-ranking-star"></i> #
		// 										// 	{shortenNumber(data.metadata.rank)} Rank
		// 										// </Link>
		// 									)}
		// 								</li>
		// 								<li>
		// 									<Link to="#" className={textColor}>
		// 										<i className={'me-2 ' + data.hot_icon}></i>
		// 										<label>
		// 											{shortenNumber(data.hot_count)} {data.hot_name}
		// 										</label>
		// 									</Link>
		// 								</li>
		// 							</ul>
		// 						</div>
		// 						<div className="col-span-12 lg:col-span-3 items-center">
		// 							{type === 'active' && (
		// 								<Link
		// 									onClick={() => {
		// 										redirectToSpaceMembershipLink(
		// 											routes.manageSpaceMembership(),
		// 											data.username,
		// 											data?.membership?.id
		// 										);
		// 									}}
		// 									className="btn btn-secondary btn-md"
		// 								>
		// 									Manage Membership
		// 								</Link>
		// 							)}
		// 							{type === 'expired' && (
		// 								<Link
		// 									onClick={() => {
		// 										redirectToSpaceMembershipLink(
		// 											routes.manageSpaceMembershipExpired(),
		// 											data.username,
		// 											data?.membership?.id
		// 										);
		// 									}}
		// 									className="btn btn-secondary btn-md"
		// 								>
		// 									{data.membership &&
		// 									data?.membership?.stripe_transaction &&
		// 									data?.membership?.stripe_transaction.status ===
		// 									'expired' ? (
		// 										<>Renew Membership</>
		// 									) : (
		// 										<>Activate</>
		// 									)}
		// 								</Link>
		// 							)}
		// 							{type === 'manage' && (
		// 								<Link
		// 									onClick={() => {
		// 										redirectToSpaceLink(
		// 											routes.spaceDashboard(),
		// 											data.username,
		// 											'@'
		// 										);
		// 									}}
		// 									className="btn btn-secondary btn-md"
		// 								>
		// 									Manage Space
		// 								</Link>
		// 							)}
		//
		// 							<Link to="#" className="btn btn-warning btn-md mt-4 text-center">
		// 								View Transactions
		// 							</Link>
		//
		// 							{/*<Link to="#" className="btn btn-warning btn-md mt-4">*/}
		// 							{/*	Get Support*/}
		// 							{/*</Link>*/}
		// 							<div className="flex justify-center mt-2">
		// 								{getSitewideLink('get_support', 'btn btn-warning btn-md mt-3')}
		// 							</div>
		//
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </>
	);
};

export default memo(SpaceItem);
