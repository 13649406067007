import { memo, useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';

import WalletConnectedSetAccount from './RegisterContent/WalletConnectedSetAccount';
import EmailConfirmedAddAccountDetails from './RegisterContent/EmailConfirmedAddAccountDetails';

import { MAGIC_CONNECT_KEY, MAGIC_CONNECT_NETWORK } from '../../constants/common';
import { registerMagic } from '../../services/user';
import {getSpaceItemData, getSpaceMembership} from '../../services/space';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import { getAuthUserInfo } from '../../states/general/actions';
import { setAccessToken } from '../../utils/common';
import routes from '../../routes';

import './Register.scss';

import default_items_img from '../../assets/images/dj-party-v2.png';
import {getSitewideLink} from "../../utils/sitewideLinks";
import { getLocalStorage } from '../../utils/storage';

const RegisterForm = (parentProps) => {
	const {
		emailVerificationToken,
		verified,
		email,
		verificationMessage,
		verifying,
		verifyEmailSpaceUsername,
		verifyEmailSpaceMembershipId,
		verifyEmailSpaceItemId
	} = parentProps.props;
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [isMagicLink, setIsMagicLink] = useState(false);
	const [isCreateWithEmail, setIsCreateWithEmail] = useState(false);
	const [alert, setAlert] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [proceedDisplay, setProceedDisplay] = useState(false);
	const [membershipDetails, setMembershipDetails] = useState(null);
	const [itemDetails, setItemDetails] = useState(null);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const web3 = new Web3(magic.rpcProvider);

	const registerMagicUser = async (values) => {
		if (authUserInfo) {
			values.email = authUserInfo.email;
		}
		const result = await registerMagic(values);
		if (result) {
			if (result.result) {
				dispatch(getAuthUserInfo());
                if(getLocalStorage('redirect_link')){
                    window.location.replace(getLocalStorage('redirect_link'))
                }else{
                    if (location.pathname === routes.connectWallet()) {
                        navigate(routes.dashboard());
                        return;
                    }
                }
				if (result.access_token) {
					setAccessToken(result);
				}
				form.resetFields();
				setAlert({
					variant: 'success',
					message:
						'Registered successfully! Please complete your details on the next page.'
				});
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'Something went wrong'
					});
				}
			}
		}
	};

	const getWalletInfo = (values) => {
		magic.connect.getWalletInfo().then((wallet_info) => {
			values.type = wallet_info.walletType;
			registerMagicUser(values).then(r => {});
		});
	};

	const getUserInfo = (values) => {
		magic.connect.requestUserInfo({ isResponseRequired: true }).then((user_account) => {
			values.wallet_email = user_account.email;
			getWalletInfo(values);
		});
	};

	// Web 3 Login
	const web3Login = async () => {
		web3.eth
			.getAccounts()
			.then((accounts) => {
				let values = {
					user_public_address: accounts?.[0]
				};
				getUserInfo(values);
			})
			.catch((error) => {
				setSubmitted(false);
				setIsMagicLink(false);
				setIsCreateWithEmail(false);
			});
	};

	const magicLogin = async (isMagicLink) => {
		setSubmitted(true);
		setIsMagicLink(isMagicLink);
		setIsCreateWithEmail(!isMagicLink);
		await magic.connect.disconnect().catch((e) => {});
		await web3Login();
	};

	const skipForNow = () => {
        const redirectLink = getLocalStorage('redirect_link');
        if (redirectLink && redirectLink !== 'null' && redirectLink !== '') {
            window.location.replace(redirectLink);
        } else {
            navigate(routes.dashboard());
        }
	};

	const getMembership = useCallback(async (space_id, membership_id) => {
		const response = await getSpaceMembership({
			space_id: space_id,
			membership_id: membership_id
		});
		if (response && response.result) {
			const membershipData = response.membership;
			setMembershipDetails(membershipData);
		}
	}, []);

	const getItem = useCallback(async (space_id, item_id) => {
		const response = await getSpaceItemData({
			space_id: space_id,
			item_id: item_id
		});
		if (response && response.result) {
			const itemData = response.item;
			setItemDetails(itemData);
		}
	}, []);

	useEffect(() => {
		if (verifyEmailSpaceUsername) {
			if (spaceInfoLoading && spaceInfoLoading === 'done') {
				if (spaceInfo && spaceInfo.id) {
					if (verifyEmailSpaceMembershipId) {
						getMembership(spaceInfo.id, verifyEmailSpaceMembershipId).then((r) => {});
					}
					if (verifyEmailSpaceItemId) {
						getItem(spaceInfo.id, verifyEmailSpaceItemId).then((r) => {});
					}
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(verifyEmailSpaceUsername));
				}
			}
		}

		if (loading && loading === 'done') {
			if (authUserInfo) {
				if (location.pathname === routes.walletSetAccount() && authUserInfo.email) {
					form.setFieldsValue({
						email: authUserInfo.email
					});
				} else {
					if (
						location.pathname !== routes.connectWallet() &&
						location.pathname !== routes.emailVerificationWalletSetPassword() &&
						(!verifyEmailSpaceUsername || (!verifyEmailSpaceMembershipId && !verifyEmailSpaceItemId))
					) {
						if (
							authUserInfo.profile &&
							!authUserInfo.profile.username &&
							!authUserInfo.profile.display_name &&
							!authUserInfo.profile.city &&
							(!getLocalStorage('redirect_link') || getLocalStorage('redirect_link') === 'null')
						) {
							window.location.replace(routes.registerMemberProfile());
						}
					}
				}
				setProceedDisplay(true);
			} else {
				if (location.pathname === routes.connectWallet()) {
					navigate(routes.login());
				}
			}
		}
	}, [loading, authUserInfo, spaceInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId]);

	return (
            <main id="cs-platform-main" className="cs-main main-theme"> 
                <section id="cs-platform-auth-c1" className="py-0 px-0 sm:py-section-safeview theme-transparent">
                    <div className="cs-centerframe-split">
                        <div className="cs-centerframe-split-left order-2 sm:order-1">
							{proceedDisplay && authUserInfo &&
							location.pathname !== routes.emailVerificationWalletSetPassword() &&
							(!verifyEmailSpaceUsername || (!verifyEmailSpaceMembershipId && !verifyEmailSpaceItemId)) ? (
								<>
									{location.pathname === routes.walletSetAccount() ? (
										<WalletConnectedSetAccount authUserInfo={authUserInfo} />
									) : (
										{/* <div className="p-section-safeview sm:pr-gutter">
											<div className="cs-stack-auth-form sm:max-w-half-breakpoint-less-gutter">
												<h1 className="mb-8 header-large">Connect a Wallet</h1>
												<h5 className="mb-8">
													If you have an ethereum-based wallet such as MetaMask or
													Coinbase Wallet, you can connect it here.
												</h5>
												<button type="submit"
													className="primary-gradient w-full mt-4"
													onClick={()=>magicLogin(true)}
													disabled={submitted}
												>
													<span><p>
														<i className={isMagicLink ? 'fa-solid fa-spinner fa-spin' : 'fa-solid fa-wallet'}/>
														{' '}Connect Existing Wallet
													</p></span>
												</button>
												<button type="submit"
													className="primary-gradient w-full mt-4"
													onClick={()=>magicLogin(false)}
													disabled={submitted}
												>
													<span><p>
														<i className={isCreateWithEmail ? 'fa-regular fa-spinner fa-spin' : 'fa-regular fa-wallet'}/>
														{' '}Create with Email
													</p></span>
												</button>
												<button type="submit"
													className="secondary-gradient w-full mt-4"
													disabled={submitted}
													onClick={skipForNow}
												>
													<span><p>Skip</p></span>
												</button>
											</div>
										</div> */}
									)}
								</>
							) : (
								<>
									{emailVerificationToken && (
										<>
											{verifying && (
												<div className="p-section-safeview sm:pr-gutter text-center">
													<div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter">
														<hgroup className="heading">
															<h2>Verifying</h2>
															<p className="text-p1">
																<h5>Please wait...</h5>
															</p>
														</hgroup>
													</div>
												</div>
											)}
											{!verifying && !verified && (
												<div className="p-section-safeview sm:pr-gutter text-center">
													<div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter">
														<hgroup className="heading">
															<h2>Oops!</h2>
															<p className="text-p1">
																<h5>{verificationMessage}</h5>
															</p>
														</hgroup>
													</div>
												</div>
											)}
											{!verifying && verified && (
												<EmailConfirmedAddAccountDetails
													email={email}
													authUserInfo={authUserInfo}
													verifyEmailSpaceUsername={verifyEmailSpaceUsername}
													verifyEmailSpaceMembershipId={
														verifyEmailSpaceMembershipId
													}
													verifyEmailSpaceItemId={
														verifyEmailSpaceItemId
													}
												/>
											)}
										</>
									)}
								</>
							)}
							{authUserInfo ? (
								
                                <></>
							) : (
								<>
									{emailVerificationToken && (
										<>
											{location.pathname === routes.emailVerificationWalletSetPassword() || (verifyEmailSpaceUsername && verifyEmailSpaceMembershipId) ? (
												<h5 className="mt-5 fst-italic">
													Need help?
													{getSitewideLink('get_support', 'gradient-color-txt ms-1')}.
												</h5>
											) : (
												<>
													{/* {verifying && !verified && (
														<div className="p-section-safeview sm:pr-gutter text-center">
															<div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter text-center">
																<hgroup className="heading">
																	<h2>Already have an account?</h2>
																	<p className="text-p1">
																		<h5>
																			<Link
																				to={routes.login()}
																				className="gradient-color-txt ms-1"
																			>
																				Login here.
																			</Link>
																		</h5>
																	</p>
																</hgroup>
															</div>
														</div>
													)} */}
												</>
											)}
										</>
									)}
								</>
							)}
                        </div>
                        <div className="cs-centerframe-split-right order-1 sm:order-2">                    
                            <div className="lg:pl-half-gutter">
                                <img src={membershipDetails ? membershipDetails.photo : default_items_img} className="sm:max-h-element"></img>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
			
	);
};

const Register = (parentProps) => {
	return <RegisterForm props={parentProps} />;
};

export default memo(Register);
