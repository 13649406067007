import { memo, useEffect, useState, useCallback } from 'react';
import {Button, Form, Pagination} from 'antd';
import { useSelector } from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { getMySpaces, getSpaceBenefits } from '../../../services/space';
import default_space_bg from '../../../assets/images/profile_spaces_bg.png';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import {
    MANAGE_BENEFITS_FILTER, MANAGE_BENEFITS_SORT,
    MANAGE_MEMBERS_FILTER,
    MANAGE_MEMBERS_SORT,
    SPACE_LAUNCH_LINK_VALIDATION
} from "../../../constants/space";
import CreateBenefit from './CreateBenefit';
import CustomPagination from '../../../components/commons/CustomPagination/CustomPagination';
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import InviteGrantMembershipModal from "../../../components/commons/Modals/InviteGrantMembership";

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const Benefits = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('active');
    const [spaceUsername, setSpaceUsername] = useState(null);
    const currentLocation = useLocation();
    const [selectedBenefitType, setSelectedBenefitType] = useState('default');
    const [selectedFilter, setSelectedFilter] = useState('default');
    const [selectedSort, setSelectedSort] = useState('default');
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [ spaceBenefits, setSpaceBenefits ] = useState([]);
    const [ totalBenefits, setTotalBenefits ] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedBenefit, setSelectedBenefit] = useState(null);

    const benefitRedux = useSelector((state) => state.benefits.benefit);
    const editBenefitRedux = useSelector((state) => state.benefits.editBenefit);

    const pageSize = 5;
    const createNewDropdownOptions = [
        { label: 'Create New', value: 'default', disabled: true },
        { label: 'Download', value: 'download_benefit' },
        { label: 'Access', value: 'access_benefit' }
    ];

    const redirectToSpaceLink = (route, spaceUsername) => {
		if (spaceUsername) {
			return route.replace(':space_username', '@' + spaceUsername);
		}
		return '';
	};

    const onPageChange = (page) => {
        setCurrentPage(page);
        const section = document.getElementById('draggable-cards');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const fetchData = async () => {
        if(spaceInfo){
            const data = {space_id: spaceInfo.id, page: currentPage, per_page: pageSize };
            const response = await getSpaceBenefits(data);
            setSpaceBenefits(response.space_benefits)
            setTotalBenefits(response.total_benefits)
        }
    };

    const filterDropdownChange = useCallback(
        async (value) => {
            console.log(value);
        },
        []
    );

    const sortDropdownChange = useCallback(
        async (value) => {
            console.log(value);
        },
        []
    );

    const handleUpdateBenefit = useCallback(
        (benefit) => {
            setSelectedBenefit(benefit);
        },
        [spaceInfo]
    );

    useEffect(() => {
        if (benefitRedux) {
            setSpaceBenefits(prevState => [...prevState, benefitRedux]);
        }
    },[benefitRedux])

    useEffect((editBenefitRedux) => {
        if (editBenefitRedux) {
            // Replace the object with the specified ID
            setSpaceBenefits(prevItems => prevItems.map(item =>
                item.id === editBenefitRedux.id ? editBenefitRedux : item
            ));
        }
    },[editBenefitRedux])

    useEffect(() => {
        fetchData();
    }, [currentPage, spaceInfo]);

    useEffect(() => {
        if(spaceInfo){                       
            fetchData();
        }                     
    }, [spaceInfo]);

    useEffect(() => {
		const path = currentLocation.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.replace('/@', '');
			if (username.includes('/')) {
				const usernameExp = username.split('/');
				username = usernameExp[0] ? usernameExp[0] : '';
				setSpaceUsername(username);
            }
        }
    })

    useEffect(()=> {
        if (selectedBenefit) {
            let url = redirectToSpaceLink(routes.editAccessBenefit(), spaceInfo.username);
            if (selectedBenefit.type === 'download') {
                url = redirectToSpaceLink(routes.editDownloadBenefit(), spaceInfo.username);
            }
            navigate(url.replace(':benefit_id', selectedBenefit.id));
            // window.location.replace(url.replace(':benefit_id', selectedBenefit.id));
        }
    }, [totalBenefits, selectedBenefit]);

    useEffect(()=> {
        if (selectedBenefitType === 'access_benefit') {
            navigate(redirectToSpaceLink(routes.createAccessBenefit(), spaceInfo.username));
        }
        if (selectedBenefitType === 'download_benefit') {
            navigate(redirectToSpaceLink(routes.createDownloadBenefit(), spaceInfo.username));
        }
    }, [spaceInfo, selectedBenefitType]);

	return (
        <>
            <div className="cs-dash-card block-full card">
                <hgroup className="heading">
                    <h2>Manage Benefits</h2>
                    <p><a href="#link-via-admin" target="_blank">Benefits</a> customizable access, redemptions, privileges, and more to your Members.
                        Each Benefit can be assigned multiple Memberships and Items, and also has an optional Benefits Post which displays in the Benefits section of your Space.
                        There are many types of Benefits and you can add as many as you like. {getSitewideLink('learn_more_benefits')}</p>
                </hgroup>
                <div id="cs-dash-card-nav-spaces" className="cs-nav-tabs">
                    <nav className="cs-nav-buttons-row block-full">
                        <div className="stack-row">
                            <a
                                onClick={() => {
                                    setActiveTab('active');
                                }}
                                className={"button secondary gradient rounded-corners " + (activeTab === 'active' ? 'active' : '')}
                            >
                                <span><p>Active</p></span>
                            </a>
                            <a
                                onClick={() => {
                                    setActiveTab('inactive');
                                }}
                                className={"button secondary gradient rounded-corners " + (activeTab === 'inactive' ? 'active' : '')}
                            >
                                <span><p>Inactive</p></span>
                            </a>
                            <a
                                onClick={() => {
                                    setActiveTab('posts');
                                }}
                                className={"button secondary gradient rounded-corners " + (activeTab === 'posts' ? 'active' : '')}
                            >
                                <span><p>2 Posts</p></span>
                            </a>
                        </div>
                    </nav>
                </div>
            </div>

            {activeTab === 'active' && (
                <div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 block-full card">
                    <div className="col-span-12">
                        <div className="dashboard-wrapper">
                            <div className="section-flex">
                                <div className="block-lg">
                                    <div className="section-flex items-center">
                                        <div className="block-md">
                                            <h2>Create New</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-lg">
                                    <div className="section-flex">
                                        <DropdownWithItems
                                            title="Create New"
                                            customButtonClass="button gradient secondary distribute w-full"
                                            icon="fa-light fa-gift"
                                            selectedValue={selectedBenefitType}
                                            listItems={createNewDropdownOptions}
                                            onDropdownChange={(value) => { setSelectedBenefitType(value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 mt-5 gap-8">
                                <DropdownWithItems
                                    title="Currency"
                                    customButtonClass="button gradient secondary distribute w-full"
                                    icon="fa-light fa-filter"
                                    selectedValue={selectedFilter}
                                    listItems={MANAGE_BENEFITS_FILTER}
                                    onDropdownChange={(value) => filterDropdownChange(value)}
                                />
                                <DropdownWithItems
                                    title="Currency"
                                    customButtonClass="button gradient secondary distribute w-full"
                                    icon="fa-light fa-bars-sort"
                                    selectedValue={selectedSort}
                                    listItems={MANAGE_BENEFITS_SORT}
                                    onDropdownChange={(value) => sortDropdownChange(value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {activeTab === 'active' && spaceBenefits && spaceBenefits.length > 0 && totalBenefits && (
                <>
                    <div id="draggable-cards" className="cs-stack-form-drag-cards gap-gutter">
                        {spaceBenefits.map((benefit) => (
                            <div key={benefit.id} draggable="true" className="cs-dash-card block-full card cs-form-drag-card">
                                <div className="cs-stack-controlrows gap-form">
                                    <div className="cs-form-drag-card-title">
                                        <div className="cs-controls-draggable">
                                            <p><i className="fa-regular fa-grip-lines"></i></p>
                                            <div className="cs-controls-draggable-buttons">
                                                <button><i className="fa-regular fa-angle-up"></i></button>
                                                <button><i className="fa-regular fa-angle-down"></i></button>
                                            </div>
                                        </div>
                                        <div className="cs-benefit-card-title stack-row">
                                            <h4>{benefit.name}</h4>
                                            <p>{benefit.description}</p>
                                        </div>
                                    </div>
                                    <div className="cs-stack-icons-attributes">
                                        <p><i className="fa-regular fa-id-card"></i></p>
                                        <p>{benefit.id}</p>
                                        <p><i className="fa-regular fa-hexagon-image"></i></p>
                                        <p>{benefit.imageCount}</p>
                                    </div>
                                    <div className="cs-access-card-controls stack-row">
                                        <a href="#" id="modal-open"
                                           className="button gradient secondary"
                                           onClick={() => { handleUpdateBenefit(benefit) }}
                                        >
                                            <span><i className="fa-light fa-money-check-pen"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="cs-pagination block-full">
                        <CustomPagination items={totalBenefits} pageSize={pageSize} currentPage={currentPage} onPageChange={onPageChange} />
                    </div>
                </>
            )}
        </>
	);
};

export default memo(Benefits);
