import React, {memo, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Form} from "antd";
import {MEMBER_QUESTIONS_DROPDOWN, MEMBER_QUESTIONS_REQUIRED_DROPDOWN, MEMBER_QUESTIONS_VISIBILITY_DROPDOWN, SPACE_VISIBILITY} from "../../../../constants/space";
import DropdownWithItems from "../../../../components/commons/DropdownWithItems";
import {VALIDATE_MESSAGES} from "../../../../constants/common";
import {createSpaceChannel, updateSpaceChannel} from "../../../../services/space";
import {getSpaceInfo} from "../../../../states/spaceGlobal/actions";
import {CUSTOM_REGEX} from "../../../../constants/custom_regex";
import FormFieldStacked from "../../../../components/commons/FormFieldStacked";
import {createMemberQuestion, deleteMemberQuestion, editMemberQuestion, getMemberQuestions} from "../../../../services/user";


const MemberQuestions = (props) => {
    const { authUserInfo, spaceInfo } = props;

    const dispatch = useDispatch();
    const [createQuestionForm] = Form.useForm();
    const [editQuestionForm] = Form.useForm();

    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [formHasError, setFormHasError] = useState(false);
    const [isCreateQuestionLoading, setIsCreateQuestionLoading] = useState(false);
    const [isEditQuestionLoading, setIsEditQuestionLoading] = useState(false);
    const [isDeleteQuestionLoading, setIsDeleteQuestionLoading] = useState(false);

    const [selectedDropdownQuestion, setSelectedDropdownQuestion] = useState('default');
    const [selectedDropdownRequired, setSelectedDropdownRequired] = useState('required');
    const [selectedDropdownVisibility, setSelectedDropdownVisibility] = useState('visible');

    const [memberQuestions, setMemberQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState();

    const handleCreateQuestionChange = useCallback((value) => {
            setSelectedDropdownQuestion(value);
            // if (value === 'create') {
            //     setSelectedQuestion(null);
            // }
        },
        []
    );

    const handleRequiredQuestionChange = useCallback((value) => {
            setSelectedDropdownRequired(value);
        },
        []
    );

    const handleVisibilityQuestionChange = useCallback((value) => {
            setSelectedDropdownVisibility(value);
        },
        []
    );

    const checkValidQuestionData = useCallback(
        () => {
            if (selectedDropdownVisibility === 'default' || selectedDropdownRequired === 'default') {
                return false;
            } else {
                return true
                // if (selectedVisibility === 'hidden' && selectedAccess === 'default')
                //     return false;
                // else
                //     return true
            }
        },
        [selectedDropdownVisibility, selectedDropdownRequired]
    );

    const handleSaveEditQuestion = useCallback(async (values) => {
            try {
                if (spaceInfo) {
                    setIsEditQuestionLoading(true);

                    const data = {
                        member_question_id: selectedQuestion.id,
                        question_text: values.question_text,
                        question_help_text: values.question_help_text,
                        is_visible: selectedDropdownVisibility === 'visible',
                        is_required: selectedDropdownRequired === 'required',
                        // order: values.order,
                        // question_type: values.question_type,
                    };

                    const response = await editMemberQuestion(data);
                    if (response && response.result) {
                        const memberQuestion = response.member_question;

                        // Update the memberQuestions array
                        const updatedMemberQuestions = memberQuestions.map(mq =>
                            mq.id === memberQuestion.id ? memberQuestion : mq
                        );

                        setMemberQuestions(updatedMemberQuestions);

                        setIsEditQuestionLoading(false);
                    } else {
                        setIsEditQuestionLoading(false);
                    }
                }
            } catch (error) {
                setIsEditQuestionLoading(false);
            }
        },
        [
            authUserInfo, spaceInfo, selectedDropdownVisibility, selectedDropdownRequired, isCreateQuestionLoading, selectedQuestion, memberQuestions
        ]
    );

    const handleDeleteQuestion = useCallback(async () => {
            try {
                if (spaceInfo) {
                    setIsDeleteQuestionLoading(true);

                    const data = {
                        member_question_id: selectedQuestion.id,
                    };

                    const response = await deleteMemberQuestion(data);
                    if (response && response.result) {

                        // Update the memberQuestions array to remove the specified memberQuestion
                        const updatedMemberQuestions = memberQuestions.filter(mq => mq.id !== selectedQuestion.id);

                        setMemberQuestions(updatedMemberQuestions);
                        setSelectedQuestion(null)
                        setIsDeleteQuestionLoading(false);
                    } else {
                        setIsDeleteQuestionLoading(false);
                    }
                }
            } catch (error) {
                setIsDeleteQuestionLoading(false);
            }
        },
        [
            authUserInfo, spaceInfo, selectedDropdownVisibility, selectedDropdownRequired, isCreateQuestionLoading, selectedQuestion, memberQuestions
        ]
    );

    const handleSaveNewQuestion = useCallback(async (values) => {
            try {
                if (spaceInfo) {
                    setIsCreateQuestionLoading(true);

                    const data = {
                        space_id: spaceInfo.id,
                        user_id: authUserInfo.id,
                        question_text: values.question_text,
                        question_help_text: values.question_help_text,
                        is_visible: selectedDropdownVisibility === 'visible',
                        is_required: selectedDropdownRequired === 'required',
                        is_default: false,
                        // order: values.order,
                        // question_type: values.question_type,
                    };

                    const response = await createMemberQuestion(data);
                    if (response && response.result) {
                        setSelectedDropdownQuestion(null);
                        setIsCreateQuestionLoading(false);
                        createQuestionForm.setFieldsValue({
                            question_text: "",
                            question_help_text: "",
                        })
                        await getMemberQuestionsRequest()
                    } else {
                        setIsCreateQuestionLoading(false);
                    }
                }
            } catch (error) {
                setIsCreateQuestionLoading(false);
            }
        },
        [
            authUserInfo,
            spaceInfo,
            selectedDropdownVisibility,
            selectedDropdownRequired,
            isCreateQuestionLoading,
            createQuestionForm
        ]
    );

    const getMemberQuestionsRequest = useCallback(async () => {
            try {
                if (!spaceInfo) {
                    console.log('Space not found!')
                    return;
                }

                if (!authUserInfo) {
                    console.log('User not found!')
                    return;
                }

                const data = {
                    space_id: spaceInfo.id,
                    user_id: authUserInfo.id,
                };

                const response = await getMemberQuestions(data);
                // console.log(response);
                if (response && response.result) {
                    setMemberQuestions(response.member_questions)
                }
            } catch (error) {
            }
        },
        [
            authUserInfo, spaceInfo
        ]
    );

    const handleCreateFormOnBlur = useCallback(async () => {
        await createQuestionForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleEditFormOnBlur = useCallback(async () => {
        await editQuestionForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleEditQuestion = useCallback(
        (memberQuestion) => {
            // setAlertCheckbox(null);
            setSelectedDropdownQuestion(null);
            setSelectedQuestion(memberQuestion);
            editQuestionForm.setFieldsValue({
                question_text: memberQuestion.question_text,
                question_help_text: memberQuestion.question_help_text,
            })
            setSelectedDropdownRequired(memberQuestion.is_required ? 'required' : 'optional');
            setSelectedDropdownVisibility(memberQuestion.is_visible ? 'visible' : 'hidden');
        },
        [editQuestionForm, spaceInfo, selectedQuestion, selectedDropdownVisibility, selectedDropdownRequired
        ]
    );

    useEffect(()=>{
        if (authUserInfo && spaceInfo) {
            getMemberQuestionsRequest().then(() => {});
        }
    },[authUserInfo, spaceInfo])

    useEffect(()=>{

    },[selectedQuestion, memberQuestions])

    return (
        <>
            <div className="cs-dash-card block-full card">
                <hgroup className="heading">
                    <h2>Member Questions</h2>
                    <p>Create custom questions and data fields for Members to answer when they sign up. Member Questions can be in different formats, such as text, multiple choice, and other types. Each question can be set to display on the Members' Profile or be kept private and only visible to you and your team. The display order of Member Questions will match the order below, and can be rearranged.</p>
                </hgroup>
                <div className="stack-buttons col">
                    <DropdownWithItems
                        title="Create New Question"
                        dropDownClass="dropdown-bg"
                        icon="fa-light fa-clipboard-question"
                        customButtonClass="button gradient distribute dropdown w-full"
                        // displayError={selectedSpaceVisibility === 'default'}
                        selectedValue={selectedDropdownQuestion}
                        listItems={MEMBER_QUESTIONS_DROPDOWN}
                        onDropdownChange={(value) => handleCreateQuestionChange(value)}
                    />
                    {/*<button type="button" className="button gradient distribute w-full" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-clipboard-question"></i><p>Create New Question</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
                     {/*CSJS This dropdown should display the following options: Short Text, Long Text, Number, Checkbox, Date, URL, Multiple Choice, Multi-Select, City, Country. Upon selection, should load the matching "Create New Question" block form.*/}
                    <a
                        // href="cs-space-dash-space-questions-archive.html"
                        className="button gradient secondary"><span><p>View Archived Questions</p></span></a>
                </div>
            </div>

            { selectedDropdownQuestion === 'create' &&
                <div className="cs-dash-card block-full card">
                    <Form
                        form={createQuestionForm}
                        name="createQuestionForm"
                        validateMessages={VALIDATE_MESSAGES}
                        onFocus={() => {
                            setFormIsOnFocus(true);
                        }}
                        onBlur={() => {
                            // setTimeout(() => {
                            setFormIsOnFocus(false);
                            handleCreateFormOnBlur().then(r => {});
                            // }, 100);
                        }}
                        onFinish={handleSaveNewQuestion}
                        className="stack-col"
                    >
                        <div className="formrow title">
                            <h3>Create New Question</h3>
                            <p>Short Text</p>
                        </div>
                        <div className="cs-stack-controlrows stack-form-col">
                            <div className="formrow split">
                                <div className="form-input gradient">
                                    <span>
                                        <label htmlFor="question_text">Question</label>
                                        <Form.Item
                                            label="Question"
                                            name="question_text"
                                            validateTrigger="onBlur"
                                            className="w-full"
                                            rules={[
                                                { required: true },
                                                {
                                                    max: 256,
                                                    message: 'Must not exceed 256 characters.'
                                                },
                                            ]}
                                        >
                                            <FormFieldStacked
                                                formType='input'
                                                type="text"
                                                maxLength={256}
                                                disabled={false}
                                                placeholder="Up to 256 characters."
                                            />
                                        </Form.Item>
                                    </span>
                                </div>
                                <div><p>This is the question text that will be displayed to the Member.</p></div>
                            </div>
                        </div>
                        <div className="cs-stack-controlrows stack-form-col">
                            <div className="formrow split">
                                <div className="form-input gradient">
                                    <span>
                                        <label htmlFor="question_help_text">Help Text</label>
                                        <Form.Item
                                            label="Help Text"
                                            name="question_help_text"
                                            validateTrigger="onBlur"
                                            className="w-full"
                                            rules={[
                                                { required: true },
                                                {
                                                    max: 256,
                                                    message: 'Must not exceed 256 characters.'
                                                },
                                            ]}
                                        >
                                            <FormFieldStacked
                                                formType='input'
                                                type="text"
                                                maxLength={256}
                                                disabled={false}
                                                placeholder="Up to 256 characters."
                                            />
                                        </Form.Item>
                                    </span>
                                </div>
                                <div><p>This is the help text that will be displayed next to your question.</p></div>
                            </div>
                        </div>

                        <div className="formrow title">
                            <h4>Settings</h4>
                            <p>Set visibility and requirements for this Question</p>
                        </div>
                        <div className="cs-stack-controlrows stack-form-col">
                            <div className="formrow split">
                                <DropdownWithItems
                                    title="Required"
                                    dropDownClass="dropdown-bg"
                                    icon={`${selectedDropdownRequired === 'optional' ? 'fa-light fa-question' : 'fa-light fa-asterisk'}`}
                                    customButtonClass="button gradient secondary distribute dropdown w-full"
                                    // displayError={selectedSpaceVisibility === 'default'}
                                    selectedValue={selectedDropdownRequired}
                                    listItems={MEMBER_QUESTIONS_REQUIRED_DROPDOWN}
                                    onDropdownChange={(value) => handleRequiredQuestionChange(value)}
                                />
                                <div><p>Required questions must be answered by all Members on signup, and existing Members will be prompted to answer. Optional questions are skippable.</p></div>
                            </div>
                            <div className="formrow split">
                                <DropdownWithItems
                                    title="Visible"
                                    dropDownClass="dropdown-bg"
                                    icon={`${selectedDropdownVisibility === 'visible' ? 'fa-light fa-eye' : 'fa-light fa-eye-low-vision'}`}
                                    customButtonClass="button gradient secondary distribute dropdown w-full"
                                    // displayError={selectedSpaceVisibility === 'default'}
                                    selectedValue={selectedDropdownVisibility}
                                    listItems={MEMBER_QUESTIONS_VISIBILITY_DROPDOWN}
                                    onDropdownChange={(value) => handleVisibilityQuestionChange(value)}
                                />
                                <div><p>Visible will display on Members' Profile and can be seen by all Members. Hidden will only allow the Space Owner and Team to view the Members' answer.</p></div>
                            </div>
                            <div className="stack-buttons col">
                                <button
                                    className="button gradient"
                                    type="submit"
                                    disabled={
                                        isCreateQuestionLoading || !checkValidQuestionData()
                                    }
                                >
										<span>
											<p>
												{isCreateQuestionLoading && (
                                                    <i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
                                                )}
                                                Save Question
											</p>
										</span>
                                </button>
                                <a
                                    className="button gradient secondary"
                                    onClick={() => {
                                        setSelectedDropdownQuestion(null);
                                        // setOpenChannelForm(false);
                                    }}
                                    aria-disabled={
                                        isCreateQuestionLoading
                                    }
                                >
										<span>
											<p>Cancel</p>
										</span>
                                </a>
                            </div>
                        </div>
                    </Form>
                </div>
            }




            <div id="draggable-cards" className="cs-stack-form-drag-cards gap-gutter">
                {/*ANCHOR Short Text Question Card*/}
                {/*<div draggable="true" className="cs-dash-card block-full card cs-form-drag-card">*/}
                {/*    <div className="cs-stack-controlrows stack-form-col">*/}
                {/*        <div className="cs-form-drag-card-title">*/}
                {/*            <div className="cs-controls-draggable">*/}
                {/*                <p><i className="fa-regular fa-grip-lines"></i></p>*/}
                {/*                <div className="cs-controls-draggable-buttons">*/}
                {/*                    <button><i className="fa-regular fa-angle-up"></i></button>*/}
                {/*                    <button><i className="fa-regular fa-angle-down"></i></button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="cs-question-card-title stack-row">*/}
                {/*                <h5>Favorite City</h5>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-icons-attributes">*/}
                {/*            <p><i className="fa-regular fa-question"></i></p>*/}
                {/*            <p><i className="fa-regular fa-eye"></i></p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-question-card-controls stack-row">*/}
                {/*            <a href="#" className="button gradient secondary"><span><i className="fa-light fa-list"></i></span></a>*/}
                {/*            <a href="#" className="button gradient secondary"><span><i className="fa-light fa-money-check-pen"></i></span></a>*/}
                {/*            /!*CSJS EDIT BUTTON - Toggle "active" class when clicked.*!/*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*{(memberQuestions && memberQuestions.length > 0) && memberQuestions.map((memberQuestion, index) => (*/}
                {/*    <Form className="cs-stack-form-question-edit stack-col hide" index={index}>*/}
                {/*        /!*<CSJS EDIT FORM - Toggle "hide" class when Edit Button clicked.*!/*/}
                {/*        <div className="formrow title">*/}
                {/*            <h3>Edit Question</h3>*/}
                {/*            <p>Short Text</p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <div className="form-input gradient"><span><label htmlFor="question">Question</label><input id="question" name="text" value="Favorite City" type="text" required placeholder="Up to 256 characters."/></span></div>*/}
                {/*                <div><p>This is the question text that will be displayed to the Member.</p></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <div className="form-input gradient"><span><label htmlFor="name">Help Text</label><input id="name" name="text" type="text" value="Share your favorite city with the FAM." required placeholder="Up to 256 characters."/></span></div>*/}
                {/*                <div><p>This is the help text that will be displayed next to your question.</p></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className="formrow title">*/}
                {/*            <h4>Settings</h4>*/}
                {/*            <p>Set visibility and requirements for this Question</p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-question"></i><p>Optional</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
                {/*                <div><p>Required questions must be answered by all Members on signup, and existing Members will be prompted to answer. Optional questions are skippable.</p></div>*/}
                {/*            </div>*/}
                {/*            <div className="formrow split">*/}
                {/*                <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-eye"></i><p>Visible</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
                {/*                <div><p>Visible will display on Members' Profile and can be seen by all Members. Hidden will only allow the Space Owner and Team to view the Members' answer.</p></div>*/}
                {/*            </div>*/}
                {/*            <div className="stack-buttons col">*/}
                {/*                <a href="#" className="button gradient"><span><p>Save Question</p></span></a>*/}
                {/*                <a href="#" className="button gradient secondary"><span><p>Archive</p></span></a>*/}
                {/*                <p className="text-help text-center">Member Questions which have been answered by one or more Members cannot be deleted. Instead, archiving will remove the Question from use.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </Form>*/}
                {/*))}*/}


                {/* ANCHOR Short Text Question Card */}
                {(memberQuestions && memberQuestions.length > 0) && memberQuestions.map((memberQuestion, index) => (
                    <div key={index} draggable="true" className="cs-dash-card block-full card cs-form-drag-card">
                        <div className="cs-stack-controlrows stack-form-col">
                            <div className="cs-form-drag-card-title">
                                <div className="cs-controls-draggable">
                                    <p><i className="fa-regular fa-grip-lines"></i></p>
                                    <div className="cs-controls-draggable-buttons">
                                        <button><i className="fa-regular fa-angle-up"></i></button>
                                        <button><i className="fa-regular fa-angle-down"></i></button>
                                    </div>
                                </div>
                                <div className="cs-question-card-title stack-row">
                                    <h5>{memberQuestion.question_text}</h5>
                                </div>
                            </div>
                            <div className="cs-stack-icons-attributes">
                                <p><i className={`${memberQuestion.is_required ? 'fa-regular fa-asterisk' : 'fa-regular fa-question'}`}></i></p>
                                <p><i className={`${memberQuestion.is_visible ? 'fa-regular fa-eye' : 'fa-regular fa-eye-low-vision'}`}></i></p>
                            </div>
                            <div className="cs-question-card-controls stack-row">
                                <a href="#" className="button gradient secondary"><span><i className="fa-light fa-list"></i></span></a>
                                <a
                                    className={"button gradient secondary" + (memberQuestion.id === selectedQuestion?.id ? " active" : "")}
                                    onClick={() => {
                                        handleEditQuestion(memberQuestion);
                                    }}
                                >
                                    <span>
                                        <i className="fa-light fa-money-check-pen"></i>
                                    </span>
                                </a>
                                {/*CSJS EDIT BUTTON - Toggle "active" class when clicked.*/}
                            </div>
                        </div>
                        {/*Edit Form*/}
                        <Form
                            form={editQuestionForm}
                            name="editQuestionForm"
                            validateMessages={VALIDATE_MESSAGES}
                            onFocus={() => {
                                setFormIsOnFocus(true);
                            }}
                            onBlur={() => {
                                // setTimeout(() => {
                                setFormIsOnFocus(false);
                                handleEditFormOnBlur().then(r => {});
                                // }, 100);
                            }}
                            onFinish={handleSaveEditQuestion}
                            className={'cs-stack-form-question-edit stack-col ' + (memberQuestion.id === selectedQuestion?.id ? "" : " hide")}
                        >

                            <div className="formrow title">
                                <h3>Edit Question</h3>
                                <p>Short Text</p>
                            </div>
                            <div className="cs-stack-controlrows stack-form-col">
                                <div className="formrow split">
                                    <div className="form-input gradient">
                                        <span>
                                            <label htmlFor="question_text">Question</label>
                                            <Form.Item
                                                label="Question"
                                                name="question_text"
                                                validateTrigger="onBlur"
                                                className="w-full"
                                                rules={[
                                                    { required: true },
                                                    {
                                                        max: 256,
                                                        message: 'Must not exceed 256 characters.'
                                                    },
                                                ]}
                                            >
                                                <FormFieldStacked
                                                    formType='input'
                                                    type="text"
                                                    maxLength={256}
                                                    disabled={false}
                                                    placeholder="Up to 256 characters."
                                                />
                                            </Form.Item>
                                        </span>
                                    </div>
                                    <div><p>This is the question text that will be displayed to the Member.</p></div>
                                </div>
                            </div>
                            <div className="cs-stack-controlrows stack-form-col">
                                <div className="formrow split">
                                    <div className="form-input gradient">
                                        <span>
                                            <label htmlFor="question_help_text">Help Text</label>
                                            <Form.Item
                                                label="Help Text"
                                                name="question_help_text"
                                                validateTrigger="onBlur"
                                                className="w-full"
                                                rules={[
                                                    { required: true },
                                                    {
                                                        max: 256,
                                                        message: 'Must not exceed 256 characters.'
                                                    },
                                                ]}
                                            >
                                                <FormFieldStacked
                                                    formType='input'
                                                    type="text"
                                                    maxLength={256}
                                                    disabled={false}
                                                    placeholder="Up to 256 characters."
                                                />
                                            </Form.Item>
                                        </span>
                                    </div>
                                    <div><p>This is the help text that will be displayed next to your question.</p></div>
                                </div>
                            </div>

                            <div className="formrow title">
                                <h4>Settings</h4>
                                <p>Set visibility and requirements for this Question</p>
                            </div>
                            <div className="cs-stack-controlrows stack-form-col">
                                <div className="formrow split">
                                    <DropdownWithItems
                                        title="Required"
                                        dropDownClass="dropdown-bg"
                                        icon={`${selectedDropdownRequired === 'optional' ? 'fa-light fa-question' : 'fa-light fa-asterisk'}`}
                                        customButtonClass="button gradient secondary distribute dropdown w-full"
                                        // displayError={selectedSpaceVisibility === 'default'}
                                        selectedValue={selectedDropdownRequired}
                                        listItems={MEMBER_QUESTIONS_REQUIRED_DROPDOWN}
                                        onDropdownChange={(value) => handleRequiredQuestionChange(value)}
                                    />
                                    <div><p>Required questions must be answered by all Members on signup, and existing Members will be prompted to answer. Optional questions are skippable.</p></div>
                                </div>
                                <div className="formrow split">
                                    <DropdownWithItems
                                        title="Visible"
                                        dropDownClass="dropdown-bg"
                                        icon={`${selectedDropdownVisibility === 'visible' ? 'fa-light fa-eye' : 'fa-light fa-eye-low-vision'}`}
                                        customButtonClass="button gradient secondary distribute dropdown w-full"
                                        // displayError={selectedSpaceVisibility === 'default'}
                                        selectedValue={selectedDropdownVisibility}
                                        listItems={MEMBER_QUESTIONS_VISIBILITY_DROPDOWN}
                                        onDropdownChange={(value) => handleVisibilityQuestionChange(value)}
                                    />
                                    <div><p>Visible will display on Members' Profile and can be seen by all Members. Hidden will only allow the Space Owner and Team to view the Members' answer.</p></div>
                                </div>
                                <div className="stack-buttons col">
                                    <button
                                        className="button gradient"
                                        type="submit"
                                        disabled={
                                            isDeleteQuestionLoading || isEditQuestionLoading || !checkValidQuestionData()
                                        }
                                    >
										<span>
											<p>
												{(isEditQuestionLoading) && (
                                                    <i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
                                                )}
                                                Save Question
											</p>
										</span>
                                    </button>
                                    <a
                                        className="button gradient secondary"
                                        onClick={() => {
                                            handleDeleteQuestion().then(() => {});
                                        }}
                                        aria-disabled={
                                            (isEditQuestionLoading || isDeleteQuestionLoading)
                                        }
                                    >
										<span>
                                            {(isDeleteQuestionLoading) && (
                                                <i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
                                            )}
											<p>Delete</p>
										</span>
                                    </a>
                                    <p className="text-help text-center">Member Questions which have been answered by one or more Members cannot be deleted. Instead, archiving will remove the Question from use.</p>
                                </div>
                            </div>
                        </Form>
                    </div>
                ))}




                {/*/!*ANCHOR Short Text Question Card*!/*/}
                {/*<div draggable="true" className="cs-dash-card block-full card cs-form-drag-card">*/}
                {/*    <div className="cs-stack-controlrows gap-form">*/}
                {/*        <div className="cs-form-drag-card-title">*/}
                {/*            <div className="cs-controls-draggable">*/}
                {/*                <p><i className="fa-regular fa-grip-lines"></i></p>*/}
                {/*                <div className="cs-controls-draggable-buttons">*/}
                {/*                    <button><i className="fa-regular fa-angle-up"></i></button>*/}
                {/*                    <button><i className="fa-regular fa-angle-down"></i></button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="cs-question-card-title stack-row">*/}
                {/*                <h5>Do you have any allergies?</h5>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-icons-attributes">*/}
                {/*            <p><i className="fa-regular fa-asterisk"></i></p>*/}
                {/*            <p><i className="fa-regular fa-eye-low-vision"></i></p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-question-card-controls stack-row">*/}
                {/*            <a href="#" className="button gradient secondary"><span><i className="fa-light fa-list"></i></span></a>*/}
                {/*            <a href="#" className="button gradient secondary active"><span><i className="fa-light fa-money-check-pen"></i></span></a>*/}
                {/*            /!*CSJS EDIT BUTTON - Toggle "active" class when clicked.*!/*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    /!*Edit Form*!/*/}
                {/*    <form className="cs-stack-form-question-edit stack-col">*/}
                {/*        /!*CSJS EDIT FORM - Toggle "hide" class when Edit Button clicked.*!/*/}
                {/*        <div className="formrow title">*/}
                {/*            <h3>Edit Question</h3>*/}
                {/*            <p>Short Text</p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <div className="form-input gradient"><span><label htmlFor="question">Question</label><input id="question" name="text" value="Do you have any allergies?" type="text" required placeholder="Up to 256 characters."/></span></div>*/}
                {/*                <div><p>This is the question text that will be displayed to the Member.</p></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <div className="form-input gradient"><span><label htmlFor="name">Help Text</label><input id="name" name="text" type="text" value="List any allergies. Enter 'None' if none." required placeholder="Up to 256 characters."/></span></div>*/}
                {/*                <div><p>This is the help text that will be displayed next to your question.</p></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className="formrow title">*/}
                {/*            <h4>Settings</h4>*/}
                {/*            <p>Set visibility and requirements for this Question</p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-asterisk"></i><p>Required</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
                {/*                <div><p>Required questions must be answered by all Members on signup, and existing Members will be prompted to answer. Optional questions are skippable.</p></div>*/}
                {/*            </div>*/}
                {/*            <div className="formrow split">*/}
                {/*                <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-eye"></i><p>Hidden</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
                {/*                <div><p>Visible will display on Members' Profile and can be seen by all Members. Hidden will only allow the Space Owner and Team to view the Members' answer.</p></div>*/}
                {/*            </div>*/}
                {/*            <div className="stack-buttons col">*/}
                {/*                <a href="#" className="button gradient"><span><p>Save Question</p></span></a>*/}
                {/*                <a href="#" className="button gradient secondary"><span><p>Archive</p></span></a>*/}
                {/*                <p className="text-help text-center">Member Questions which have been answered by one or more Members cannot be deleted. Instead, archiving will remove the Question from use.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </form>*/}
                {/*</div>*/}

                {/*/!*ANCHOR Short Text Question Card*!/*/}
                {/*<div draggable="true" className="cs-dash-card block-full card cs-form-drag-card">*/}
                {/*    <div className="cs-stack-controlrows gap-form">*/}
                {/*        <div className="cs-form-drag-card-title">*/}
                {/*            <div className="cs-controls-draggable">*/}
                {/*                <p><i className="fa-regular fa-grip-lines"></i></p>*/}
                {/*                <div className="cs-controls-draggable-buttons">*/}
                {/*                    <button><i className="fa-regular fa-angle-up"></i></button>*/}
                {/*                    <button><i className="fa-regular fa-angle-down"></i></button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="cs-question-card-title stack-row">*/}
                {/*                <h5>What is your favorite color?</h5>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-icons-attributes">*/}
                {/*            <p><i className="fa-regular fa-asterisk"></i></p>*/}
                {/*            <p><i className="fa-regular fa-eye-low-vision"></i></p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-question-card-controls stack-row">*/}
                {/*            <a href="#" className="button gradient secondary"><span><i className="fa-light fa-list"></i></span></a>*/}
                {/*            <a href="#" className="button gradient secondary active"><span><i className="fa-light fa-money-check-pen"></i></span></a>*/}
                {/*            /!*CSJS EDIT BUTTON - Toggle "active" class when clicked.*!/*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    /!*Edit Form*!/*/}
                {/*    <form className="cs-stack-form-question-edit stack-col">*/}
                {/*        /!*CSJS EDIT FORM - Toggle "hide" class when Edit Button clicked.*!/*/}
                {/*        <div className="formrow title">*/}
                {/*            <h3>Edit Question</h3>*/}
                {/*            <p>Short Text</p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <div className="form-input gradient"><span><label htmlFor="question">Question</label><input id="question" name="text" value="What is your favorite color?" type="text" required placeholder="Up to 256 characters."/></span></div>*/}
                {/*                <div><p>This is the question text that will be displayed to the Member.</p></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <div className="form-input gradient"><span><label htmlFor="name">Help Text</label><input id="name" name="text" type="text" value="Let us know." required placeholder="Up to 256 characters."/></span></div>*/}
                {/*                <div><p>This is the help text that will be displayed next to your question.</p></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className="formrow title">*/}
                {/*            <h4>Settings</h4>*/}
                {/*            <p>Set visibility and requirements for this Question</p>*/}
                {/*        </div>*/}
                {/*        <div className="cs-stack-controlrows stack-form-col">*/}
                {/*            <div className="formrow split">*/}
                {/*                <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-asterisk"></i><p>Required</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
                {/*                <div><p>Required questions must be answered by all Members on signup, and existing Members will be prompted to answer. Optional questions are skippable.</p></div>*/}
                {/*            </div>*/}
                {/*            <div className="formrow split">*/}
                {/*                <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-eye"></i><p>Hidden</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
                {/*                <div><p>Visible will display on Members' Profile and can be seen by all Members. Hidden will only allow the Space Owner and Team to view the Members' answer.</p></div>*/}
                {/*            </div>*/}
                {/*            <div className="stack-buttons col">*/}
                {/*                <a href="#" className="button gradient"><span><p>Save Question</p></span></a>*/}
                {/*                <a href="#" className="button gradient secondary"><span><p>Delete</p></span></a>*/}
                {/*                <p className="text-help text-center">Member Questions which have have not been answered yet can be deleted.</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </form>*/}
                {/*</div>*/}

            </div>
        </>
    );

}

export default memo(MemberQuestions);