import {memo, useCallback, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useSearchParams} from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import { OAuthExtension } from '@magic-ext/oauth';
import Alert from 'react-bootstrap/Alert';
import Web3 from 'web3';
import { getAuthUserInfo } from '../../states/general/actions';

import {
	VALIDATE_MESSAGES,
	MAGIC_CONNECT_KEY,
	MAGIC_OAUTH_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../constants/common';
import { setAccessToken } from '../../utils/common';
import { checkMfaEnabled, checkSmsEnabled, requestSmsOtp, dualCheck2FA } from '../../services/user';
import { login, magic_login } from '../../services/auth';
import routes from '../../routes';

import './Login.scss';
import dj_bg_img from '../../assets/images/cs-splash.jpg';
import {getLocalStorage, removeLocalStorage} from "../../utils/storage";
import {useTranslation} from "react-i18next";

const LoginForm = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [params] = useSearchParams();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [isMagicLink, setIsMagicLink] = useState(false);
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [isLoginBtn, setLoginBtn] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(true);
	const [formHasError, setFormHasError] = useState(true);
	const [hasMfaEnabled, setHasMfaEnabled] = useState(false);
	const { size } = useSelector((state) => state.classSize);
	const [margin, setMargin] = useState();
	const [hasEmailSupplied, setHasEmailSupplied] = useState(false);

	const [blurredField, setBlurredField] = useState(null);
	const [isSubmitClicked, setSubmitClicked] = useState(false);
	const [errorMessages, setErrorMessages] = useState([]);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});
	const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new OAuthExtension()]
	});
	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);


	const handleFormOnBlur = useCallback(async () => {
		setAlert(null);
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				// console.log(errors)
				setFormHasError(true);
			});
	}, []);

	// const handleFormOnBlur = useDebounce(async () => {
	// 	setAlert(null);
	// 	await form
	// 		.validateFields()
	// 		.then(() => {
	// 			setFormHasError(false);
	// 		})
	// 		.catch((errors) => {
	// 			console.log(errors)
	// 			setFormHasError(true);
	// 		});
	// }, []);

	const handleFormChanges = useCallback(async () => {
		setAlert(null);
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);


	const handleFinishFailed = useCallback(async (errorInfo) => {
		setSubmitClicked(true);
		// Set the blurred field to the first field with an error
		if (errorInfo.errorFields.length > 0) {
			setBlurredField('all');
		}
	}, [blurredField, isSubmitClicked]);

	// const handleOnblurField = useCallback(async (field) => {
	//
	// }, [blurredField, isSubmitClicked]);

	const handleOnblurField = useCallback((field) => {
		if (!isSubmitClicked) {
			setBlurredField(field);
		}
		setSubmitClicked(false);

		const fieldError = form.getFieldError(field);
		setErrorMessages((prev) => ({
			...prev,
			[field]: fieldError,
		}));

		// Trigger a re-render to ensure the error message is updated
		form.validateFields([field]).catch(() => {
			const updatedFieldError = form.getFieldError(field);
			setErrorMessages((prev) => ({
				...prev,
				[field]: updatedFieldError,
			}));
		});
	}, [form, blurredField, isSubmitClicked]);

	const handleSubmit = useCallback(
		async (values) => {
			if (formHasError) {
				setTimeout(() => {
					setBlurredField('all');
				}, 1000);
				return;
			}
			try {
				setLoginBtn(true);

				if (hasMfaEnabled) {
					values.user_id = authUserInfo.id;
					await handleOtp(values);
				} else {
					await loginUser(values);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[hasMfaEnabled, formHasError, blurredField]
	);

	useEffect(()=>{

	},[isSubmitClicked, blurredField])

	const supplyEmail = () => {
		const fields = form.getFieldsValue();
		if (!formHasError) {
			setHasEmailSupplied(true);
			form.resetFields();
			form.setFieldsValue({ email: String(fields.email) });
		}
	};
	const checkHas2FA = async () => {
		try {
			let values = {
				user_id: authUserInfo.id
			};
			const mfa_async = checkMfaEnabled(values);
			const sms_async = checkSmsEnabled(values);
			const mfa_res = await mfa_async;
			const sms_res = await sms_async;
			if (mfa_res.result || sms_res.result) {
				setHasMfaEnabled(true);
			} else {
				setHasMfaEnabled(false);
				window.location.replace(routes.dashboard());
			}
			console.log(mfa_res.message);
			console.log(sms_res.message);
		} catch (error) {
			console.log(error);
		}
	};

	const requestSMS = async () => {
		let values = {
			user_id: authUserInfo.id
		};
		const result = await requestSmsOtp(values);
		if (result.result) {
			setAlert({
				variant: 'success',
				message: 'OTP sent to your number.'
			});
		} else {
			setAlert({
				variant: 'danger',
				message: 'OTP send failed.'
			});
		}
	};

	const handleOtp = async (values) => {
		try {
			// let values = {
			//     user_id: authUserInfo.id
			// };
			let result = await dualCheck2FA(values);
			if (result.result) {
				form.resetFields();
				setAlert({
					variant: 'success',
					message: 'Log in successful!'
				});
				setLoginBtn(false);
				window.location.replace(routes.dashboard());
			} else {
				setAlert({
					variant: 'danger',
					message: 'OTP Code is incorrect.'
				});
				setLoginBtn(false);
			}
		} catch (error) {
			setLoginBtn(false);
			console.log(error);
		}
	};

	// Web 3 Login
	const web3Login = async () => {
		setSubmitted(true);
		setIsMagicLink(true);

		await magic.connect.disconnect().catch((e) => {
			// console.log(e);
		});

		web3.eth
			.getAccounts()
			.then((accounts) => {
				loginMagic({ user_public_address: accounts?.[0] });
			})
			.catch((error) => {
				setSubmitted(false);
				setIsMagicLink(false);
			});
	};

	const loginUser = async (values) => {
		const result = await login(values);
		await processLogin(result);
	};

	const loginMagic = async (values) => {
		const result = await magic_login(values);
		await processLogin(result);
	};

	const processLogin = async (result) => {
		if (result) {
			if (result.result) {
				if (result.access_token) {
					setAccessToken(result);
				}

				if (params.get('rd')) {
					const from_domain_decoded = atob(params.get('rd'));
					let redirect_url = 'https://';
					if (from_domain_decoded === 'localhost') {
						redirect_url = 'http://localhost:3004/login?rd_at=' + result.access_token + '&rd_rt=' + result.refresh_token;
					} else {
						redirect_url = 'https://' + from_domain_decoded + '/login?rd_at=' + result.access_token + '&rd_rt=' + result.refresh_token;
					}

					window.location.href = redirect_url;
				} else {
					dispatch(getAuthUserInfo());
				}
			} else {
				if (result.message) {
					setAlert({ variant: 'danger', message: result.message });
				} else {
					setAlert({
						variant: 'danger',
						message: 'User not found.Please try again.'
					});
				}
			}
		}
		setLoginBtn(false);
		setSubmitted(false);
		setIsMagicLink(false);
	};


	const get = async (values) => {
		const result = await magic_login(values);
		await processLogin(result);
	};

	useEffect(() => {
		document.title = 'Commonspace Login';

		setMargin(() => {
			if (size !== 'lg') {
				return '4';
			} else {
				return '5';
			}
		});
		if (loading && loading === 'done' && authUserInfo) {
			// window.location.replace(routes.dashboard());
			setSubmitted(false);
			checkHas2FA().then(() => {});
			if (getLocalStorage('redirect_link')) {
				removeLocalStorage('redirect_link')
			}
		}
	}, [authUserInfo, loading, hasMfaEnabled, isMagicLink, size, margin]);

	return (
		<main id="cs-platform-main" className="cs-main main-theme">
			<section id="cs-space-auth-c1" className="py-0 px-0 sm:py-section-safeview theme-transparent">
				<div className="cs-centerframe-split">
					<div className="cs-centerframe-split-left order-2 sm:order-1">
						<div className="p-section-safeview sm:pr-gutter">
							<div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter">
								<hgroup className="heading">
									{hasMfaEnabled && (
										<h2>{t('secure_enabled')}</h2>
									)}
									{!hasMfaEnabled && !hasEmailSupplied &&  (
										<h2>{t('login_header')}</h2>
									)}
									{!hasMfaEnabled && hasEmailSupplied &&(
										<h2>{t('login_with_email')}</h2>
									)}

									{hasMfaEnabled && (
										<p>
											{t('enter_the_2fa')}
										</p>
									)}

									{!hasMfaEnabled && !hasEmailSupplied && (
										<p className='text-p1'>
											{t('login_header_details')}
											{/*Enter your email/username or use your wallet to login. If you have 2 Factor Authentication enabled, be sure to have your method available.*/}
										</p>
									)}
									{!hasMfaEnabled && hasEmailSupplied && (
										<p className='text-p1' >
											{t('password_associated_email')}
										</p>
									)}
								</hgroup>
								<Form
									form={form}
									name="loginForm"
									validateMessages={VALIDATE_MESSAGES}
									onFocus={() => {
										setFormIsOnFocus(true);
									}}
									onBlur={() => {
										setFormIsOnFocus(false);
										handleFormOnBlur().then(() => {});
									}}
									autoComplete="off"
									onChange={handleFormChanges}
									onFinish={handleSubmit}
									onFinishFailed={handleFinishFailed}
                                    className='w-full stack-form-col'
								>
									{!hasMfaEnabled && (
                                        <>
											<Form.Item
												name="email"
												validateTrigger="onBlur"
												rules={[{ required: true}]}
                                                className='w-full stack-form-col'
											>
												<div className="form-input unlabeled">
                                                    <label htmlFor="email">{t('username_placeholder')}</label>
                                                    <input id="email"
														   onBlur={()=>handleOnblurField('email')}
														   name="email" type="text" autoComplete="email" placeholder={t('username_placeholder')}/>
                                                </div>
											</Form.Item>
											{((blurredField === 'email' || blurredField === 'all') && (errorMessages?.email?.length > 0)) && (
												<div className="alert callout warning !m-0">
													<div className="action">
														<i className="fa-solid fa-triangle-exclamation"></i>
													</div>
													<div className="content">
														<div>
															<h6>{errorMessages?.email[0]}</h6>
														</div>
													</div>
												</div>
											)}
                                        </>
									)}
									{!hasMfaEnabled && (
										<>
											<Form.Item
												name="password"
												validateTrigger="onBlur"
												rules={[{ required: true }]}
											>
												<div className="form-input-combo">
													<label htmlFor="password">{t('password')}</label>
													<input id="password" name="password"
														   onBlur={()=>handleOnblurField('password')}
														   type="password" autoComplete="current-password" placeholder={t('password')}/>
													<button disabled={submitted} type="submit" className="secondary-gradient">
                                                        <span><p>{isLoginBtn && (
															<i className="fa-solid fa-spinner fa-spin z-0 mr-2"></i>
														)}
															{t('login_header')}</p></span>
													</button>
												</div>
											</Form.Item>
											 {((blurredField === 'password' || blurredField === 'all') && (errorMessages?.password?.length > 0)) && (
												<div className="alert callout warning !m-0">
													<div className="action">
														<i className="fa-solid fa-triangle-exclamation"></i>
													</div>
													<div className="content">
														<div>
															<h6>{errorMessages?.password[0]}</h6>
														</div>
													</div>
												</div>
											)}
										</>
									)}
									{hasMfaEnabled && (
										<div>
											<Form.Item
												name="otp"
												rules={[{ required: true }]}
											>
												<div className="form-input unlabeled">
													<label htmlFor="otp">{t('login_2fa_code')}</label>
													<input id="otp" name="otp" type="number" placeholder={t('login_2fa_code')}/>
												</div>
											</Form.Item>
										</div>
									)}
									{(alert && alert.message) && (
										<div className="alert callout warning !m-0">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{(alert && alert.message) ? alert.message : ''}</h6>
												</div>
											</div>

										</div>
									)}
									{hasMfaEnabled && (                                        
                                        <button type="submit"  disabled={submitted} className="secondary-gradient">
											<span><p>{submitted && (
												<i className="fa-solid fa-spinner fa-spin"></i>
												)}
												{t('confirm_login')}</p></span>
                                        </button>
									)}
									{!hasMfaEnabled && (
                                        <button onClick={web3Login} disabled={submitted} type="submit" className="secondary-gradient !hidden">
                                            <i
												className={
													isMagicLink
														? 'fa-solid fa-spinner fa-spin mr-2'
														: 'fa-solid fa-wallet mr-2'
												}
											></i>
											{t('login_with_wallet')}
                                        </button>
									)}
								</Form>
								<p>
									{t('login_2_factor')}
								</p>
								<p>
									{t('login_trouble_logging_in')}{' '}
									<a href={routes.forgotPassword()} >{t('login_reset')}</a>
								</p>
							</div>
						</div>
					</div>

					<div className="cs-centerframe-split-right order-1 sm:order-2">
						<div className="lg:pl-half-gutter">
							<img
								className="sm:max-h-element"
								src={dj_bg_img}
							/>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

const Login = () => {
	return <LoginForm />;
};
export default memo(Login);
