
// export const DiscountCalculator = (originalPrice, discountedPrice, months) => {
//     // const [originalPrice] = useState(55);
//     // const [months] = useState(3);
//     // const [discountedPrice] = useState(150);
//     const totalOriginalPrice = originalPrice * months; // 3 * 55 = 165
//     // console.log(`Total Original Price for 3 months: $${totalOriginalPrice}`);
//
//     const discountAmount = totalOriginalPrice - discountedPrice; // 165 - 150 = 15
//     // console.log(`Discount Amount: $${discountAmount}`);
//
//     const discountPercentage = (discountAmount / totalOriginalPrice) * 100; // (15 / 165) * 100 = 9.1
//     // console.log(`Discount Percentage: ${discountPercentage}%`);
//
//     const discountPercentageFixed = discountPercentage.toFixed(1);
//     const discountPercentageNumber = parseFloat(discountPercentageFixed);
//     // Check if the result is any whole number and return it
//     if (Number.isInteger(discountPercentageNumber)) {
//         return discountPercentageNumber;
//     }
//     return discountPercentageFixed;
// };


export const DiscountCalculator = (monthlyPrice, months, membershipBenefit) => {
    const monthly = membershipBenefit.monthly_price;
    const threeMonthRenewal = membershipBenefit.three_month_renewal;
    const sixMonthRenewal = membershipBenefit.six_month_renewal;
    const twelveMonthRenewal = membershipBenefit.twelve_month_renewal;

    let monthlyCount = 0;
    if (monthly) {
        monthlyCount++;
    }
    if (threeMonthRenewal) {
        monthlyCount++;
    }
    if (sixMonthRenewal) {
        monthlyCount++;
    }
    if (twelveMonthRenewal) {
        monthlyCount++;
    }

    let basePrice = 0;
    let baseMonth = 1;
    if (monthlyCount > 1) {
        if (twelveMonthRenewal) {
            basePrice = twelveMonthRenewal;
            baseMonth = 12;
        }
        if (sixMonthRenewal) {
            basePrice = sixMonthRenewal;
            baseMonth = 6;
        }
        if (threeMonthRenewal) {
            basePrice = threeMonthRenewal;
            baseMonth = 3;
        }
        if (monthly) {
            basePrice = monthly;
            baseMonth = 1;
        }
    } else {
        baseMonth = months;
    }

    let isBaseMonth = months === baseMonth;


   // Calculate the full price without discount for the given months
    const fullPrice = (basePrice * (months / baseMonth))

    // Calculate the discount percentage
    const discountPercentage = (fullPrice - monthlyPrice) / fullPrice * 100

    const discountPercentageFixed = discountPercentage.toFixed(2);
    const discountPercentageNumber = parseFloat(discountPercentageFixed);
    // Check if the result is any whole number and return it
    if (Number.isInteger(discountPercentageNumber)) {
        return !isBaseMonth ? discountPercentageNumber + '% off' : '';
    }
    return !isBaseMonth ? discountPercentageFixed + '% off' : '';
};


