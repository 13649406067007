import { memo, useState, useEffect } from 'react';
import { Input} from 'antd';

const FormFieldStacked = (parentProps) => {

    const { type, formType, maxLength, placeholder, onChange, value, rows, customClassName, onBlur } = parentProps;
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	},[value])
	
	const onInputChange = e => {
		setInputValue(e.target.value);
		if (onChange) {
			onChange(e.target.value);
		}
	};

	return (
	<>
		{formType === 'input' ? (
			<input
				type={type}
				value={inputValue}
				onChange={onInputChange}
				maxLength={maxLength}
				// className="block h-10 bg-[#ffffff80] w-full rounded-none border-0 py-1.5 px-3 text-gray-900 placeholder:text-lg text-lg placeholder:font-['notoSansItalic']  placeholder:text-gray-400 focus:outline-none focus:border-b focus:border-fuchsia-500"
				className={customClassName ? customClassName : ""}
				onBlur={onBlur}
				placeholder={placeholder}
			/>
			//   <Input
			//   defaultValue={value}
			//   type={type}
			//   placeholder={placeholder}

			//   className="block h-10 bg-[#ffffff80] w-full rounded-none border-0 py-1.5 px-3 text-gray-900 placeholder:text-lg font-['notoSansItalic']  placeholder:text-gray-400  sm:text-sm sm:leading-6"
			//    value={inputValue} onChange={onInputChange} />
		): formType === 'textarea'?  (
			<textarea
				rows={rows ? rows : 1}
				maxLength={maxLength}
				placeholder={placeholder}
				// className="block w-full rounded-none border-0 bg-[#ffffff80] py-1.5 px-3 text-gray-900 placeholder:text-lg text-lg placeholder:font-['notoSansItalic']  placeholder:text-gray-400 focus:outline-none focus:border-b focus:border-fuchsia-500"
				className={customClassName ? customClassName : ""}
				onBlur={onBlur}
				value={inputValue}
				onChange={onInputChange}
			/>
		) : (
			<></>
		)}
	</>);
}

export default memo(FormFieldStacked);