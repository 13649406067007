import { memo, useEffect, useState } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import ReactPlayer from 'react-player/lazy';

import {
	ALLOWED_UPLOAD_SIZE_LETTERS, AUDIO_FILETYPES,
	IMAGE_FILETYPES, UPLOAD_FILE_TYPE,
	VIDEO_FILETYPES
} from '../../../constants/file';
import { formatFileSize } from '../../../utils/file';

const UploadDraggerV2 = (parentProps) => {

	const {
		isAudioUpload,
		hideDragIcon,
		hideText,
		shape,
		uploadFileTypes,
		uploadedFilePreview,
		onUploadFileChange,
		cropperAspectRatio,
		minHeight,
		minWidth,
		maxHeight,
		maxWidth,
		uploadSizeLimit,
		passedError,
		setPassedError,
		disableCropper,
		imagePreviewCustomClassName,
		blobUrlType,
        onError = null
	} = parentProps;

	const { Dragger } = Upload;
	const [acceptedFileTypes, setAcceptedFileTypes] = useState('');
	const [fileType, setFileType] = useState(UPLOAD_FILE_TYPE.IMAGE);
	const [alert, setAlert] = useState(null);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [cropperEnabled, setCropperEnabled] = useState(null);

	const dummyRequest = ({ file, onSuccess }) => {
		// console.log('dummy_request');
		setTimeout(() => {
			onSuccess('ok');
		});
	};

    useEffect(() => {
		if (onError && alert && alert.message) {
			onError(alert?.message)
		}
    }, [alert])

	const handleUploadFileChange = async (file) => {
		const isFileTypeVideo = uploadFileTypes.includes(VIDEO_FILETYPES);
		const file_extension = getFileExtension(file);
		if (isFileTypeVideo && !VIDEO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
			setAlert({
				variant: 'danger',
				message: 'Unsupported file type.'
			});
			return false;
		}
		if (!isFileTypeVideo && !IMAGE_FILETYPES.includes('.' + file_extension.toLowerCase())) {
			setAlert({
				variant: 'danger',
				message: 'Unsupported file type.'
			});
			return false;
		}
		if (!cropperEnabled) {
			setAlert(null);
			if (VIDEO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
				const vidValid = await checkVideoValidity(file);
				if (!vidValid) {
                    onUploadFileChange(null, null, true);
					return false;
				}
				setFileType(UPLOAD_FILE_TYPE.VIDEO);
			}
			if (IMAGE_FILETYPES.includes('.' + file_extension.toLowerCase())) {
				const imgValid = await checkImageValidity(file);
				if (!imgValid) {
                    onUploadFileChange(null, null, true);
					return false;
				}
				setFileType(UPLOAD_FILE_TYPE.IMAGE);
			}
			if (AUDIO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
				setFileType(UPLOAD_FILE_TYPE.AUDIO);
			}
		}
        
		const objectUrl = URL.createObjectURL(file);
		onUploadFileChange(file, objectUrl);
	};

	const handleResetAsset = () => {
		onUploadFileChange(null, null);
	};

	const getFileExtension = (file, is_url = false) => {
		let file_name = null;
		if (file) {
			if (!is_url) {
				file_name = file.name.split('.');
			} else {
				file_name = file.split('.');
			}
			let file_type = file_name[file_name.length - 1];
			if (file_type.includes('?')) {
				return file_type.split('?')[0];
			}
			return file_name[file_name.length - 1];
		}
		return file_name;
	};

	const checkFileSizeValidity = (file) => {
		const fileSize = formatFileSize(file.size);
		const byteSizeValue = fileSize.split(' ')[0];
		const byteSizeLetter = fileSize.split(' ')[1];
		if (uploadSizeLimit) {
			if (!ALLOWED_UPLOAD_SIZE_LETTERS.includes(byteSizeLetter)) {
				setAlert({
					variant: 'danger',
					message: 'Your file must be smaller than 100 MB'
					// message: 'File exceeds max upload size ' + uploadSizeLimit
				});
				return false;
			}
			if (byteSizeLetter === 'MB') {
				const byteSizeValueLimit = parseFloat(uploadSizeLimit.split(' ')[0]);
				// console.log('byteSizeValueLimit')
				// console.log(byteSizeValueLimit)
				//
				// console.log('byteSizeValue')
				// console.log(byteSizeValue)
				if (parseFloat(byteSizeValue) > byteSizeValueLimit) {
					setAlert({
						variant: 'danger',
						message: 'Your file must be smaller than 100 MB'
						// message: 'File exceeds max upload size ' + uploadSizeLimit
					});
					return false;
				}
			}
		}
		return true;
	};

	const checkImageValidity = async (file) => {
		//  Comment out the image height width checking
		let img = new Image();
		img.src = URL.createObjectURL(file);
		await img.decode();
		let width = img.width;
		let height = img.height;
		let passed = false;
		let error_message = null;
		let minHeightError = false;
		let maxHeightError = false;
        
		if (minHeight) {
			if (height < minHeight) {
				error_message = 'Minimum height ' + minHeight + 'px';
				minHeightError = true;
			} else {
				passed = true;
			}
		}
		if (minWidth) {
			if (width < minWidth) {
				if (!minHeightError) {
					error_message = 'Minimum width ' + minWidth + 'px';
				}
			} else {
				passed = true;
			}
		}
		if (maxHeight) {
			if (height > maxHeight) {
				error_message = 'Maximum height ' + maxHeight + 'px';
				maxHeightError = true;
				passed = false;
			}
		}
		if (maxWidth) {
			if (width > maxWidth) {
				if (!maxHeightError) {
					error_message = 'Maximum width ' + maxWidth + 'px';
				}
				passed = false;
			}
		}
		if (!passed && error_message) {
			setAlert({
				variant: 'danger',
				message: error_message
			});
            onError(error_message)
			return false;
		}
        
		return checkFileSizeValidity(file);
	};

	const getVideoDimensions = async (file) => {
		return new Promise((res) => {
			const url = URL.createObjectURL(file);
			const video = document.createElement('video');
			video.onloadedmetadata = (evt) => {
				// Revoke when you don't need the url any more to release any reference
				URL.revokeObjectURL(url);
				res([video.videoWidth, video.videoHeight]);
			};
			video.src = url;
			video.load(); // fetches metadata
		});
	};

	const checkVideoValidity = async (file) => {
		//  Comment out the video dimension checking
		const dimensions = await getVideoDimensions(file);
		if (dimensions) {
			const height = dimensions[1];
			const width = dimensions[0];
			if (height && minHeight && width && minWidth) {
				if (height < minHeight || width < minWidth) {
					const dimensionStr = minHeight + 'px x ' + minWidth + 'px';
					setAlert({
						variant: 'danger',
						message: 'Minimum dimension ' + dimensionStr
					});
					return false;
				}
			}
			if (height && maxHeight && width && maxWidth) {
				if (height > maxHeight || width > maxWidth) {
					const dimensionStr = maxHeight + 'px x ' + maxWidth + 'px';
					setAlert({
						variant: 'danger',
						message: 'Maximum dimension ' + dimensionStr
					});
					return false;
				}
			}
		}
		return checkFileSizeValidity(file);
	};

	const handleBeforeCrop = async (file) => {
		setAlert(null);
		const file_extension = getFileExtension(file);
		if (VIDEO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
			const vidValid = await checkVideoValidity(file);
			if (!vidValid) {
				return false;
			}
			handleUploadFileChange(file);
			setFileType(UPLOAD_FILE_TYPE.VIDEO);
			return false;
		}
		if (IMAGE_FILETYPES.includes('.' + file_extension.toLowerCase())) {
			const imgValid = await checkImageValidity(file);
			if (!imgValid) {
				return false;
			}
			setFileType(UPLOAD_FILE_TYPE.IMAGE);
			return true;
		}
	};

	useEffect(() => {
		if (disableCropper) {
			setCropperEnabled(!disableCropper);
		}
	},[disableCropper])

	useEffect(() => {
		// const mergedFileTypes = IMAGE_FILETYPES.concat(VIDEO_FILETYPES);  Note: commenting this code to prevent multiple file types
		// setAcceptedFileTypes(mergedFileTypes.toString());
		if (uploadFileTypes) {
			setAcceptedFileTypes(uploadFileTypes)
		}
	},[uploadFileTypes])

	useEffect(() => {
		if (blobUrlType) {
			if (blobUrlType === 'video') {
				setFileType(UPLOAD_FILE_TYPE.VIDEO);
			}
		}
	},[blobUrlType])

	useEffect(() => {
		if (uploadedFilePreview) {
			const backgroundImage = {
				backgroundImage: 'url(' + uploadedFilePreview + ')'
			};
			setBackgroundImage(backgroundImage);
			const onload_file_extension = getFileExtension(uploadedFilePreview, true);
			if (
				onload_file_extension &&
				VIDEO_FILETYPES.includes('.' + onload_file_extension.toLowerCase())
			) {
				setFileType(UPLOAD_FILE_TYPE.VIDEO);
			}
		}
	}, [uploadedFilePreview]);

	useEffect(() => {
		if (isAudioUpload) {
			setFileType(UPLOAD_FILE_TYPE.AUDIO);
		}
	},[isAudioUpload])

	useEffect(() => {
		if (passedError) {
			setAlert({
				variant: 'danger',
				message: passedError
			});
		}
	}, [passedError]);

	useEffect(() => {
	}, [fileType, isAudioUpload, cropperEnabled]);

	return (
		<>
			{cropperEnabled ? (
				<>
					<ImgCrop
						aspect={cropperAspectRatio}
						fillColor={'transparent'}
						beforeCrop={handleBeforeCrop}
						rotate
					>
						<Dragger
							accept={acceptedFileTypes}
							showUploadList={false}
							beforeUpload={handleUploadFileChange}
							customRequest={dummyRequest}
                            onRemove={() => {console.log('remove')}}
						>
							{uploadedFilePreview ? (
								<>
									{fileType === UPLOAD_FILE_TYPE.VIDEO ? (
										<div className={"form-input-media "+ (shape) +" md air corner-i"}>
											<video
												autoPlay
												muted
												loop
												src={uploadedFilePreview}
											/>
											<a>
												{/*<ReactPlayer*/}
												{/*	width="100%"*/}
												{/*	height="100%"*/}
												{/*	playing={true}*/}
												{/*	loop={true}*/}
												{/*	muted={true}*/}
												{/*	className={``}*/}
												{/*	controls={false}*/}
												{/*	url={uploadedFilePreview}*/}
												{/*	style={*/}
												{/*		{*/}
												{/*			width: '100%',*/}
												{/*			height: '100%',*/}
												{/*			objectFit: 'cover',*/}
												{/*			borderRadius: '9999px'*/}
												{/*		} }*/}
												{/*/>*/}
												<i
													className="fa-solid fa-circle-xmark"
													onClick={(e) => {
														e.stopPropagation();
														handleResetAsset();
													}}
												/>
											</a>
										</div>
									) : (
										<>
											<div className={"form-input-media "+ (shape) +" md air corner-i"}
												 style={backgroundImage}>
												<a>
													<i
														className="fa-solid fa-circle-xmark"
														onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleResetAsset();
                                                        }}
													/>
												</a>
											</div>
										</>
									)}
								</>
							) : (
								<div className="cs-stack-form-media-item">
									<div className={"form-input-media "+ (shape) +" md air"}>
										<a>
											<i className="fa-light fa-plus"/>
										</a>
									</div>
									{!hideDragIcon && (
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
									)}
									{!hideText && (
										<p className="ant-upload-text">
											Click or drag file to this area to upload
										</p>
									)}
								</div>
							)}
						</Dragger>
					</ImgCrop>
				</>
			) : (
				<>
					<Dragger
						accept={acceptedFileTypes}
						showUploadList={false}
						beforeUpload={handleUploadFileChange}
						customRequest={dummyRequest}
					>
						{uploadedFilePreview ? (
							<>
								{fileType === UPLOAD_FILE_TYPE.VIDEO ? (
									<div className={"form-input-media "+ (shape) +" md air corner-i"}>
										<video
											autoPlay
											muted
											loop
											src={uploadedFilePreview}
										/>
										<a>
											{/*<ReactPlayer*/}
											{/*	width="100%"*/}
											{/*	height="100%"*/}
											{/*	playing={true}*/}
											{/*	loop={true}*/}
											{/*	muted={true}*/}
											{/*	className={``}*/}
											{/*	controls={false}*/}
											{/*	url={uploadedFilePreview}*/}
											{/*	style={*/}
											{/*		{*/}
											{/*			width: '100%',*/}
											{/*			height: '100%',*/}
											{/*			objectFit: 'cover',*/}
											{/*			borderRadius: '9999px'*/}
											{/*		} }*/}
											{/*/>*/}
											<i
												className="fa-solid fa-circle-xmark"
												onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleResetAsset();
                                                        }}
											/>
										</a>
									</div>
								) : (
									<>
										{fileType === UPLOAD_FILE_TYPE.AUDIO ?
											<audio controls>
												<source src={uploadedFilePreview} type="audio/mpeg" />
											</audio>
											:
											<div className={"form-input-media "+ (shape) +" md air corner-i"}
												 style={backgroundImage}>
												<a>
													<i
														className="fa-solid fa-circle-xmark"
														onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleResetAsset();
                                                        }}
													/>
												</a>
											</div>
										}
									</>
								)}
							</>
						) : (
							<div className="cs-stack-form-media-item">
								<div className={"form-input-media "+ (shape) +" md air"}>
									<a>
										<i className="fa-light fa-plus"/>
									</a>
								</div>
								{!hideDragIcon && (
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
								)}
								{!hideText && (
									<p className="ant-upload-text">
										Click or drag file to this area to upload
									</p>
								)}
							</div>
						)}
					</Dragger>
				</>
			)}
            
		</>
        
	);
};

export default memo(UploadDraggerV2);
