import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import { VALIDATE_MESSAGES, GOOGLE_MAP_KEY } from '../../../../constants/common';
import { register, walletSetAccountPassword } from '../../../../services/user';
import { isAlphaNumeric } from '../../../../utils/form';
import { getAuthUserInfo } from '../../../../states/general/actions';
import { setAccessToken } from '../../../../utils/common';
import routes from '../../../../routes';

import Autocomplete from 'react-google-autocomplete';
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import { getSitewideLink } from '../../../../utils/sitewideLinks';

const EmailConfirmedAddAccountDetails = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { email, authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId } = props;
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [emptyPass, setEmptyPass] = useState(true);
	const [emptyCity, setEmptyCity] = useState(true);
	const [inputElement, setInputElement] = useState();
	const { size } = useSelector((state) => state.classSize);
	const [errorSize, setErrorSize] = useState('sm');

	const [blurredField, setBlurredField] = useState(null);
	const [isSubmitClicked, setSubmitClicked] = useState(false);

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		if (form) {
            setSubmitted(false)
			let emptyPass = true;
           
			if (form.getFieldValue('password')) {
				emptyPass = false;
			}
			setEmptyPass(emptyPass);

			let emptyCity = true;
			if (form.getFieldValue('city')) {
				emptyCity = false;
			}
			setEmptyCity(emptyCity);

			await form
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
		}
	}, []);

    useEffect(() => {
    }, [formHasError])

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setSubmitted(true);
			try {
				if (location.pathname === routes.emailVerificationWalletSetPassword() ||
					(verifyEmailSpaceUsername && (verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)))
				{
					await setWalletAccountPassword(email, values);
				} else {
					// await registerUser(email, values);
					const data = form.getFieldsValue();
					if (authUserInfo) {
						data.user_id = authUserInfo.id;
					}
					data.email = email;
					const result = await register(data);
					if (result) {
						if (result.result) {
							if (result.access_token) {
								setAccessToken(result);
							}
							form.resetFields();
							setAlert({
								variant: 'success',
								message: 'Registered successfully!'
							});
							window.location.replace(routes.registerMemberProfile());
						} else {
							if (result.message) {
								setAlert({ variant: 'danger', message: result.message });
							} else {
								setAlert({
									variant: 'danger',
									message: 'Something went wrong'
								});
							}
						}
					}
					setSubmitted(false);
				}
				setSubmitted(false);
			} catch (error) {
				setAlert({ variant: 'danger', message: error.message });
				setSubmitted(false);
			}
		},
		[email, authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId]
	);

	const setWalletAccountPassword = useCallback(
		async (email, values) => {
			const data = values;
			data.email = email;

			const result = await walletSetAccountPassword(data);
			if (result) {
				if (result.result) {
					dispatch(getAuthUserInfo());

					form.resetFields();
					setAlert({
						variant: 'success',
						message: result.message
					});

					if (verifyEmailSpaceUsername && (verifyEmailSpaceMembershipId || verifyEmailSpaceItemId)) {
						if (verifyEmailSpaceMembershipId) {
							const url = routes.spaceMembershipRegisterMemberProfile();
							const fin_url =
								url.replace(':space_username', '@' + verifyEmailSpaceUsername) +
								'?id=' +
								verifyEmailSpaceMembershipId;
							navigate(fin_url);
						}
						if (verifyEmailSpaceItemId) {
							const url = routes.spaceItemRegisterMemberProfile();
							const fin_url =
								url.replace(':space_username', '@' + verifyEmailSpaceUsername) +
								'?id=' +
								verifyEmailSpaceItemId;
							navigate(fin_url);
						}

					} else {
						navigate(routes.registerMemberProfile());
					}
				} else {
					if (result.message) {
						setAlert({ variant: 'danger', message: result.message });
					} else {
						setAlert({
							variant: 'danger',
							message: 'Something went wrong'
						});
					}
				}
			}

			setSubmitted(false);
		},
		[verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, verifyEmailSpaceItemId]
	);

	// const registerUser = async (email, values) => {
	// 	const data = form.getFieldsValue();
	// 	console.log('data: ' + JSON.stringify(data));
	// 	console.log('values: ' + JSON.stringify(values));
	// 	console.log('email: ' + email);
	// 	if (values.birthdate) {
	// 		console.log('values.birthdate: ' + JSON.stringify(values.birthdate));
	// 		data.birthdate = values.birthdate.format('YYYY-MM-DD');
	// 		console.log('data.birthdate: ' + JSON.stringify(data.birthdate));
	// 	}
	// 	console.log('data1: ' + data);
	//
	// 	if (authUserInfo) {
	// 		data.user_id = authUserInfo.id;
	// 	}
	// 	console.log('data2: ' + data);
    //     data.email = email;
	//
	// 	const result = await register(data);
	// 	console.log('result: ' + result);
	// 	if (result) {
	// 		if (result.result) {
	// 			if (result.access_token) {
	// 				setAccessToken(result);
	// 			}
	//
	// 			form.resetFields();
	// 			setAlert({
	// 				variant: 'success',
	// 				message: 'Registered successfully!'
	// 			});
	//
	// 			window.location.replace(routes.registerMemberProfile());
	// 		} else {
	// 			if (result.message) {
	// 				setAlert({ variant: 'danger', message: result.message });
	// 			} else {
	// 				setAlert({
	// 					variant: 'danger',
	// 					message: 'Something went wrong'
	// 				});
	// 			}
	// 		}
	// 	}
	//
	// 	setSubmitted(false);
	// };

	const handleFinishFailed = useCallback(async (errorInfo) => {
		setSubmitClicked(true);
		console.log('handleFinishFailed')
		// Set the blurred field to the first field with an error
		if (errorInfo.errorFields.length > 0) {
			setBlurredField('all');
		}
	}, [blurredField, isSubmitClicked]);

	const handleOnblurField = useCallback(async (field) => {
		if (!isSubmitClicked) {
			setBlurredField(field);
		}
		setSubmitClicked(false);
	}, [blurredField, isSubmitClicked]);

	// useEffect(() => {
    //     console.log("test")
	// 	const checkValue = (str, max) => {
	// 		if (str.charAt(0) !== '0' || str === '00') {
	// 			var num = parseInt(str);
	// 			if (isNaN(num) || num <= 0 || num > max) num = 1;
	// 			str =
	// 				num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
	// 					? '0' + num
	// 					: num.toString();
	// 		}
	// 		return str;
	// 	};

	// 	const handleInput = (e) => {
	// 		e.target.type = 'text';
	// 		let input = e.target.value;
	// 		if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
	// 		const values = input.split('/').map((v) => v.replace(/\D/g, ''));
	// 		if (values[0]) values[0] = checkValue(values[0], 12);
	// 		if (values[1]) values[1] = checkValue(values[1], 31);
	// 		const output = values
	// 			.map((v, i) => (v.length === 2 && i < 2 ? v + ' / ' : v))
	// 			.join('')
	// 			.substr(0, 14);
	// 		inputElement.value = output;

	// 		const inputDate = output;
	// 		const dateParts = inputDate.split('/');
	// 		const year = parseInt(dateParts[2], 10);
	// 		const month = parseInt(dateParts[0], 10) - 1;
	// 		const day = parseInt(dateParts[1], 10);
	// 		// const newDate = new Date(year, month, day);

	// 		const isFourDigits = (num) => {
	// 			return /^\d{4}$/.test(num.toString());
	// 		};

	// 		// console.log('year: ' + year + ' month: ' + month + ' day:' + day);
	// 		if (!isNaN(day) && !isNaN(month) && !isNaN(year) && isFourDigits(year)) {
	// 			form.setFieldValue('birthdate', dayjs(new Date(year, month, day)));
	// 		}
    //         console.log(form.getFieldValue('birthdate'))
	// 	};

	// 	setInputElement(document.getElementById('registerForm_birthdate'));
	// 	if (inputElement) {
	// 		inputElement.addEventListener('input', handleInput);
	// 	}

	// 	return () => {
	// 		if (inputElement) {
	// 			inputElement.removeEventListener('input', handleInput);
	// 		}
	// 	};

	// 	// const handleBlur = (e) => {
	// 	// 	e.target.type = 'text';
	// 	// 	let input = e.target.value;
	// 	// 	const values = input.split('/').map((v) => v.replace(/\D/g, ''));
	// 	// 	let output = '';
	// 	// 	if (values.length === 3) {
	// 	// 		const year =
	// 	// 			values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
	// 	// 		const month = parseInt(values[0]) - 1;
	// 	// 		const day = parseInt(values[1]);
	// 	// 		const d = new Date(year, month, day);
	// 	// 		if (!isNaN(d)) {
	// 	// 			document.getElementById('result').innerText = d.toString();
	// 	// 			const dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
	// 	// 			output = dates
	// 	// 				.map((v) => {
	// 	// 					v = v.toString();
	// 	// 					return v.length === 1 ? '0' + v : v;
	// 	// 				})
	// 	// 				.join(' / ');
	// 	// 		}
	// 	// 	}
	// 	// 	setValue(output);
	// 	// };
	// }, [inputElement, errorSize]);

    useEffect(() => {
        // Add a hidden username field to trick browsers
        const form = document.getElementById('registerForm');
        if (form) {
            const hiddenUsername = document.createElement('input');
            hiddenUsername.type = 'text';
            hiddenUsername.id = 'username';
            hiddenUsername.name = 'username';
            hiddenUsername.style.display = 'none';
            hiddenUsername.tabIndex = -1;
            hiddenUsername.autocomplete = 'username';
            form.prepend(hiddenUsername);
        }
    }, []);

    const validateField = async () => {
        await form
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});
    }

	return (
		<div className="p-section-safeview sm:pr-gutter">
			<div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter">
				<hgroup className="heading">
					<h2>Email Confirmed!</h2>
					<p className="text-p1">Continue your account creation by adding your details and choosing a password.</p>
				</hgroup>
				{location.pathname !== routes.emailVerificationWalletSetPassword() && (!verifyEmailSpaceUsername || (!verifyEmailSpaceMembershipId && !verifyEmailSpaceItemId)) && (
					<Form
						form={form}
						name="registerForm"
                        id="registerForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
                            setFormIsOnFocus(false);
							handleFormOnBlur().then(r => {});
                        }}
                
                        validateTrigger={['onBlur', 'onChange']}
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						onFinishFailed={handleFinishFailed}
						className='cs-stack-create-account stack-col'
						autoComplete="off"
					>
						{/* Add this hidden field at the top of the form */}
						<input type="text" 
							   style={{display: 'none'}} 
							   name="fakeusernameremembered"
							   tabIndex="-1"
						/>
						<input type="password" 
							   style={{display: 'none'}} 
							   name="fakepasswordremembered"
							   tabIndex="-1"
						/>

						{location.pathname !== routes.emailVerificationWalletSetPassword() &&
							(!verifyEmailSpaceUsername || (!verifyEmailSpaceMembershipId && !verifyEmailSpaceItemId)) && (
								<>
                                
									<Form.Item
										name="first_name"
                                        onChange={validateField}
										rules={[
											{
												required: true,
                                                message: "First Name is required"
											}
										]}
									>
                                    
                                        <div className="form-input">
											<label htmlFor="firstname">First Name</label>
											<input id="first_name" name="first_name"
												   onBlur={()=>handleOnblurField('first_name')}
												   type="text" autoComplete="first_name" placeholder="Name"/>
										</div>
										
                                    
										
									</Form.Item>
									{((blurredField === 'first_name' || blurredField === 'all') && form.getFieldsError()[0]?.errors.length > 0) && (
											<div className="alert callout warning !m-0">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{form.getFieldsError()[0]?.errors}</h6>
													</div>
												</div>
											</div>
										)}

									<Form.Item
										name="last_name"
                                        onChange={validateField}
										rules={[
											{
												required: true,
                                                message: "Last Name is required"
											}
										]}
									>
										<div className="form-input">
											<label htmlFor="lastname">Last Name</label>
											<input id="last_name"  name="last_name"
												   onBlur={()=>handleOnblurField('last_name')}
												   type="text" autoComplete="last_name" placeholder="Surname"/>
                                        </div>
									</Form.Item>
                                    {((blurredField === 'last_name' || blurredField === 'all') && form.getFieldsError()[1]?.errors.length > 0) && (
											<div className="alert callout warning !m-0 ">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{form.getFieldsError()[1]?.errors}</h6>
													</div>
												</div>
											</div>
										)
									}
									<Form.Item
										name="birthdate"
										rules={[
											{
												required: true,
                                                message: "Birthdate is required"
											}
										]}
									>
										<div  className="form-input">
											<label htmlFor="birthdate">Birthdate</label>
											{/* <DatePicker
												placeholder="dd/mm/yy"
												className={
													'w-full rounded-full bg-transparent border-0'
												}
												format="DD/MM/YYYY"
                                                onChange={(date) => {
                                                    handleInput(date)
                                                    form.setFieldsValue({ birthdate: date });
                                                }}
											/> */}
                                            <input id="birthdate"
												   onChange={(date) => {
													   form.setFieldsValue({ birthdate: date.target.value });
												   }}
												   name="birthdate"
												   onBlur={()=>handleOnblurField('birthdate')}
												   type="date" autoComplete="birthdate" placeholder="MM/DD/YYYY">
											</input>
										</div>
									</Form.Item>
									{((blurredField === 'birthdate' || blurredField === 'all') && form.getFieldsError()[2]?.errors.length > 0) && (
											<div className="alert callout warning !m-0 ">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{form.getFieldsError()[2]?.errors}</h6>

													</div>
												</div>

											</div>
										)
									}
									<Form.Item
                                        name="city"
                                        id="city"
                                        autoComplete="off" 
                                        data-form-type="other"
                                        rules={[
                                            {
                                                required: true,
                                                message: "City is required"
                                            },
                                            {
                                                validator: (rule, value) => {
                                                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                    if (emailPattern.test(value)) {
                                                        return Promise.reject(new Error('City cannot be an email address.'));
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }
                                        ]}
                                    >
                                        <div className="form-input">
                                            <label htmlFor="city">City</label>
                                            <Autocomplete
                                                placeholder="Start typing & select from list."
                                                autoComplete="city"  // Changed to be more specific
                                                className={'txt-long input-' + size}
                                                apiKey={GOOGLE_MAP_KEY}
                                                onBlur={()=>handleOnblurField('city')}
                                                onPlaceSelected={(place) => {
                                                    form.setFieldValue(
                                                        'city',
                                                        place.formatted_address
                                                    );
                                                }}
                                                data-form-type="address"
                                                aria-label="City"
                                                name="city"
                                                id="city"
                                                type="search"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                data-username="false"
                                                data-credential="false"
                                                data-lpignore="true"
                                                role="combobox"
                                            />
                                        </div>
                                    </Form.Item>
									{((blurredField === 'city' || blurredField === 'all') && form.getFieldsError()[3]?.errors.length > 0) && (
											<div className="alert callout warning !m-0 ">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{form.getFieldsError()[3]?.errors}</h6>
													</div>
												</div>
											</div>
										)
									}
									<p className="cs-helper">Your Birthdate and City are kept private and not publicly visible. This information is needed to comply with local regulations and for access to age-appropriate content.</p>
                                    <Form.Item
                                        name="fake-city"
                                        id="fake-city"
                                        autoComplete="off" 
                                        data-form-type="other"                                        
                                    >
                                        <div className="form-input hidden">
                                            <label htmlFor="fake-city">City</label>
                                            <Autocomplete
                                                id="fake-city"
                                                placeholder="Start typing & select from list."
                                                className={'txt-long input-' + size}
                                                apiKey={GOOGLE_MAP_KEY}
                                                onBlur={()=>handleOnblurField('fake-city')}
                                                onPlaceSelected={(place) => {
                                                    form.setFieldValue(
                                                        'fake-city',
                                                        place.formatted_address
                                                    );
                                                }}
                                                data-form-type="other"
                                                aria-label="Fake City"
                                                name="fake-city"  // Using standard address field name
                                                id="fake-city2"    // Match name
                                                type="search"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                data-username="false"  // Explicitly tell Chrome this isn't a username field
                                                data-credential="false" // Explicitly tell Chrome this isn't a credential field
                                                disableAutoComplete="true" // Disable autocomplete to prevent email suggestions
                                            />
                                        </div>
                                    </Form.Item>
									<Form.Item
										name="password"
                                        id="password"
										rules={[
											{required: true,
                                            message: "Password is required"
                                            },
											({getFieldValue}) => ({
												validator(_, value) {
													setErrorSize('sm');
													if (value && value.length < 8) {
														setErrorSize('md');
														return Promise.reject(
															new Error('Password must be 8 characters long or more.')
														);
													}
													if (isAlphaNumeric(value)) {
														return Promise.resolve();
													}
													if (value && value.length > 0) {
														setErrorSize('lg');
														return Promise.reject(
															new Error(
																'Password is case sensitive, must be at least 8 characters, and include a symbol and number.'
															)
														);
													}
													return Promise.resolve();
												}
											})
										]}
									>
										<div className="form-input">
											<label htmlFor="password">Password</label>
											<input id="password" name="password"
												   onBlur={()=>handleOnblurField('password')}
												   type="password" autoComplete="password" placeholder="8+ characters with a symbol and number."/>
										</div>
										
									</Form.Item>
									{((blurredField === 'password' || blurredField === 'all') && form.getFieldsError()[4]?.errors.length > 0) && (
											<div className="alert callout warning !m-0 ">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{form.getFieldsError()[4]?.errors}</h6>
													</div>
												</div>
											</div>
										)
									}
									<Form.Item
										name="confirmpassword"
										dependencies={['password']}
										rules={[
											{
												required: true,
												message: 'Please confirm your password.'
											},
											({getFieldValue}) => ({
												validator(_, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject(new Error('Password does not match.'));
												}
											})
										]}
									>
										<div className="form-input-combo">
											<label htmlFor="password">Re-enter Password*</label>
											<input id="confirmpassword" name="confirmpassword"
												   onBlur={()=>handleOnblurField('confirmpassword')}
												   type="password" autoComplete="confirmpassword" placeholder="Re-enter Password"/>
											<button type="submit"
													className={`secondary-gradient` + (submitted ? " pointer-events-none" : " pointer-events-auto")}
													disabled={submitted}
                                                    // onClick={handleSubmit}
												>
												<span>
                                                    {submitted ? (
                                                        <p><i className="fa-solid fa-spinner fa-spin  mr-2"></i></p>
                                                    ) : (
                                                        <p> Save & Continue</p>
                                                    )}
                                            		
                                            	</span>
											</button>
										</div>							
									</Form.Item>
									{((blurredField === 'confirmpassword' || blurredField === 'all') && form.getFieldsError()[5]?.errors.length > 0) && (
											<div className="alert callout warning !m-0 ">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{form.getFieldsError()[5]?.errors}</h6>
													</div>
												</div>
											</div>
										)
									}
								</>
							)}

					</Form>
				)}
			</div>
		</div>
	);
};

export default memo(EmailConfirmedAddAccountDetails);
