import React, { memo, useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Form, Input} from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {getSitewideLink} from "../../../utils/sitewideLinks";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import Alert from "react-bootstrap/Alert";
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {SPACE_PAYOUT_METHOD} from "../../../constants/space";
import {
	createStripeAccount,
	createStripePayoutAccount,
	generateSpaceRandomString,
	getSpacePayoutMethods, getStripeLoginLink,
	removeSpacePayoutAccount, updateLastStepLink,
} from "../../../services/space";
import AddStripeBankAccountModal
	from "../../../components/commons/Modals/AddStripeBankAccountModal/AddStripeBankAccountModal";
import moment from "moment";
import copy from 'copy-to-clipboard';

const AddPaymentMethod = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [addPayoutMethodForm] = Form.useForm();
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [alert, setAlert] = useState(null);
	const [selectedPayoutMethod, setSelectedPayoutMethod] = useState('default');
	const [submitted, setSubmitted] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [isSetupStripe, setIsSetupStripe] = useState(false);
	const [showStripeModal, setShowStripeModal] = useState(false);
	const [isOnBoardingSubmitted, setIsOnBoardingSubmitted] = useState(false);
	const [isUserHaveStripeAccount, setIsUserHaveStripeAccount] = useState(false);
	const [isUserHaveStripePayoutAccount, setIsUserHaveStripePayoutAccount] = useState(false);
	const [payoutMethods, setPayoutMethods] = useState([]);
	const [payoutAccount, setPayoutAccount] = useState();
	const [isGenerateLoading, setIsGenerateLoading] = useState(false);
	const [spaceRandomString, setSpaceRandomString] = useState();
	const [copyAlert, setCopyAlert] = useState(null);
	const [isVerified, setIsVerified] = useState(false);
	const [loginLinkLoading, setLoginLinkLoading] = useState(false);

	const handleUpdateLastStepLink = useCallback(async () => {
			const data = {};
			// include last step link data for continuing the space creation
			const url = routes.createSpacePrevLaunch();
			data.last_step_link = url.replace(':space_username', space_username);
			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					const response = await updateLastStepLink(data);
					if (response && response.result) {
						saveContinue();
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		[space_username, authUserInfo, spaceInfo]
	);

	const saveContinue = () => {
		const url = routes.createSpacePrevLaunch();
		navigate(url.replace(':space_username', space_username));
		// const url = routes.createSpaceBilling();
		// navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createSpaceAddPages();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	const handleFormChanges = useCallback(async () => {
		await addPayoutMethodForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await addPayoutMethodForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const setUpStripe = useCallback(
		async () => {
			setAlert(null);
			setSubmitted(true);
			setIsUserHaveStripeAccount(false);
			setIsUserHaveStripePayoutAccount(false);

			try {
				if (authUserInfo) {
					const data = {
						user_id: authUserInfo.id,
						space_id: spaceInfo.id
					};

					const responseCreateStripeAccount = await createStripeAccount(data);
					if (responseCreateStripeAccount && responseCreateStripeAccount.result) {
						setIsUserHaveStripeAccount(true);
					} else {
						if (responseCreateStripeAccount.message === "User already created a payment gateway account.")
							setIsUserHaveStripeAccount(true);
					}

					const responseCreateStripePayoutAccount = await createStripePayoutAccount(data);
					if (responseCreateStripePayoutAccount && responseCreateStripePayoutAccount.result) {
						setIsUserHaveStripePayoutAccount(true);
					} else {
						if (responseCreateStripePayoutAccount.message === "User already created a payment gateway payout account.")
							setIsUserHaveStripePayoutAccount(true);
					}

					setSubmitted(false);
				}
			} catch (error) {
				setAlert({ variant: 'danger', message: error.message });
				setSubmitted(false);
			}
		},
		[authUserInfo, spaceInfo, submitted]
	);

	const getSpacePayoutMethodsRequest = useCallback(async () => {
		try {
			const params = {
				space_id: spaceInfo.id
			};

			const response = await getSpacePayoutMethods(params);
			if (response && response.result) {
				if (response.payout_account.external_accounts.data) {
					setPayoutMethods(response.payout_account.external_accounts.data);
					setPayoutAccount(response.payout_account);
					setIsVerified(response.payout_account.payouts_enabled);
				}
			} else {
				setPayoutMethods([]);
			}
		} catch (error) {
			console.log(error);
		}
	}, [spaceInfo]);

	const removeSpacePayoutAccountRequest = useCallback(async (external_bank_account_id) => {
		try {
			const params = {
				space_id: spaceInfo.id,
				// external_bank_account_id: external_bank_account_id
			};

			const response = await removeSpacePayoutAccount(params);
			if (response && response.result) {
				setIsSetupStripe(false);
				setIsOnBoardingSubmitted(false);
				setSubmitted(false);
				getSpacePayoutMethodsRequest().then(r => {});
			}
		} catch (error) {
			console.log(error);
		}
	}, [spaceInfo]);

	const getStripeLoginLinkRequest = useCallback(async () => {
		if (payoutAccount) {
			setLoginLinkLoading(true);
			try {
				const params = {
					account_id: payoutAccount.id
				};

				const response = await getStripeLoginLink(params);
				if (response && response.result) {
					setLoginLinkLoading(false);
					popupCenter({url: response.login_link_object?.url, w: 500, h: 650});
				}
			} catch (error) {
				console.log(error);
				setLoginLinkLoading(false);
			}
		}
	}, [payoutAccount]);

	const popupCenter = ({url, w, h}) => {
		// Fixes dual-screen position                             Most browsers      Firefox
		const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft
		const top = (height - h) / 2 / systemZoom + dualScreenTop
		const newWindow = window.open(url, "_blank", `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`)

		if (window.focus) newWindow.focus();
	}

	const handleSubmit = useCallback(
		async () => {
			// setAlert(null);
			// setSubmitted(false);
			//
			// try {
			// 	if (authUserInfo) {
			// 		const data = {
			// 			user_id: authUserInfo.id
			// 		};
			//
			// 		setSubmitted(true);
			// 		const responseCreateStripeAccount = await createStripeAccount(data);
			// 		if (responseCreateStripeAccount && responseCreateStripeAccount.result) {
			// 			console.log(responseCreateStripeAccount);
			// 			const responseCreateStripePayoutAccount = await createStripePayoutAccount(data);
			// 			if (responseCreateStripePayoutAccount && responseCreateStripePayoutAccount.result) {
			// 				console.log(responseCreateStripePayoutAccount);
			// 			}
			// 		}
			// 	}
			// 	setSubmitted(false);
			// } catch (error) {
			// 	setAlert({ variant: 'danger', message: error.message });
			// 	setSubmitted(false);
			// }
		},
		[authUserInfo]
	);

	const getCreatedDate = (created, status) => {
		// let newStatus = status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
		// let newStatus = '';
		// if (status)
		// 	newStatus = "Verified";
		// else
		// 	newStatus = "Stripe Verification Required";

		if (created) {
			const createdDays = created / (60*60*24);
			const newDate = moment(moment("01/01/1970", "MM/DD/YYYY").add(createdDays, 'days')).format("MM/DD");
			// return 'Submitted on ' + newDate + ': ' + newStatus;
			if (status)
				return 'Submitted on ' + newDate + ': Verified';
			else
				return 'Setup Incomplete';
		}
		else
			return '';
	};

	const generateSpaceRandomStringRequest = useCallback(async () => {
		setIsGenerateLoading(true);
		if (spaceRandomString) {
			copy(window.location.hostname + routes.viewUserSpace() + spaceRandomString);
			setCopyAlert({
				variant: 'notify',
				message: 'Preview Link Copied.'
			});
			setIsGenerateLoading(false);
		} else {
			const data = {};
			try {
				data.space_id = spaceInfo.id;
				const response = await generateSpaceRandomString(data);
				if (response.result) {
					setSpaceRandomString(response.random_string);
					copy(window.location.hostname + routes.viewUserSpace() + response.random_string);
					setCopyAlert({
						variant: 'notify',
						message: 'Preview Link Copied.'
					});
					setIsGenerateLoading(false);
				} else {
					setIsGenerateLoading(false);
				}
			} catch (error) {
				setIsGenerateLoading(false);
			}
		}
		setTimeout(() => {
			setCopyAlert(null);
		}, 2000);
	}, [spaceInfo, spaceRandomString, isGenerateLoading]);

	const checkPayoutStatus = useCallback(async () => {
		payoutMethods.map((payout) => {
			if (payout.status !== 'verified') {
				setIsVerified(false);
			} else {
				setIsVerified(true);
			}
		});
	}, [payoutMethods]);

	useEffect(() => {
		if (isUserHaveStripeAccount && isUserHaveStripePayoutAccount) {
			setIsSetupStripe(true);
			setShowStripeModal(true);
		}
	}, [isUserHaveStripeAccount, isUserHaveStripePayoutAccount]);


	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
				getSpacePayoutMethodsRequest().then(r => {});
			}
		}
		document.title = 'Commonspace Spaces';
	}, [spaceInfo, spaceInfoLoading]);

	useEffect(() => {
		if (!spaceInfoLoading && initialLoad) {
			setInitialLoad(false);
			dispatch(getSpaceInfo(space_username));
		}
		if (spaceInfoLoading === 'failed') {
			navigate(routes.createSpace());
		}
	}, [spaceInfoLoading, space_username, initialLoad]);

	// useEffect(() => {
	// 	if (spaceInfoLoading && spaceInfoLoading === 'done') {
	// 		if (spaceInfo && spaceInfo.id) {
	// 			setFormSpaceData(spaceInfo).then((r) => {});
	// 			getSpacePayoutMethodsRequest().then(r => {});
	// 		}
	// 	} else {
	// 		console.log(!spaceInfoLoading && initialLoad)
	// 		if (!spaceInfoLoading && initialLoad) {
	// 			console.log('test')
	// 			setInitialLoad(false);
	// 			dispatch(getSpaceInfo(space_username));
	// 		}
	// 		if (spaceInfoLoading === 'failed') {
	// 			navigate(routes.createSpace());
	// 		}
	// 	}
	// 	document.title = 'Commonspace Spaces';
	// }, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);


	useEffect(() => {
		if (payoutMethods && payoutMethods.length > 0) {
			setIsSetupStripe(true);
			// if (!isOnBoardingSubmitted)
			// 	setIsOnBoardingSubmitted(true);
			// checkPayoutStatus().then(r => {});
		} else {
			setIsVerified(false);
		}
	}, [payoutMethods]);

	useEffect(() => {
		if (isOnBoardingSubmitted) {
			getSpacePayoutMethodsRequest().then(r => {});
			setIsOnBoardingSubmitted(false);
		}
	}, [isOnBoardingSubmitted]);

	useEffect(()=>{
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},[])

	return (
        <main id="cs-platform-main" className="cs-main main-theme">
            <section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
                <div className="centerframe-grid gap-2gutter">
                    <div className="block-full">
                        <div className="cs-stack-heading">
                            <div className="stack-row split">
                                <h1>Set up Payout methods</h1>
                                <h3>Step 3 of 4</h3>
                            </div>
                            <p>
                            To begin selling Paid Memberships and Items, accept credit card
		 						and/or crypto payments, and receive Payouts you will need to setup
		 						your Payout Methods. You can choose to enable different payment
		 						methods and currencies using different services.{' '}							
		 						{getSitewideLink('learn_more_payment_payout_method', 'gradient-color-txt body-txtitalic--reg')}
		 						{' '}
                            </p>
                            
                        </div>
                        <div className="cs-stack-form-guided">
                            <div className="formrow title">
                                <div>
                                    <h2>Add Payout Method</h2>
                                    <p>Optional</p>
                                </div>
								{getSitewideLink('space_creation_guide_payout_method', 'button-secondary-gradient', true)}
							</div>
                            <Form
                                form={addPayoutMethodForm}
                                name="addPayoutMethodForm"
                                id="addPayoutMethodForm"
                                validateMessages={VALIDATE_MESSAGES}
                                onFocus={() => {
                                    setFormIsOnFocus(true);
                                }}
                                onBlur={() => {
                                    // setTimeout(() => {
									setFormIsOnFocus(false);
									handleFormOnBlur().then(r => {});
                                    // }, 100);
                                }}
                                onChange={handleFormChanges}
                                onFinish={handleSubmit}
                                className='w-full stack-form-col'
                            >
                                <div className="formrow">
                                    <div id="cscxl-payout-dropdown" className="cs-stack-form-buttons">
                                		<DropdownWithItems
                                            title="Payout Method"
                                            dropDownClass=""
                                            customButtonClass="button-dropdown-gradient-alt secondary "
                                            selectedValue={selectedPayoutMethod}
                                            listItems={SPACE_PAYOUT_METHOD}
                                            onDropdownChange={(value) => {
                                                setSelectedPayoutMethod(value);
                                                //setIsSetupStripe(false);
                                                //setIsOnBoardingSubmitted(false);
                                            }}
                                        />
                                    </div>
									<p className='italic text-sm'>All On-Platform Sales transacted through your Space will be processed and paid out to you via your Payout Methods. The timing, terms, and costs may vary based on the type and provider.</p>
                                </div>
                                {(selectedPayoutMethod === 'stripe') && (
                                    <>
										<div className="formrow title">
											<div className='flex'>
												<h3 className='flex items-center'><i className="fa-brands fa-cc-stripe gradient-color-txt text-[40px] mr-2"/> {' '}Payout Method</h3>
												<p>Add Payouts to your Bank Account via Stripe</p>
											</div>
											<div className="cs-formrow-helper-none">
												{getSitewideLink('learn_more_on_platform_sale', 'gradient-color-txt italic')}
											</div>
										</div>
										<div className="formrow">
											<div>
												<p>Enable credit card payments for all On-Platform Sales on your Space via Stripe with Payouts automatically deposited to your Bank Account. Stripe is a trusted global leader in secure payment processing used by millions of companies.</p>
											</div>
											<p className='italic text-sm'>Your total cost per transaction will be Stripe’s fee of 2.9% + 30¢ for each credit card transaction processed plus your Commonspace Plan’s On-Platform Sales Rate.</p>
										</div>

										{(payoutMethods && payoutMethods?.length < 1) && (
											<div id="cs-stack-product-actions" className="stack-row">
												<a onClick={()=>{
													if (!submitted) {
														setUpStripe().then(r => {});
													}
												}} className="button-primary-gradient"><span><p>Setup Stripe</p></span></a>
												{/*<a onClick={() => {*/}
												{/*    setSelectedPayoutMethod('default');*/}
												{/*}} class="button-secondary-gradient"><span><p>Remove Method</p></span></a>*/}

											</div>
										)}
                                    </>
                                )}
                                {showStripeModal && (
                                    <AddStripeBankAccountModal
                                        modalOpen={showStripeModal}
                                        spaceID={spaceInfo.id}
                                        setModalOpen={setShowStripeModal}
                                        userID={authUserInfo.id}
                                        setIsOnBoardingSubmitted={setIsOnBoardingSubmitted}
                                    />
                                )}
                                {(payoutMethods && payoutMethods?.length > 0) && (
                                    <>
                                        <div className="formrow title">
                                            <div>
                                                <h2>Payout Method</h2>
                                                <p>Active</p>
                                            </div>
                                            {/* <button type="submit" className="primary-gradient">
                                                    View & Edit on Stripe
                                                    <i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
                                            </button> */}
                                            <div className="cs-formrow-helper-none"></div>
                                        </div>
										{payoutMethods.map((payoutMethod, index) => (
											<div
												className="formrow title"
												key={index}
											>
												<div>
													<h5>{payoutMethod.bank_name + ' Bank Account Ending in ' + payoutMethod.last4}</h5>
													<p>
														<span className="body-text--reg !text-sm">
															{getCreatedDate((payoutAccount?.tos_acceptance?.date
																? payoutAccount?.tos_acceptance?.date
																: payoutAccount?.created), payoutAccount.payouts_enabled)}
														</span>
													</p>
												</div>
												<a onClick={() => {
													removeSpacePayoutAccountRequest(payoutMethod.id).then(r => {});
												}}
												   className="button-secondary-gradient"><span><p>Remove Method</p></span></a>
											</div>
										))}
                                    </>
                                )}
                            </Form>                            
                            {/*<button type="submit" onClick={handleUpdateLastStepLink}*/}
							{/*	disabled={!isSetupStripe} className={isSetupStripe ? "primary-gradient" : "primary-gradient disabled "}><span><p>Save & Continue</p></span></button>*/}
							{isVerified ? (
								<button
									type="submit"
									onClick={handleUpdateLastStepLink}
									className="secondary-gradient">
									<span>
										<p>Continue</p>
									</span>
								</button>
							) : (
								(payoutMethods && payoutMethods?.length > 0) && (
									<button
										type=""
										onClick={()=>{
											if (!submitted) {
												setUpStripe().then(r => {});
											}
										}}
										// onClick={() => getStripeLoginLinkRequest()}
										className="primary-gradient"
										disabled={loginLinkLoading}
									>
										<span>
											<p>{loginLinkLoading && <i className="fa-solid fa-spinner fa-spin me-1"/>}Setup Stripe</p>
										</span>
									</button>
								)
							)}
							<button
								type="submit"
								onClick={generateSpaceRandomStringRequest}
								className="primary-gradient">
								<span>
									<p>{isGenerateLoading && <i className="fa-solid fa-spinner fa-spin me-1"/>}Copy Preview Link</p>
								</span>
							</button>
							{copyAlert && (
								<div className={`alert callout ${copyAlert.variant} items-center`}>
									<div className="action">
										<i className="fa-solid fa-circle-info"/>
									</div>
									<div className="content">
										<div>
											<h6>{copyAlert.message}</h6>
										</div>
									</div>
								</div>
							)}
                        </div>
                    </div>
                </div>
            </section>
        </main>
		// <>
		// 	<main className="dashboard-wrapper flex">
		// 		<div className="container !py-20">
		// 			<div className="grid grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
		// 				<div className="col-span-12 md:col-span-8 flex items-center">
		// 					<h1 className="header-large !text-5xl !font-bold">Set up Payout Methods</h1>
		// 				</div>
		// 				<div className="col-span-12 md:col-span-4 flex items-center justify-center md:justify-end">
		// 					<p className="body-txtitalic--large">Step 3 of 5</p>
		// 				</div>
		// 				<div className="col-span-12 mt-4">
		// 					<h5 className="body-text--reg">
		// 						To begin selling Paid Memberships and Items, accept credit card
		// 						and/or crypto payments, and receive Payouts you will need to setup
		// 						your Payout Methods. You can choose to enable different payment
		// 						methods and currencies using different services.{' '}							
		// 						{getSitewideLink('learn_more_payment_payout_method', 'gradient-color-txt body-txtitalic--reg')}
		// 						{' '}
		// 					</h5>
		// 				</div>
		// 			</div>
		// 			<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8">
		// 				<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
		// 					<h5 className="header-medium leading-10 !font-bold">Add Payout Method</h5>
		// 					<span className="body-text--reg !italic">Optional</span>
		// 				</div>
		// 				<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">
		// 					<button type="submit" className="btn btn-lg btn-secondary">
		// 						Space Creation Guide
		// 						<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
		// 					</button>
		// 				</div>
		// 			</div>
		// 			<Form
		// 				form={addPayoutMethodForm}
		// 				name="addPayoutMethodForm"
		// 				id="addPayoutMethodForm"
		// 				validateMessages={VALIDATE_MESSAGES}
		// 				onFocus={() => {
		// 					setFormIsOnFocus(true);
		// 				}}
		// 				onBlur={() => {
		// 					setTimeout(() => {
		// 						setFormIsOnFocus(false);
		// 						handleFormOnBlur();
		// 					}, 100);
		// 				}}
		// 				onChange={handleFormChanges}
		// 				onFinish={handleSubmit}
		// 				className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
		// 			>
		// 				<>
		// 					{alert && (
		// 						<div className="grid grid-cols-12 gap-8 mt-5">
		// 							<div className="col-span-8">
		// 								<Alert
		// 									key={alert.variant}
		// 									variant={alert.variant}
		// 									className={
		// 										'custom-alert ' +
		// 										(alert.variant === 'danger'
		// 											? 'custom-alert-danger'
		// 											: 'custom-alert-success')
		// 									}
		// 								>
		// 									{alert.message}
		// 								</Alert>
		// 							</div>
		// 						</div>
		// 					)}
		// 				</>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 !order-last md:!order-first">
		// 						<DropdownWithItems
		// 							title="Payout Method"
		// 							dropDownClass=""
		// 							customButtonClass="payout"
		// 							selectedValue={selectedPayoutMethod}
		// 							listItems={SPACE_PAYOUT_METHOD}
		// 							onDropdownChange={(value) => {
		// 								setSelectedPayoutMethod(value);
		// 								setIsSetupStripe(false);
		// 								setIsOnBoardingSubmitted(false);
		// 							}}
		// 						/>
		// 					</div>
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							All On-Platform Sales transacted through your Space will be processed and paid out to you via your Payout Methods. The timing, terms, and costs may vary based on the type and provider.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				{(selectedPayoutMethod === 'stripe' && !isOnBoardingSubmitted) && (
		// 					<>
		// 						<div className="grid gap-y-4 mt-8">
		// 							<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
		// 								<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
		// 									<h5 className="header-medium leading-10 !text-2xl !font-bold">
		// 										<div className="flex items-center gap-4">
		// 											<i className="fa-brands fa-cc-stripe gradient-color-txt text-[40px]"/>
		// 											<div>
		// 												Payout Method
		// 											</div>
		// 										</div>
		// 									</h5>
		// 									<span className="body-text--reg !text-lg">Add Payouts to your Bank Account via Stripe</span>
		// 								</div>
		// 								<div className="md:col-span-4 !order-first md:!order-last flex items-center">
		// 									<div className="gradient-color-txt text-sm italic ">
		// 										Learn more about On-Platform Sales.
		// 									</div>
		// 								</div>
		// 							</div>
		// 							<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
		// 								<div className="md:col-span-8 !order-last md:!order-first">
		// 									<span className="font-notoSansRegular text-base">Enable credit card payments for all On-Platform Sales on your Space via Stripe with Payouts automatically deposited to your Bank Account. Stripe is a trusted global leader in secure payment processing used by millions of companies.</span>
		// 								</div>
		// 								<div className="md:col-span-4 !order-first md:!order-last flex items-center">
		// 									<div className="font-notoSansRegular text-sm italic ">
		// 										Your total cost per transaction will be Stripe’s fee of 2.9% + 30¢ for each credit card transaction processed plus your Commonspace Plan’s On-Platform Sales Rate.
		// 									</div>
		// 								</div>
		// 							</div>
		// 							<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
		// 								<div className="md:col-span-8">
		// 									<Button
		// 										onClick={()=>{
		// 											if (!submitted) {
		// 												setUpStripe().then(r => {});
		// 											}
		// 										}}
		// 										className="btn btn-lg btn-primary"
		// 										type="submit"
		// 									>
		// 										Set Up Stripe
		// 									</Button>
		// 								</div>
		// 								<div className="md:col-span-4 flex items-end">
		// 									<Button
		// 										onClick={() => {
		// 											setSelectedPayoutMethod('default');
		// 										}}
		// 										className="btn btn-lg btn-secondary"
		// 										type="submit"
		// 									>
		// 										Remove Method
		// 									</Button>
		// 								</div>
		// 							</div>
		// 						</div>
		// 					</>
		// 				)}
		// 				{showStripeModal && (
		// 					<AddStripeBankAccountModal
		// 						modalOpen={showStripeModal}
		// 						spaceID={spaceInfo.id}
		// 						setModalOpen={setShowStripeModal}
		// 						userID={authUserInfo.id}
		// 						setIsOnBoardingSubmitted={setIsOnBoardingSubmitted}
		// 					/>
		// 				)}
		// 				{isOnBoardingSubmitted && (
		// 					<>
		// 						<div className="grid gap-y-4 mt-8">
		// 							<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
		// 								<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
		// 									<h5 className="header-medium leading-10 !text-2xl !font-bold">
		// 										<div className="flex items-center gap-4">
		// 											<i className="fa-brands fa-cc-stripe gradient-color-txt text-[40px]"/>
		// 											<div>
		// 												Payout Method
		// 											</div>
		// 										</div>
		// 									</h5>
		// 									<span className="body-text--reg !text-lg !italic">Active</span>
		// 								</div>
		// 								<div className="md:col-span-4 !order-first md:!order-last flex items-center">
		// 									<button type="submit" className="btn btn-lg btn-secondary">
		// 										View & Edit on Stripe
		// 										<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>
		// 									</button>
		// 								</div>
		// 							</div>
		// 							<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8">
		// 								<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
		// 									<h5 className="header-medium leading-10 !text-xl !font-bold">[NICKNAME] Bank Account Ending in 0939</h5>
		// 									<span className="body-text--reg !text-sm">Added on 08/28</span>
		// 								</div>
		// 							</div>
		// 						</div>
		// 					</>
		// 				)}
		// 			</Form>
		// 			<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8">
		// 				<div className="md:col-span-8">
		// 					<Button
		// 						onClick={saveContinue}
		// 						disabled={!isSetupStripe}
		// 						className={"btn btn-lg " + (isSetupStripe ? 'btn-primary' : 'btn-secondary')}
		// 						type="submit"
		// 					>
		// 						Save & Continue
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</main>
		// </>
	);
};

export default memo(AddPaymentMethod);
