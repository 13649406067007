import React, {memo, useEffect, useState, useCallback, useRef} from 'react';
import { Form } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import {
    deleteSpaceBenefit,
    updateSpaceBenefit,
    updateSpaceBenefitStatus
} from '../../../../services/space';
import {useDispatch, useSelector} from 'react-redux';
import DropdownWithItems from "../../../../components/commons/DropdownWithItems";
import routes from "../../../../routes";
import {useNavigate} from "react-router-dom";
import {formatFileSize, getFileExtension} from "../../../../utils/file";
import {BENEFITS_FILETYPES} from "../../../../constants/file";
import {setBenefit, setEditBenefit} from "../../../../states/benefitsSlice";



const EditDownloadBenefit = (props) => {
    const { benefitData } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [editBenefitForm] = Form.useForm();
    const [formHasError, setFormHasError] = useState(true);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [selectedButtonSetting, setSelectedButtonSetting] = useState()
    const [alert, setAlert] = useState(null);
    const [isSubmitClicked, setSubmitClicked] = useState(false);
    const [blurredField, setBlurredField] = useState(null);
    const [numberOfFiles, setNumberOfFiles] = useState([1]);
    const [files, setFiles] = useState([]);
    const inputFileRef = useRef([]);
    const [submitted, setSubmitted] = useState(false);

    const [name, setName] = useState(null);
    const [details, setDetails] = useState(null);
    const [note, setNote] = useState(null);
    const [dlLimit, setDlLimit] = useState(null);

    const dummyButtonOptions = [
        { label: 'Default', value: 'default' },
        // { label: 'Benefits Post', value: 'benefits_post' },
        { label: 'Custom URL', value: 'custom_url' }
    ];

    const handleFormOnBlur = useCallback(async () => {
        await editBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const setFormData = async (data) => {
        if (data) {
            editBenefitForm.setFieldsValue({
                name: data.name,
                details: data.details,
                note: data.note,
                dl_limit: data.download_limit,
                custom_url: data.custom_url,
                button_settings: data.button_settings
            });

            setName(data.name);
            setDetails(data.details);
            setNote(data.note);
            setDlLimit(data.download_limit);

            if (data.button_settings) {
                setSelectedButtonSetting(data.button_settings);
            }

            if (data.files && data.files.length > 0) {
                const arr = [];
                data.files.forEach((val, index) => {
                    arr.push(index + 1);
                });
                setNumberOfFiles(arr);
            }

            await editBenefitForm
                .validateFields()
                .then(() => {
                    setFormHasError(false);
                })
                .catch((errors) => {
                    setFormHasError(true);
                });
        }
    };

    const handleSubmit = useCallback(
        async () => {
            if (formHasError || submitted) {
                return;
            }

            setSubmitted(true);

            const data = { ...editBenefitForm.getFieldsValue() };
            data['benefit_id'] = benefitData.id;

            let count = 0;
            files.forEach((val) => {
                data['file_' + val['index']] = val['file'];

                if (val['id']) {
                    data['file_' + val['index'] + '_id'] = val['id'];
                }

                count = val['index'] + 1;
            });
            data['files_count'] = count;

            const response = await updateSpaceBenefit(data);
            if (response) {
                if (response.result) {
                    dispatch(setEditBenefit(response.benefit));
                    redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@');
                } else {
                    setAlert({ variant: 'danger', message: response.message });
                }
            }

            setSubmitted(true);

        }, [spaceInfo, benefitData, formHasError, files, submitted, editBenefitForm]);

    const onChangeFile = useCallback(async (elem, file_data, index) => {
        setAlert(null);
        const arr = {
            'index': index,
            'file': file_data
        };
        const file_extension = getFileExtension(file_data);
        if (!BENEFITS_FILETYPES.includes(file_extension.toLowerCase())) {
            setAlert({
                variant: 'danger',
                message: 'Unsupported file type.'
            });
            elem.target.value = null;
            return false;
        }

        const big_size_array = ['GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const fileSize = formatFileSize(file_data.size);
        if (fileSize) {
            const size = parseInt(fileSize.split(" ")[0]);
            const size_group = fileSize.split(" ")[1];
            if (big_size_array.includes(size_group)) {
                // console.log('File exceed max size!');
                setAlert({
                    variant: 'danger',
                    message: 'File exceed max size!'
                });
                elem.target.value = null;
                return false;
            }
            if (size_group === 'MB') {
                if (size > 128) {
                    // console.log('File exceed max size!');
                    setAlert({
                        variant: 'danger',
                        message: 'File exceed max size!'
                    });
                    elem.target.value = null;
                    return false;
                }
            }
        }

        if (benefitData && benefitData.files && benefitData.files.length > 0 && benefitData.files[index]) {
            arr['id'] = benefitData.files[index].id;
        }

        const file_index = files.findIndex(x => x.index === index);
        if (file_index >= 0) {
            files[file_index] = arr;
        } else {
            files.push(arr);
        }

        setFiles(files);

        setFormIsOnFocus(false);
        handleFormOnBlur().then(r => {});
    }, [files, benefitData]);

    const setFileValue = (data, index, name_only=false, current_files=null) => {
        if (current_files) {
            const file_index = current_files.findIndex(x => x.index === index);

            if (file_index >= 0) {
                return null;
            }
        }

        if (data && data.files && data.files.length > 0 && data.files[index]) {
            if (name_only) {
                return data.files[index].original_file_name;
            }
            return data.files[index].file_url;
        }

        if (name_only) {
            return '';
        }

        return null;
    };

    const checkExistingIndexChange = (files, index) => {
        const file_index = files.findIndex(x => x.index === index);
        return file_index >= 0;
    };

    const updateBenefitStatus = useCallback(
        async (status) => {
            const data = {
                benefit_id: benefitData.id,
                status
            };

            const response = await updateSpaceBenefitStatus(data);
            if (response) {
                if (!response.result) {
                    if (response.message) {
                        setAlert({ variant: 'danger', message: response.message });
                    } else {
                        setAlert({
                            variant: 'danger',
                            message: 'Something went wrong'
                        });
                    }
                }
            }

            redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@');
        }, [benefitData, spaceInfo]);

    const deleteBenefit = useCallback(
        async () => {
            const data = {
                benefit_id: benefitData.id
            };

            const response = await deleteSpaceBenefit(data);
            if (response) {
                if (!response.result) {
                    if (response.message) {
                        setAlert({ variant: 'danger', message: response.message });
                    } else {
                        setAlert({
                            variant: 'danger',
                            message: 'Something went wrong'
                        });
                    }
                }
            }

            redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@');
        }, [benefitData, spaceInfo]);

    const handleFormChanges = useCallback(async () => {

        setName(editBenefitForm.getFieldValue('name'));
        setDetails(editBenefitForm.getFieldValue('details'));
        setNote(editBenefitForm.getFieldValue('note'));
        setDlLimit(editBenefitForm.getFieldValue('dl_limit'));

        await editBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const redirectToSpaceLink = (route, spaceUsername, prepend = '') => {
        setTimeout(()=>{
            navigate(route.replace(':space_username', prepend + spaceUsername));
        },[1500])
    };

    const handleButtonSettingChange = (value) => {
        setSelectedButtonSetting(value)
        editBenefitForm.setFieldsValue({ button_settings: value });
    };

    const handleOnblurField = useCallback(async (field) => {
        if (!isSubmitClicked) {
            setBlurredField(field);
        }
        setSubmitClicked(false);
    }, [editBenefitForm, blurredField, isSubmitClicked]);

    const addAdditionalFile = useCallback(async () => {
        if (numberOfFiles.length >= 16) {
            return;
        }
        setFormIsOnFocus(true);

        numberOfFiles.push(numberOfFiles.length + 1);
        setNumberOfFiles(numberOfFiles);

        setTimeout(() => {
            setFormIsOnFocus(false);
            handleFormOnBlur().then(r => {});
        });
    }, [numberOfFiles]);

    const removeInputFile = (numberOfFiles, files, index) => {
        const file_index = files.findIndex(x => x.index === index);
        if (file_index >= 0) {
            files.splice(file_index, 1);
        }

        const input_index = numberOfFiles.indexOf(index);
        if (input_index >= 0) {
            numberOfFiles.splice(index, 1);
        }

        setFormIsOnFocus(true);

        setNumberOfFiles(numberOfFiles);
        setFiles(files);

        setTimeout(() => {
            setFormIsOnFocus(false);
            handleFormOnBlur().then(r => {});
        });
    };

    useEffect(()=>{
        if (selectedButtonSetting === 'custom_url') {
            handleOnblurField('custom_url').then(r => {});
            handleFormChanges().then(r => {});
        }
    }, [selectedButtonSetting])

    useEffect(() =>{},[spaceInfo, editBenefitForm, numberOfFiles, files, submitted]);

    useEffect(() => {
        if (benefitData) {
            setFormData(benefitData).then(r => {});
        }
    }, [benefitData]);

    return (
        <div className="cs-dashframe section-flex">
            <div className="cs-dash-card block-full card">
                <hgroup className="heading">
                    <h2>Edit Access Benefit</h2>
                    <p>An <a href="#link-via-admin" target="_blank">Access Benefit</a> is a type of Benefit which allows you to provide access to specific content on your Space. You can apply this Benefit to any Memberships or Items and Members with that specific Membership or Item will gain access to the specified content. This is different than standard Members Only access, which provides access to associated content to Members with <span className="italic">any</span> Membership. <a href="#link-via-admin" target="_blank">Learn more about the Access Benefit Type.</a></p>
                </hgroup>
            </div>
            <div className="cs-dash-card block-full card">
                <Form
                    form={editBenefitForm}
                    name="editBenefitForm"
                    id="editBenefitForm"
                    validateMessages={VALIDATE_MESSAGES}
                    onFocus={() => {
                        setFormIsOnFocus(true);
                    }}
                    onBlur={() => {
                        setFormIsOnFocus(false);
                        handleFormOnBlur().then(r => {});
                    }}
                    onChange={handleFormChanges}
                    onFinish={handleSubmit}
                    className={formIsOnFocus ? 'hide-antd-error-messages stack-col' : 'stack-col'}
                >
                    {/* <form className="stack-col"> */}
                    <div className="formrow title">
                        <h3>Details</h3>
                        <p>Provide Benefit information</p>
                    </div>
                    <Form.Item
                        label="Name"
                        name="name"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable">
                                <span>
                                    <label htmlFor="name">Name</label>
                                    <textarea id="name"
                                           maxLength={32}
                                           value={name}
                                           onBlur={()=>handleOnblurField('name')}
                                           placeholder="Up to 32 characters."/>
                                </span>
                            </div>
                            <div>
                                <p className='text-sm text-color-secondary italic'>This will be listed on the Product page of Memberships or Items with this Benefit.</p>
                            </div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'name' || blurredField === 'all') && editBenefitForm.getFieldsError()[0]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{editBenefitForm.getFieldsError()[0]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <Form.Item
                        label="Summary"
                        name="details"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable">
                                <span>
                                    <label htmlFor="details">Summary</label>
                                    <textarea id="details"
                                           maxLength={160}
                                           value={details}
                                           onBlur={()=>handleOnblurField('details')}
                                           placeholder="Up to 160 characters."/>
                                </span>
                            </div>
                            <div>
                                <p className='text-sm text-color-secondary italic'>This Summary text with display next to the Benefit Name and should communicate what the Benefit provides.</p>
                            </div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'details' || blurredField === 'all') && editBenefitForm.getFieldsError()[1]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{editBenefitForm.getFieldsError()[1]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <Form.Item
                        label="Note"
                        name="note"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable">
                                <span>
                                    <label htmlFor="note">Note</label>
                                    <textarea id="note"
                                           maxLength={160}
                                           value={note}
                                           onBlur={()=>handleOnblurField('note')}
                                           placeholder="Optional, up to 160 characters."/>
                                </span>
                            </div>
                            <div><p className='text-sm text-color-secondary italic'>This private text is shown to Members as a interstitial, pop-up, or content before the Benefit is accessed.</p></div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'note' || blurredField === 'all') && editBenefitForm.getFieldsError()[2]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{editBenefitForm.getFieldsError()[2]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <div className="formrow title">
                        <h4>Settings</h4>
                        <p>Configure the functions for this Benefit type</p>
                    </div>
                    <Form.Item
                        label="DL Limit"
                        name="dl_limit"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                    >
                        <div className="formrow split">
                            <div className="form-input gradient">
                                <span>
                                    <label htmlFor="dl_limit">DL Limit</label>
                                    <input id="dl_limit"
                                           type="text"
                                           value={dlLimit}
                                           onBlur={()=>handleOnblurField('dl_limit')}
                                           placeholder="Enter number or 0 for unlimited."
                                           onKeyPress={(event) => {
                                               if (!/[0-9]/.test(event.key)) {
                                                   event.preventDefault();
                                               }
                                           }}
                                    />
                                </span>
                            </div>
                            <div><p className='text-sm text-color-secondary italic'>Limit downloads or enter "0" for unlimited downloads. Each download makes the file(s) available for 24 hours.</p></div>
                        </div>
                    </Form.Item>

                    {numberOfFiles && numberOfFiles.map((val, index) => (
                        <div className="formrow split" key={index}>
                            <div className={"form-input-file gradient " + (setFileValue(benefitData, index, false, files) ? "" : "unlabeled" ) }>
                                <span>
                                    {setFileValue(benefitData, index) ? (
                                        <>
                                            <label htmlFor={'file_input_' + index}>Choose File</label>
                                            <input id={'file_input_' + index}
                                                   type="text"
                                                   className={checkExistingIndexChange(files, index) ? '!hidden' : ''}
                                                   placeholder={setFileValue(benefitData, index, true)}
                                                   onClick={() => { inputFileRef.current[index].click(); }}
                                            />
                                            <input type="file"
                                                   ref={ref => {
                                                       inputFileRef.current[index] = ref
                                                   }}
                                                   className={checkExistingIndexChange(files, index) ? '' : '!hidden'}
                                                   onChange={(event) => onChangeFile(event, event.target.files[0] || null, index)}
                                            />
                                            {/*<i className="fa fa-minus-circle remove-benefit-file" onClick={(e) => {removeInputFile(numberOfFiles, files, index)}}></i>*/}
                                        </>

                                    ) : (
                                        <>
                                            <input type="file"
                                                   onChange={(event) => onChangeFile(event, event.target.files[0] || null, index)}
                                            />
                                            {/*<i className="fa fa-minus-circle remove-benefit-file" onClick={(e) => {removeInputFile(numberOfFiles, files, index)}}></i>*/}
                                        </>
                                    )}

                                </span>
                            </div>
                            <div><p className='text-sm text-color-secondary italic'>Upload any document, media, or archive file type, up to 128MB.</p></div>
                        </div>
                    ))}

                    {numberOfFiles.length < 16 && (
                        <>
                            <div className="formrow split">
                                <div><p className="w-full text-center"><a href="#" onClick={addAdditionalFile}>Add Another File</a></p></div>
                                <div><p className='text-sm text-color-secondary italic'>You can add up to 16 files.</p></div>
                            </div>
                        </>
                    )}

                    <div className="formrow title">
                        <h4>Button</h4>
                        <p>Set the button behavior for this Benefit</p>
                    </div>
                    <Form.Item
                        label="Button Settings"
                        name="button_settings"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            {/* <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-key"></i><p>Default</p><i className="fa-solid fa-caret-down"></i></span></button> */}
                            <DropdownWithItems
                                customButtonClass="button gradient secondary dropdown w-full"
                                selectedValue={selectedButtonSetting}
                                listItems={dummyButtonOptions}
                                onDropdownChange={(value) => { handleButtonSettingChange(value) }}
                            />
                            <div><p className='text-sm text-color-secondary italic'>Default directly accesses the Benefit. Benefits Post links to a selected post. Custom URL opens a specified link.</p></div>
                        </div>
                    </Form.Item>

                    {selectedButtonSetting === 'custom_url' && (
                        <>
                            <Form.Item
                                label="Custom URL"
                                name="custom_url"
                                className="cs-stack-controlrows stack-form-col"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                                <div className="formrow split">
                                    <div className="form-input gradient">
                                    <span>
                                        <label htmlFor="name">Custom URL</label>
                                        {/*<input id="custom_url" name="text" type="text" required=""/>*/}
                                        <input id="custom_url"
                                               type="text"
                                               defaultValue={benefitData ? benefitData.custom_url : ''}
                                               onBlur={()=>handleOnblurField('custom_url')}/>
                                    </span>
                                    </div>
                                    <div><p className='text-sm text-color-secondary italic'>Redirect URL</p></div>
                                </div>
                            </Form.Item>
                            { ((blurredField === 'custom_url' || blurredField === 'all') && editBenefitForm.getFieldsError()[5]?.errors.length > 0) && (
                                <div className="alert callout warning !w-full items-center">
                                    <div className="action">
                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                    </div>
                                    <div className="content">
                                        <div>
                                            <h6>{editBenefitForm.getFieldsError()[5]?.errors}</h6>
                                        </div>
                                    </div>

                                </div>
                            )}
                        </>
                    )}

                    <div className="stack-buttons col">
                        <a onClick={handleSubmit} className={"button gradient " + (submitted ? "disabled" : "")}>
                            <span>
                                {submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
                                <p className="pl-1">Save Benefit</p>
                            </span>
                        </a>
                        <a onClick={()=>{updateBenefitStatus(benefitData ? !benefitData.is_active : false).then(r => {})}} className="button gradient secondary">
                            <span>
                                <p>{benefitData ? benefitData.is_active ? 'Deactivate' : 'Activate': 'Deactivate'}</p>
                            </span>
                        </a>
                        <p className="text-help text-center">Archiving a Benefit will remove it from use. Benefits with active stored values cannot be deleted. </p>
                        <a onClick={()=>{deleteBenefit().then(r => {})}} className="button gradient secondary"><span><p>Delete</p></span></a>
                    </div>
                    <>
                        {(alert && alert.message) && (
                            <div className="alert callout warning !m-0">
                                <div className="action">
                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                </div>
                                <div className="content">
                                    <div>
                                        <h6>{(alert && alert.message) ? alert.message : ''}</h6>
                                    </div>
                                </div>

                            </div>
                        )}
                    </>
                </Form>
            </div>
        </div>
    )
};

export default memo(EditDownloadBenefit);