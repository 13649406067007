import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import { VALIDATE_MESSAGES } from '../../../../constants/common';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH, IMAGE_VIDEO_FILE_SIZE_LIMIT,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	LINKS_VISIBILITY_LIST_WITH_DEFAULT
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import {
	updateSpaceWelcomeLinksSection,
	updateSpaceWelcomeSection
} from '../../../../services/space';
import Links from '../../../SpaceCreate/AddDetails/Links';
import {
	displayPageContentLoader,
	hidePageContentLoader
} from '../../../../states/pageContentLoader';
import { getSpaceInfo } from '../../../../states/spaceGlobal/actions';

import './../../../SpaceCreate/AddDetails/Links/Links.scss';
import UploadDraggerV2 from "../../../../components/commons/UploadDraggerV2";
import FormFieldStacked from "../../../../components/commons/FormFieldStacked";

const linkObject = {
	id: null,
	title: null,
	description: null,
	image: null,
	url: null
};

const ManageHomePage = (props) => {
	const currentLocation = useLocation();
	const { authUserInfo, spaceInfo } = props;
	const dispatch = useDispatch();
	const [welcomeSectionForm] = Form.useForm();
	const [welcomeSectionLinksForm] = Form.useForm();
	const [welcomeSectionFormAlert, setWelcomeSectionFormAlert] = useState(null);
	const [welcomeSectionFormSubmitted, setWelcomeSectionFormSubmitted] = useState(false);
	const [welcomeSectionFormIsOnFocus, setWelcomeSectionFormIsOnFocus] = useState(false);
	const [welcomeSectionFormHasError, setWelcomeSectionFormHasError] = useState(true);
	const [welcomeSectionLinksFormAlert, setWelcomeSectionLinksFormAlert] = useState(null);
	const [welcomeSectionLinksFormSubmitted, setWelcomeSectionLinksFormSubmitted] = useState(false);
	const [welcomeSectionLinksFormIsOnFocus, setWelcomeSectionLinksFormIsOnFocus] = useState(false);
	const [welcomeSectionLinksFormHasError, setWelcomeSectionLinksFormHasError] = useState(true);
	const [welcomeTitle, setWelcomeTitle] = useState(null);
	const [welcomeMessage, setWelcomeMessage] = useState(null);
	const [welcomeYoutubeVideo, setWelcomeYoutubeVideo] = useState(null);
	const [welcomeImageError, setWelcomeImageError] = useState(null);
	const [welcomeImage, setWelcomeImage] = useState(null);
	const [welcomeImageBlobPreview, setWelcomeImageBlobPreview] = useState(null);
	const [welcomeVideo, setWelcomeVideo] = useState(null);
	const [welcomeVideoBlobPreview, setWelcomeVideoBlobPreview] = useState(null);
	const [welcomeVideoError, setWelcomeVideoError] = useState(null);
	const [newsletterChecked, setNewsletterChecked] = useState(false);
	const [linksTitleFirst, setLinksTitleFirst] = useState(null);
	const [linksTitleSecond, setLinksTitleSecond] = useState(null);
	const [selectedLinksVisibility, setSelectedLinksVisibility] = useState('default');
	const [linksArr, setLinksArr] = useState(null);
	const [linksArrLength, setLinksArrLength] = useState(null);
	const [removedLinksIdArr, setRemovedLinksIdArr] = useState([]);
	const [addLinkDisabled, setAddLinkDisabled] = useState(false);
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [resetLinkPreviews, setResetLinkPreviews] = useState(false);
	const [addLinkLinkImages, setAddLinkLinkImages] = useState({});
	const [addLinkLinkVideos, setAddLinkLinkVideos] = useState({});

	const [blurredField, setBlurredField] = useState(null);
	const [isSubmitClicked, setSubmitClicked] = useState(false);
	const [titleFieldErrors, setTitleFieldErrors] = useState('');
	const [welcomeMessageFieldErrors, setWelcomeMessageFieldErrors] = useState('');


	const handleFinishFailed = useCallback(async (errorInfo) => {
		setSubmitClicked(true);
		// Set the blurred field to the first field with an error
		if (errorInfo.errorFields.length > 0) {
			setBlurredField('all');
			// window.scrollTo(0, 0);
		}
	}, [blurredField, isSubmitClicked]);

	const handleOnblurField = useCallback(async (field) => {
		if (!isSubmitClicked) {
			setBlurredField(field);
		}
		setSubmitClicked(false);
	}, [blurredField, isSubmitClicked]);

	/**
	 * Form 'welcomeSectionForm' onBlur event trigger
	 *
	 * @type {(function(): Promise<void>)|*}
	 */
	const handleWelcomeSectionFormOnBlur = useCallback(async () => {
		await welcomeSectionForm
			.validateFields()
			.then(() => {
				setWelcomeSectionFormHasError(false);
			})
			.catch((errors) => {
				// console.log(errors)
				setWelcomeSectionFormHasError(true);
			});
	}, []);

	/**
	 * Form 'welcomeSectionLinksForm' onBlur event trigger
	 *
	 * @type {(function(): Promise<void>)|*}
	 */
	const handleWelcomeSectionLinksFormOnBlur = useCallback(async () => {
		await welcomeSectionLinksForm
			.validateFields()
			.then(() => {
				setWelcomeSectionLinksFormHasError(false);
			})
			.catch((errors) => {
				setWelcomeSectionLinksFormHasError(true);
			});
	}, []);

	/**
	 * Form 'welcomeSectionForm' onChange event trigger
	 *
	 * @type {(function(): Promise<void>)|*}
	 */
	const handleWelcomeSectionFormChanges = useCallback(async () => {
		setWelcomeTitle(welcomeSectionForm.getFieldValue('welcome_title'));
		setWelcomeMessage(welcomeSectionForm.getFieldValue('welcome_message'));
		setWelcomeYoutubeVideo(welcomeSectionForm.getFieldValue('welcome_youtube_video'));
		// console.log('handleWelcomeSectionFormChanges')
		await welcomeSectionForm
			.validateFields()
			.then(() => {
				setWelcomeSectionFormHasError(false);
			})
			.catch((errors) => {
				setWelcomeSectionFormHasError(true);
			});
	}, []);

	/**
	 * Form 'welcomeSectionLinksForm' onChange event trigger
	 *
	 * @type {(function(): Promise<void>)|*}
	 */
	const handleWelcomeSectionLinksFormChanges = useCallback(async () => {
		setLinksTitleFirst(welcomeSectionLinksForm.getFieldValue('links_title_first'));
		setLinksTitleSecond(welcomeSectionLinksForm.getFieldValue('links_title_second'));

		await welcomeSectionLinksForm
			.validateFields()
			.then(() => {
				setWelcomeSectionLinksFormHasError(false);
			})
			.catch((errors) => {
				setWelcomeSectionLinksFormHasError(true);
			});
	}, []);

	/**
	 * Form 'welcomeSectionForm' submit and POST request
	 *
	 * @type {(function(*): Promise<void>)|*}
	 */
	const handleWelcomeSectionSubmit = useCallback(
		async (values) => {
			setWelcomeSectionFormAlert(null);
			setDisplayDropDownError(true);
			setWelcomeImageError(null);

			let hasError = false;

			if (!welcomeImageBlobPreview) {
				setWelcomeImageError('Welcome Image is required.');
				hasError = true;
			}

			if (selectedLinksVisibility === 'default') {
				hasError = true;
			}

			if (hasError) {
				window.scrollTo(0, 0);
				return;
			}

			const data = {};
			data.welcome_title = values.welcome_title;
			data.welcome_message = values.welcome_message;
			data.welcome_youtube_url = values.welcome_youtube_video;
			data.has_newsletters_signup = newsletterChecked;

			if (welcomeImage) {
				data.welcome_image = welcomeImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (welcomeVideo) {
				data.welcome_video = welcomeVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					setWelcomeSectionFormSubmitted(true);
					const response = await updateSpaceWelcomeSection(data);
					if (response && response.result) {
						setWelcomeSectionFormAlert({
							variant: 'success',
							message: response.message
						});
						setPhotoIsRemoved(false);
						setVideoIsRemoved(false);
						dispatch(getSpaceInfo(spaceInfo.username));
					} else {
						setWelcomeSectionFormAlert({
							variant: 'danger',
							message: response.message
						});
					}
					setTimeout(() => {
						setWelcomeSectionFormAlert(null);
					}, 3000);
					setWelcomeSectionFormSubmitted(false);
					setDisplayDropDownError(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			welcomeTitle,
			welcomeMessage,
			welcomeYoutubeVideo,
			welcomeImage,
			welcomeVideo,
			newsletterChecked,
			photoIsRemoved,
			videoIsRemoved,
			welcomeImageBlobPreview
		]
	);

	/**
	 * Form 'welcomeSectionLinksForm' submit and POST request
	 *
	 * @type {(function(*): Promise<void>)|*}
	 */
	const handleWelcomeSectionLinksSubmit = useCallback(
		async (values) => {


			setWelcomeSectionLinksFormAlert(null);
			setDisplayDropDownError(true);

			let hasError = false;

			if (selectedLinksVisibility === 'default') {
				hasError = true;
			}

			if (hasError) {
				setBlurredField('all');
				window.scrollTo(0, 0);
				return;
			}

			const data = {};
			data.links_visibility = selectedLinksVisibility;
			data.links_section_first_title = values.links_title_first;
			data.links_section_second_title = values.links_title_second;

			const links = [];
			let link_index = 0;
			let link_index_found = true;
			while (link_index_found) {
				if (
					values['add_link_link_title_' + link_index] &&
					values['add_link_link_description_' + link_index] &&
					values['add_link_link_url_' + link_index]
				) {
					const arr = {
						title: values['add_link_link_title_' + link_index],
						description: values['add_link_link_description_' + link_index],
						url: values['add_link_link_url_' + link_index]
					};

					if (linksArr[link_index] && linksArr[link_index]['id']) {
						arr['id'] = linksArr[link_index]['id'];
					}

					links.push(arr);
				} else {
					link_index_found = false;
				}
				link_index++;
			}

			const links_length = links.length;
			if (links_length > 0) {
				const links_final_index = links_length - 1;
				Object.entries(addLinkLinkImages).forEach(([image_index, image_data]) => {
					const int_image_index = parseInt(image_index.replace('image_', ''));
					if (links_final_index >= int_image_index) {
						if (image_data) {
							if (image_data.name && image_data.size && image_data.type) {
								data['link' + (int_image_index + 1) + '_image'] = image_data;
							}
						} else {
							data['link' + (int_image_index + 1) + '_image_is_empty'] = true;
						}
					}
				});
				Object.entries(addLinkLinkVideos).forEach(([video_index, video_data]) => {
					const int_video_index = parseInt(video_index.replace('video_', ''));
					if (links_final_index >= int_video_index) {
						if (video_data) {
							if (video_data.name && video_data.size && video_data.type) {
								data['link' + (int_video_index + 1) + '_video'] = video_data;
							}
						} else {
							data['link' + (int_video_index + 1) + '_video_is_empty'] = true;
						}
					}
				});
			}

			if (spaceInfo.plan !== 'pro' && links && links.length > 3) {
				setWelcomeSectionLinksFormAlert({
					variant: 'danger',
					message: 'Exceeds maximum 3 links.'
				});
				return;
			}

			data.links = JSON.stringify(links);

			if (removedLinksIdArr && removedLinksIdArr.length > 0) {
				data.removed_links = JSON.stringify(removedLinksIdArr);
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					setWelcomeSectionLinksFormSubmitted(true);
					const response = await updateSpaceWelcomeLinksSection(data);
					if (response && response.result) {
						setWelcomeSectionLinksFormAlert({
							variant: 'success',
							message: response.message
						});
						setRemovedLinksIdArr([]);
						dispatch(getSpaceInfo(spaceInfo.username));
					} else {
						setWelcomeSectionLinksFormAlert({
							variant: 'danger',
							message: response.message
						});
					}
					setTimeout(() => {
						setWelcomeSectionLinksFormAlert(null);
					}, 3000);
					setWelcomeSectionLinksFormSubmitted(false);
					setDisplayDropDownError(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			linksArr,
			linksTitleFirst,
			linksTitleSecond,
			selectedLinksVisibility,
			addLinkLinkImages,
			addLinkLinkVideos,
			removedLinksIdArr
		]
	);

	/**
	 * UploadDragger file change for Welcome Image
	 *
	 * @param file
	 * @param blobPreview
	 * @param error
	 */
	const handleWelcomeImageUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
			if(!error){
				setWelcomeImageError(null);
			}
		}

		setWelcomeImage(file);
		setWelcomeImageBlobPreview(blobPreview);

		welcomeSectionForm
			.validateFields()
			.then(() => {
				setWelcomeSectionFormHasError(false);
			})
			.catch((errors) => {
				setWelcomeSectionFormHasError(true);
			});
	};

	/**
	 * UploadDragger file change for Welcome Video
	 *
	 * @param file
	 * @param blobPreview
	 * @param error
	 */
	const handleWelcomeVideoUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
			// if(error){
			// 	setWelcomeVideoError('Space Background Video is Invalid.');
			// }
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
			if(!error){
				setWelcomeVideoError(null);
			}
		}

		setWelcomeVideo(file);
		setWelcomeVideoBlobPreview(blobPreview);

		welcomeSectionForm
			.validateFields()
			.then(() => {
				setWelcomeSectionFormHasError(false);
			})
			.catch((errors) => {
				setWelcomeSectionFormHasError(true);
			});
	};

	/**
	 * Newsletter Checkbox event trigger handler
	 *
	 * @param e
	 */
	const onChangeNewsletter = (e) => {
		setNewsletterChecked(e.target.checked);
	};

	/**
	 * Function to insert new empty link form group
	 *
	 * @type {(function(): void)|*}
	 */
	const addLink = useCallback(() => {
		let currArr = linksArr;

		if (spaceInfo.plan !== 'pro' && linksArr && linksArr.length >= 3) {
			setAddLinkDisabled(true);
			return;
		}

		if (!currArr) {
			currArr = [];
		}

		currArr.push(linkObject);

		if (spaceInfo.plan !== 'pro') {
			if (currArr.length <= 2) {
				setAddLinkDisabled(false);
				setLinksArr(currArr);
			} else {
				setAddLinkDisabled(true);
			}
		} else {
			setAddLinkDisabled(false);
			setLinksArr(currArr);
		}

		setLinksArrLength(currArr.length);

		currArr.forEach((data, key) => {
			setLinkFormValues(data, key).then((r) => {});
		});

		setResetLinkPreviews(false);
	}, [spaceInfo, linksArr, addLinkLinkImages, addLinkLinkVideos]);

	/**
	 * Function to remove selected link and reorder links
	 *
	 * @type {(function(*): void)|*}
	 */
	const handleUpdateLinkToRemove = useCallback(
		async (passedData) => {
			const currArr = linksArr;
			const currRemovedLinksIdArr = removedLinksIdArr;
			const newArr = [];
			const currentAddLinkLinkImages = addLinkLinkImages;
			const currentAddLinkLinkVideos = addLinkLinkVideos;

			setLinksArr([]);

			currArr.forEach((data, key) => {
				welcomeSectionLinksForm.setFieldValue('add_link_link_title_' + key.toString());
				welcomeSectionLinksForm.setFieldValue(
					'add_link_link_description_' + key.toString()
				);
				welcomeSectionLinksForm.setFieldValue('add_link_link_url_' + key.toString());

				if (key === passedData.index) {
					if (passedData.id && !currRemovedLinksIdArr.includes(passedData.id)) {
						currRemovedLinksIdArr.push(passedData.id);
						setRemovedLinksIdArr(currRemovedLinksIdArr);
					}
				} else {
					const newData = data;
					data['image'] = currentAddLinkLinkImages['image_' + key.toString()];
					data['video'] = currentAddLinkLinkVideos['video_' + key.toString()];
					newArr.push(newData);
				}
			});

			if (newArr.length > 0) {
				setAddLinkLinkImages({});
				setAddLinkLinkVideos({});
				newArr.forEach((data, key) => {
					setLinkFormValues(data, key).then((r) => {});
				});
				setLinksArr(newArr);
				setLinksArrLength(newArr.length);
			} else {
				setLinksArr(null);
				setLinksArrLength(0);
			}

			setAddLinkDisabled(false);
			setResetLinkPreviews(true);
		},
		[linksArr, removedLinksIdArr, addLinkLinkImages, addLinkLinkVideos]
	);

	/**
	 * Function to handle data changes from <Links> component
	 *
	 * @type {(function(*, *, *): Promise<void>)|*}
	 */
	const handleUpdateData = useCallback(
		async (data, field, key) => {
			if (field === 'image') {
				const currentAddLinkLinkImages = addLinkLinkImages;
				if (Object.keys(currentAddLinkLinkImages).length > 0) {
					Object.entries(currentAddLinkLinkImages).forEach(([item_index, item_data]) => {
						const int_item_index = parseInt(item_index.replace('image_', ''));
						if (key === int_item_index) {
							if (data) {
								currentAddLinkLinkImages[item_index] = data;
							} else {
								currentAddLinkLinkImages[item_index] = null;
							}
						} else {
							currentAddLinkLinkImages['image_' + key] = data;
						}
					});
				} else {
					currentAddLinkLinkImages['image_' + key] = data;
				}
				setAddLinkLinkImages(currentAddLinkLinkImages);
			} else if (field === 'video') {
				const currentAddLinkLinkVideos = addLinkLinkVideos;
				if (Object.keys(currentAddLinkLinkVideos).length > 0) {
					Object.entries(currentAddLinkLinkVideos).forEach(([item_index, item_data]) => {
						const int_item_index = parseInt(item_index.replace('video_', ''));
						if (key === int_item_index) {
							if (data) {
								currentAddLinkLinkVideos[item_index] = data;
							} else {
								currentAddLinkLinkVideos[item_index] = null;
							}
						} else {
							currentAddLinkLinkVideos['video_' + key] = data;
						}
					});
				} else {
					currentAddLinkLinkVideos['video_' + key] = data;
				}
				setAddLinkLinkVideos(currentAddLinkLinkVideos);
			} else {
				const currArr = JSON.parse(JSON.stringify(linksArr));
				if (currArr[key]) {
					currArr[key][field] = data;
					setLinksArr(currArr);
					setLinksArrLength(currArr.length);
				}
			}
		},
		[linksArr, addLinkLinkImages, addLinkLinkVideos]
	);

	/**
	 * Set values for links in 'welcomeSectionLinksForm' form
	 *
	 * @param data
	 * @param index
	 * @returns {Promise<void>}
	 */
	const setLinkFormValues = useCallback(
		async (data, index) => {
			const obj = {};
			obj['add_link_link_title_' + index] = data.title;
			obj['add_link_link_description_' + index] = data.description;
			obj['add_link_link_url_' + index] = data.url;

			welcomeSectionLinksForm.setFieldsValue(obj);

			if (data.photo) {
				const currentAddLinkLinkImages = addLinkLinkImages;
				currentAddLinkLinkImages['image_' + index] = data.photo;
				setAddLinkLinkImages(currentAddLinkLinkImages);
			}

			if (data.image) {
				const currentAddLinkLinkImages = addLinkLinkImages;
				currentAddLinkLinkImages['image_' + index] = data.image;
				setAddLinkLinkImages(currentAddLinkLinkImages);
			}

			if (data.video) {
				const currentAddLinkLinkVideos = addLinkLinkVideos;
				currentAddLinkLinkVideos['video_' + index] = data.video;
				setAddLinkLinkVideos(currentAddLinkLinkVideos);
			}

			setTimeout(() => {
				welcomeSectionLinksForm
					.validateFields()
					.then(() => {
						setWelcomeSectionLinksFormHasError(false);
					})
					.catch((errors) => {
						setWelcomeSectionLinksFormHasError(true);
					});
			}, 500);
		},
		[addLinkLinkImages, addLinkLinkVideos]
	);

	/**
	 * Set 'welcomeSectionForm' form values
	 *
	 * @type {(function(*): Promise<void>)|*}
	 */
	const setFormSpaceData = useCallback(
		async (spaceData) => {
			let textAreaWelcomeMessage = spaceData.welcome_message ? spaceData.welcome_message : '';
			welcomeSectionForm.setFieldsValue({
				welcome_title: spaceData.welcome_title,
				welcome_message: textAreaWelcomeMessage,
				welcome_youtube_video: spaceData.welcome_youtube_url
			});
			welcomeSectionLinksForm.setFieldsValue({
				links_title_first: spaceData.link_title_first,
				links_title_second: spaceData.link_title_second
			});
			setWelcomeTitle(spaceData.welcome_title);
			setWelcomeMessage(textAreaWelcomeMessage);
			setWelcomeYoutubeVideo(spaceData.welcome_youtube_url);
			setNewsletterChecked(spaceData.has_newsletters_signup);
			setLinksTitleFirst(spaceData.link_title_first);
			setLinksTitleSecond(spaceData.link_title_second);
			setSelectedLinksVisibility(spaceData.links_visibility);
			if (spaceData.welcome_photo) {
				if (!photoIsRemoved) {
					setWelcomeImageBlobPreview(spaceData.welcome_photo);
				}
			}
			if (spaceData.welcome_video) {
				if (!videoIsRemoved) {
					setWelcomeVideoBlobPreview(spaceData.welcome_video);
				}
			}

			if (spaceData.hasOwnProperty('links') && spaceData.links.length > 0) {
				const arr = [];
				spaceData.links.forEach((item, index) => {
					let proceed = false;

					if (spaceData.plan === 'pro') {
						proceed = true;
					} else {
						if (index <= 2) {
							proceed = true;
						}
					}

					if (proceed) {
						arr.push({
							id: item.id,
							title: item.title,
							description: item.description,
							image: item.photo,
							video: item.video,
							url: item.url
						});
					}
				});

				setLinksArr(arr);
				setLinksArrLength(arr.length);

				if (spaceData.links && spaceData.links.length > 0) {
					setAddLinkLinkImages({});
					setAddLinkLinkVideos({});
					spaceData.links.forEach((item, index) => {
						setLinkFormValues(item, index);
					});
				}
			}

			await welcomeSectionForm
				.validateFields()
				.then(() => {
					setWelcomeSectionFormHasError(false);
				})
				.catch((errors) => {
					setWelcomeSectionFormHasError(true);
				});
		},
		[photoIsRemoved, videoIsRemoved, addLinkLinkImages, addLinkLinkVideos]
	);

	/**
	 * Dropdown selected value change handler for Links Visibility
	 *
	 * @type {(function(*): Promise<void>)|*}
	 */
	const handleLinksVisibilityChange = useCallback(async (value) => {
		setSelectedLinksVisibility(value);
	}, []);

	useEffect(() => {
		setTimeout(()=>{
			// Ensure the field name is correct and exists
			const titleField = 'welcome_title';
			const welcomeMessage = 'welcome_message';
			setTitleFieldErrors(welcomeSectionForm.getFieldError(titleField));
			setWelcomeMessageFieldErrors(welcomeSectionForm.getFieldError(welcomeMessage));
		},[1000])

	}, [blurredField]);

	useEffect(() => {
		if (spaceInfo.plan === 'pro') {
			setAddLinkDisabled(false);
		} else {
			if (linksArr && linksArr.length >= 3) {
				setAddLinkDisabled(true);
			}
		}

		// linksArrLength is needed here to detect changes on linksArr
	}, [currentLocation, spaceInfo, linksArr, linksArrLength, resetLinkPreviews]);

	useEffect(() => {
		if (spaceInfo) {
			dispatch(hidePageContentLoader());
			setFormSpaceData(spaceInfo).then((r) => {});
		} else {
			dispatch(displayPageContentLoader());
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, welcomeImageError]);


	return (
		<>
			<div className="cs-dash-card block-full card">
				<div className="cs-stack-heading">
					<div className="stack-row split">
						<h1>Space Homepage</h1>
					</div>
					<p>
						Edit and manage your Homepage Welcome Section, Links Section, and other optional content blocks.{' '}
						<a>
							Learn more about your space homepage.
						</a>
					</p>
				</div>
			</div>

			<div className="cs-dash-card block-full card">
				<div className="cs-stack-form-guided">
					<div className="stack-row-wrap split">
						<div>
							<h2>Welcome Section</h2>
						</div>
						<p>Required</p>
					</div>

					<div className="formrow media">
						<div className="cs-stack-form-media stack-row">
							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									onError={setWelcomeImageError}
									cropperAspectRatio={4 / 3}
									hideDragIcon="true"
									hideText="true"
									shape="video"
									uploadFileTypes={IMAGE_FILETYPES.toString()}
									uploadedFilePreview={welcomeImageBlobPreview}
									onUploadFileChange={handleWelcomeImageUploadFileChange}
									minHeight={IMAGE_LARGE_MIN_HEIGHT}
									minWidth={IMAGE_LARGE_MIN_WIDTH}
									maxHeight={IMAGE_LARGE_MAX_HEIGHT}
									maxWidth={IMAGE_LARGE_MAX_WIDTH}
									uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
									// uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
									disableCropper={true}
								/>
								<p className="cs-label">Welcome Image</p>
								<p className="cs-helper">Up to 3840px JPEG, PNG, or GIF</p>
								{welcomeImageError && (
									<div className="alert callout warning !w-full items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{welcomeImageError}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="cs-stack-form-media-item">
								<UploadDraggerV2
									onError={setWelcomeVideoError}
									hideDragIcon="true"
									hideText="true"
									shape="video"
									uploadFileTypes={VIDEO_FILETYPES.toString()}
									uploadedFilePreview={welcomeVideoBlobPreview}
									onUploadFileChange={handleWelcomeVideoUploadFileChange}
									minHeight={VIDEO_STANDARD_MIN_HEIGHT}
									minWidth={VIDEO_STANDARD_MIN_WIDTH}
									maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
									maxWidth={VIDEO_STANDARD_MAX_WIDTH}
									uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
									// uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
								/>
								<p className="cs-label">Welcome Video</p>
								<p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
								{welcomeVideoError && (
									<div className="alert callout warning !w-full items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{welcomeVideoError}</h6>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="cs-formrow-helper-split">
							<p>Your Welcome Image (up to 4MB, 16:9 ratio preferred) for your Space is displayed next to the Welcome Title and Message on your homepage.</p>
							<p>You can also upload a short Welcome Video (up to 16MB, 16:9 ratio preferred) to be used instead of the image. Optional.</p>
						</div>
					</div>

					<Form
						form={welcomeSectionForm}
						name="welcomeSectionForm"
						id="welcomeSectionForm"
						validateMessages={VALIDATE_MESSAGES}
						oonFocus={() => {
							setWelcomeSectionFormIsOnFocus(true);
						}}
						onBlur={() => {
							setWelcomeSectionFormIsOnFocus(false);
							handleWelcomeSectionFormOnBlur().then(() => {});
						}}
						onChange={handleWelcomeSectionFormChanges}
						onFinishFailed={handleFinishFailed}
						onFinish={handleWelcomeSectionSubmit}
						className='w-full stack-form-col'
					>
						<Form.Item
							label="Welcome Video"
							name="welcome_youtube_video"
							validateTrigger="onBlur"
						>
							<div className='formrow'>
								<div className="form-input air">
									<label htmlFor="welcome_youtube_video">Welcome Video (Youtube Hosted)</label>
									<FormFieldStacked
										formType='input'
										type="text"
										maxLength={255}
										disabled={welcomeSectionFormSubmitted}
										value={welcomeYoutubeVideo}
										onBlur={()=>handleOnblurField('welcome_youtube_video')}
										placeholder="Add a Youtube Video Link i.e. youtube.com/watch?v=XYZ123AB"
									/>
									{((blurredField === 'welcome_youtube_video' || blurredField === 'all') && welcomeSectionForm.getFieldsError()[0]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{welcomeSectionForm.getFieldsError()[0]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="cs-formrow-helper-end">
									<p>If you want to have a longer Welcome Video, you can use a Youtube-hosted video instead. This requires that the video settings on Youtube allow embedding. Optional.</p>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							label="Title"
							name="welcome_title"
							validateTrigger="onBlur"
							rules={[
								{ required: true },
								{
									max: 32,
									message: 'Must not exceed 32 characters.'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-textarea air">
									<label htmlFor="welcome_title">Title</label>
									<FormFieldStacked
										formType='input'
										type="text"
										maxLength={32}
										disabled={welcomeSectionFormSubmitted}
										value={welcomeTitle}
										onBlur={()=>handleOnblurField('welcome_title')}
										placeholder="Up to 32 characters, including spaces."
									/>
									{((blurredField === 'welcome_title' || blurredField === 'all') && titleFieldErrors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{titleFieldErrors[0]}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div>
									<p>This is first section on your homepage. It should be a short welcome or introductory title.</p>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							label="Message"
							name="welcome_message"
							validateTrigger="onBlur"
							rules={[
								{ required: true },
								{
									max: 255,
									message: 'Must not exceed 255 characters.'
								}
							]}
						>
							<div className='formrow'>
								<div className="form-textarea air">
									<label htmlFor="welcome_message">Message</label>
									<FormFieldStacked
										formType='textarea'
										type="text"
										maxLength={255}
										disabled={welcomeSectionFormSubmitted}
										value={welcomeMessage}
										rows="3"
										onBlur={()=>handleOnblurField('welcome_message')}
										placeholder="Up to 255 characters, including spaces."
									/>
									{((blurredField === 'welcome_message' || blurredField === 'all') && welcomeMessageFieldErrors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content content-center">
												<div>
													<h6>{welcomeMessageFieldErrors[0]}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div>
									<p>Add an intro to your Space and encourage visitors to join your community, up to 255 characters.</p>
								</div>
							</div>
						</Form.Item>

						<div className="formrow">
							<div className="form-input-checkbox gradient md rounded row w-full">
								<div className="checkbox">
									<input
										id="checkbox-cs-agreement"
										aria-describedby="checkbox-cs-agreement-description"
										name="checkbox-cs-agreement"
										type="checkbox"
										required=""
										checked={newsletterChecked}
										onChange={onChangeNewsletter}
									/>
								</div>
								<div>
									<label htmlFor="checkbox-cs-agreement" className="sr-only">Collect Opt-In Emails for Newsletter Signup for Visitors</label>
									<p id="checkbox-cs-agreement-description">
										Collect Opt-In Emails for Newsletter Signup for Visitors
									</p>
								</div>
							</div>
							<div>
								<p>
									A Newsletter Signup form for opt-in email collection can be
									added and will display on your Space for Visitors who are not
									signed into an account. You will be able to export this and use
									your preferred newsletter app to send emails.
								</p>
							</div>
						</div>

						{welcomeSectionFormAlert && (
							<div className={"alert banner px-section-safeview" + (welcomeSectionFormAlert.variant === 'danger' ? " error" : " success")}>
								<div className="action">
									<i className={"fa-solid" + (welcomeSectionFormAlert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
								</div>
								<div className="content">
									<div>
										<h6>{welcomeSectionFormAlert.message}</h6>
										<p></p>
									</div>
								</div>
								<div className="action"
									 onClick={()=>{
										 setWelcomeSectionFormAlert(null)
									 }}
								>
									<a>
										<i className="fa-solid fa-xmark"/>
									</a>
								</div>
							</div>
						)}
						<button
							type="submit"
							className="primary-gradient"
							disabled={
								welcomeSectionFormSubmitted
								// ||
								// welcomeSectionFormHasError
							}
						>
							<span>
								<p>
									{welcomeSectionFormSubmitted && (
										<i className="fa-solid fa-spinner fa-spin"></i>
									)}
									Save
								</p>
							</span>
						</button>
					</Form>
				</div>
			</div>

			<div className="cs-dash-card block-full card">
				<div className="cs-stack-form-guided">
					<div className="stack-row-wrap split">
						<div>
							<h2>Link Section</h2>
						</div>
						<p>Optional</p>
					</div>
					<Form
						form={welcomeSectionLinksForm}
						name="welcomeSectionLinksForm"
						id="welcomeSectionLinksForm"
						validateMessages={VALIDATE_MESSAGES}
						onFinishFailed={handleFinishFailed}
						onFocus={() => {
							setWelcomeSectionLinksFormIsOnFocus(true);
						}}
						onBlur={() => {
							// setTimeout(() => {
							setWelcomeSectionLinksFormIsOnFocus(false);
							handleWelcomeSectionLinksFormOnBlur();
							// }, 200);
						}}
						onChange={handleWelcomeSectionLinksFormChanges}
						onFinish={handleWelcomeSectionLinksSubmit}
						className='w-full stack-form-col'
					>
						<div className='formrow'>
							<div className="cs-stack-form-buttons">
								<DropdownWithItems
									title="Links Visibility"
									customButtonClass="button-dropdown-gradient-alt secondary "
									dropDownClass="dropdown-bg"
									displayError={
										displayDropDownError &&
										selectedLinksVisibility === 'default'
									}
									icon="fa-light fa-eye"
									selectedValue={selectedLinksVisibility}
									listItems={LINKS_VISIBILITY_LIST_WITH_DEFAULT}
									onDropdownChange={(value) => handleLinksVisibilityChange(value)}
								/>
							</div>
							<div className="cs-formrow-helper-end">
								<p>
									Set the visibility of your Links Section. If you do not have any
									links added, your Links Section will not be visible, even if you
									have set it show here.
								</p>
							</div>
						</div>

						{selectedLinksVisibility === 'show' && (
							<>
								<div className="formrow">
									<div className="cs-stack-form-inputs stack-row">
										<Form.Item
											label="Featured"
											name="links_title_first"
										>
											<div className="form-input air">
												<label htmlFor="links_title_first">
													Section Title 1st Half
												</label>
												<FormFieldStacked
													formType='input'
													type="text"
													maxLength={50}
													value={linksTitleFirst}
													placeholder="Featured"
												/>
											</div>
										</Form.Item>
										<Form.Item
											label="Links"
											name="links_title_second"
										>
											<div className="form-input air">
												<label htmlFor="links_title_second">
													Section Title 2nd Half
												</label>
												<FormFieldStacked
													formType='input'
													type="text"
													maxLength={50}
													value={linksTitleSecond}
													placeholder="Links"
												/>
											</div>
										</Form.Item>
									</div>
									<div className="cs-formrow-helper-split">
										<p>
											Section Titles are split into 2 parts of up
											to 16 characters each. The 1st part is a
											standard color, and the 2nd part is a
											highlight color. The default is “Featured
											Links”
										</p>
									</div>
								</div>
								<div className="formrow">
									<div>
										<h3>Add a Link</h3>
									</div>
									<div>
										<p>
											You can add up to 3 links on a Basic Space.
											Additional links can be added later if you
											upgrade.
										</p>
									</div>
								</div>
								{linksArr &&
									linksArr.map((data, index) => (
										<>
											<Links
												data={data}
												key={index}
												index={index}
												submitted={welcomeSectionLinksFormSubmitted}
												updateData={handleUpdateData}
												form={welcomeSectionLinksForm}
												updateLinkToRemove={
													handleUpdateLinkToRemove
												}
												resetLinkPreviews={resetLinkPreviews}
												createSpaceAddDetailsForm={
													welcomeSectionLinksForm
												}
												handleOnblurField={handleOnblurField}
												blurredField={blurredField}
												isFromManage={true}
											/>
											<div></div>
										</>
									))}

								<div></div>
								<div className="formrow">
									<a
										onClick={addLink}
										disabled={addLinkDisabled}
										className={
											addLinkDisabled
												? 'pointer-events-none button-secondary-gradient'
												: 'pointer-events-auto button-secondary-gradient'
										}
									>
										<span>
											<p><i className="fa-solid fa-link !"/>Add Another Link</p>
										</span>
									</a>
									<div className="cs-formrow-helper-none">
										<p>
											Click this button to show more Add Link
											fields.
										</p>
									</div>
								</div>
							</>
						)}

						{welcomeSectionLinksFormAlert && (
							<div className={"alert banner px-section-safeview" + (welcomeSectionLinksFormAlert.variant === 'danger' ? " error" : " success")}>
								<div className="action">
									<i className={"fa-solid" + (welcomeSectionLinksFormAlert.variant === 'danger' ? " fa-triangle-exclamation" : " fa-circle-check")}/>
								</div>
								<div className="content">
									<div>
										<h6>{welcomeSectionLinksFormAlert.message}</h6>
										<p></p>
									</div>
								</div>
								<div className="action"
									 onClick={()=>{
										 setWelcomeSectionLinksFormAlert(null)
									 }}
								>
									<a>
										<i className="fa-solid fa-xmark"/>
									</a>
								</div>
							</div>
						)}

						<button
							type="submit"
							className="primary-gradient"
							disabled={
								welcomeSectionLinksFormSubmitted
								// ||
								// welcomeSectionLinksFormHasError
							}
						>
							<span>
								<p>
									{welcomeSectionLinksFormSubmitted && (
										<i className="fa-solid fa-spinner fa-spin"></i>
									)}
									Save
								</p>
							</span>
						</button>
					</Form>
				</div>
			</div>

			{/*<div className="block-full card">*/}
			{/*	<div className="manage-space">*/}
			{/*		<div className="dashboard-wrapper">*/}
			{/*			<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<h3 className="header-medium">Space Homepage</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<p className="body-text--small">*/}
			{/*						Edit and manage your Homepage Welcome Section, Links Section, and*/}
			{/*						other optional content blocks.{' '}*/}
			{/*						<a className="body-txtitalic--small gradient-color-txt">*/}
			{/*							Learn more about your Space Homepage.*/}
			{/*						</a>*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*				<div className="col-span-6">*/}
			{/*					<h3 className="header-small">Welcome Section</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-6">*/}
			{/*					<p className="body-text--small text-end">Required</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={welcomeSectionForm}*/}
			{/*				name="welcomeSectionForm"*/}
			{/*				id="welcomeSectionForm"*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*				onFocus={() => {*/}
			{/*					setWelcomeSectionFormIsOnFocus(true);*/}
			{/*				}}*/}
			{/*				onBlur={() => {*/}
			{/*					setTimeout(() => {*/}
			{/*						setWelcomeSectionFormIsOnFocus(false);*/}
			{/*						handleWelcomeSectionFormOnBlur().then(() => {});*/}
			{/*					}, 200);*/}
			{/*				}}*/}
			{/*				onChange={handleWelcomeSectionFormChanges}*/}
			{/*				onFinish={handleWelcomeSectionSubmit}*/}
			{/*				className={welcomeSectionFormIsOnFocus ? 'hide-antd-error-messages' : ''}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-3  gap-y-8 md:gap-y-0 md:gap-8 mt-5">*/}
			{/*					<div className='max-[768px]:mt-4'>*/}
			{/*						<div className="upload-rec">*/}
			{/*							<UploadDragger*/}
			{/*								passedError={welcomeImageError}*/}
			{/*								cropperAspectRatio={4 / 3}*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={welcomeImageBlobPreview}*/}
			{/*								onUploadFileChange={handleWelcomeImageUploadFileChange}*/}
			{/*								minHeight={IMAGE_LARGE_MIN_HEIGHT}*/}
			{/*								minWidth={IMAGE_LARGE_MIN_WIDTH}*/}
			{/*								maxHeight={IMAGE_LARGE_MAX_HEIGHT}*/}
			{/*								maxWidth={IMAGE_LARGE_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}*/}
			{/*								disableCropper={true}*/}
			{/*							/>*/}
			{/*							<i className="fa-regular fa-plus"></i>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Welcome Image**/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest">*/}
			{/*							Up to 1920px wide. <br />*/}
			{/*							JPEG, PNG, or GIF*/}
			{/*						</p>*/}
			{/*						<p className="body-txtitalic--smallest block md:hidden text-center">*/}
			{/*							Your Welcome Image (up to 4MB, 16:9 ratio preferred) for your*/}
			{/*							Space is displayed next to the Welcome Title and Message on your*/}
			{/*							homepage.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className='max-[768px]:mt-[2rem]'>*/}
			{/*						<div className="upload-rec">*/}
			{/*							<UploadDragger*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={welcomeVideoBlobPreview}*/}
			{/*								onUploadFileChange={handleWelcomeVideoUploadFileChange}*/}
			{/*								minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*							/>*/}
			{/*							<i className="fa-regular fa-plus"></i>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Welcome Video*/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest">*/}
			{/*							Up to 1920px/1080px wide. <br />*/}
			{/*							MOV or MP4*/}
			{/*						</p>*/}
			{/*						<p className="body-txtitalic--smallest block md:hidden text-center">*/}
			{/*							You can also upload a short Welcome Video (up to 16MB, 16:9*/}
			{/*							ratio preferred) to be used instead of the image. Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className='hidden md:block'>*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Your Welcome Image (up to 4MB, 16:9 ratio preferred) for your*/}
			{/*							Space is displayed next to the Welcome Title and Message on your*/}
			{/*							homepage.*/}
			{/*						</p>*/}
			{/*						<p className="body-txtitalic--smallest mt-5">*/}
			{/*							You can also upload a short Welcome Video (up to 16MB, 16:9*/}
			{/*							ratio preferred) to be used instead of the image. Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 mt-4 gap-y-8 md:gap-y-0 md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className=" gradient-color-txt -top-4  w-[100px] md:w-1/4 two-rows">*/}
			{/*								<span className='text-[12px]  min-[400px]:text-[18px]'>Welcome Video</span>*/}
			{/*								<br />*/}
			{/*								<span className="text-[12px] min-[400px]:text-[14px] body-txtitalic--smallest">*/}
			{/*								Youtube Hosted*/}
			{/*							</span>*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Welcome Video"*/}
			{/*								name="welcome_youtube_video"*/}
			{/*								className="mb-0 hidden-label z-0"*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Add a Youtube Video Link i.e. youtube.com/watch?v=XYZ123AB"*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									disabled={welcomeSectionFormSubmitted}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest m-0">*/}
			{/*							If you want to have a longer Welcome Video, you can use a*/}
			{/*							Youtube-hosted video instead. This requires that the video*/}
			{/*							settings on Youtube allow embedding. Optional.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 mt-4 gap-y-8 md:gap-y-0 md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className="body-text--small gradient-color-txt w-1/4">*/}
			{/*								Title*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Title"*/}
			{/*								name="welcome_title"*/}
			{/*								className="mb-0 hidden-label z-0"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 32,*/}
			{/*										message: 'Must not exceed 32 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Up to 32 characters, including spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={32}*/}
			{/*									disabled={welcomeSectionFormSubmitted}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is first section on your homepage. It should be a short*/}
			{/*							welcome or introductory title.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 mt-4 gap-y-8 md:gap-y-0 md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="input-group-com w-full mt-2">*/}
			{/*							<label className="body-text--small gradient-color-txt w-[100px] md:w-1/4">*/}
			{/*								Message*/}
			{/*							</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Message"*/}
			{/*								name="welcome_message"*/}
			{/*								className="mb-0 hidden-label z-0"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 255,*/}
			{/*										message: 'Must not exceed 255 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<TextareaAutosize*/}
			{/*									minRows={8}*/}
			{/*									placeholder="Up to 255 characters, including spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={255}*/}
			{/*									disabled={welcomeSectionFormSubmitted}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest m-0">*/}
			{/*							Add an intro to your Space and encourage visitors to join your*/}
			{/*							community, up to 255 characters.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 mt-4 gap-y-8 md:gap-y-0 md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8">*/}
			{/*						<div className="form-check flex items-center">*/}
			{/*							<Checkbox*/}
			{/*								checked={newsletterChecked}*/}
			{/*								className="inline-flex form-check-input items-center mr-8 scale-150 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
			{/*								onChange={onChangeNewsletter}*/}
			{/*							></Checkbox>*/}
			{/*							<label className="form-check-label body-text--small gradient-color-txt">*/}

			{/*								Collect Opt-In Emails for Newsletter Signup for Visitors*/}
			{/*							</label>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest m-0">*/}
			{/*							A Newsletter Signup form for opt-in email collection can be*/}
			{/*							added and will display on your Space for Visitors who are not*/}
			{/*							signed into an account. You will be able to export this and use*/}
			{/*							your preferred newsletter app to send emails.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*					<>*/}
			{/*						{welcomeSectionFormAlert && (*/}
			{/*							<div className="col-span-12 mb-3">*/}
			{/*								<Alert*/}
			{/*									key={welcomeSectionFormAlert.variant}*/}
			{/*									variant={welcomeSectionFormAlert.variant}*/}
			{/*									className={*/}
			{/*										'custom-alert ' +*/}
			{/*										(welcomeSectionFormAlert.variant === 'danger'*/}
			{/*											? 'custom-alert-danger'*/}
			{/*											: 'custom-alert-success')*/}
			{/*									}*/}
			{/*								>*/}
			{/*									{welcomeSectionFormAlert.message}*/}
			{/*								</Alert>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</>*/}
			{/*					<div className="col-span-12">*/}
			{/*						<Form.Item className="mb-0 z-0">*/}
			{/*							<Button*/}
			{/*								className="btn btn-primary btn-md"*/}
			{/*								htmlType="submit"*/}
			{/*								disabled={*/}
			{/*									welcomeSectionFormSubmitted ||*/}
			{/*									welcomeSectionFormHasError*/}
			{/*								}*/}
			{/*							>*/}
			{/*								{welcomeSectionFormSubmitted && (*/}
			{/*									<i className="fa-solid fa-spinner fa-spin"></i>*/}
			{/*								)}*/}
			{/*								Saves*/}
			{/*							</Button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}
			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*				<div className="col-span-6">*/}
			{/*					<h3 className="header-small">Link Section</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-6">*/}
			{/*					<p className="body-text--small text-end">Optional</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={welcomeSectionLinksForm}*/}
			{/*				name="welcomeSectionLinksForm"*/}
			{/*				id="welcomeSectionLinksForm"*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*				onFocus={() => {*/}
			{/*					setWelcomeSectionLinksFormIsOnFocus(true);*/}
			{/*				}}*/}
			{/*				onBlur={() => {*/}
			{/*					setTimeout(() => {*/}
			{/*						setWelcomeSectionLinksFormIsOnFocus(false);*/}
			{/*						handleWelcomeSectionLinksFormOnBlur();*/}
			{/*					}, 200);*/}
			{/*				}}*/}
			{/*				onChange={handleWelcomeSectionLinksFormChanges}*/}
			{/*				onFinish={handleWelcomeSectionLinksSubmit}*/}
			{/*				className={*/}
			{/*					welcomeSectionLinksFormIsOnFocus ? 'hide-antd-error-messages' : ''*/}
			{/*				}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-12 mt-4 gap-y-8 md:gap-y-0 md:gap-8 flex items-center">*/}
			{/*					<div className="col-span-12 md:col-span-8 ">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Links Visibility"*/}
			{/*							customButtonClass="btn-md btn-secondary"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							displayError={*/}
			{/*								displayDropDownError &&*/}
			{/*								selectedLinksVisibility === 'default'*/}
			{/*							}*/}
			{/*							icon="fa-light fa-eye"*/}
			{/*							selectedValue={selectedLinksVisibility}*/}
			{/*							listItems={LINKS_VISIBILITY_LIST_WITH_DEFAULT}*/}
			{/*							onDropdownChange={(value) => handleLinksVisibilityChange(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-12 md:col-span-4 ">*/}
			{/*						<p className="body-txtitalic--smallest m-0">*/}
			{/*							Set the visibility of your Links Section. If you do not have any*/}
			{/*							links added, your Links Section will not be visible, even if you*/}
			{/*							have set it show here.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				{selectedLinksVisibility === 'show' && (*/}
			{/*					<>*/}
			{/*						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 flex items-center">*/}
			{/*							<div className="col-span-12 md:col-span-4">*/}
			{/*								<div className="input-group-com w-full mt-2 overflow-hidden">*/}
			{/*									/!* <label className="body-text--small gradient-color-txt w-50 two-rows">*/}
			{/*									Section Title 1st Half*/}

			{/*								</label> *!/*/}
			{/*									<label className=" gradient-color-txt -top-4  w-[100px] md:w-1/4 two-rows">*/}
			{/*										<span className='text-[12px]  min-[400px]:text-[18px]'>Section Title</span>*/}
			{/*										<br />*/}
			{/*										<span className="text-[12px] min-[400px]:text-[14px] body-txtitalic--smallest">*/}
			{/*										1st Half*/}
			{/*									</span>*/}
			{/*									</label>*/}
			{/*									<Form.Item*/}
			{/*										label="Featured"*/}
			{/*										name="links_title_first"*/}
			{/*										className="mb-0 hidden-label z-0"*/}
			{/*									>*/}
			{/*										<Input*/}
			{/*											placeholder="Featured"*/}
			{/*											className="input-md body-text--small color-reg section-link"*/}
			{/*											maxLength={20}*/}
			{/*											disabled={welcomeSectionLinksFormSubmitted}*/}
			{/*										/>*/}
			{/*									</Form.Item>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*							<div className="col-span-12 md:col-span-4">*/}
			{/*								<div className="input-group-com w-full mt-2 overflow-hidden">*/}
			{/*									<label className=" gradient-color-txt -top-4  w-[100px] md:w-1/4 two-rows">*/}
			{/*										<span className='text-[12px]  min-[400px]:text-[18px]'>Section Title</span>*/}
			{/*										<br />*/}
			{/*										<span className="text-[12px] min-[400px]:text-[14px] body-txtitalic--smallest">*/}
			{/*										2nd Half*/}
			{/*									</span>*/}
			{/*									</label>*/}
			{/*									<Form.Item*/}
			{/*										label="Links"*/}
			{/*										name="links_title_second"*/}
			{/*										className="mb-0 hidden-label z-0"*/}
			{/*									>*/}
			{/*										<Input*/}
			{/*											placeholder="Links"*/}
			{/*											className="input-md body-text--small color-reg section-link"*/}
			{/*											maxLength={20}*/}
			{/*											disabled={welcomeSectionLinksFormSubmitted}*/}
			{/*										/>*/}
			{/*									</Form.Item>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*							<div className="col-span-12 md:col-span-4">*/}
			{/*								<p className="body-txtitalic--smallest m-0">*/}
			{/*									Section Titles are split into 2 parts of up to 16*/}
			{/*									characters each. The 1st part is a standard color, and*/}
			{/*									the 2nd part is a highlight color. The default is{' '}*/}
			{/*									<a className="gradient-color-txt">“Featured Links”</a>*/}
			{/*								</p>*/}
			{/*							</div>*/}
			{/*						</div>*/}

			{/*						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">*/}
			{/*							<div className="col-span-8 flex items-center justify-between">*/}
			{/*								<h5 className="header-small">Add a Link</h5>*/}
			{/*							</div>*/}
			{/*							<div className="col-span-4 flex items-center">*/}
			{/*								<p className="body-txtitalic--smallest color-reg">*/}
			{/*									You can add up to 3 links on a Basic Space. Additional*/}
			{/*									links can be added later if you upgrade.*/}
			{/*								</p>*/}
			{/*							</div>*/}
			{/*						</div>*/}

			{/*						{linksArr &&*/}
			{/*							linksArr.map((data, index) => (*/}
			{/*								<Links*/}
			{/*									data={data}*/}
			{/*									key={index}*/}
			{/*									index={index}*/}
			{/*									submitted={welcomeSectionLinksFormSubmitted}*/}
			{/*									updateData={handleUpdateData}*/}
			{/*									form={welcomeSectionLinksForm}*/}
			{/*									updateLinkToRemove={handleUpdateLinkToRemove}*/}
			{/*									resetLinkPreviews={resetLinkPreviews}*/}
			{/*								></Links>*/}
			{/*							))}*/}
			{/*						<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5">*/}
			{/*							<div className="col-span-12 md:col-span-8">*/}
			{/*								<Button*/}
			{/*									className="btn btn-secondary btn-lg "*/}
			{/*									onClick={addLink}*/}
			{/*									disabled={addLinkDisabled}*/}
			{/*								>*/}
			{/*									<i className="fa-solid fa-link !"></i>*/}
			{/*									<span className=''>Add Another Link</span>*/}
			{/*								</Button>*/}
			{/*							</div>*/}
			{/*							<div className="col-span-12 md:col-span-4 flex justify-center md:justify-start items-center">*/}
			{/*								<p className="body-txtitalic--smallest">*/}
			{/*									Click this button to add another link.*/}
			{/*								</p>*/}
			{/*							</div>*/}
			{/*						</div>*/}
			{/*					</>*/}
			{/*				)}*/}
			{/*				<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-5 flex items-center">*/}
			{/*					<>*/}
			{/*						{welcomeSectionLinksFormAlert && (*/}
			{/*							<div className="col-span-12 mb-3">*/}
			{/*								<Alert*/}
			{/*									key={welcomeSectionLinksFormAlert.variant}*/}
			{/*									variant={welcomeSectionLinksFormAlert.variant}*/}
			{/*									className={*/}
			{/*										'custom-alert ' +*/}
			{/*										(welcomeSectionLinksFormAlert.variant === 'danger'*/}
			{/*											? 'custom-alert-danger'*/}
			{/*											: 'custom-alert-success')*/}
			{/*									}*/}
			{/*								>*/}
			{/*									{welcomeSectionLinksFormAlert.message}*/}
			{/*								</Alert>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</>*/}
			{/*					<div className="col-span-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*						<Form.Item className="mb-0 z-0">*/}
			{/*							<Button*/}
			{/*								className="btn btn-primary btn-md"*/}
			{/*								htmlType="submit"*/}
			{/*								disabled={*/}
			{/*									welcomeSectionLinksFormSubmitted ||*/}
			{/*									welcomeSectionLinksFormHasError*/}
			{/*								}*/}
			{/*							>*/}
			{/*								{welcomeSectionLinksFormSubmitted && (*/}
			{/*									<i className="fa-solid fa-spinner fa-spin me-2"></i>*/}
			{/*								)}*/}
			{/*								Save*/}
			{/*							</Button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
};

export default memo(ManageHomePage);
