import {memo, useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getMembersList, getSpaceItems} from "../../../services/space";
import {getPercentage} from "antd/es/progress/utils";

const DashboardHome = () => {
	const {authUserInfo, loading} = useSelector((state) => state.general);
	const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);

	const [totalMembersCount, setTotalMembersCount] = useState(0);
	const [totalActiveMembersCount, setTotalActiveMembersCount] = useState(0);
	const [newMembersTodayCount, setNewMembersTodayCount] = useState(0);
	const [newMembersMonthCount, setNewMembersMonthCount] = useState(0);
	const [lostMembersMonthCount, setLostMembersMonthCount] = useState(0);
	const [monthlyActiveCount, setMonthlyActiveCount] = useState(0);
	const [weeklyActiveCount, setWeeklyActiveCount] = useState(0);
	const [dailyActiveCount, setDailyActiveCount] = useState(0);

	const [totalMembershipsCount, setTotalMembershipsCount] = useState(0);
	const [totalSoldMembershipsCount, setTotalSoldMembershipsCount] = useState(0);
	const [totalActiveMembershipsValue, setTotalActiveMembershipsValue] = useState(0);

	const [totalItemsCount, setTotalItemsCount] = useState(0);
	const [totalSoldItemsCount, setTotalSoldItemsCount] = useState(0);
	const [averageSoldPerMember, setAverageSoldPerMember] = useState(0);
	const [totalSoldItemsThisMonthCount, setTotalSoldItemsThisMonthCount] = useState(0);
	const [mostPopularItem, setMostPopularItem] = useState('SW001');
	const [totalActiveItemsValue, setTotalActiveItemsValue] = useState(0);
	const [averagePerMemberValue, setAveragePerMemberValue] = useState(0);
	const [salesThisMonthValue, setSalesThisMonthValue] = useState(0);
	const [recurringRevenueValue, setRecurringRevenueValue] = useState(0);


	const [initialLoad, setInitialLoad] = useState(true);

	const [membersCurrentPage, setMembersCurrentPage] = useState(1);
	const [itemsCurrentPage, setItemsCurrentPage] = useState(1);

	const getMembersRequest = useCallback(async () => {
		try {
			if (authUserInfo && spaceInfo) {
				const data = {
					viewing_user_id: authUserInfo.id,
					space_id: spaceInfo.id,
					list_count: 10,
					page: membersCurrentPage
				};

				// if (selectedFilter !== 'default') {
				// 	data['filter_type'] = filterArr['filter_type'];
				// 	data['filter_value'] = filterArr['filter_value'];
				// }
				//
				// if (selectedSort !== 'default') {
				// 	data['sort_by'] = sortArr['sort_by'];
				//
				// 	if (selectedSort === 'following_count') {
				// 		data['sort_order'] = 'desc';
				// 	}
				// }
				//
				// if (clickedTableColumnOrder === 'username') {
				// 	if (usernameSortOrder) {
				// 		data['sort_by'] = 'username';
				// 		data['sort_order'] = usernameSortOrder;
				// 	}
				// }
				// if (clickedTableColumnOrder === 'join_date') {
				// 	if (joinDateSortOrder) {
				// 		data['sort_by'] = 'join_date';
				// 		data['sort_order'] = joinDateSortOrder;
				// 	}
				// }
				// if (clickedTableColumnOrder === 'follower_count') {
				// 	if (followerSortOrder) {
				// 		data['sort_by'] = 'follower_count';
				// 		data['sort_order'] = followerSortOrder;
				// 	}
				// }
				// if (clickedTableColumnOrder === 'memberships') {
				// 	if (membershipSortOrder) {
				// 		data['sort_by'] = 'memberships';
				// 		data['sort_order'] = membershipSortOrder;
				// 	}
				// }
				// if (clickedTableColumnOrder === 'items') {
				// 	if (itemSortOrder) {
				// 		data['sort_by'] = 'items';
				// 		data['sort_order'] = itemSortOrder;
				// 	}
				// }
				//
				// if (searchText && searchText !== '') {
				// 	data['search'] = searchText;
				// }

				// setMembersFetching(true);

				const response = await getMembersList(data);
				if (response && response.result) {
					if (response.data) {
						// setMembersList(response.data);
						setTotalMembersCount(response.total_members_count);
						setTotalActiveMembersCount(response.total_active_members_count);
						setNewMembersTodayCount(response.new_members_today_count);
						setNewMembersMonthCount(response.new_members_month_count);
						setLostMembersMonthCount(response.lost_members_month_count);
						setMonthlyActiveCount(response.monthly_active_count);
						setWeeklyActiveCount(response.weekly_active_count);
						setDailyActiveCount(response.daily_active_count);
						// buildPagination(parseInt(response.page), parseInt(response.page_range));
					} else {
						// setMembersList(null);
					}
				}
				// setMembersFetching(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [
		authUserInfo,
		spaceInfo,
		// membersCurrentPage,
		// selectedFilter,
		// selectedSort,
		// filterArr,
		// sortArr,
		// clickedTableColumnOrder,
		// usernameSortOrder,
		// joinDateSortOrder,
		// followerSortOrder,
		// membershipSortOrder,
		// itemSortOrder,
		// searchText
	]);



	const getItemsRequest = useCallback(async () => {
		try {
			if (authUserInfo && spaceInfo) {
				const data = {
					viewing_user_id: authUserInfo.id,
					space_id: spaceInfo.id,
					list_count: 10,
					page: itemsCurrentPage
				};

				// if (selectedFilter !== 'default') {
				// 	data['filter_type'] = filterArr['filter_type'];
				// 	data['filter_value'] = filterArr['filter_value'];
				// }
				//
				// if (selectedSort !== 'default') {
				// 	data['sort_by'] = sortArr['sort_by'];
				// }
				//
				// if (searchText && searchText !== '') {
				// 	data['search'] = searchText;
				// }
				//
				// if (clickedTableColumnOrder === 'name') {
				// 	if (nameSortOrder) {
				// 		data['sort_by'] = 'name';
				// 		data['sort_order'] = nameSortOrder;
				// 	}
				// }
				// if (clickedTableColumnOrder === 'price') {
				// 	if (priceSortOrder) {
				// 		data['sort_by'] = 'price';
				// 		data['sort_order'] = priceSortOrder;
				// 	}
				// }
				// if (clickedTableColumnOrder === 'benefits') {
				// 	if (benefitsSortOrder) {
				// 		data['sort_by'] = 'benefits';
				// 		data['sort_order'] = benefitsSortOrder;
				// 	}
				// }
				// if (clickedTableColumnOrder === 'type') {
				// 	if (typeSortOrder) {
				// 		data['sort_by'] = 'type';
				// 		data['sort_order'] = typeSortOrder;
				// 	}
				// }

				// setItemsFetching(true);

				const response = await getSpaceItems(data);
				if (response && response.result) {
					if (response.data) {
						// setItemsList(response.data);
						setTotalItemsCount(response.total_items_count);
						// buildPagination(parseInt(response.page), parseInt(response.page_range));
					} else {
						// setItemsList(null);
					}
				}

				// setItemsFetching(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [
		authUserInfo,
		spaceInfo,
		// itemsCurrentPage,
		// selectedFilter,
		// selectedSort,
		// filterArr,
		// sortArr,
		// clickedTableColumnOrder,
		// nameSortOrder,
		// priceSortOrder,
		// benefitsSortOrder,
		// typeSortOrder,
		// searchText
	]);

	useEffect(() => {
		if (!initialLoad) {
			getMembersRequest().then((r) => {
			});
		}

		if (authUserInfo && spaceInfoLoading && spaceInfoLoading === 'done') {
			if (initialLoad) {
				getMembersRequest().then((r) => {
				});
				getItemsRequest().then((r) => {
				});
			}

			setInitialLoad(false);
		}
	}, [
		authUserInfo,
		spaceInfoLoading,
		spaceInfo,
	]);

	return (
		<div className="block-full">
			<div className="grid grid-cols-12 gap-2 md:gap-8">
				<div className="block-lg card">
					<div id="cs-platform-dash-item" className="cs-dash">
						<div className="cs-dashframe section-flex text-center">
							<div className="block-full">
								<h5 className="header-small text-center">{totalMembersCount}</h5>
								<p className="!mt-0">Total Members</p>
							</div>
						</div>
					</div>
				</div>

				<div className="block-lg card">
					<div id="cs-platform-dash-item" className="cs-dash">
						<div className="cs-dashframe section-flex text-center">
							<div className="block-full">
								<h5 className="header-small text-center">
									{totalActiveMembersCount}({getPercentage(totalMembersCount, totalActiveMembersCount)})
								</h5>
								<p className="!mt-0">Recently Active</p>
							</div>

						</div>
					</div>
				</div>

				{/*<div className="col-span-12">*/}
				{/*	<div className="block-smx "><h5>Items</h5></div>*/}
				{/*</div>*/}

				<div className="block-lg card">
					<div id="cs-platform-dash-item" className="cs-dash">
						<div className="cs-dashframe section-flex text-center">
							<div className="block-full">
								<h5 className="header-small text-center">{totalSoldItemsCount}</h5>
								<p className="!mt-0">Item Sold Today</p>
							</div>
							{/*<div className="block-smx ">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {averageSoldPerMember}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5 !mt-0">Average Per Member</p>*/}
							{/*</div>*/}
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {totalSoldItemsThisMonthCount}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Sold This Month</p>*/}
							{/*</div>*/}
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {mostPopularItem}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Most Popular</p>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
				<div className="block-lg card">
					<div id="cs-platform-dash-item" className="cs-dash">
						<div className="cs-dashframe section-flex text-center">
							<div className="block-full">
								<h5 className="header-small text-center">${totalActiveItemsValue} USD</h5>
								<p className=" !mt-0">Recently Active</p>
							</div>
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {averagePerMemberValue}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Average Per Member</p>*/}
							{/*</div>*/}
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {salesThisMonthValue}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Sales This Month</p>*/}
							{/*</div>*/}
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {recurringRevenueValue}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Recurring Revenue</p>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>

				{/*<div className="col-span-12">*/}
				{/*	<div className="block-smx"><h5>Memberships</h5></div>*/}
				{/*</div>*/}

				<div className="block-lg card">
					<div id="cs-platform-dash-item" className="cs-dash">
						<div className="cs-dashframe section-flex text-center">
							<div className="block-full">
								<h5 className="header-small text-center">{totalSoldMembershipsCount}</h5>
								<p className="!mt-0">Item Sold Today</p>
							</div>
							{/*<div className="block-smx ">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {averageSoldPerMember}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5 !mt-0">Average Per Member</p>*/}
							{/*</div>*/}
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {totalSoldItemsThisMonthCount}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Sold This Month</p>*/}
							{/*</div>*/}
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {mostPopularItem}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Most Popular</p>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
				<div className="block-lg card">
					<div id="cs-platform-dash-item" className="cs-dash">
						<div className="cs-dashframe section-flex text-center">
							<div className="block-full">
								<h5 className="header-small text-center">${totalActiveMembershipsValue} USD</h5>
								<p className=" !mt-0">Recently Active</p>
							</div>
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {averagePerMemberValue}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Average Per Member</p>*/}
							{/*</div>*/}
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {salesThisMonthValue}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Sales This Month</p>*/}
							{/*</div>*/}
							{/*<div className="block-smx">*/}
							{/*    <p className="body-text--smallest gradient-color-txt mb-2">*/}
							{/*        {recurringRevenueValue}*/}
							{/*    </p>*/}
							{/*    <p className="text-p5  !mt-0">Recurring Revenue</p>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>

			</div>
		</div>
	);
};

export default memo(DashboardHome);
