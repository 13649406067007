import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormFieldStacked from '../../../components/commons/FormFieldStacked';

import {
	UPDATES_VISIBILITY,
	UPDATE_AUTHORS_LIST_WITH_DEFAULT,
	UPDATE_COMMENTS_AVAILABILITY,
	UPDATE_PUBLISH_STATUSES,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	ACCESS_TYPES_LIST_WITH_DEFAULT
} from '../../../constants/space';
import { VALIDATE_MESSAGES } from '../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE, IMAGE_VIDEO_FILE_SIZE_LIMIT
} from '../../../constants/file';
import routes from '../../../routes';
import { hideTooltip, showTooltip } from '../../../utils/input';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {createSpaceAddFirstUpdate, getSpaceCategories, updateLastStepLink} from '../../../services/space';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import UploadDragger from '../../../components/commons/UploadDragger';
import TextareaAutosize from 'react-textarea-autosize';

import './PostUpdate.scss';
import { getListIcon } from '../../../utils/list';
import AddNewCategoryModal from '../../../components/commons/Modals/AddNewCategory/AddNewCategoryModal';
import ExplicitContent from '../../../components/commons/Modals/ExplicitContent';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import UploadDraggerV2 from "../../../components/commons/UploadDraggerV2";

const PostUpdate = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createSpaceFirstUpdateForm] = Form.useForm();
	const [initialLoad, setInitialLoad] = useState(true);
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [selectedUpdatesVisibility, setSelectedUpdatesVisibility] = useState('members');
	const [title, setTitle] = useState(null);
	const [publicTitle, setPublicTitle] = useState(null);

	const [publicCoverAsRequired, setPublicCoverAsRequired] = useState(false);
	const [coverImageError, setCoverImageError] = useState(null);
	const [coverVideoError, setCoverVideoError] = useState(null);
	const [publicCoverImageError, setPublicCoverImageError] = useState(null);
	const [publicCoverVideoError, setPublicCoverVideoError] = useState(null);

	const [coverImage, setCoverImage] = useState(null);
	const [coverImageBlobPreview, setCoverImageBlobPreview] = useState(null);
	const [coverVideo, setCoverVideo] = useState(null);
	const [coverVideoBlobPreview, setCoverVideoBlobPreview] = useState(null);
	const [publicCoverImage, setPublicCoverImage] = useState(null);
	const [publicCoverImageBlobPreview, setPublicCoverImageBlobPreview] = useState(null);
	const [publicCoverVideo, setPublicCoverVideo] = useState(null);
	const [publicCoverVideoBlobPreview, setPublicCoverVideoBlobPreview] = useState(null);

	const [coverImageIsRemoved, setCoverImageIsRemoved] = useState(false);
	const [coverVideoIsRemoved, setCoverVideoIsRemoved] = useState(false);
	const [publicCoverImageIsRemoved, setPublicCoverIsRemoved] = useState(false);
	const [publicCoverVideoIsRemoved, setPublicCoverVideoIsRemoved] = useState(false);

	const [content, setContent] = useState('');
	const [previewText, setPreviewText] = useState(null);
	const [publicPreviewText, setPublicPreviewText] = useState(null);
	const [tags, setTags] = useState(null);
	const [selectedAuthor, setSelectedAuthor] = useState('default');
	const [selectedCommentAvailability, setSelectedCommentAvailability] = useState('enabled');
	const [selectedPublishStatus, setSelectedPublishStatus] = useState('published');
	const [isSaveAndGoBack, setIsSaveAndGoBack] = useState(false);
	const [updateId, setUpdateId] = useState(null);

	const titleRef = useRef(null);
	const publicTitleRef = useRef(null);
	const previewTextRef = useRef(null);
	const publicPreviewTextRef = useRef(null);
	const tagsRef = useRef(null);

	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');

	const [modalOpen, setModalOpen] = useState(false);
	const [categoryList, setCategoryList] = useState([]);
	const [filteredCategoryList, setFilteredCategoryList] = useState([
		{ label: 'Create New Category', value: 'create' }
	]);
	const [selectedCategory, setSelectedCategory] = useState('create');
	const [selectedCategoryIDS, setSelectedCategoryIDS] = useState([]);
	const [isUpdateCategory, setUpdateCategory] = useState(true);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);
	const [showPublicFields, setShowPublicFields] = useState(true);

	const [blurredField, setBlurredField] = useState(null);
	const [isSubmitClicked, setSubmitClicked] = useState(false);

	const setCoverImageUploadErrors = (error) => {
		setCoverImageError(error?.message)
	}
	const setPublicCoverImageUploadErrors = (error) => {
		setPublicCoverImageError(error?.message)
	}

	const setCoverVideoUploadErrors = (error) => {
		setCoverVideoError(error?.message)
	}
	const setPublicCoverVideoUploadErrors = (error) => {
		setPublicCoverVideoError(error?.message)
	}

	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
		[{ font: [] }],
		[{ header: 1 }, { header: 2 }], // custom button values
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		['blockquote', 'code-block'],
		['link', 'image', 'video', 'formula'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
		[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
		[{ direction: 'rtl' }], // text direction
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],
		['clean'] // remove formatting button
	];

	const checkPublicFieldsIfPopulated = () => {
		setPublicCoverAsRequired(false);

		if (
			createSpaceFirstUpdateForm.getFieldValue('public_title') ||
			createSpaceFirstUpdateForm.getFieldValue('public_preview_text')
		) {
			setPublicCoverAsRequired(true);
		}
	};

	const handleFormOnBlur = useCallback(async () => {
		await createSpaceFirstUpdateForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});

		checkPublicFieldsIfPopulated();
	}, []);

	const handleFormChanges = useCallback(async () => {
		setTitle(createSpaceFirstUpdateForm.getFieldValue('title'));
		setPublicTitle(createSpaceFirstUpdateForm.getFieldValue('public_title'));
		setPreviewText(createSpaceFirstUpdateForm.getFieldValue('preview_text'));
		setPublicPreviewText(createSpaceFirstUpdateForm.getFieldValue('public_preview_text'));
		setTags(createSpaceFirstUpdateForm.getFieldValue('tags'));

		await createSpaceFirstUpdateForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});

		checkPublicFieldsIfPopulated();
	}, []);

	const handleCoverImageUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
            // if(error){
            //     setCoverImageError('Cover Image is Invalid.');
            // }
			setCoverImageIsRemoved(true);
		} else {
			setCoverImageIsRemoved(false);
            if(!error){
                setCoverImageError(null);
            }
		}

		setCoverImage(file);
		setCoverImageBlobPreview(blobPreview);
	};

	const handleCoverVideoUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
            // if(error){
            //     setCoverVideoError('Cover Video is Invalid.');
            // }
			setCoverVideoIsRemoved(true);
		} else {
			setCoverVideoIsRemoved(false);
            if(!error){
                setCoverVideoError(null);
            }
		}

		setCoverVideo(file);
		setCoverVideoBlobPreview(blobPreview);
	};

	const handlePublicCoverImageUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
            // if(error){
            //     setPublicCoverImageError('Public Cover Image is Invalid.');
            // }
			setPublicCoverIsRemoved(true);
		} else {
			setPublicCoverIsRemoved(false);
            if(!error){
                setPublicCoverImageError(null);
            }
		}

		setPublicCoverImage(file);
		setPublicCoverImageBlobPreview(blobPreview);
	};

	const handlePublicCoverVideoUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
            // if(error){
            //     setPublicCoverVideoError('Public Cover Video is Invalid.');
            // }
			setPublicCoverVideoIsRemoved(true);
		} else {
			setPublicCoverVideoIsRemoved(false);
            if(!error){
                setPublicCoverVideoError(null);
            }
		}

		setPublicCoverVideo(file);
		setPublicCoverVideoBlobPreview(blobPreview);
	};

	const goBackPage = () => {
		const url = routes.createSpaceAddFirstItemPage();
		navigate(url.replace(':space_username', space_username));
	};

	const skipNextPage = () => {
		const url = routes.createCommunityFeatures();
		navigate(url.replace(':space_username', space_username));
	};

	const handleUpdateLastStepLink = useCallback(async (isSkipNextPage) => {
			if (isSkipNextPage) {
				skipNextPage();
			} else {
				const data = {};
				// include last step link data for continuing the space creation
				const url = routes.createSpacePostUpdate();
				data.last_step_link = url.replace(':space_username', space_username);
				try {
					if (authUserInfo) {
						data.user_id = authUserInfo.id;
						data.space_id = spaceInfo.id;
						const response = await updateLastStepLink(data);
						if (response && response.result) {
							goBackPage();
						}
					}
				} catch (error) {
					console.log(error);
				}
			}

		},
		[space_username, authUserInfo, spaceInfo]
	);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setDisplayDropDownError(true);
			setCoverImageError(null);
			setPublicCoverImageError(null);

			let hasError = false;

			if (
				!content ||
				selectedAuthor === 'default' ||
				selectedContentType === 'default' ||
				selectedAccessType === 'default' ||
				selectedCategory === 'create'
			) {
				hasError = true;
			}

			if (!coverImageBlobPreview) {
				setCoverImageError('Cover Image is required.');
				hasError = true;
			}

			// if (
			// 	(values.public_title || values.public_preview_text) &&
			// 	!publicCoverImageBlobPreview
			// ) {
			// 	setPublicCoverImageError('Public Cover Image is required.');
			// 	hasError = true;
			// }

			if (hasError) {
				setFormIsOnFocus(false);
				handleFormOnBlur();
				window.scrollTo(0, 600);
				return;
			}

			const data = {};
			data.visibility = selectedUpdatesVisibility;
			data.title = values.title;
			data.public_title = values.public_title;
			data.preview_text = values.preview_text;
			data.public_preview_text = values.public_preview_text;
			data.tags = values.tags;
			data.author = selectedAuthor;
			data.comments_availability = selectedCommentAvailability;
			data.publish_status = selectedPublishStatus;
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);

			if (content) {
				data.content = content;
			}

			if (coverImage) {
				data.cover_image = coverImage;
			} else {
				if (coverImageIsRemoved) {
					data.cover_image_is_empty = true;
				}
			}

			if (coverVideo) {
				data.cover_video = coverVideo;
			} else {
				if (coverVideoIsRemoved) {
					data.cover_video_is_empty = true;
				}
			}

			if (publicCoverImage) {
				data.public_cover_image = publicCoverImage;
			} else {
				if (publicCoverImageIsRemoved) {
					data.public_cover_image_is_empty = true;
				}
			}

			if (publicCoverVideo) {
				data.public_cover_video = publicCoverVideo;
			} else {
				if (publicCoverVideoIsRemoved) {
					data.public_cover_video_is_empty = true;
				}
			}

			// sample payload for adding category to space update
			data.category_ids = JSON.stringify(selectedCategoryIDS);

			// include last step link data for continuing the space creation
			const url = routes.createCommunityFeatures();
			data.last_step_link = url.replace(':space_username', space_username);

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					if (updateId) {
						data.update_id = updateId;
					}

					setSubmitted(true);
					const response = await createSpaceAddFirstUpdate(data);
					if (response && response.result) {
						dispatch(getSpaceInfo(space_username));

						if (isSaveAndGoBack) {
							const url = routes.createSpaceAddFirstItemPage();
							navigate(url.replace(':space_username', space_username));
						} else {
							const url = routes.createCommunityFeatures();
							navigate(url.replace(':space_username', space_username));
						}
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						// window.scrollTo(0, 0);
					}
					setCoverImageIsRemoved(false);
					setCoverVideoIsRemoved(false);
					setPublicCoverIsRemoved(false);
					setPublicCoverVideoIsRemoved(false);
					setSubmitted(false);
					setDisplayDropDownError(false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[
			space_username,
			authUserInfo,
			spaceInfo,
			updateId,
			selectedUpdatesVisibility,
			content,
			coverImage,
			coverVideo,
			publicCoverImage,
			publicCoverVideo,
			coverImageIsRemoved,
			coverVideoIsRemoved,
			publicCoverImageIsRemoved,
			publicCoverVideoIsRemoved,
			selectedAuthor,
			selectedCommentAvailability,
			selectedPublishStatus,
			isSaveAndGoBack,
			selectedContentType,
			selectedAccessType,
			selectedExplicitAccess,
			selectedExplicitContent,
			selectedCategory,
			selectedCategoryIDS,
			coverImageBlobPreview,
			publicCoverImageBlobPreview
		]
	);

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
		if (createdSpace.first_space_update) {
			const firstSpaceUpdate = createdSpace.first_space_update;
			setUpdateId(firstSpaceUpdate.id);
			createSpaceFirstUpdateForm.setFieldsValue({
				title: firstSpaceUpdate.title,
				public_title: firstSpaceUpdate.public_title,
				preview_text: firstSpaceUpdate.preview_text,
				public_preview_text: firstSpaceUpdate.public_preview_text,
				tags: firstSpaceUpdate.tags,
				content: firstSpaceUpdate.content
			});
			setTitle(firstSpaceUpdate.title);
			setPublicTitle(firstSpaceUpdate.public_title);
			setContent(firstSpaceUpdate.content);
			setPreviewText(firstSpaceUpdate.preview_text);
			setPublicPreviewText(firstSpaceUpdate.public_preview_text);
			setTags(firstSpaceUpdate.tags);
			setSelectedUpdatesVisibility(firstSpaceUpdate.visibility);
			setSelectedAuthor(firstSpaceUpdate.author);
			setSelectedCommentAvailability(firstSpaceUpdate.comments_availability);

			setSelectedExplicitContent(JSON.parse(firstSpaceUpdate.explicit_content));
			setSelectedExplicitAccess(JSON.parse(firstSpaceUpdate.explicit_access));

			let publishStatus = firstSpaceUpdate.publish_status;
			if (firstSpaceUpdate.publish_status === 'immediately') {
				publishStatus = 'published';
			}
			setSelectedPublishStatus(publishStatus);

			setSelectedContentType(firstSpaceUpdate.content_type);
			setSelectedAccessType(firstSpaceUpdate.content_access);
			if (firstSpaceUpdate.cover_photo) {
				setCoverImageBlobPreview(firstSpaceUpdate.cover_photo);
			}
			if (firstSpaceUpdate.cover_video) {
				setCoverVideoBlobPreview(firstSpaceUpdate.cover_video);
			}
			if (firstSpaceUpdate.public_cover_photo) {
				setPublicCoverImageBlobPreview(firstSpaceUpdate.public_cover_photo);
			}
			if (firstSpaceUpdate.public_cover_video) {
				setPublicCoverVideoBlobPreview(firstSpaceUpdate.public_cover_video);
			}

			if (firstSpaceUpdate.categories && firstSpaceUpdate.categories.length > 0) {
			// 	const selectedIds = [];
			// 	firstSpaceUpdate.categories.map((category, index) => {
			// 		selectedIds.push(category.id);
			// 		setSelectedCategory(category.slug);
			// 	});
			// 	setSelectedCategoryIDS(selectedIds);
				setSelectedCategoryIDS([firstSpaceUpdate.categories[0].id]);
				setSelectedCategory(firstSpaceUpdate.categories[0].slug);
			}

			await createSpaceFirstUpdateForm
				.validateFields()
				.then(() => {
					setFormHasError(false);
				})
				.catch((errors) => {
					setFormHasError(true);
				});

			checkPublicFieldsIfPopulated();
		}
	};

	const selectUpdateVisibility = (value) => {
		setSelectedUpdatesVisibility(value);

		if (value === 'members') {
			setShowPublicFields(true);
		} else {
			setShowPublicFields(false);
		}
	};

	const filterDropdownChange = useCallback(
		async (value) => {
			setSelectedCategory(value);
			if (value === 'create') {
				setModalOpen(true);
			} else {
				if (categoryList != null && categoryList.length > 0) {
					const selectedCategory = categoryList.find((category) => category.slug === value);
					if (selectedCategory) {
						setSelectedCategoryIDS([selectedCategory.id]);
					}
					// TODO: once multi categories is implemented use code below
					// const isIdExist = selectedCategoryIDS.some((id) => id === selectedCategory.id);
					// if (!isIdExist) {
					// 	selectedCategoryIDS.push(selectedCategory.id);
					// 	setSelectedCategoryIDS(selectedCategoryIDS);
					// }
				}
			}
		},
		[modalOpen, selectedCategory, selectedCategoryIDS, categoryList]
	);


	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	const handleFinishFailed = useCallback(async (errorInfo) => {
		setSubmitClicked(true);
		// Set the blurred field to the first field with an error
		if (errorInfo.errorFields.length > 0) {
			setBlurredField('all');
		}
	}, [blurredField, isSubmitClicked]);

	const handleOnblurField = useCallback(async (field) => {
		if (!isSubmitClicked) {
			setBlurredField(field);
		}
		setSubmitClicked(false);
	}, [blurredField, isSubmitClicked]);

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				if (!updateId) {
					setFormSpaceData(spaceInfo).then((r) => {});
					// getSpaceCategories({ space_id: spaceInfo.id }).then((r) => {});
				}
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		if (spaceInfo && spaceInfo.id && isUpdateCategory) {
			setUpdateCategory(false);
			getSpaceCategories({ space_id: spaceInfo.id }).then((response) => {
				if (response && response.result) {
					const categories = response.categories;
					setCategoryList(categories);
					if (categories && categories.length > 0) {
						// Get the selected category ID
						const matchCategory = categories.find((category) => category.slug === selectedCategory);
						if (matchCategory) {
							setSelectedCategoryIDS([matchCategory.id]);
						}
						// Filter the categories for the dropdown component
						const filteredCategories = categories.reduce((acc, category) => {
							if (!acc.some(({label}) => label === category.name)) {
								acc.push({label: category.name, value: category.slug});
							}
							return acc;
						}, []);
						filteredCategories.unshift( { label: 'Create New Category', value: 'create' })
						setFilteredCategoryList(filteredCategories);
					}
				}
			});
		}


		document.title = 'Commonspace Spaces';
	}, [
		authUserInfo,
		spaceInfo,
		spaceInfoLoading,
		space_username,
		initialLoad,
		modalOpen,
		filteredCategoryList,
		selectedCategory,
		isUpdateCategory,
		updateId,
		selectedCategoryIDS
	]);

	useEffect(()=>{
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},[])

	useEffect(() => {}, [coverImageError, publicCoverImageError, publicCoverAsRequired, categoryList]);

	return (
		<>
			<main id="cs-platform-main" className="cs-main main-theme">
				<section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<div className="block-full">
							<div className="cs-stack-heading">
								<div className="stack-row split">
									<h1>Post Your First Update</h1>
									<h3>Step 2 of 5</h3>
								</div>
								<p>{getSitewideLink('updates_first', ' ')} are blog style posts which you
									can use to share news, information, or anything else you like with
									your community. Access to each Update can be set to Public, Members
									Only, or to Members with a specific Membership or Item.{' '}
									{getSitewideLink('learn_more_updates', ' ')}.
								</p>
							</div>
							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>Create a Members Only Update</h2>
										<p>Optional</p>
									</div>
									{getSitewideLink('view_guide_post_update', 'button-secondary-gradient', true)}
								</div>
								<p>
									Let’s create your first Update for Members Only. This post can
									introduce new Members to the community and share some of what the
									community site is for.{' '}
									<a href="#">
										If you want to skip this step, scroll to the bottom of the page.
									</a>{' '}
									All posts can be edited at any time, or unpublished if needed. For
									Updates that are not Public, you can provide a publicly viewable
									alternate title and preview details for non-members to see. This
									will also improve your{' '}
									{getSitewideLink('seo_traffic')}.
								</p>
								{alert && (
									<div className="alert callout warning items-center">
										<div className="action">
											<i className="fa-solid fa-triangle-exclamation"></i>
										</div>
										<div className="content">
											<div>
												<h6>{alert.message}</h6>
											</div>
										</div>
									</div>
								)}
								<Form
									form={createSpaceFirstUpdateForm}
									name="createSpaceFirstUpdateForm"
									id="createSpaceFirstUpdateForm"
									validateMessages={VALIDATE_MESSAGES}
									onFocus={() => {
										setFormIsOnFocus(true);
									}}
									onBlur={() => {
										// setTimeout(() => {
										setFormIsOnFocus(false);
										handleFormOnBlur().then(r => {});
										// }, 1000);
									}}
									onChange={handleFormChanges}
									onFinish={handleSubmit}
									onFinishFailed={handleFinishFailed}
									className={'cs-stack-form-guided'}
								>
									<div className="formrow title">
										<div>
											<h3>Basics</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												The Update Post Basics set the title, details, and access
												settings for your post.
											</p>
										</div>
									</div>

									<div className="formrow">
										<DropdownWithItems
											isDisable={true}
											title="Update Visibility"
											selectedValue={selectedUpdatesVisibility}
											listItems={UPDATES_VISIBILITY}
											onDropdownChange={(value) => selectUpdateVisibility(value)}
											//remove customButtonClass when implementing more visibility options
											customButtonClass="button-dropdown-gradient-alt secondary border-2 pointer-events-none"
										/>
										<div className="cs-formrow-helper-none">
											<p>
												This is where you set the Visibility of the post to Public,
												Members Only (any membership), or Custom Access. For this guided
												step, it is set to Members Only.
											</p>
										</div>
									</div>
									<Form.Item
										name="title"
                                        label="Title"
										rules={[
											{required: true},
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Title</label>
												<input id="text" name="text" type="text"
													   onBlur={()=>handleOnblurField('title')}
													   value={title}
													   placeholder="Add Title. Up to 32 characters, including spaces"/>
												{ ((blurredField === 'title' || blurredField === 'all') && createSpaceFirstUpdateForm.getFieldsError()[0]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceFirstUpdateForm.getFieldsError()[0]?.errors}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													This is the Title of the post that will be seen by Members Only.
													If the Visibility of the post was set to Custom Access, then
													this is what the Members of the custom group would see.
												</p>
											</div>
										</div>
									</Form.Item>

									{showPublicFields && (
										<Form.Item
											name="public_title"
                                            label="Public Title"
											rules={[
												// {required: true},
												// {
												// 	max: 32,
												// 	message: 'Must not exceed 32 characters.'
												// }
											]}
										>
											<div className="formrow">
												<div className="form-input air">
													<label htmlFor="text">Public Title</label>
													<input id="text" name="text" type="text"
														   onBlur={()=>handleOnblurField('public_title')}
														   value={publicTitle} maxLength={32}
														   placeholder="Add alternate title for public viewers (optional) up to 32 characters, including spaces."/>
													{ ((blurredField === 'public_title' || blurredField === 'all') && createSpaceFirstUpdateForm.getFieldsError()[1]?.errors.length > 0) && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{createSpaceFirstUpdateForm.getFieldsError()[1]?.errors}</h6>
																</div>
															</div>

														</div>
													)}
												</div>
												<div className="cs-formrow-helper-end">
													<p>
														This is the Public Title which everyone else will see. It is
														also what will be seen by search engines.{' '}
														{getSitewideLink('if_leave_blank')}
													</p>
												</div>
											</div>
										</Form.Item>
									)}

									<div className="formrow media">
										<div className="cs-stack-form-media stack-row">
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setCoverImageError}
													cropperAspectRatio={16 / 9}
													hideDragIcon={true}
													hideText={true}
													uploadFileTypes={IMAGE_FILETYPES.toString()}
													uploadedFilePreview={coverImageBlobPreview}
													onUploadFileChange={handleCoverImageUploadFileChange}
													minHeight={IMAGE_STANDARD_MIN_HEIGHT}
													minWidth={IMAGE_STANDARD_MIN_WIDTH}
													maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
													maxWidth={IMAGE_STANDARD_MAX_WIDTH}
													uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
													// uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
													disableCropper={true}
													shape='square'
												/>
												<p className="cs-label">Cover Image*</p>
												<p className="cs-helper">Up to 3840px square or wide. JPEG, PNG, or GIF</p>
                                                {coverImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{coverImageError}</h6>
                                                            </div>  
                                                        </div>                                                        
                                                    </div>
                                                )}
											</div>
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setCoverVideoError}
													hideDragIcon={true}
													hideText={true}
													uploadFileTypes={VIDEO_FILETYPES.toString()}
													uploadedFilePreview={coverVideoBlobPreview}
													onUploadFileChange={handleCoverVideoUploadFileChange}
													minHeight={VIDEO_STANDARD_MIN_HEIGHT}
													minWidth={VIDEO_STANDARD_MIN_WIDTH}
													maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
													maxWidth={VIDEO_STANDARD_MAX_WIDTH}
													uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
													// uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
													shape='square'
												/>
												<p className="cs-label">Cover Video</p>
												<p className="cs-helper">Up to 1920px/1080px, square or wide. MOV or MP4</p>
                                                {coverVideoError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{coverVideoError}</h6>
                                                            </div>  
                                                        </div>                                                        
                                                    </div>
                                                )}
											</div>
										</div>
										<div className="cs-formrow-helper-split">
											<p>
												The Cover Image (up to 8MB) for your Update is used for the
												preview image of your Update when browsing, and on Pro Spaces it
												is the cover image on the full post page.
											</p>
											<p>
												Optional. On supported devices, the Cover Video (up to 16MB) is
												displayed in place of the Cover Image.
											</p>
										</div>
									</div>

									<div className="formrow media">
										<div className="cs-stack-form-media stack-row">
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setPublicCoverImageError}
													cropperAspectRatio={16 / 9}
													hideDragIcon={true}
													hideText={true}
													uploadFileTypes={IMAGE_FILETYPES.toString()}
													uploadedFilePreview={publicCoverImageBlobPreview}
													onUploadFileChange={handlePublicCoverImageUploadFileChange}
													minHeight={IMAGE_LARGE_MIN_HEIGHT}
													minWidth={IMAGE_LARGE_MIN_WIDTH}
													maxHeight={IMAGE_LARGE_MAX_HEIGHT}
													maxWidth={IMAGE_LARGE_MAX_WIDTH}
													uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
													// uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
													disableCropper={true}
													shape='square'
												/>
												<p className="cs-label">Public Cover Image{publicCoverAsRequired ? '*' : ''}</p>
												<p className="cs-helper">Up to 3840px square or wide. JPEG, PNG, or GIF</p>
                                                {publicCoverImageError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{publicCoverImageError}</h6>
                                                            </div>  
                                                        </div>                                                        
                                                    </div>
                                                )}
											</div>
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setPublicCoverVideoError}
													hideDragIcon={true}
													hideText={true}
													uploadFileTypes={VIDEO_FILETYPES.toString()}
													uploadedFilePreview={publicCoverVideoBlobPreview}
													onUploadFileChange={handlePublicCoverVideoUploadFileChange}
													minHeight={VIDEO_STANDARD_MIN_HEIGHT}
													minWidth={VIDEO_STANDARD_MIN_WIDTH}
													maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
													maxWidth={VIDEO_STANDARD_MAX_WIDTH}
													uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
													// uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
													shape='square'
												/>
												<p className="cs-label">Public Cover Video</p>
												<p className="cs-helper">Up to 1920px/1080px, square or wide. MOV or MP4</p>
                                                {publicCoverVideoError && (
                                                    <div className="alert callout warning items-center">
                                                        <div className="action">
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                        </div>
                                                        <div className="content">
                                                            <div>
                                                                <h6>{publicCoverVideoError}</h6>
                                                            </div>  
                                                        </div>                                                        
                                                    </div>
                                                )}
											</div>
										</div>
										<div className="cs-formrow-helper-split">
											<p>
												The Public Cover Image (up to 8MB) is what everyone without
												access will see instead of the Cover Image in previews and when
												browsing. {getSitewideLink('required_public_title')}
											</p>
											<p>
												Optional. On supported devices, the Public Cover Video (up to
												16MB) is displayed in place of the Public Cover Image.
											</p>
										</div>
									</div>
									<Form.Item
										name="preview_text"
                                        label="Preview Text"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 255,
												message: 'Must not exceed 255 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="preview_text">Preview Text</label>
												<textarea id="preview_text"
														  onBlur={()=>handleOnblurField('preview_text')}
														  name="preview_text" placeholder="Up to 255 characters, including spaces." rows="3" value={previewText}/>
												{ ((blurredField === 'preview_text' || blurredField === 'all') && createSpaceFirstUpdateForm.getFieldsError()[2]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceFirstUpdateForm.getFieldsError()[2]?.errors}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div>
												<p>
													This is the Preview Text for the post that will be seen by
													Members Only when browsing. If the Visibility of the post was
													set to Custom Access, then this is what the Members of the
													custom group would see.
												</p>
											</div>
										</div>
									</Form.Item>
									{showPublicFields && (
										<Form.Item
											name="public_preview_text"
                                            label="Public Preview Text"
											validateTrigger="onBlur"
											rules={[
												// {required: true},
												// {
												// 	max: 255,
												// 	message: 'Must not exceed 255 characters.'
												// }
											]}
										>
											<div className="formrow">
												<div className="form-textarea air">
													<label htmlFor="shortdesc">Public Preview Text</label>
													<textarea id="shortdesc" name="shortdesc"
															  onBlur={()=>handleOnblurField('public_preview_text')}
															  placeholder="Up to 255 characters, including spaces." maxLength={255} rows="3" value={publicPreviewText}/>
													{ ((blurredField === 'public_preview_text' || blurredField === 'all') && createSpaceFirstUpdateForm.getFieldsError()[3]?.errors.length > 0) && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{createSpaceFirstUpdateForm.getFieldsError()[3]?.errors}</h6>
																</div>
															</div>

														</div>
													)}
												</div>
												<div>
													<p>
														This is the Public Preview Text which everyone else will
														see. It is also what will be seen by search engines as the
														preview text in search results.{' '}
														{getSitewideLink('required_public_title')}
													</p>
												</div>
											</div>
										</Form.Item>
									)}

									<div className="formrow title">
										<div>
											<h3>Content</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												This is the main content of the post viewable on the full Update
												post page by Members Only. If the Visibility of the post was set
												to Custom Access, then only the Members of the specified custom
												group would be able to see it.
											</p>
										</div>
									</div>

									<Form.Item
										name="content"
                                        label="Content"
										validateTrigger="onBlur"
										rules={[
											{
												required: true,
												message: 'Content is Required.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<ReactQuill
													theme="snow"
													modules={{
														toolbar: toolbarOptions
													}}
													value={content}
													onBlur={()=>handleOnblurField('content')}
													onChange={(e) => {
														if ('<p><br></p>' === e) {
															createSpaceFirstUpdateForm.setFieldValue(
																'content',
																''
															);
															setContent(null);
														} else {
															createSpaceFirstUpdateForm.setFieldValue('content', e);
															setContent(e);
														}
													}}
												/>
												{ ((blurredField === 'content' || blurredField === 'all') && createSpaceFirstUpdateForm.getFieldsError()[4]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceFirstUpdateForm.getFieldsError()[4]?.errors}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div>
												<p>
													You can style your content, add images, videos, and more.
												</p>
											</div>
										</div>
									</Form.Item>

									<div className="formrow">
										<div className="form-input air">
											<label>Category</label>
											{spaceInfo != null && authUserInfo != null && (
												<AddNewCategoryModal
													modalOpen={modalOpen}
													setModalOpen={setModalOpen}
													filteredCategoryList={filteredCategoryList}
													setSelectedCategory={setSelectedCategory}
													spaceID={spaceInfo.id}
													userID={authUserInfo.id}
													setUpdateCategory={setUpdateCategory}
												/>
											)}

											<DropdownWithItems
												title="Category"
												displayError={
													displayDropDownError && selectedCategory === 'create'
												}
												selectedValue={selectedCategory}
												listItems={filteredCategoryList}
												onDropdownChange={(value) => filterDropdownChange(value)}
											/>
										</div>
										<div className="cs-formrow-helper-none">
										</div>
									</div>

									<Form.Item
										name="tags"
                                        label="Tags"
										validateTrigger="onBlur"
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Tags</label>
												<input id="text"
													   onBlur={()=>handleOnblurField('tags')}
													   name="text" type="text"
													   placeholder="Enter each tag, separated by a comma." value={tags}/>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													Optional. You can add tags to your post. This is used for
													searching and for relating similar posts to each other. You can
													add as many as you like, with each tag separated by a comma.
												</p>
											</div>
										</div>
									</Form.Item>

									<div className="formrow">
										<div className="cs-stack-form-inputs stack-row">
											<div>
												<DropdownWithItems
													title="Select Content Type"
													displayError={
														displayDropDownError && selectedContentType === 'default'
													}
													icon={getListIcon(
														CONTENT_TYPES_LIST_WITH_DEFAULT,
														selectedContentType
													)}
													selectedValue={selectedContentType}
													listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
													onDropdownChange={(value) => openContentTypeModal(value)}
												/>
												<ExplicitContent
													modalOpen={modalOpenExplicitContent}
													setModalOpen={setModalOpenExplicitContent}
													selectedItems={selectedExplicitContent}
													isExplicitContent={true}
													onSelectedItems={(value) => setSelectedExplicitContent(value)}
												/>
											</div>
											<div>
												<DropdownWithItems
													title="Select Access Type"
													displayError={
														displayDropDownError && selectedAccessType === 'default'
													}
													icon={getListIcon(
														ACCESS_TYPES_LIST_WITH_DEFAULT,
														selectedAccessType
													)}
													selectedValue={selectedAccessType}
													listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
													onDropdownChange={(value) => openAccessTypeModal(value)}
												/>
												<ExplicitContent
													modalOpen={modalOpenExplicitAccess}
													setModalOpen={setModalOpenExplicitAccess}
													selectedItems={selectedExplicitAccess}
													isExplicitContent={false}
													onSelectedItems={(value) => setSelectedExplicitAccess(value)}
												/>
											</div>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												Change the Content setting if your Details, Image, or Video
												contains explicit or sensitive content. Change the Access
												setting if your Space will provide access to explicit or
												sensitive content. {getSitewideLink('view_content_guidelines_post_update')}
											</p>
										</div>
									</div>

									<div className="formrow title">
										<div>
											<h3>Settings</h3>
										</div>
										<div className="cs-formrow-helper-none">
											<p>
												Specify the category, tags, and author for your post here.
											</p>
										</div>
									</div>

									<div className="formrow">
										<DropdownWithItems
											title="Authors"
											displayError={
												displayDropDownError && selectedAuthor === 'default'
											}
											selectedValue={selectedAuthor}
											listItems={UPDATE_AUTHORS_LIST_WITH_DEFAULT}
											onDropdownChange={(value) => setSelectedAuthor(value)}
										/>
										<div className="cs-formrow-helper-none">
											<p>
												Choose an Author for the post for attribution. This can be the
												Space itself, you, or a person on your team (once you give them
												access).
											</p>
										</div>
									</div>

									<div className="formrow">
										<DropdownWithItems
											title="Comments"
											selectedValue={selectedCommentAvailability}
											listItems={UPDATE_COMMENTS_AVAILABILITY}
											onDropdownChange={(value) =>
												setSelectedCommentAvailability(value)
											}
										/>
										<div className="cs-formrow-helper-none">
											<p>
												Enable or disable Commenting on the post by Members. Regardless
												of the Visibility setting, comments can only be posted by a
												logged in Member of your Space. The default setting is “Enable
												Comments”.
											</p>
										</div>
									</div>

									<div className="formrow">
										<DropdownWithItems
											title="Publish Status"
											selectedValue={selectedPublishStatus}
											listItems={UPDATE_PUBLISH_STATUSES}
											onDropdownChange={(value) => setSelectedPublishStatus(value)}
										/>
										<div className="cs-formrow-helper-none">
											<p>
												Select the Publish Status of this Update Post. You can choose
												Publish Immediately, Schedule Publishing, or Unpublished Draft.
												You can change this at any time.
											</p>
										</div>
									</div>

									<Form.Item>
										<div className="formrow">
											<Button
												htmlType="submit"
												className="primary-gradient"
												onClick={() => setIsSaveAndGoBack(false)}
												disabled={submitted}
											>
													<span>
														<p>
															{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
															Save & Continue
														</p>
													</span>
											</Button>
											<Button
												htmlType="submit"
												className="primary-gradient"
												onClick={() => setIsSaveAndGoBack(true)}
												disabled={submitted || formHasError}
											>
												<span>
													<p>
														{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
														Save & Go Back
													</p>
												</span>
											</Button>
										</div>
									</Form.Item>

									<Form.Item>
										<div className="formrow">
											<Button
												htmlType="submit"
												className="secondary-gradient"
												onClick={()=>{handleUpdateLastStepLink(true).then(r => {})}}
											>
												<span>
													<p>
														Skip Without Saving
													</p>
												</span>
											</Button>
											<Button
												htmlType="submit"
												className="secondary-gradient"
												onClick={()=>{handleUpdateLastStepLink(false).then(r => {})}}
											>
												<span>
													<p>
														Go Back Without Saving
													</p>
												</span>
											</Button>
										</div>
									</Form.Item>
								</Form>
							</div>
						</div>
					</div>
				</section>
			</main>

			{/*<main id="member-profile" className="flex">*/}
			{/*	<div className="container !py-20">*/}
			{/*		<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*			<div className="col-span-12 md:col-span-6 flex items-center">*/}
			{/*				<h1 className="mb-4 mt-8 header-large">Post Your First Update</h1>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12 md:col-span-6 flex items-center justify-center md:justify-end">*/}
			{/*				<p className="body-txtitalic--large mt-8">Step 2 of 5</p>*/}
			{/*			</div>*/}
			{/*			<div className="col-span-12">*/}
			{/*				<p className="body-text--reg">*/}
			{/*					/!*<Link to="#" className="gradient-color-txt">*!/*/}
			{/*					/!*	Updates*!/*/}
			{/*					/!*</Link>*!/*/}
			{/*					{getSitewideLink('updates_first')} are blog style posts which you*/}
			{/*					can use to share news, information, or anything else you like with*/}
			{/*					your community. Access to each Update can be set to Public, Members*/}
			{/*					Only, or to Members with a specific Membership or Item.{' '}*/}
			{/*					/!*<Link to="#" className="gradient-color-txt">*!/*/}
			{/*					/!*	Learn more about Updates and how they work.*!/*/}
			{/*					/!*</Link>*!/*/}
			{/*					{getSitewideLink('learn_more_updates')}.*/}
			{/*				</p>*/}
			{/*			</div>*/}
			{/*		</div>*/}

			{/*		<div className="mt-8">*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8">*/}
			{/*				<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">*/}
			{/*					<h5 className="header-medium">Create a Members Only Update</h5>*/}
			{/*					<span className="body-text--reg">Optional</span>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 flex justify-center items-center !order-first md:!order-last ">*/}
			{/*					/!*<button type="submit" className="btn btn-lg btn-secondary">*!/*/}
			{/*					/!*	View Guide*!/*/}
			{/*					/!*	<i className="fa-light fa-arrow-up-right-from-square ms-2"></i>*!/*/}
			{/*					/!*</button>*!/*/}
			{/*					{getSitewideLink('view_guide_post_update', 'btn btn-lg btn-secondary')}*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="w-full">*/}
			{/*				<p className="body-text--small mt-8">*/}
			{/*					Let’s create your first Update for Members Only. This post can*/}
			{/*					introduce new Members to the community and share some of what the*/}
			{/*					community site is for.{' '}*/}
			{/*					<Link to="#" className="gradient-color-txt">*/}
			{/*						If you want to skip this step, scroll to the bottom of the page.*/}
			{/*					</Link>{' '}*/}
			{/*					All posts can be edited at any time, or unpublished if needed. For*/}
			{/*					Updates that are not Public, you can provide a publicly viewable*/}
			{/*					alternate title and preview details for non-members to see. This*/}
			{/*					will also improve your{' '}*/}
			{/*					/!*<Link to="#" className="gradient-color-txt">*!/*/}
			{/*					/!*	SEO and search engine traffic.*!/*/}
			{/*					/!*</Link>*!/*/}
			{/*					{getSitewideLink('seo_traffic')}.*/}
			{/*				</p>*/}
			{/*			</div>*/}
			{/*		</div>*/}

			{/*		<Form*/}
			{/*			form={createSpaceFirstUpdateForm}*/}
			{/*			name="createSpaceFirstUpdateForm"*/}
			{/*			id="createSpaceFirstUpdateForm"*/}
			{/*			validateMessages={VALIDATE_MESSAGES}*/}
			{/*			onFocus={() => {*/}
			{/*				setFormIsOnFocus(true);*/}
			{/*			}}*/}
			{/*			onBlur={() => {*/}
			{/*				setTimeout(() => {*/}
			{/*					setFormIsOnFocus(false);*/}
			{/*					handleFormOnBlur();*/}
			{/*				}, 200);*/}
			{/*			}}*/}
			{/*			onChange={handleFormChanges}*/}
			{/*			onFinish={handleSubmit}*/}
			{/*			className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-23'}*/}
			{/*		>*/}
			{/*			<>*/}
			{/*				{alert && (*/}
			{/*					<div className="grid grid-cols-12 mt-8">*/}
			{/*						<div className="col-span-8">*/}
			{/*							<Alert*/}
			{/*								key={alert.variant}*/}
			{/*								variant={alert.variant}*/}
			{/*								className={*/}
			{/*									'custom-alert ' +*/}
			{/*									(alert.variant === 'danger'*/}
			{/*										? 'custom-alert-danger'*/}
			{/*										: 'custom-alert-success')*/}
			{/*								}*/}
			{/*							>*/}
			{/*								{alert.message}*/}
			{/*							</Alert>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				)}*/}
			{/*			</>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">*/}
			{/*				<div className="md:col-span-8 flex items-center !order-last md:!order-first">*/}
			{/*					<h6 className="header-small">Basics</h6>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 flex items-center !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg">*/}
			{/*						The Update Post Basics set the title, details, and access*/}
			{/*						settings for your post.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*				<div className="md:col-span-8 !order-last md:!order-first">*/}
			{/*					<DropdownWithItems*/}
			{/*						isDisable={true}*/}
			{/*						title="Update Visibility"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="btn-lg btn-secondary"*/}
			{/*						selectedValue={selectedUpdatesVisibility}*/}
			{/*						listItems={UPDATES_VISIBILITY}*/}
			{/*						onDropdownChange={(value) => selectUpdateVisibility(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						This is where you set the Visibility of the post to Public,*/}
			{/*						Members Only (any membership), or Custom Access. For this guided*/}
			{/*						step, it is set to Members Only.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*			<div className="md:col-span-8 my-auto !order-last md:!order-first">*/}
			{/*					<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">*/}
			{/*						Title**/}
			{/*					</label>*/}
			{/*					<div className="relative mt-2 rounded-md ">*/}
			{/*						<Form.Item*/}
			{/*							label="Title"*/}
			{/*							name="title"*/}
			{/*							className="mb-0 hidden-label"*/}
			{/*							validateTrigger="onBlur"*/}
			{/*							rules={[{ required: true }]}*/}
			{/*						>*/}
			{/*							*/}
			{/*								<FormFieldStacked*/}
			{/*								formType='input'*/}
			{/*								type="text"	*/}
			{/*								placeholder="Add Title. Up to 32 characters, including spaces"*/}
			{/*								disabled={submitted}*/}
			{/*								ref={titleRef}*/}
			{/*								></FormFieldStacked>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*					*/}
			{/*				</div>	*/}
			{/*				*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						This is the Title of the post that will be seen by Members Only.*/}
			{/*						If the Visibility of the post was set to Custom Access, then*/}
			{/*						this is what the Members of the custom group would see.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			{showPublicFields && (*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*					<div className="md:col-span-8 my-auto !order-last md:!order-first">*/}
			{/*					<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">*/}
			{/*						Public Title*/}
			{/*					</label>*/}
			{/*					<div className="relative mt-2 rounded-md ">*/}
			{/*					<Form.Item*/}
			{/*								label="Public Title"*/}
			{/*								name="public_title"*/}
			{/*								className="mb-0 hidden-label"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*							>*/}
			{/*							*/}
			{/*							<FormFieldStacked*/}
			{/*							formType='input'*/}
			{/*							type="text"	*/}
			{/*							placeholder="Add alternate title for public viewers (optional) up to 32 characters, including spaces."*/}
			{/*							disabled={submitted}*/}
			{/*							ref={publicTitleRef}*/}
			{/*							></FormFieldStacked>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*					*/}
			{/*				</div>	*/}
			{/*					*/}
			{/*					<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*						<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*							This is the Public Title which everyone else will see. It is*/}
			{/*							also what will be seen by search engines.{' '}*/}
			{/*							/!*<a className="gradient-color-txt">*!/*/}
			{/*							/!*	If you leave this blank, then the Update will not be visible*!/*/}
			{/*							/!*	as a preview to anyone else and will not be indexed by*!/*/}
			{/*							/!*	search engines.*!/*/}
			{/*							/!*</a>*!/*/}
			{/*							{getSitewideLink('if_leave_blank')}*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			)}*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">*/}
			{/*				<div className="md:hidden">*/}
			{/*					<p className="body-txtitalic--smallest color-reg">*/}
			{/*						The Cover Image (up to 8MB) for your Update is used for the*/}
			{/*						preview image of your Update when browsing, and on Pro Spaces it*/}
			{/*						is the cover image on the full post page.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 my-auto">*/}
			{/*					<>*/}
			{/*						{coverImageError && coverImageIsRemoved && (*/}
			{/*							<div className="">*/}
			{/*								<Alert*/}
			{/*									// key={alert.variant}*/}
			{/*									// variant={alert.variant}*/}
			{/*									className='custom-alert custom-alert-danger cover-image-error'*/}
			{/*								>*/}
			{/*									{coverImageError}*/}
			{/*								</Alert>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</>*/}
			{/*					<div className="upload-rec">*/}
			{/*						<UploadDragger*/}
			{/*							//passedError={coverImageError}*/}
			{/*							cropperAspectRatio={16 / 9}*/}
			{/*							hideDragIcon="true"*/}
			{/*							hideText="true"*/}
			{/*							uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
			{/*							uploadedFilePreview={coverImageBlobPreview}*/}
			{/*							onUploadFileChange={handleCoverImageUploadFileChange}*/}
			{/*							minHeight={IMAGE_STANDARD_MIN_HEIGHT}*/}
			{/*							minWidth={IMAGE_STANDARD_MIN_WIDTH}*/}
			{/*							maxHeight={IMAGE_STANDARD_MAX_HEIGHT}*/}
			{/*							maxWidth={IMAGE_STANDARD_MAX_WIDTH}*/}
			{/*							uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*							disableCropper={true}*/}
			{/*						/>*/}
			{/*						<i className="fa-regular fa-plus"></i>*/}
			{/*					</div>*/}
			{/*					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*						Cover Image**/}
			{/*					</p>*/}
			{/*					<p className="text-center body-txtitalic--smallest">*/}
			{/*						Up to 3840px square or wide. JPEG, PNG, or GIF*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="md:hidden">*/}
			{/*					<p className="mt-8 body-txtitalic--smallest color-reg">*/}
			{/*						Optional. On supported devices, the Cover Video (up to 16MB) is*/}
			{/*						displayed in place of the Cover Image.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 my-auto">*/}
			{/*					<div className="upload-rec">*/}
			{/*						<UploadDragger*/}
			{/*							hideDragIcon="true"*/}
			{/*							hideText="true"*/}
			{/*							uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
			{/*							uploadedFilePreview={coverVideoBlobPreview}*/}
			{/*							onUploadFileChange={handleCoverVideoUploadFileChange}*/}
			{/*							minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
			{/*							minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
			{/*							maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
			{/*							maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
			{/*							uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*						/>*/}
			{/*						<i className="fa-regular fa-plus"></i>*/}
			{/*					</div>*/}
			{/*					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*						Cover Video*/}
			{/*					</p>*/}
			{/*					<p className="text-center body-txtitalic--smallest">*/}
			{/*						Up to 1920px/1080px, square or wide. MOV or MP4*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="hidden md:block md:col-span-4 my-auto">*/}
			{/*					<p className="body-txtitalic--smallest color-reg">*/}
			{/*						The Cover Image (up to 8MB) for your Update is used for the*/}
			{/*						preview image of your Update when browsing, and on Pro Spaces it*/}
			{/*						is the cover image on the full post page.*/}
			{/*					</p>*/}
			{/*					<p className="mt-8 body-txtitalic--smallest color-reg">*/}
			{/*						Optional. On supported devices, the Cover Video (up to 16MB) is*/}
			{/*						displayed in place of the Cover Image.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">*/}
			{/*				<div className="md:hidden">*/}
			{/*					<p className="body-txtitalic--smallest color-reg">*/}
			{/*						The Public Cover Image (up to 8MB) is what everyone without*/}
			{/*						access will see instead of the Cover Image in previews and when*/}
			{/*						browsing. /!*<a className="gradient-color-txt">*!/*/}
			{/*						/!*	{' '}*!/*/}
			{/*						/!*	This is required if you have added a Public Title.*!/*/}
			{/*						/!*</a>*!/*/}
			{/*						{getSitewideLink('required_public_title')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 my-auto">*/}
			{/*					<div className="upload-rec">*/}
			{/*						<UploadDragger*/}
			{/*							passedError={publicCoverImageError}*/}
			{/*							cropperAspectRatio={16 / 9}*/}
			{/*							hideDragIcon="true"*/}
			{/*							hideText="true"*/}
			{/*							uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
			{/*							uploadedFilePreview={publicCoverImageBlobPreview}*/}
			{/*							onUploadFileChange={handlePublicCoverImageUploadFileChange}*/}
			{/*							minHeight={IMAGE_LARGE_MIN_HEIGHT}*/}
			{/*							minWidth={IMAGE_LARGE_MIN_WIDTH}*/}
			{/*							maxHeight={IMAGE_LARGE_MAX_HEIGHT}*/}
			{/*							maxWidth={IMAGE_LARGE_MAX_WIDTH}*/}
			{/*							uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}*/}
			{/*							disableCropper={true}*/}
			{/*						/>*/}
			{/*						<i className="fa-regular fa-plus"></i>*/}
			{/*					</div>*/}
			{/*					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*						Public Cover Image{publicCoverAsRequired ? '*' : ''}*/}
			{/*					</p>*/}
			{/*					<p className="text-center body-txtitalic--smallest">*/}
			{/*						Up to 3840px square or wide. JPEG, PNG, or GIF*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="md:hidden">*/}
			{/*					<p className="mt-8 body-txtitalic--smallest color-reg">*/}
			{/*						Optional. On supported devices, the Public Cover Video (up to*/}
			{/*						16MB) is displayed in place of the Public Cover Image.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 my-auto">*/}
			{/*					<div className="upload-rec">*/}
			{/*						<UploadDragger*/}
			{/*							hideDragIcon="true"*/}
			{/*							hideText="true"*/}
			{/*							uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
			{/*							uploadedFilePreview={publicCoverVideoBlobPreview}*/}
			{/*							onUploadFileChange={handlePublicCoverVideoUploadFileChange}*/}
			{/*							minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
			{/*							minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
			{/*							maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
			{/*							maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
			{/*							uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*						/>*/}
			{/*						<i className="fa-regular fa-plus"></i>*/}
			{/*					</div>*/}
			{/*					<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*						Public Cover Video*/}
			{/*					</p>*/}
			{/*					<p className="text-center body-txtitalic--smallest">*/}
			{/*						Up to 1920px/1080px, square or wide. MOV or MP4*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="hidden md:block col-span-4 my-auto">*/}
			{/*					<p className="body-txtitalic--smallest color-reg">*/}
			{/*						The Public Cover Image (up to 8MB) is what everyone without*/}
			{/*						access will see instead of the Cover Image in previews and when*/}
			{/*						browsing. /!*<a className="gradient-color-txt">*!/*/}
			{/*						/!*	{' '}*!/*/}
			{/*						/!*	This is required if you have added a Public Title.*!/*/}
			{/*						/!*</a>*!/*/}
			{/*						{getSitewideLink('required_public_title')}*/}
			{/*					</p>*/}
			{/*					<p className="mt-8 body-txtitalic--smallest color-reg">*/}
			{/*						Optional. On supported devices, the Public Cover Video (up to*/}
			{/*						16MB) is displayed in place of the Public Cover Image.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*				<div className="md:col-span-8 my-auto !order-last md:!order-first">*/}
			{/*					<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">*/}
			{/*						Preview Text**/}
			{/*					</label>*/}
			{/*					<div className="relative mt-2 rounded-md ">*/}
			{/*					<Form.Item*/}
			{/*							label="Preview Text"*/}
			{/*							name="preview_text"*/}
			{/*							className="mb-0 hidden-label"*/}
			{/*							validateTrigger="onBlur"*/}
			{/*							rules={[{ required: true }]}*/}
			{/*						>*/}
			{/*							<textarea*/}
			{/*							id="about"*/}
			{/*							name="about"*/}
			{/*							rows={1}*/}
			{/*							maxLength={255}*/}
			{/*							disabled={submitted}*/}
			{/*							ref={previewTextRef}*/}
			{/*							placeholder="Up to 255 characters, including spaces."*/}
			{/*							className="block bg-[#ffffff80] w-full rounded-md border-0  py-1.5 pl-4 pr-20  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
			{/*							defaultValue={''}*/}
			{/*								/>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*					*/}
			{/*				</div>	*/}
			{/*				*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						This is the Preview Text for the post that will be seen by*/}
			{/*						Members Only when browsing. If the Visibility of the post was*/}
			{/*						set to Custom Access, then this is what the Members of the*/}
			{/*						custom group would see.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			{showPublicFields && (*/}
			{/*				<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*					<div className="md:col-span-8 my-auto !order-last md:!order-first">*/}
			{/*					<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">*/}
			{/*						Public Preview Text*/}
			{/*					</label>*/}
			{/*					<div className="relative mt-2 rounded-md ">*/}
			{/*					<Form.Item*/}
			{/*								label="Public Preview Text"*/}
			{/*								name="public_preview_text"*/}
			{/*								className="mb-0 hidden-label"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*							>*/}
			{/*							<textarea*/}
			{/*							id="about"*/}
			{/*							name="about"*/}
			{/*							rows={1}*/}
			{/*							maxLength={255}*/}
			{/*							disabled={submitted}*/}
			{/*							ref={publicPreviewTextRef}*/}
			{/*							placeholder="Up to 255 characters, including spaces."*/}
			{/*							className="block bg-[#ffffff80] w-full rounded-md border-0  py-1.5 pl-4 pr-20  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
			{/*							defaultValue={''}*/}
			{/*								/>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*					*/}
			{/*				</div>	*/}
			{/*					*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						This is the Public Preview Text which everyone else will*/}
			{/*						see. It is also what will be seen by search engines as the*/}
			{/*						preview text in search results.{' '}*/}
			{/*						/!*<a className="gradient-color-txt">*!/*/}
			{/*						/!*	This is required if you have added a Public Title.*!/*/}
			{/*						/!*</a>*!/*/}
			{/*						{getSitewideLink('required_public_title')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			)}*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*				<div className="md:col-span-8 !order-last md:!order-first">*/}
			{/*					<h6 className="header-small">Content</h6>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 flex items-center !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg">*/}
			{/*						This is the main content of the post viewable on the full Update*/}
			{/*						post page by Members Only. If the Visibility of the post was set*/}
			{/*						to Custom Access, then only the Members of the specified custom*/}
			{/*						group would be able to see it.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*				<div className="md:col-span-8 text-editor-area !order-last md:!order-first">*/}
			{/*					<Form.Item*/}
			{/*						name="content"*/}
			{/*						label="content"*/}
			{/*						className="mb-0 hidden-label"*/}
			{/*						validateTrigger="onBlur"*/}
			{/*						rules={[*/}
			{/*							{*/}
			{/*								required: true,*/}
			{/*								message: 'Content is Required.'*/}
			{/*							}*/}
			{/*						]}*/}
			{/*					>*/}
			{/*						<ReactQuill*/}
			{/*							theme="snow"*/}
			{/*							modules={{*/}
			{/*								toolbar: toolbarOptions*/}
			{/*							}}*/}
			{/*							value={content}*/}
			{/*							onChange={(e) => {*/}
			{/*								if ('<p><br></p>' === e) {*/}
			{/*									createSpaceFirstUpdateForm.setFieldValue(*/}
			{/*										'content',*/}
			{/*										''*/}
			{/*									);*/}
			{/*									setContent(null);*/}
			{/*								} else {*/}
			{/*									setContent(e);*/}
			{/*								}*/}
			{/*							}}*/}
			{/*						/>*/}
			{/*					</Form.Item>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 flex items-center !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg">*/}
			{/*						You can style your content, add images, videos, and more.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 mt-8 items-center">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<label className="body-text--small gradient-color-txt pt-3">*/}
			{/*						Category**/}
			{/*					</label>*/}
			{/*					{spaceInfo != null && authUserInfo != null && (*/}
			{/*						<AddNewCategoryModal*/}
			{/*							modalOpen={modalOpen}*/}
			{/*							setModalOpen={setModalOpen}*/}
			{/*							filteredCategoryList={filteredCategoryList}*/}
			{/*							setSelectedCategory={setSelectedCategory}*/}
			{/*							spaceID={spaceInfo.id}*/}
			{/*							userID={authUserInfo.id}*/}
			{/*							setUpdateCategory={setUpdateCategory}*/}
			{/*						/>*/}
			{/*					)}*/}

			{/*					<DropdownWithItems*/}
			{/*						title="Category"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="btn-lg btn-secondary mt-2 btn-size"*/}
			{/*						displayError={*/}
			{/*							displayDropDownError && selectedCategory === 'create'*/}
			{/*						}*/}
			{/*						selectedValue={selectedCategory}*/}
			{/*						listItems={filteredCategoryList}*/}
			{/*						onDropdownChange={(value) => filterDropdownChange(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			/!* <div className="row mt-8 flex items-center">*!/*/}
			{/*			/!*	<div className="col-8">*!/*/}
			{/*			/!*		<div className="input-group-com">*!/*/}
			{/*			/!*			<label className="body-text--small w-1/4 gradient-color-txt ms-4">*!/*/}
			{/*			/!*				Category**!/*/}
			{/*			/!*			</label>*!/*/}
			{/*			/!*			<Form.Item*!/*/}
			{/*			/!*				label="Category"*!/*/}
			{/*			/!*				name="category"*!/*/}
			{/*			/!*				className="mb-0 hidden-label"*!/*/}
			{/*			/!*				validateTrigger="onBlur"*!/*/}
			{/*			/!*				rules={[{ required: true }]}*!/*/}
			{/*			/!*			>*!/*/}
			{/*			/!*				<Input*!/*/}
			{/*			/!*					onFocus={hideTooltip}*!/*/}
			{/*			/!*					onBlur={showTooltip}*!/*/}
			{/*			/!*					className="input-lg body-text--small"*!/*/}
			{/*			/!*					maxLength={24}*!/*/}
			{/*			/!*					disabled={submitted}*!/*/}
			{/*			/!*					ref={categoryRef}*!/*/}
			{/*			/!*				/>*!/*/}
			{/*			/!*			</Form.Item>*!/*/}
			{/*			/!*			{!category && (*!/*/}
			{/*			/!*				<span*!/*/}
			{/*			/!*					className="tool-tip prevent-select"*!/*/}
			{/*			/!*					onClick={() => tooltipFocused('category')}*!/*/}
			{/*			/!*				>*!/*/}
			{/*			/!*					Up to 24 characters, including spaces.*!/*/}
			{/*			/!*				</span>*!/*/}
			{/*			/!*			)}*!/*/}
			{/*			/!*		</div>*!/*/}
			{/*			/!*	</div>*!/*/}
			{/*			/!*	<div className="col-4">*!/*/}
			{/*			/!*		<p className="body-txtitalic--smallest color-reg m-0">*!/*/}
			{/*			/!*			Create a new Category for this post. This will be saved and can*!/*/}
			{/*			/!*			be used for future Updates. You can add and manage your Updates*!/*/}
			{/*			/!*			Categories in your Dashboard.*!/*/}
			{/*			/!*		</p>*!/*/}
			{/*			/!*	</div>*!/*/}
			{/*			/!*</div> *!/*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*			<div className="md:col-span-8 my-auto !order-last md:!order-first">*/}
			{/*					<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">*/}
			{/*						Tags*/}
			{/*					</label>*/}
			{/*					<div className="relative mt-2 rounded-md ">*/}
			{/*					<Form.Item*/}
			{/*							label="Tags"*/}
			{/*							name="tags"*/}
			{/*							className="mb-0 hidden-label"*/}
			{/*							validateTrigger="onBlur"*/}
			{/*						>*/}
			{/*							*/}
			{/*							<FormFieldStacked*/}
			{/*							formType='input'*/}
			{/*							type="text"	*/}
			{/*							placeholder="Enter each tag, separated by a comma."*/}
			{/*							disabled={submitted}*/}
			{/*							ref={tagsRef}*/}
			{/*							></FormFieldStacked>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*					*/}
			{/*				</div>								*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						Optional. You can add tags to your post. This is used for*/}
			{/*						searching and for relating similar posts to each other. You can*/}
			{/*						add as many as you like, with each tag separated by a comma.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*				<div className="md:col-span-4 !order-2 md:!order-1">*/}
			{/*					<DropdownWithItems*/}
			{/*						title="Select Content Type"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"*/}
			{/*						displayError={*/}
			{/*							displayDropDownError && selectedContentType === 'default'*/}
			{/*						}*/}
			{/*						icon={getListIcon(*/}
			{/*							CONTENT_TYPES_LIST_WITH_DEFAULT,*/}
			{/*							selectedContentType*/}
			{/*						)}*/}
			{/*						selectedValue={selectedContentType}*/}
			{/*						listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}*/}
			{/*						onDropdownChange={(value) => openContentTypeModal(value)}*/}
			{/*					/>*/}
			{/*					<ExplicitContent*/}
			{/*						modalOpen={modalOpenExplicitContent}*/}
			{/*						setModalOpen={setModalOpenExplicitContent}*/}
			{/*						selectedItems={selectedExplicitContent}*/}
			{/*						isExplicitContent={true}*/}
			{/*						onSelectedItems={(value) => setSelectedExplicitContent(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 !order-3 md:!order-2">*/}
			{/*					<DropdownWithItems*/}
			{/*						title="Select Access Type"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="btn-lg btn-secondary whitespace-pre-wrap"*/}
			{/*						displayError={*/}
			{/*							displayDropDownError && selectedAccessType === 'default'*/}
			{/*						}*/}
			{/*						icon={getListIcon(*/}
			{/*							ACCESS_TYPES_LIST_WITH_DEFAULT,*/}
			{/*							selectedAccessType*/}
			{/*						)}*/}
			{/*						selectedValue={selectedAccessType}*/}
			{/*						listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}*/}
			{/*						onDropdownChange={(value) => openAccessTypeModal(value)}*/}
			{/*					/>*/}
			{/*					<ExplicitContent*/}
			{/*						modalOpen={modalOpenExplicitAccess}*/}
			{/*						setModalOpen={setModalOpenExplicitAccess}*/}
			{/*						selectedItems={selectedExplicitAccess}*/}
			{/*						isExplicitContent={false}*/}
			{/*						onSelectedItems={(value) => setSelectedExplicitAccess(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 !order-1 md:!order-3">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						Change the Content setting if your Details, Image, or Video*/}
			{/*						contains explicit or sensitive content. Change the Access*/}
			{/*						setting if your Space will provide access to explicit or*/}
			{/*						sensitive content. /!*<a className="gradient-color-txt">*!/*/}
			{/*						/!*	View our Content Guidelines.*!/*/}
			{/*						/!*</a>*!/*/}
			{/*						{getSitewideLink('view_content_guidelines_post_update')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*				<div className="md:col-span-8 !order-last md:!order-first">*/}
			{/*					<h6 className="header-small">Settings</h6>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 flex items-center !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg">*/}
			{/*						Specify the category, tags, and author for your post here.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}

			{/*			<div className="grid grid-cols-1 md:grid-cols-12 mt-8 gap-y-8 md:gap-y-0 md:gap-8 items-center">*/}
			{/*				<div className="input-dropdown md:col-span-8 !order-last md:!order-first">*/}
			{/*					<DropdownWithItems*/}
			{/*						title="Authors"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="btn-lg btn-secondary"*/}
			{/*						displayError={*/}
			{/*							displayDropDownError && selectedAuthor === 'default'*/}
			{/*						}*/}
			{/*						selectedValue={selectedAuthor}*/}
			{/*						listItems={UPDATE_AUTHORS_LIST_WITH_DEFAULT}*/}
			{/*						onDropdownChange={(value) => setSelectedAuthor(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						Choose an Author for the post for attribution. This can be the*/}
			{/*						Space itself, you, or a person on your team (once you give them*/}
			{/*						access).{' '}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*				<div className="md:col-span-8 !order-last md:!order-first">*/}
			{/*					<DropdownWithItems*/}
			{/*						title="Comments"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="btn-lg btn-secondary"*/}
			{/*						selectedValue={selectedCommentAvailability}*/}
			{/*						listItems={UPDATE_COMMENTS_AVAILABILITY}*/}
			{/*						onDropdownChange={(value) =>*/}
			{/*							setSelectedCommentAvailability(value)*/}
			{/*						}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						Enable or disable Commenting on the post by Members. Regardless*/}
			{/*						of the Visibility setting, comments can only be posted by a*/}
			{/*						logged in Member of your Space. The default setting is “Enable*/}
			{/*						Comments”.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8 items-center">*/}
			{/*				<div className="md:col-span-8 !order-last md:!order-first">*/}
			{/*					<DropdownWithItems*/}
			{/*						title="Publish Status"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="btn-lg btn-secondary"*/}
			{/*						selectedValue={selectedPublishStatus}*/}
			{/*						listItems={UPDATE_PUBLISH_STATUSES}*/}
			{/*						onDropdownChange={(value) => setSelectedPublishStatus(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 !order-first md:!order-last">*/}
			{/*					<p className="body-txtitalic--smallest color-reg m-0">*/}
			{/*						Select the Publish Status of this Update Post. You can choose*/}
			{/*						Publish Immediately, Schedule Publishing, or Unpublished Draft.*/}
			{/*						You can change this at any time.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<Button*/}
			{/*						htmlType="submit"*/}
			{/*						className="btn btn-lg btn-primary"*/}
			{/*						onClick={() => setIsSaveAndGoBack(false)}*/}
			{/*						disabled={submitted || formHasError}*/}
			{/*					>*/}
			{/*						{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}*/}
			{/*						Save & Continue*/}
			{/*					</Button>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 flex items-end">*/}
			{/*					<Button*/}
			{/*						htmlType="submit"*/}
			{/*						className="btn btn-lg btn-secondary"*/}
			{/*						onClick={() => setIsSaveAndGoBack(true)}*/}
			{/*						disabled={submitted || formHasError}*/}
			{/*					>*/}
			{/*						{submitted && <i className="fa-solid fa-spinner fa-spin"></i>}*/}
			{/*						Save & Go Back*/}
			{/*					</Button>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">*/}
			{/*				<div className="md:col-span-8">*/}
			{/*					<Button className="btn btn-lg btn-secondary" onClick={skipNextPage}>*/}
			{/*						Skip Without Saving*/}
			{/*					</Button>*/}
			{/*				</div>*/}
			{/*				<div className="md:col-span-4 flex items-end">*/}
			{/*					<Button className="btn btn-lg btn-secondary whitespace-pre-wrap" onClick={goBackPage}>*/}
			{/*						Go Back Without Saving*/}
			{/*					</Button>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</Form>*/}
			{/*	</div>*/}
			{/*</main>*/}
		</>
	);
};

export default memo(PostUpdate);
