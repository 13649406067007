import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {getSitewideLink} from "../../../utils/sitewideLinks";
import {updateLastStepLink} from "../../../services/space";

const SpaceCreateAddTraits = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);

	const handleUpdateLastStepLink = useCallback(async () => {
			const data = {};
			// include last step link data for continuing the space creation
			// const url = routes.createSpaceAddMembershipBenefitPage();
			const url = routes.createSpaceBasicCompletePage();
			data.last_step_link = url.replace(':space_username', space_username);
			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					const response = await updateLastStepLink(data);
					if (response && response.result) {
						saveContinue();
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		[space_username, authUserInfo, spaceInfo]
	);

	const saveContinue = () => {
		// const url = routes.createSpaceAddMembershipBenefitPage();
		const url = routes.createSpaceBasicCompletePage();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createSpaceAddMembershipPage();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(()=>{
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},[])


	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	return (
            <main id="cs-platform-main" className="cs-main main-theme"> 
                <section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
                    <div className="centerframe-grid gap-2gutter">
                        <div className="block-full">
                            <div className="cs-stack-heading">
                                <div className="stack-row split">
                                    <h1>Membership Traits</h1>
                                    <h3>Step 4 of 5</h3>
                                </div>
                                <p>
                                {getSitewideLink('memberships_create_space_4', 'gradient-color-txt body-txtitalic--reg')}
								{' '}can have optional traits assigned to them such as text properties,
								level counts, numerical stats, dates, percentages, and hidden
								content. For Memberships that are created based on an existing NFT
								Collection, Traits are automatically enabled and will be inherited
								from the traits in the existing NFT metadata.{' '}
								
								{getSitewideLink('learn_more_traits', 'gradient-color-txt body-txtitalic--reg')}
                                </p>
                            </div>
                            <div className="cs-stack-form-guided">
                                <div className="formrow">
                                    <a  className="button-primary-gradient" onClick={handleUpdateLastStepLink}><span><p>Save Membership & Continue</p></span></a>
                                    <a  className="button-secondary-gradient" onClick={saveBack}><span><p>Save & Go Back</p></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	);
};

export default memo(SpaceCreateAddTraits);
