import { memo, useEffect, useCallback, useState, useRef } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {
	updateSpaceProNavigation,
	setSpaceProDomain,
	setSpaceProPageElements,
	updateLastStepLink
} from '../../../services/space';
import { hideTooltip, showTooltip } from '../../../utils/input';
import { CURRENT_LINK_OPTIONS } from '../../../constants/space';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import { CUSTOM_REGEX } from '../../../constants/custom_regex';

const CustomizeProSpace = ({ dropSection }) => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [initialLoad, setInitialLoad] = useState(true);
	const [alert, setAlert] = useState(null);
	const [submitted1, setSubmitted1] = useState(false);
	const [submitted2, setSubmitted2] = useState(false);
	const [submitted3, setSubmitted3] = useState(false);
	const [headerNavigationLink1Id, setHeaderNavigationLink1Id] = useState(null);
	const [headerNavigationLink2Id, setHeaderNavigationLink2Id] = useState(null);
	const [headerNavigationLink3Id, setHeaderNavigationLink3Id] = useState(null);
	const [headerNavigationLink4Id, setHeaderNavigationLink4Id] = useState(null);
	const [headerNavigationLink1, setHeaderNavigationLink1] = useState('');
	const [headerNavigationLink2, setHeaderNavigationLink2] = useState('');
	const [headerNavigationLink3, setHeaderNavigationLink3] = useState('');
	const [headerNavigationLink4, setHeaderNavigationLink4] = useState('');
	const [headerNavigationLink1Url, setHeaderNavigationLink1Url] = useState('');
	const [headerNavigationLink2Url, setHeaderNavigationLink2Url] = useState('');
	const [headerNavigationLink3Url, setHeaderNavigationLink3Url] = useState('');
	const [headerNavigationLink4Url, setHeaderNavigationLink4Url] = useState('');
	const [footerNavigationListTitle, setFooterNavigationListTitle] = useState('');
	const [footerNavigationLink1Id, setFooterNavigationLink1Id] = useState(null);
	const [footerNavigationLink2Id, setFooterNavigationLink2Id] = useState(null);
	const [footerNavigationLink3Id, setFooterNavigationLink3Id] = useState(null);
	const [footerNavigationLink4Id, setFooterNavigationLink4Id] = useState(null);
	const [footerNavigationLink5Id, setFooterNavigationLink5Id] = useState(null);
	const [footerNavigationLink6Id, setFooterNavigationLink6Id] = useState(null);
	const [footerNavigationLink1, setFooterNavigationLink1] = useState('');
	const [footerNavigationLink2, setFooterNavigationLink2] = useState('');
	const [footerNavigationLink3, setFooterNavigationLink3] = useState('');
	const [footerNavigationLink4, setFooterNavigationLink4] = useState('');
	const [footerNavigationLink5, setFooterNavigationLink5] = useState('');
	const [footerNavigationLink6, setFooterNavigationLink6] = useState('');
	const [footerNavigationLink1Url, setFooterNavigationLink1Url] = useState('');
	const [footerNavigationLink2Url, setFooterNavigationLink2Url] = useState('');
	const [footerNavigationLink3Url, setFooterNavigationLink3Url] = useState('');
	const [footerNavigationLink4Url, setFooterNavigationLink4Url] = useState('');
	const [footerNavigationLink5Url, setFooterNavigationLink5Url] = useState('');
	const [footerNavigationLink6Url, setFooterNavigationLink6Url] = useState('');
	const [footerLinksColumnTitle, setFooterLinksColumnTitle] = useState('');

	const headerNavigationLink1Ref = useRef(null);
	const headerNavigationLink2Ref = useRef(null);
	const headerNavigationLink3Ref = useRef(null);
	const headerNavigationLink4Ref = useRef(null);
	const headerNavigationLink1UrlRef = useRef(null);
	const headerNavigationLink2UrlRef = useRef(null);
	const headerNavigationLink3UrlRef = useRef(null);
	const headerNavigationLink4UrlRef = useRef(null);
	const footerNavigationListTitleRef = useRef(null);
	const footerLinkTitleRef = useRef(null);
	const footerNavigationLink1Ref = useRef(null);
	const footerNavigationLink2Ref = useRef(null);
	const footerNavigationLink3Ref = useRef(null);
	const footerNavigationLink4Ref = useRef(null);
	const footerNavigationLink5Ref = useRef(null);
	const footerNavigationLink6Ref = useRef(null);
	const footerNavigationLink1UrlRef = useRef(null);
	const footerNavigationLink2UrlRef = useRef(null);
	const footerNavigationLink3UrlRef = useRef(null);
	const footerNavigationLink4UrlRef = useRef(null);
	const footerNavigationLink5UrlRef = useRef(null);
	const footerNavigationLink6UrlRef = useRef(null);

	const [selectedHeaderLink1OpenType, setSelectedHeaderLink1OpenType] =
		useState('open_new_window');
	const [selectedHeaderLink2OpenType, setSelectedHeaderLink2OpenType] =
		useState('open_new_window');
	const [selectedHeaderLink3OpenType, setSelectedHeaderLink3OpenType] =
		useState('open_new_window');
	const [selectedHeaderLink4OpenType, setSelectedHeaderLink4OpenType] =
		useState('open_new_window');
	const [selectedFooterLink1OpenType, setSelectedFooterLink1OpenType] =
		useState('open_new_window');
	const [selectedFooterLink2OpenType, setSelectedFooterLink2OpenType] =
		useState('open_new_window');
	const [selectedFooterLink3OpenType, setSelectedFooterLink3OpenType] =
		useState('open_new_window');
	const [selectedFooterLink4OpenType, setSelectedFooterLink4OpenType] =
		useState('open_new_window');
	const [selectedFooterLink5OpenType, setSelectedFooterLink5OpenType] =
		useState('open_new_window');
	const [selectedFooterLink6OpenType, setSelectedFooterLink6OpenType] =
		useState('open_new_window');

	const [domain, setDomain] = useState('');
	const [error1, setError1] = useState('');
	const [error2, setError2] = useState('');
	const [error3, setError3] = useState('');
	const [success1, setSuccess1] = useState(false);
	const [success2, setSuccess2] = useState(false);
	const [success3, setSuccess3] = useState(false);
	const [joinTitle, setJoinTitle] = useState('');
	const [communityTitleFirst, setCommunityTitleFirst] = useState('');
	const [communityTitleSecond, setCommunityTitleSecond] = useState('');
	const [featuredLinksFirstTitle, setFeaturedLinksFirstTitle] = useState('');
	const [featuredLinksSecondTitle, setFeaturedLinksSecondTitle] = useState('');
	const [memberAccessFirstTitle, setMemberAccessFirstTitle] = useState('');
	const [memberAccessSecondTitle, setMemberAccessSecondTitle] = useState('');
	const [featuredMembershipsFirstTitle, setFeaturedMembershipsFirstTitle] = useState('');
	const [featuredMembershipsSecondTitle, setFeaturedMembershipsSecondTitle] = useState('');

	const saveContinue = () => {
		const url = routes.createSpaceLaunch();
		navigate(url.replace(':space_username', space_username));
	};

	const saveBack = () => {
		const url = routes.createCustomizeProSpace();
		navigate(url.replace(':space_username', space_username));
	};

	const setFormSpaceData = async (createdSpace) => {
		if (createdSpace) {
			if (createdSpace.plan === 'pro') {
				if (createdSpace.pro_style) {
					setFooterNavigationListTitle(createdSpace.pro_style.navigation_list_title);
				}

				if (createdSpace.pro_navigations) {
					const navs = createdSpace.pro_navigations;
					const header_navs = [];
					const footer_navs = [];

					navs.forEach((nav) => {
						if (nav['section'] === 'header') {
							header_navs.push(nav);
						}
						if (nav['section'] === 'footer') {
							footer_navs.push(nav);
						}
					});

					setJoinTitle(spaceInfo.join_title);
					setCommunityTitleFirst(spaceInfo.community_title_first_half);
					setCommunityTitleSecond(spaceInfo.community_title_second_half);
					setFeaturedLinksFirstTitle(spaceInfo.link_title_first);
					setFeaturedLinksSecondTitle(spaceInfo.link_title_second);
					setMemberAccessFirstTitle(spaceInfo.member_access_first_title);
					setMemberAccessSecondTitle(spaceInfo.member_access_second_title);
					setFeaturedMembershipsFirstTitle(spaceInfo.featured_memberships_first_title);
					setFeaturedMembershipsSecondTitle(spaceInfo.featured_memberships_second_title);

					header_navs.forEach((nav, index) => {
						if (index === 0) {
							setHeaderNavigationLink1Id(nav.id);
							setHeaderNavigationLink1(nav.label);
							setHeaderNavigationLink1Url(nav.url);
							setSelectedHeaderLink1OpenType(nav.open_type);
						}
						if (index === 1) {
							setHeaderNavigationLink2Id(nav.id);
							setHeaderNavigationLink2(nav.label);
							setHeaderNavigationLink2Url(nav.url);
							setSelectedHeaderLink2OpenType(nav.open_type);
						}
						if (index === 2) {
							setHeaderNavigationLink3Id(nav.id);
							setHeaderNavigationLink3(nav.label);
							setHeaderNavigationLink3Url(nav.url);
							setSelectedHeaderLink3OpenType(nav.open_type);
						}
						if (index === 3) {
							setHeaderNavigationLink4Id(nav.id);
							setHeaderNavigationLink4(nav.label);
							setHeaderNavigationLink4Url(nav.url);
							setSelectedHeaderLink4OpenType(nav.open_type);
						}
					});

					footer_navs.forEach((nav, index) => {
						if (index === 0) {
							setFooterNavigationLink1Id(nav.id);
							setFooterNavigationLink1(nav.label);
							setFooterNavigationLink1Url(nav.url);
							setSelectedFooterLink1OpenType(nav.open_type);
						}
						if (index === 1) {
							setFooterNavigationLink2Id(nav.id);
							setFooterNavigationLink2(nav.label);
							setFooterNavigationLink2Url(nav.url);
							setSelectedFooterLink2OpenType(nav.open_type);
						}
						if (index === 2) {
							setFooterNavigationLink3Id(nav.id);
							setFooterNavigationLink3(nav.label);
							setFooterNavigationLink3Url(nav.url);
							setSelectedFooterLink3OpenType(nav.open_type);
						}
						if (index === 3) {
							setFooterNavigationLink4Id(nav.id);
							setFooterNavigationLink4(nav.label);
							setFooterNavigationLink4Url(nav.url);
							setSelectedFooterLink4OpenType(nav.open_type);
						}
						if (index === 4) {
							setFooterNavigationLink5Id(nav.id);
							setFooterNavigationLink5(nav.label);
							setFooterNavigationLink5Url(nav.url);
							setSelectedFooterLink5OpenType(nav.open_type);
						}
						if (index === 5) {
							setFooterNavigationLink6Id(nav.id);
							setFooterNavigationLink6(nav.label);
							setFooterNavigationLink6Url(nav.url);
							setSelectedFooterLink6OpenType(nav.open_type);
						}
					});
				}

				if (spaceInfo.domain_name) {
					setDomain(spaceInfo.domain_name);
				}
			} else {
				const url = routes.createSpaceLaunch();
				navigate(url.replace(':space_username', space_username));
			}
		}
	};

	const setNavObj = (space_id, label, url, type, open_type, nav_id = null) => {
		const obj = {
			space_id: space_id,
			label: label,
			url: url,
			section: type,
			open_type: open_type
		};
		if (nav_id) {
			obj['id'] = nav_id;
		}
		return obj;
	};

	const submitProDomain = useCallback(async () => {
		if (!CUSTOM_REGEX.IDENTIFY_VALID_DOMAIN.test(domain)) {
			setError3('Please enter a valid domain or subdomain.');
		} else {
			setError3('');

			const data = {
				space_id: spaceInfo.id,
				user_id: authUserInfo.id,
				space_domain: domain
			};

			try {
				setSubmitted3(true);
				const response = await setSpaceProDomain(data);
				if (response.result) {
					setSuccess3(true);
				} else {
					let error_message = 'Something went wrong. Please try again.';
					if (response.result) {
						error_message = response.message;
					}

					setError3(error_message);
					setSuccess3(false);
				}

				setSubmitted3(false);
			} catch (error) {
				setError3('Something went wrong. Please try again.');
				setSuccess3(false);
				setSubmitted3(false);
			}
		}
	});

	const submitProPageElements = useCallback(async (isSavePageElements) => {
		setSuccess2(true);
		const data = {
			space_id: spaceInfo.id,
			user_id: authUserInfo.id,
			join_title: joinTitle,
			community_title_first_half: communityTitleFirst,
			community_title_second_half: communityTitleSecond,
			featuredLinks_title_first_half: featuredLinksFirstTitle,
			featuredLinks_title_second_half: featuredLinksSecondTitle,
			member_access_first_title: memberAccessFirstTitle,
			member_access_second_title: memberAccessSecondTitle,
			featured_memberships_first_title: featuredMembershipsFirstTitle,
			featured_memberships_second_title: featuredMembershipsSecondTitle
		};
		try {
			setSubmitted2(true);
			const response = await setSpaceProPageElements(data);
			setSubmitted2(false);
		} catch (error) {
			console.log(error);
			setError2('Something went wrong. Please try again.');
			setSuccess2(false);
		}
	});

	const submitProNav = useCallback(
		async (isSaveAndGoBack) => {
			const navigations = [];
			setSuccess1(true);

			if (headerNavigationLink1 && headerNavigationLink1Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						headerNavigationLink1,
						headerNavigationLink1Url,
						'header',

						selectedHeaderLink1OpenType,
						headerNavigationLink1Id
					)
				);
			}

			if (headerNavigationLink2 && headerNavigationLink2Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						headerNavigationLink2,
						headerNavigationLink2Url,
						'header',
						selectedHeaderLink2OpenType,
						headerNavigationLink2Id
					)
				);
			}

			if (headerNavigationLink3 && headerNavigationLink3Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						headerNavigationLink3,
						headerNavigationLink3Url,
						'header',
						selectedHeaderLink3OpenType,
						headerNavigationLink3Id
					)
				);
			}

			if (headerNavigationLink4 && headerNavigationLink4Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						headerNavigationLink4,
						headerNavigationLink4Url,
						'header',
						selectedHeaderLink4OpenType,
						headerNavigationLink4Id
					)
				);
			}

			if (footerNavigationLink1 && footerNavigationLink1Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink1,
						footerNavigationLink1Url,
						'footer',
						selectedFooterLink1OpenType,
						footerNavigationLink1Id
					)
				);
			}

			if (footerNavigationLink2 && footerNavigationLink2Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink2,
						footerNavigationLink2Url,
						'footer',
						selectedFooterLink2OpenType,
						footerNavigationLink2Id
					)
				);
			}

			if (footerNavigationLink3 && footerNavigationLink3Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink3,
						footerNavigationLink3Url,
						'footer',
						selectedFooterLink3OpenType,
						footerNavigationLink3Id
					)
				);
			}

			if (footerNavigationLink4 && footerNavigationLink4Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink4,
						footerNavigationLink4Url,
						'footer',
						selectedFooterLink4OpenType,
						footerNavigationLink4Id
					)
				);
			}

			if (footerNavigationLink5 && footerNavigationLink5Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink5,
						footerNavigationLink5Url,
						'footer',
						selectedFooterLink5OpenType,
						footerNavigationLink5Id
					)
				);
			}

			if (footerNavigationLink6 && footerNavigationLink6Url) {
				navigations.push(
					setNavObj(
						spaceInfo.id,
						footerNavigationLink6,
						footerNavigationLink6Url,
						'footer',
						selectedFooterLink6OpenType,
						footerNavigationLink6Id
					)
				);
			}

			const data = {
				space_id: spaceInfo.id,
				user_id: authUserInfo.id,
				navigations: JSON.stringify(navigations),
				navigation_list_title: footerNavigationListTitle
			};

			try {
				setSubmitted1(true);
				const response = await updateSpaceProNavigation(data);

				// if (isSaveAndGoBack) {
				// 	const url = routes.createSpaceLaunch();
				// 	navigate(url.replace(':space_username', space_username));
				// } else {
				// 	const url = routes.createCustomizeProSpace();
				// 	navigate(url.replace(':space_username', space_username));
				// }
				dispatch(getSpaceInfo(spaceInfo.username));
				if (dropSection) {
					setAlert({
						variant: 'danger',
						message: response.message
					});
					window.scrollTo(0, 0);
				}
				// if (!dropSection) {
				// 	dispatch(getSpaceInfo(space_username));
				// } else {
				// 	setAlert({
				// 		variant: 'danger',
				// 		message: response.message
				// 	});
				// 	window.scrollTo(0, 0);
				// }
				setSubmitted1(false);
			} catch (error) {
				console.log(error);
				setError1('Something went wrong. Please try again.');
				setSuccess1(false);
			}
			// setSubmitted(true);
		},
		[
			space_username,
			authUserInfo,
			spaceInfo,
			headerNavigationLink1Id,
			headerNavigationLink2Id,
			headerNavigationLink3Id,
			selectedHeaderLink1OpenType,
			selectedHeaderLink2OpenType,
			selectedHeaderLink3OpenType,
			selectedHeaderLink4OpenType,
			selectedFooterLink1OpenType,
			selectedFooterLink2OpenType,
			selectedFooterLink3OpenType,
			selectedFooterLink4OpenType,
			selectedFooterLink5OpenType,
			selectedFooterLink6OpenType,
			headerNavigationLink4Id,
			headerNavigationLink1,
			headerNavigationLink2,
			headerNavigationLink3,
			headerNavigationLink4,
			footerNavigationLink1Id,
			footerNavigationLink2Id,
			footerNavigationLink3Id,
			footerNavigationLink4Id,
			footerNavigationLink5Id,
			footerNavigationLink6Id,
			footerNavigationLink1,
			footerNavigationLink2,
			footerNavigationLink3,
			footerNavigationLink4,
			footerNavigationLink5,
			footerNavigationLink6,
			headerNavigationLink1Url,
			headerNavigationLink2Url,
			headerNavigationLink3Url,
			headerNavigationLink4Url,
			footerNavigationLink1Url,
			footerNavigationLink2Url,
			footerNavigationLink3Url,
			footerNavigationLink4Url,
			footerNavigationLink5Url,
			footerNavigationLink6Url,
			footerNavigationListTitle
		]
	);

	const handleUpdateLastStepLink = useCallback(async () => {
		const data = {};
		// include last step link data for continuing the space creation
		const url = routes.createSpaceLaunch();
		data.last_step_link = url.replace(':space_username', space_username);
		try {
			if (authUserInfo) {
				data.user_id = authUserInfo.id;
				data.space_id = spaceInfo.id;
				const response = await updateLastStepLink(data);
				if (response && response.result) {
					saveAndContinue();
				}
			}
		} catch (error) {
			console.log(error);
		}
	}, [space_username, authUserInfo, spaceInfo]);

	const saveAndContinue = () => {
		let url = routes.createSpaceLaunch();
		if (dropSection) {
			url = routes.spaceManageSpace();
		}
		navigate(url.replace(':space_username', space_username));
	};
	const viewSpace = () => {
		const url = routes.viewUserSpace();
		const finalUrl = `${url}${dropSection ? space_username.replace('@', '') : space_username}`;
		navigate(finalUrl);
	};
	const proSpaceGuide = () => {
		const url = 'https://help.common.space/?view_guide_domain';
		window.location.href = url;
	};
	const tooltipFocused = (input) => {
		if (input === 'header_navigation_link1') {
			headerNavigationLink1Ref.current.focus();
		}
		if (input === 'header_navigation_link2') {
			headerNavigationLink2Ref.current.focus();
		}
		if (input === 'header_navigation_link3') {
			headerNavigationLink3Ref.current.focus();
		}
		if (input === 'header_navigation_link4') {
			headerNavigationLink4Ref.current.focus();
		}
		if (input === 'header_navigation_link1_url') {
			headerNavigationLink1UrlRef.current.focus();
		}
		if (input === 'header_navigation_link2_url') {
			headerNavigationLink2UrlRef.current.focus();
		}
		if (input === 'header_navigation_link3_url') {
			headerNavigationLink3UrlRef.current.focus();
		}
		if (input === 'header_navigation_link4_url') {
			headerNavigationLink4UrlRef.current.focus();
		}
		if (input === 'footer_navigation_list_title') {
			footerNavigationListTitleRef.current.focus();
		}
		// if (input === 'footer_link_column_title') {
		// 	footerLinkTitleRef.current.focus();
		// }
		if (input === 'footer_navigation_link1') {
			footerNavigationLink1Ref.current.focus();
		}
		if (input === 'footer_navigation_link2') {
			footerNavigationLink2Ref.current.focus();
		}
		if (input === 'footer_navigation_link3') {
			footerNavigationLink3Ref.current.focus();
		}
		if (input === 'footer_navigation_link4') {
			footerNavigationLink4Ref.current.focus();
		}
		if (input === 'footer_navigation_link5') {
			footerNavigationLink5Ref.current.focus();
		}
		if (input === 'footer_navigation_link6') {
			footerNavigationLink6Ref.current.focus();
		}
		if (input === 'footer_navigation_link1_url') {
			footerNavigationLink1UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link2_url') {
			footerNavigationLink2UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link3_url') {
			footerNavigationLink3UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link4_url') {
			footerNavigationLink4UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link5_url') {
			footerNavigationLink5UrlRef.current.focus();
		}
		if (input === 'footer_navigation_link6_url') {
			footerNavigationLink6UrlRef.current.focus();
		}
	};

	const draggableLinks = document.querySelectorAll('.cs-form-drag-link');
	const container = document.getElementById('draggable-links');
	const container_2 = document.getElementById('draggable-links_2');

	draggableLinks.forEach((link) => {
		link.addEventListener('dragstart', dragStart);
		link.addEventListener('dragover', dragOver);
		link.addEventListener('dragenter', dragEnter);
		link.addEventListener('dragleave', dragLeave);
		link.addEventListener('drop', dragDrop);
		link.addEventListener('dragend', dragEnd);
	});

	function dragStart(e) {
		e.dataTransfer.setData('text/plain', e.target.dataset.index);
		e.target.classList.add('dragging');
	}

	function dragOver(e) {
		e.preventDefault();
		e.target.classList.add('over');
	}

	function dragEnter(e) {
		e.preventDefault();
	}

	function dragLeave(e) {
		e.target.classList.remove('over');
	}

	function dragDrop(e) {
		e.preventDefault();
		e.target.classList.remove('over');

		const draggedIndex = e.dataTransfer.getData('text/plain');
		const droppedIndex = e.target.dataset.index;

		const draggedItem = draggableLinks[draggedIndex];
		const droppedItem = draggableLinks[droppedIndex];

		container.insertBefore(draggedItem, droppedItem);
		container_2.insertBefore(draggedItem, droppedItem);
	}

	function dragEnd(e) {
		e.target.classList.remove('dragging');
	}

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);
	const toggleNavigationLinks = () => {
		var navigationLinksToggle = document.getElementById('cs-theming-navlinks-toggle');
		var navigationLinksContent = document.getElementById('cs-theming-navlinks-content');

		navigationLinksToggle.classList.toggle('active');
		navigationLinksContent.classList.toggle('hide');
	};

	const togglePageElements = () => {
		var pageElementsToggle = document.getElementById('cs-theming-blocks-toggle');
		var pageElementsContent = document.getElementById('cs-theming-blocks-content');

		pageElementsToggle.classList.toggle('active');
		pageElementsContent.classList.toggle('hide');
	};
	const toggleDomain = () => {
		var pageDomainToggle = document.getElementById('cs-theming-domain-toggle');
		var pageDomainContent = document.getElementById('cs-theming-domain-content');

		pageDomainToggle.classList.toggle('active');
		pageDomainContent.classList.toggle('hide');
	};
	useEffect(() => {
		var navlinksTitle = document.getElementById('cs-theming-navlinks-title');
		var navDomainTitle = document.getElementById('cs-theming-domain-title');
		var navigationLinksToggle = document.getElementById('cs-theming-navlinks-toggle');
		var navigationLinksContent = document.getElementById('cs-theming-navlinks-content');

		var pageElementsToggle = document.getElementById('cs-theming-blocks-toggle');
		var pageElementsContent = document.getElementById('cs-theming-blocks-content');

		var pageDomainToggle = document.getElementById('cs-theming-domain-toggle');
		var pageDomainContent = document.getElementById('cs-theming-domain-content');

		if (dropSection === '#navigations') {
			if (pageElementsToggle.classList.contains('active')) {
				pageElementsToggle.classList.toggle('active');
				pageElementsContent.classList.toggle('hide');
			}

			if (pageDomainToggle.classList.contains('active')) {
				pageDomainToggle.classList.toggle('active');
				pageDomainContent.classList.toggle('hide');
			}
			navlinksTitle.scrollIntoView({ behavior: 'smooth' });
		} else if (dropSection === '#domain') {
			if (navigationLinksToggle.classList.contains('active')) {
				navigationLinksToggle.classList.toggle('active');
				navigationLinksContent.classList.toggle('hide');
			}
			if (pageElementsToggle.classList.contains('active')) {
				pageElementsToggle.classList.toggle('active');
				pageElementsContent.classList.toggle('hide');
			}
			navDomainTitle.scrollIntoView({ behavior: 'smooth' });
		}
	}, [dropSection]);

	useEffect(() => {}, [submitted3]);

	useEffect(()=>{
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},[])

	return (
		<>
			<main id="cs-platform-main" className="cs-main main-theme">
				<section
					id="cs-platform-create-pro-theming-c1"
					className={`p-section-safeview theme-transparent ${dropSection ? '!bg-white' : ''}`}
				>
					<div className="centerframe-grid gap-2 gutter">
						<div className="block-full">
							<div className="cs-stack-heading">
								<div className="stack-row split">
									<h1>Customize Your Pro Space</h1>
									{!dropSection && <h3>Step 3 of 3</h3>}
								</div>
								<p>
									Your Pro Space enables you to customize your{' '}
									<a href="link-via-admin" target="_blank">
										Navigation
									</a>
									, configure your{' '}
									<a href="link-via-admin" target="_blank">
										Page Elements
									</a>
									, and connect your own{' '}
									<a href="link-via-admin" target="_blank">
										Custom Domain Name.
									</a>{' '}
									You can manage these features below, or continue and edit them
									later from your Dashboard.
								</p>
							</div>
							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h2>Navigation, Page Elements, and Domain</h2>
										<p></p>
									</div>
									<a
										onClick={proSpaceGuide}
										className="button-secondary-gradient"
									>
										<span>
											<p>
												Pro Space Guide{' '}
												<i className="fa-regular fa-arrow-up-right-from-square i-r"></i>
											</p>
										</span>
									</a>
								</div>
								<div className="block-full">
									<p>
										A Pro Space can be set up in different ways, depending on
										your business or community. As a main site at your own
										domain, you can add Navigation Links to other sites and
										services. Alternatively, as a sub-domain or members site,
										you can customize your navigation to match your main site.
										If you don't have a domain, you can also use your{' '}
										<a href="#link-via-admin" target="_blank">
											common.space/@spacename URL
										</a>
										. Page Elements can also be customized to match the details
										of your content, community, and members.
									</p>
								</div>
								<form id="cs-theming-navlinks-panel" className="cs-panel">
									{/* <!-- ANCHOR Panel Heading & Toggle --> */}
									<div className="cs-panel-heading">
										<div
											id="cs-theming-navlinks-title"
											className="cs-panel-title"
										>
											<h3>Navigation Links</h3>
											<a
												id="cs-theming-navlinks-toggle"
												target="_blank"
												className="button-icon square gradient cs-panel-toggle active"
												onClick={toggleNavigationLinks}
											>
												<span>
													<p>
														<i className="fa-regular fa-angle-down"></i>
													</p>
												</span>
											</a>
										</div>
									</div>

									{/* <!-- ANCHOR Panel Content Wrapper --> */}
									<div
										id="cs-theming-navlinks-content"
										className="cs-panel-content"
									>
										{/* <!-- ANCHOR Panel Intro --> */}
										<div className="block-full">
											<p>
												Both your Header and Footer Navigation have links
												which are required and optional customizable links.
												Your Header Navigation has up to 3 customizable
												links, and your Footer has up to 5. You can also
												arrange the link order.
											</p>

											{/* <!-- SECTION Header Navigation --> */}
											<div className="formrow subtitle">
												<h4 className="hr-inline gradient">
													Header Navigation Links
												</h4>
												<div>
													<p>Optional</p>
												</div>
											</div>

											<div className="formrow">
												<div
													id="draggable-links"
													className="cs-stack-form-drag-links"
												>
													{/* <!-- ANCHOR Nav Link Form --> */}
													<div
														draggable="true"
														className="cs-form-drag-link"
														data-index="0"
													>
														<div className="cs-stack-form-drag-link">
															<div className="cs-controls-draggable">
																<p>
																	<i className="fa-regular fa-grip-lines"></i>
																</p>
																<div className="cs-controls-draggable-buttons">
																	<button>
																		<i className="fa-regular fa-angle-up"></i>
																	</button>
																	<button>
																		<i className="fa-regular fa-angle-down"></i>
																	</button>
																</div>
															</div>
															<div className="form-input air unlabeled">
																<label for="title">
																	Link Title
																</label>
																<input
																	id="title"
																	name="title"
																	type="text"
																	onChange={(e) =>
																		setHeaderNavigationLink1(
																			e.target.value
																		)
																	}
																	onClick={() =>
																		tooltipFocused(
																			'header_navigation_link1'
																		)
																	}
																	value={headerNavigationLink1}
																	ref={headerNavigationLink1Ref}
																	maxLength={16}
																	placeholder="Link Title"
																/>
															</div>
															<div className="form-input air unlabeled">
																<label for="url">Link URL</label>
																<input
																	id="url"
																	name="url"
																	type="url"
																	onChange={(e) =>
																		setHeaderNavigationLink1Url(
																			e.target.value
																		)
																	}
																	onClick={() =>
																		tooltipFocused(
																			'header_navigation_link1_url'
																		)
																	}
																	value={headerNavigationLink1Url}
																	ref={
																		headerNavigationLink1UrlRef
																	}
																	placeholder="domain.com/page/url"
																/>
															</div>
															<div>
																<DropdownWithItems
																	className="form-input-dropdown gradient air"
																	id="menu-button"
																	aria-expanded="false"
																	aria-haspopup="true"
																	selectedValue={
																		selectedHeaderLink1OpenType
																	}
																	listItems={CURRENT_LINK_OPTIONS}
																	onDropdownChange={(value) => {
																		setSelectedHeaderLink1OpenType(
																			value
																		);
																	}}
																>
																	<span>
																		<p>Same Window</p>
																		<i className="fa-solid fa-caret-down"></i>
																	</span>
																</DropdownWithItems>
															</div>
														</div>
													</div>

													{/* <!-- ANCHOR Nav Link Form --> */}
													<div
														draggable="true"
														className="cs-form-drag-link"
														data-index="1"
													>
														<div className="cs-stack-form-drag-link">
															<div className="cs-controls-draggable">
																<p>
																	<i className="fa-regular fa-grip-lines"></i>
																</p>
																<div className="cs-controls-draggable-buttons">
																	<button>
																		<i className="fa-regular fa-angle-up"></i>
																	</button>
																	<button>
																		<i className="fa-regular fa-angle-down"></i>
																	</button>
																</div>
															</div>
															<div className="form-input air unlabeled">
																<label for="title">
																	Link Title
																</label>
																<input
																	id="title"
																	name="title"
																	type="text"
																	onChange={(e) =>
																		setHeaderNavigationLink2(
																			e.target.value
																		)
																	}
																	onClick={() =>
																		tooltipFocused(
																			'header_navigation_link2'
																		)
																	}
																	value={headerNavigationLink2}
																	ref={headerNavigationLink2Ref}
																	maxLength={16}
																	placeholder="Link Title"
																/>
															</div>
															<div className="form-input air unlabeled">
																<label for="url">Link URL</label>
																<input
																	id="url"
																	name="url"
																	type="url"
																	onChange={(e) =>
																		setHeaderNavigationLink2Url(
																			e.target.value
																		)
																	}
																	onClick={() =>
																		tooltipFocused(
																			'header_navigation_link2_url'
																		)
																	}
																	value={headerNavigationLink2Url}
																	ref={
																		headerNavigationLink2UrlRef
																	}
																	placeholder="domain.com/page/url"
																/>
															</div>
															<div>
																<DropdownWithItems
																	type="button"
																	className="form-input-dropdown gradient air"
																	id="menu-button"
																	aria-expanded="false"
																	aria-haspopup="true"
																	selectedValue={
																		selectedHeaderLink2OpenType
																	}
																	listItems={CURRENT_LINK_OPTIONS}
																	onDropdownChange={(value) =>
																		setSelectedHeaderLink2OpenType(
																			value
																		)
																	}
																>
																	<span>
																		<p>Same Window</p>
																		<i className="fa-solid fa-caret-down"></i>
																	</span>
																</DropdownWithItems>
															</div>
														</div>
													</div>

													{/* <!-- ANCHOR Nav Link Form --> */}
													<div
														draggable="true"
														className="cs-form-drag-link"
														data-index="2"
													>
														<div className="cs-stack-form-drag-link">
															<div className="cs-controls-draggable">
																<p>
																	<i className="fa-regular fa-grip-lines"></i>
																</p>
																<div className="cs-controls-draggable-buttons">
																	<button>
																		<i className="fa-regular fa-angle-up"></i>
																	</button>
																	<button>
																		<i className="fa-regular fa-angle-down"></i>
																	</button>
																</div>
															</div>
															<div className="form-input air unlabeled">
																<label for="title">
																	Link Title
																</label>
																<input
																	id="title"
																	name="title"
																	type="text"
																	onChange={(e) =>
																		setHeaderNavigationLink3(
																			e.target.value
																		)
																	}
																	onClick={() =>
																		tooltipFocused(
																			'header_navigation_link3'
																		)
																	}
																	value={headerNavigationLink3}
																	ref={headerNavigationLink3Ref}
																	maxLength={16}
																	placeholder="Link Title"
																/>
															</div>
															<div className="form-input air unlabeled">
																<label for="url">Link URL</label>
																<input
																	id="url"
																	name="url"
																	type="url"
																	onChange={(e) =>
																		setHeaderNavigationLink3Url(
																			e.target.value
																		)
																	}
																	onClick={() =>
																		tooltipFocused(
																			'header_navigation_link3_url'
																		)
																	}
																	value={headerNavigationLink3Url}
																	ref={
																		headerNavigationLink3UrlRef
																	}
																	placeholder="domain.com/page/url"
																/>
															</div>
															<div>
																<DropdownWithItems
																	type="button"
																	className="form-input-dropdown gradient air"
																	id="menu-button"
																	aria-expanded="false"
																	aria-haspopup="true"
																	selectedValue={
																		selectedHeaderLink3OpenType
																	}
																	listItems={CURRENT_LINK_OPTIONS}
																	onDropdownChange={(value) =>
																		setSelectedHeaderLink3OpenType(
																			value
																		)
																	}
																>
																	<span>
																		<p>Same Window</p>
																		<i className="fa-solid fa-caret-down"></i>
																	</span>
																</DropdownWithItems>
															</div>
														</div>
													</div>

													{/* <!-- ANCHOR Nav Link Form --> */}
													<div
														draggable="true"
														className="cs-form-drag-link"
														data-index="3"
													>
														<div className="cs-stack-form-drag-link">
															<div className="cs-controls-draggable">
																<p>
																	<i className="fa-regular fa-grip-lines"></i>
																</p>
																<div className="cs-controls-draggable-buttons">
																	<button>
																		<i className="fa-regular fa-angle-up"></i>
																	</button>
																	<button>
																		<i className="fa-regular fa-angle-down"></i>
																	</button>
																</div>
															</div>
															<div className="form-input air unlabeled">
																<label for="title">
																	Link Title
																</label>
																<input
																	id="title"
																	name="title"
																	type="text"
																	onChange={(e) =>
																		setHeaderNavigationLink4(
																			e.target.value
																		)
																	}
																	onClick={() =>
																		tooltipFocused(
																			'header_navigation_link4'
																		)
																	}
																	value={headerNavigationLink4}
																	ref={headerNavigationLink4Ref}
																	maxLength={16}
																	placeholder="Link Title"
																/>
															</div>
															<div className="form-input air unlabeled">
																<label for="url">Link URL</label>
																<input
																	id="url"
																	name="url"
																	type="url"
																	onChange={(e) =>
																		setHeaderNavigationLink4Url(
																			e.target.value
																		)
																	}
																	onClick={() =>
																		tooltipFocused(
																			'header_navigation_link4_url'
																		)
																	}
																	value={headerNavigationLink4Url}
																	ref={
																		headerNavigationLink4UrlRef
																	}
																	placeholder="domain.com/page/url"
																/>
															</div>
															<div>
																<DropdownWithItems
																	type="button"
																	className="form-input-dropdown gradient air"
																	id="menu-button"
																	aria-expanded="false"
																	aria-haspopup="true"
																	selectedValue={
																		selectedHeaderLink4OpenType
																	}
																	listItems={CURRENT_LINK_OPTIONS}
																	onDropdownChange={(value) =>
																		setSelectedHeaderLink4OpenType(
																			value
																		)
																	}
																>
																	<span>
																		<p>Same Window</p>
																		<i className="fa-solid fa-caret-down"></i>
																	</span>
																</DropdownWithItems>
															</div>
														</div>
													</div>
												</div>
												<div>
													<p>
														Add custom links to your Footer and order
														them here. You can also set if the link
														should open in a new window/tab.
													</p>
												</div>
											</div>
										</div>
										{/* <!-- !SECTION Header Navigation -->

                                    <!-- SECTION Footer Navigation --> */}
										<div className="formrow subtitle">
											<h4 className="hr-inline gradient">
												Footer Navigation Links
											</h4>
											<div>
												<p>Optional</p>
											</div>
										</div>

										<div className="formrow">
											<div className="cs-stack-form-inputs stack-col cs-stack-element-preview-form-input">
												<div className="form-input air">
													<label for="text">
														Footer Links Column Title
													</label>
													<input
														id="footer_navigation_list_title"
														name="text"
														type="text"
														placeholder="Community"
														onClick={() =>
															tooltipFocused(
																'footer_navigation_list_title'
															)
														}
														onChange={(e) =>
															setFooterNavigationListTitle(
																e.target.value
															)
														}
														value={footerNavigationListTitle}
														ref={footerNavigationListTitleRef}
														maxLength={32}
													/>
												</div>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													This Title appears before the links and is
													required if you add any custom links.
												</p>
											</div>
										</div>

										<div className="formrow">
											<div
												id="draggable-links_2"
												className="cs-stack-form-drag-links"
											>
												{/* <!-- ANCHOR Nav Link Form --> */}
												<div
													draggable="true"
													className="cs-form-drag-link"
													data-index="7"
												>
													<div className="cs-stack-form-drag-link">
														<div className="cs-controls-draggable">
															<p>
																<i className="fa-regular fa-grip-lines"></i>
															</p>
															<div className="cs-controls-draggable-buttons">
																<button>
																	<i className="fa-regular fa-angle-up"></i>
																</button>
																<button>
																	<i className="fa-regular fa-angle-down"></i>
																</button>
															</div>
														</div>
														<div className="form-input air unlabeled">
															<label for="title">Link Title</label>
															<input
																id="title"
																name="title"
																type="text"
																placeholder="Link Title"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link1'
																	)
																}
																type="text"
																onChange={(e) =>
																	setFooterNavigationLink1(
																		e.target.value
																	)
																}
																value={footerNavigationLink1}
																ref={footerNavigationLink1Ref}
																maxLength={16}
															/>
														</div>
														<div className="form-input air unlabeled">
															<label for="url">Link URL</label>
															<input
																id="url"
																name="url"
																type="url"
																placeholder="domain.com/page/url"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link1_url'
																	)
																}
																onChange={(e) =>
																	setFooterNavigationLink1Url(
																		e.target.value
																	)
																}
																value={footerNavigationLink1Url}
																ref={footerNavigationLink1UrlRef}
															/>
														</div>
														<div>
															<DropdownWithItems
																type="button"
																className="form-input-dropdown gradient air"
																id="menu-button"
																aria-expanded="false"
																aria-haspopup="true"
																selectedValue={
																	selectedFooterLink1OpenType
																}
																listItems={CURRENT_LINK_OPTIONS}
																onDropdownChange={(value) =>
																	setSelectedFooterLink1OpenType(
																		value
																	)
																}
															>
																<span>
																	<p>New Window</p>
																	<i className="fa-solid fa-caret-down"></i>
																</span>
															</DropdownWithItems>
														</div>
													</div>
												</div>

												{/* <!-- ANCHOR Nav Link Form --> */}
												<div
													draggable="true"
													className="cs-form-drag-link"
													data-index="8"
												>
													<div className="cs-stack-form-drag-link">
														<div className="cs-controls-draggable">
															<p>
																<i className="fa-regular fa-grip-lines"></i>
															</p>
															<div className="cs-controls-draggable-buttons">
																<button>
																	<i className="fa-regular fa-angle-up"></i>
																</button>
																<button>
																	<i className="fa-regular fa-angle-down"></i>
																</button>
															</div>
														</div>
														<div className="form-input air unlabeled">
															<label for="title">Link Title</label>
															<input
																id="title"
																name="title"
																type="text"
																placeholder="Link Title"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link2'
																	)
																}
																type="text"
																onChange={(e) =>
																	setFooterNavigationLink2(
																		e.target.value
																	)
																}
																value={footerNavigationLink2}
																ref={footerNavigationLink2Ref}
																maxLength={16}
															/>
														</div>
														<div className="form-input air unlabeled">
															<label for="url">Link URL</label>
															<input
																id="url"
																name="url"
																type="url"
																placeholder="domain.com/page/url"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link2_url'
																	)
																}
																onChange={(e) =>
																	setFooterNavigationLink2Url(
																		e.target.value
																	)
																}
																value={footerNavigationLink2Url}
																ref={footerNavigationLink2UrlRef}
															/>
														</div>
														<div>
															<DropdownWithItems
																type="button"
																className="form-input-dropdown gradient air"
																id="menu-button"
																aria-expanded="false"
																aria-haspopup="true"
																selectedValue={
																	selectedFooterLink2OpenType
																}
																listItems={CURRENT_LINK_OPTIONS}
																onDropdownChange={(value) =>
																	setSelectedFooterLink2OpenType(
																		value
																	)
																}
															>
																<span>
																	<p>New Window</p>
																	<i className="fa-solid fa-caret-down"></i>
																</span>
															</DropdownWithItems>
														</div>
													</div>
												</div>

												{/* <!-- ANCHOR Nav Link Form --> */}
												<div
													draggable="true"
													className="cs-form-drag-link"
													data-index="9"
												>
													<div className="cs-stack-form-drag-link">
														<div className="cs-controls-draggable">
															<p>
																<i className="fa-regular fa-grip-lines"></i>
															</p>
															<div className="cs-controls-draggable-buttons">
																<button>
																	<i className="fa-regular fa-angle-up"></i>
																</button>
																<button>
																	<i className="fa-regular fa-angle-down"></i>
																</button>
															</div>
														</div>
														<div className="form-input air unlabeled">
															<label for="title">Link Title</label>
															<input
																id="title"
																name="title"
																type="text"
																placeholder="Link Title"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link3'
																	)
																}
																type="text"
																onChange={(e) =>
																	setFooterNavigationLink3(
																		e.target.value
																	)
																}
																value={footerNavigationLink3}
																ref={footerNavigationLink3Ref}
																maxLength={16}
															/>
														</div>
														<div className="form-input air unlabeled">
															<label for="url">Link URL</label>
															<input
																id="url"
																name="url"
																type="url"
																placeholder="domain.com/page/url"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link3_url'
																	)
																}
																onChange={(e) =>
																	setFooterNavigationLink3Url(
																		e.target.value
																	)
																}
																value={footerNavigationLink3Url}
																ref={footerNavigationLink3UrlRef}
															/>
														</div>
														<div>
															<DropdownWithItems
																type="button"
																className="form-input-dropdown gradient air"
																id="menu-button"
																aria-expanded="false"
																aria-haspopup="true"
																selectedValue={
																	selectedFooterLink3OpenType
																}
																listItems={CURRENT_LINK_OPTIONS}
																onDropdownChange={(value) =>
																	setSelectedFooterLink3OpenType(
																		value
																	)
																}
															>
																<span>
																	<p>New Window</p>
																	<i className="fa-solid fa-caret-down"></i>
																</span>
															</DropdownWithItems>
														</div>
													</div>
												</div>

												{/* <!-- ANCHOR Nav Link Form --> */}
												<div draggable="true" className="cs-form-drag-link">
													<div className="cs-stack-form-drag-link">
														<div className="cs-controls-draggable">
															<p>
																<i className="fa-regular fa-grip-lines"></i>
															</p>
															<div className="cs-controls-draggable-buttons">
																<button>
																	<i className="fa-regular fa-angle-up"></i>
																</button>
																<button>
																	<i className="fa-regular fa-angle-down"></i>
																</button>
															</div>
														</div>
														<div className="form-input air unlabeled">
															<label for="title">Link Title</label>
															<input
																id="title"
																name="title"
																type="text"
																placeholder="Link Title"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link4'
																	)
																}
																type="text"
																onChange={(e) =>
																	setFooterNavigationLink4(
																		e.target.value
																	)
																}
																value={footerNavigationLink4}
																ref={footerNavigationLink4Ref}
																maxLength={16}
															/>
														</div>
														<div className="form-input air unlabeled">
															<label for="url">Link URL</label>
															<input
																id="url"
																name="url"
																type="url"
																placeholder="domain.com/page/url"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link4_url'
																	)
																}
																onChange={(e) =>
																	setFooterNavigationLink4Url(
																		e.target.value
																	)
																}
																value={footerNavigationLink4Url}
																ref={footerNavigationLink4UrlRef}
															/>
														</div>
														<div>
															<DropdownWithItems
																type="button"
																className="form-input-dropdown gradient air"
																id="menu-button"
																aria-expanded="false"
																aria-haspopup="true"
																selectedValue={
																	selectedFooterLink4OpenType
																}
																listItems={CURRENT_LINK_OPTIONS}
																onDropdownChange={(value) =>
																	setSelectedFooterLink4OpenType(
																		value
																	)
																}
															>
																<span>
																	<p>New Window</p>
																	<i className="fa-solid fa-caret-down"></i>
																</span>
															</DropdownWithItems>
														</div>
													</div>
												</div>

												{/* <!-- ANCHOR Nav Link Form --> */}
												<div draggable="true" className="cs-form-drag-link">
													<div className="cs-stack-form-drag-link">
														<div className="cs-controls-draggable">
															<p>
																<i className="fa-regular fa-grip-lines"></i>
															</p>
															<div className="cs-controls-draggable-buttons">
																<button>
																	<i className="fa-regular fa-angle-up"></i>
																</button>
																<button>
																	<i className="fa-regular fa-angle-down"></i>
																</button>
															</div>
														</div>
														<div className="form-input air unlabeled">
															<label for="title">Link Title</label>
															<input
																id="title"
																name="title"
																type="text"
																placeholder="Link Title"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link5'
																	)
																}
																type="text"
																onChange={(e) =>
																	setFooterNavigationLink5(
																		e.target.value
																	)
																}
																value={footerNavigationLink5}
																ref={footerNavigationLink5Ref}
																maxLength={16}
															/>
														</div>
														<div className="form-input air unlabeled">
															<label for="url">Link URL</label>
															<input
																id="url"
																name="url"
																type="url"
																placeholder="domain.com/page/url"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link5_url'
																	)
																}
																onChange={(e) =>
																	setFooterNavigationLink5Url(
																		e.target.value
																	)
																}
																value={footerNavigationLink5Url}
																ref={footerNavigationLink5UrlRef}
															/>
														</div>
														<div>
															<DropdownWithItems
																type="button"
																className="form-input-dropdown gradient air"
																id="menu-button"
																aria-expanded="false"
																aria-haspopup="true"
																selectedValue={
																	selectedFooterLink5OpenType
																}
																listItems={CURRENT_LINK_OPTIONS}
																onDropdownChange={(value) =>
																	setSelectedFooterLink5OpenType(
																		value
																	)
																}
															>
																<span>
																	<p>New Window</p>
																	<i className="fa-solid fa-caret-down"></i>
																</span>
															</DropdownWithItems>
														</div>
													</div>
												</div>
												{/* <div draggable="true" className="cs-form-drag-link">
													<div className="cs-stack-form-drag-link">
														<div className="cs-controls-draggable">
															<p>
																<i className="fa-regular fa-grip-lines"></i>
															</p>
															<div className="cs-controls-draggable-buttons">
																<button>
																	<i className="fa-regular fa-angle-up"></i>
																</button>
																<button>
																	<i className="fa-regular fa-angle-down"></i>
																</button>
															</div>
														</div>
														<div className="form-input air unlabeled">
															<label for="title">Link Title</label>
															<input
																id="title"
																name="title"
																type="text"
																placeholder="Link Title"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link6'
																	)
																}
																type="text"
																onChange={(e) =>
																	setFooterNavigationLink6(
																		e.target.value
																	)
																}
																value={footerNavigationLink6}
																ref={footerNavigationLink6Ref}
																maxLength={16}
															/>
														</div>
														<div className="form-input air unlabeled">
															<label for="url">Link URL</label>
															<input
																id="url"
																name="url"
																type="url"
																placeholder="domain.com/page/url"
																onClick={() =>
																	tooltipFocused(
																		'footer_navigation_link6_url'
																	)
																}
																onChange={(e) =>
																	setFooterNavigationLink6Url(
																		e.target.value
																	)
																}
																value={footerNavigationLink6Url}
																ref={footerNavigationLink6UrlRef}
															/>
														</div>
														<div>
															<DropdownWithItems
																type="button"
																className="form-input-dropdown gradient air"
																id="menu-button"
																aria-expanded="false"
																aria-haspopup="true"
																selectedValue={
																	selectedFooterLink6OpenType
																}
																listItems={CURRENT_LINK_OPTIONS}
																onDropdownChange={(value) =>
																	setSelectedFooterLink6OpenType(
																		value
																	)
																}
															>
																<span>
																	<p>New Window</p>
																	<i className="fa-solid fa-caret-down"></i>
																</span>
															</DropdownWithItems>
														</div>
													</div>
												</div> */}
											</div>
											<div>
												<p>
													Add custom links to your Footer and order them
													here. You can also set if the link should open
													in a new window/tab.
												</p>
											</div>
										</div>

										<div className="formrow">
											<hr />
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>
										<div className="formrow ">
											<div className="">
												{(error1 || success1) && (
													<section
														className={`alert banner ${
															success1 ? 'success' : 'error'
														} px-section-safeview mt-4`}
													>
														<div className="action">
															<i
																className={`fa-solid ${
																	success1
																		? 'fa-circle-check'
																		: 'fa-triangle-exclamation'
																}`}
															></i>
														</div>
														<div className="content">
															<hgroup>
																<h6>
																	{success1 ? 'Success' : 'Error'}
																</h6>
																<p>
																	{success1
																		? 'Navigation Links has been successfully saved.'
																		: error1}
																</p>
															</hgroup>
														</div>
														<div className="action">
															<a href="#">
																<i className="fa-solid fa-xmark"></i>
															</a>
														</div>
													</section>
												)}
											</div>
											<p></p>
										</div>
										<div className="formrow">
											<button
												className="primary-gradient"
												type="submit"
												onClick={() => submitProNav(true)}
												disabled={submitted1}
											>
												<span>
													<p>
														{submitted1 && (
															<i className="fa-solid fa-spinner fa-spin"></i>
														)}
														Save Navigation Links
													</p>
												</span>
											</button>
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>
									</div>
								</form>

								<form id="cs-theming-blocks-panel" className="cs-panel">
									<div className="cs-panel-heading">
										<div
											id="cs-theming-blocks-title"
											className="cs-panel-title"
										>
											<h3>Page Elements</h3>
											<a
												onClick={togglePageElements}
												id="cs-theming-blocks-toggle"
												target="_blank"
												className="button-icon square gradient cs-panel-toggle active"
											>
												<span>
													<p>
														<i className="fa-regular fa-angle-down"></i>
													</p>
												</span>
											</a>
										</div>
									</div>

									<div
										id="cs-theming-blocks-content"
										className="cs-panel-content "
									>
										<div className="block-full">
											<p>
												Page Elements are different customizable titles,
												blocks, and buttons on different pages of your
												Space. You can custimoze each one to match your
												business and community.
											</p>
										</div>
										<div className="formrow subtitle">
											<h4 className="hr-inline gradient">Home</h4>
											<div>
												<p>Optional</p>
											</div>
										</div>
										<div className="formrow">
											<div className="cs-stack-form-inputs stack-col cs-stack-element-preview-form-input">
												<div className="cs-stack-element-preview">
													<div className="cs-element-preview-label">
														<h5>Welcome Block Join Button</h5>
													</div>
													<button
														className="button md disabled"
														type="button"
													>
														<span>
															<p>Join the Community</p>
														</span>
													</button>
												</div>
												<div className="form-input air">
													<label for="text">Join Button Text</label>
													<input
														id="joinTitle"
														name="joinTitle"
														type="text"
														value={joinTitle}
														onChange={(e) =>
															setJoinTitle(e.target.value)
														}
														placeholder="Join the Community"
													/>
												</div>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													This is the call-to-action button in the Welcome
													Block of the Home page. It links to the Join
													Block showing Memberships.
												</p>
											</div>
										</div>
										<div className="formrow">
											<div className="cs-stack-form-inputs stack-col cs-stack-element-preview-form-input">
												<div className="cs-stack-element-preview">
													<div className="cs-element-preview-label">
														<h5>Join Community Block Title</h5>
													</div>
													<h3>
														<span className="text-primary">Join</span>{' '}
														Friends & Mike
													</h3>
												</div>
												<div className="stack-row">
													<div className="form-input air">
														<label for="text">Title First Half</label>
														<input
															id="communityTitleFirst"
															name="communityTitleFirst"
															type="text"
															value={communityTitleFirst}
															onChange={(e) =>
																setCommunityTitleFirst(
																	e.target.value
																)
															}
															placeholder="Join"
														/>
													</div>
													<div className="form-input air">
														<label for="text">Title Second Half</label>
														<input
															id="communityTitleSecond"
															name="communityTitleSecond"
															type="text"
															value={communityTitleSecond}
															onChange={(e) =>
																setCommunityTitleSecond(
																	e.target.value
																)
															}
															placeholder="Second Title Half"
														/>
													</div>
												</div>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													This is the title for the Join Block on your
													Home page featuring Memberships & Item.
												</p>
											</div>
										</div>
										<div className="formrow">
											<div className="cs-stack-form-inputs stack-col cs-stack-element-preview-form-input">
												<div className="cs-stack-element-preview">
													<div className="cs-element-preview-label">
														<h5>Featured Links Block Title</h5>
													</div>
													<h3>
														<span className="text-primary">
															Featured
														</span>{' '}
														Links
													</h3>
												</div>
												<div className="stack-row">
													<div className="form-input air">
														<label for="text">Title First Half</label>
														<input
															id="featuredLinksFirstTitle"
															name="featuredLinksFirstTitle"
															type="text"
															value={featuredLinksFirstTitle}
															onChange={(e) =>
																setFeaturedLinksFirstTitle(
																	e.target.value
																)
															}
															placeholder="Featured"
														/>
													</div>
													<div className="form-input air">
														<label for="text">Title Second Half</label>
														<input
															id="featuredLinksSecondTitle"
															name="featuredLinksSecondTitle"
															type="text"
															value={featuredLinksSecondTitle}
															onChange={(e) =>
																setFeaturedLinksSecondTitle(
																	e.target.value
																)
															}
															placeholder="Links"
														/>
													</div>
												</div>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													This is the title for the Featured Links Block
													on your Home page.
												</p>
											</div>
										</div>
										<div className="formrow subtitle">
											<h4 className="hr-inline gradient">Members</h4>
											<div>
												<p>Optional</p>
											</div>
										</div>
										<div className="formrow">
											<div className="cs-stack-form-inputs stack-col cs-stack-element-preview-form-input">
												<div className="cs-stack-element-preview">
													<div className="cs-element-preview-label">
														<h5>Members Access Block Title</h5>
													</div>
													<h3>
														<span className="text-primary">
															Members
														</span>{' '}
														Access
													</h3>
												</div>
												<div className="stack-row">
													<div className="form-input air">
														<label for="text">Title First Half</label>
														<input
															id="memberAccessFirstTitle"
															name="memberAccessFirstTitle"
															type="text"
															value={memberAccessFirstTitle}
															onChange={(e) =>
																setMemberAccessFirstTitle(
																	e.target.value
																)
															}
															placeholder="Members"
														/>
													</div>
													<div className="form-input air">
														<label for="text">Title Second Half</label>
														<input
															id="memberAccessSecondTitle"
															name="memberAccessSecondTitle"
															type="text"
															value={memberAccessSecondTitle}
															onChange={(e) =>
																setMemberAccessSecondTitle(
																	e.target.value
																)
															}
															placeholder="Access"
														/>
													</div>
												</div>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													This is the title for the Members Access Block
													on your Members page which provides access to
													Channels and other Member Only features.
												</p>
											</div>
										</div>
										<div className="formrow">
											<div className="cs-stack-form-inputs stack-col cs-stack-element-preview-form-input">
												<div className="cs-stack-element-preview">
													<div className="cs-element-preview-label">
														<h5>Featured Products Block Title</h5>
													</div>
													<h3>
														<span className="text-primary">
															Featured
														</span>{' '}
														Memberships & Items
													</h3>
												</div>
												<div className="stack-row">
													<div className="form-input air">
														<label for="text">Title First Half</label>
														<input
															id="featuredMembershipsFirstTitle"
															name="featuredMembershipsFirstTitle"
															type="text"
															value={featuredMembershipsFirstTitle}
															onChange={(e) =>
																setFeaturedMembershipsFirstTitle(
																	e.target.value
																)
															}
															placeholder="Featured"
														/>
													</div>
													<div className="form-input air">
														<label for="text">Title Second Half</label>
														<input
															id="featuredMembershipsSecondTitle"
															name="featuredMembershipsSecondTitle"
															type="text"
															value={featuredMembershipsSecondTitle}
															onChange={(e) =>
																setFeaturedMembershipsSecondTitle(
																	e.target.value
																)
															}
															placeholder="Memberships & Items"
														/>
													</div>
												</div>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													This is the title for the Featured Memberships &
													Items Block on your Members page which can
													feature different Memberships & Items to current
													Members.
												</p>
											</div>
										</div>
										<div className="formrow ">
											<hr />
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>{' '}
										<div className="formrow ">
											<div className="">
												{(error2 || success2) && (
													<section
														className={`alert banner ${
															success2 ? 'success' : 'error'
														} px-section-safeview mt-4`}
													>
														<div className="action">
															<i
																className={`fa-solid ${
																	success2
																		? 'fa-circle-check'
																		: 'fa-triangle-exclamation'
																}`}
															></i>
														</div>
														<div className="content">
															<hgroup>
																<h6>
																	{success2 ? 'Success' : 'Error'}
																</h6>
																<p>
																	{success2
																		? 'Page Elements has been successfully saved.'
																		: error2}
																</p>
															</hgroup>
														</div>
														<div className="action">
															<a href="#">
																<i className="fa-solid fa-xmark"></i>
															</a>
														</div>
													</section>
												)}
											</div>
											<p></p>
										</div>
										<div className="formrow">
											<a
												onClick={() => submitProPageElements(true)}
												className="button-primary-gradient"
											>
												<span>
													<p>
														{submitted2 && (
															<i className="fa-solid fa-spinner fa-spin"></i>
														)}
														Save Page Elements
													</p>
												</span>
											</a>
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>
										<div className="formrow">
											<div>
												<hr />
											</div>
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>
									</div>
								</form>
								<form id="cs-theming-domain-panel" className="cs-panel">
									<div className="cs-panel-heading">
										<div
											id="cs-theming-domain-title"
											className="cs-panel-title"
										>
											<h3>Domain</h3>
											<a
												onClick={toggleDomain}
												id="cs-theming-domain-toggle"
												target="_blank"
												className="button-icon square gradient cs-panel-toggle active"
											>
												<span>
													<p>
														<i className="fa-regular fa-angle-down"></i>
													</p>
												</span>
											</a>
										</div>
									</div>

									<div
										id="cs-theming-domain-content"
										className="cs-panel-content "
									>
										<div className="block-full">
											<p>
												Your Space can be configured with your own custom
												domain (e.g. mydomain.com) or sub-domain (e.g.
												members.mydomain.com). You can use an existing
												domain that you own or purchase a new one from your
												preferred registrar, then follow the steps below to
												configure it to your Space. All Spaces also have a{' '}
												<a href="#link-via-admin" target="_blank">
													common.space/@spacename URL
												</a>
												.
											</p>
											<p>
												To configure a domain/subdomain, you will need to
												access the DNS settings for your domain. Be sure to
												look up how to manage DNS settings on your registrar
												website. This can usaully be found in your domain's
												settings or by searching your registrar help system.
												For example, the{' '}
												<a
													href="https://www.godaddy.com/help/manage-dns-records-680"
													target="_blank"
												>
													GoDaddy DNS support page is here
												</a>{' '}
												and the{' '}
												<a
													href="https://www.namecheap.com/support/knowledgebase/article.aspx/434/2237/how-do-i-set-up-host-records-for-a-domain/"
													target="_blank"
												>
													Namecheap page is here
												</a>
												.
											</p>
										</div>

										<div className="formrow subtitle">
											<h4 className="hr-inline gradient">
												Configuring a Domain
											</h4>
											<div>
												<p>Optional</p>
											</div>
										</div>
										<div className="formrow">
											<div className="cs-stack-form-inputs stack-row">
												<ol>
													<li>
														<span className="underline font-bold">
															Create a new CNAME Record
														</span>{' '}
														and set the NAME/HOST field to{' '}
														<span className="font-bold italic">
															www
														</span>{' '}
														and the DATA/VALUE field to{' '}
														<span className="font-bold italic">
															pro1.commonspace.network
														</span>
														. You can set the TTL field to{' '}
														<span className="font-bold italic">
															600
														</span>{' '}
														seconds or 1 Hour. If you already have a
														CNAME record for{' '}
														<span className="font-bold italic">
															www
														</span>{' '}
														then update it with these details instead.
													</li>
													<li>
														<span className="underline font-bold">
															Create a new A Record
														</span>{' '}
														and set the NAME/HOST field to{' '}
														<span className="font-bold italic">@</span>{' '}
														and the DATA/VALUE field to{' '}
														<span className="font-bold italic">
															13.56.168.201
														</span>
														. You can set the TTL field to 600 seconds
														or 1 Hour. If you already have a A Record
														for{' '}
														<span className="font-bold italic">@</span>{' '}
														then update it with these details instead.
													</li>
													<li>
														<span className="underline font-bold">
															Confirm your Domain
														</span>{' '}
														below or in your Dashboard under Space
														Domain settings.
													</li>
												</ol>
											</div>
											<div>
												<p>
													For more help, visit{' '}
													<a href="#set-via-admin" target="_blank">
														Setting up a Custom Domain
													</a>
													.
												</p>
											</div>
										</div>

										<div className="formrow subtitle">
											<h4 className="hr-inline gradient">
												Configuring a Subdomain
											</h4>
											<div>
												<p>Optional</p>
											</div>
										</div>
										<div className="formrow">
											<div className="cs-stack-form-inputs stack-row">
												<ol>
													<li>
														<span className="underline font-bold">
															Create a new CNAME Record
														</span>{' '}
														and set the NAME/HOST field to be the
														subdomain you would like to use. For
														example, enter{' '}
														<span className="font-bold italic">
															members
														</span>{' '}
														to set up{' '}
														<span className="italic">
															members.yourdomain.com
														</span>
														. Set the DATA/VALUE field to{' '}
														<span className="font-bold italic">
															sub1.commonspace.network
														</span>{' '}
														and the TTL field to{' '}
														<span className="font-bold italic">
															600
														</span>{' '}
														seconds or 1 Hour.
													</li>
													<li>
														<span className="underline font-bold">
															Confirm your subdomain
														</span>{' '}
														below or in your Dashboard under Space
														Domain settings.
													</li>
												</ol>
											</div>
											<div>
												<p>
													For more help, visit{' '}
													<a href="#set-via-admin" target="_blank">
														Setting up a Custom Subdomain
													</a>
													.
												</p>
											</div>
										</div>

										<div className="formrow subtitle">
											<h4 className="hr-inline gradient">
												Confirm your Domain/Subdomain
											</h4>
											<div>
												<p>Optional</p>
											</div>
										</div>

										<div className="formrow">
											<div className="cs-stack-form-inputs stack-col cs-stack-element-preview-form-input">
												<div className="form-input air">
													<label for="text">Domain/Subdomain</label>
													<input
														id="domain"
														name="domain"
														type="text"
														value={domain}
														onChange={(e) => setDomain(e.target.value)}
														placeholder="e.g. mydomain.com or members.mydomain.com"
														className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
															error3 ? 'border-red-500' : ''
														}`}
													/>
													{(error3 || success3) && (
														<section
															className={`alert banner ${
																success3 ? 'success' : 'error'
															} px-section-safeview mt-4`}
														>
															<div className="action">
																<i
																	className={`fa-solid ${
																		success3
																			? 'fa-circle-check'
																			: 'fa-triangle-exclamation'
																	}`}
																></i>
															</div>
															<div className="content">
																<hgroup>
																	<h6>
																		{success3
																			? 'Success'
																			: 'Error'}
																	</h6>
																	<p>
																		{success3
																			? 'Domain has been successfully updated.'
																			: error3}
																	</p>
																</hgroup>
															</div>
															<div className="action">
																<a href="#">
																	<i className="fa-solid fa-xmark"></i>
																</a>
															</div>
														</section>
													)}
												</div>
											</div>
											<div className="cs-formrow-helper-end">
												<p>
													Once you confirm your domain settings, your
													Space should be active under your domain or
													subdomain within 1 to 72 hours, depending on
													your registrar and location.
												</p>
											</div>
										</div>
										<div className="formrow">
											<button
												className="primary-gradient"
												type="submit"
												onClick={(e) => {
													e.preventDefault();
													submitProDomain();
												}}
												disabled={submitted3}
											>
												<span>
													<p>
														{submitted3 && (
															<i className="fa-solid fa-spinner fa-spin"></i>
														)}
														Confirm Domain
													</p>
												</span>
											</button>
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>
									</div>
								</form>
								<div className="formrow title">
									<a
										onClick={handleUpdateLastStepLink}
										className="button-secondary-gradient"
									>
										<span>
											<p>
												{' '}
												{!dropSection && 'Continue'}
												{dropSection && 'Back'}
											</p>
										</span>
									</a>
									<a onClick={viewSpace} className="button-secondary-gradient">
										<span>
											<p>
												Preview Your Space{' '}
												<i className="fa-regular fa-arrow-up-right-from-square i-r"></i>
											</p>
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default memo(CustomizeProSpace);
