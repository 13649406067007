import { createSlice } from "@reduxjs/toolkit";

export const benefitsSlice = createSlice({
    name: "benefits",
    initialState: {
        benefit: null,
        editBenefit: null,
    },
    reducers: {
        setBenefit: (state, action) => {
            state.benefit = action.payload;
        },
        setEditBenefit: (state, action) => {
            state.editBenefit = action.payload;
        }
    }
});

export const {
    setBenefit,
    setEditBenefit
} = benefitsSlice.actions;

export default benefitsSlice.reducer;