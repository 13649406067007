import React, {memo, useCallback, useEffect, useState} from "react";
import { ROOT_DOMAIN} from "../../../constants/common";
import {useDispatch, useSelector} from "react-redux";
import '../SpaceBuyMembership.scss'
import {
    getSpaceMembership, getSpaceUsernameByDomain,
} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import {useLocation, useSearchParams} from "react-router-dom";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import routes from "../../../routes";
import {getAuthUserInfo} from "../../../states/general/actions";
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import SocialLinks from "../../../components/commons/SocialLinks";

const MembershipFeatured = () => {
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);

    const currentLocation = useLocation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const membershipId = searchParams.get('id');
    const [initialLoad, setInitialLoad] = useState(true);
    const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
    const [isMembershipLoaded, setMembershipLoaded] = useState(false);

    const [membership, setMembership] = useState(null);
    const [spaceUsername, setSpaceUsername] = useState(null);

    const getMembershipDetailsRequest = useCallback(async (domain) => {
        const data = {
            space_domain: domain
        }
        const response = await getSpaceUsernameByDomain(data);
        if (response && response.result) {
            setSpaceUsername(response.space_username);
        }
    }, []);

    useEffect(() => {
        if (membership != null) {
            setMembershipLoaded(false);
        }
        if (authUserInfo===null) {
            dispatch(getAuthUserInfo());
        }
        if (spaceUsername && !isMembershipLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                // if (spaceInfo && spaceInfo.id) {
                //     getMembership(spaceInfo.id, membershipId).then((r) => {});
                //     // setSpaceName(spaceInfo.name);
                // }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(spaceUsername));
                }
                if (spaceInfoLoading === 'failed') {
                    window.location.replace(routes.createSpace());
                }
            }
        }
        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);
    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        spaceUsername,
        membershipId,
        membership,
    ]);

    const redirectToAsGuest = () => {
        const url = routes.viewUserSpace() + spaceInfo?.username;
        window.location.replace(url);
    }

    const redirectToMemberships = () => {
        const url = routes.viewUserSpace() + spaceInfo?.username + '/member';
        window.location.replace(url);
    }

    const handleMembershipBuyRedirect = (id) => {
        const url = routes.spaceMembershipBuy();
        const fin_url = url.replace(':space_username', '@' + spaceInfo?.username) + '?id=' + id;
        window.location.replace(fin_url);
    }

    useEffect(() => {
        if (ROOT_DOMAIN !== window.location.hostname) {
            getMembershipDetailsRequest(window.location.hostname).then(r => {});
        } else {
            const path = currentLocation.pathname;
            if (path && path.startsWith('/@') && path.length > 3) {
                const clean_path = path.replace('/@', '');
                const path_exp = clean_path.split('/');
                setSpaceUsername(path_exp[0]);
            }
        }
    }, [currentLocation.pathname]);


    return (
        <>
            { (!authUserInfo || !spaceInfo) ? (
                <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                    <div className="centerframe-flex frame-modal">
                        <div className="block-lg modal-block">
                            <i className="fa-regular fa-solar-system"/>
                        </div>
                    </div>
                </section>
            ) : (
                <main id="cs-space-main" className="cs-main main-theme">
                    <section id="cs-product-membership-activate-c1" className="p-section-safeview theme-transparent">
                        <div className="centerframe-grid gap-2gutter">
                            <div id="cs-product-activate-main" className="block-lg order-1">
                                <hgroup className="heading">
                                    {/*CSJS "Membership" or "Item" based on the product type*/}
                                    <h1>Welcome, {authUserInfo?.profile?.display_name}!</h1>
                                    {/*CSJS "Membership" or "Item" based on the product type*/}
                                    <p className="text-p2">Your Profile has been created! Become a Member for full access to all the features, sections, and benefits of <a href={`/@${spaceInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a> by getting a Membership! You can view Memberships below or continue to browse as a guest.</p>

                                </hgroup>

                                <h3>Featured Memberships</h3>

                                {spaceInfo && spaceInfo?.memberships.length > 0 && spaceInfo?.memberships.slice(0, 2).map((membership, index) => (
                                    <div className="cs-product-preview-row" key={index}>
                                        <a className="avatar-square-gradient-md" onClick={()=>{handleMembershipBuyRedirect(membership?.id)}} target="_self"><img src={membership?.photo} alt={membership.name}/></a>
                                        <hgroup>
                                            {/*CSJS Product Name*/}
                                            <h5><a onClick={()=>{handleMembershipBuyRedirect(membership?.id)}} target="_self">{membership ? membership.name : ''}</a></h5>
                                            {/*CSJS Product Info Listing*/}
                                            <p>{membership ? membership.summary : ''}</p>
                                        </hgroup>
                                    </div>
                                ))}

                                {spaceInfo &&
                                    <div className="stack-buttons col">
                                        <a onClick={redirectToMemberships} type="button" className="button-primary-gradient"><span><p>Browse All Memberships</p></span></a>
                                        <a onClick={redirectToAsGuest} type="button" className="button-secondary-gradient"><span><p>Continue as Guest</p></span></a>
                                    </div>
                                }

                            </div>

                            <aside id="cs-profile-create-aside" className="block-lg order-2 hidden lg:block">
                                {/*CSJS BG Image as inline style background.*/}
                                <div className="cs-profile-preview card sticky top-2gutter-xl"
                                     style={{backgroundImage: `url("${authUserInfo?.profile?.background_photo ?? ''}")`}}
                                >
                                    {/*CSJS Add class "shader" if background-image is applied in parent element cs-profile-preview, i.e when a user has uploaded a background.*/}
                                    <div className="cs-stack-profile-preview rounded-card stack-col shader">
                                        {/*CSJS Image as inline style background.*/}
                                        <div className="form-input-media lg circle air nohover"
                                             style={{backgroundImage: `url("${authUserInfo?.profile?.photo_thumb ?? ''}")`}}
                                        >
                                            <a href="#">
                                            </a>
                                        </div>
                                        <hgroup>
                                            {/*CSJS Add "active" class when content present.*/}
                                            <p className="text-p1 active">@{authUserInfo?.profile?.username ?? 'username'}</p>
                                            <h1>{authUserInfo?.profile?.display_name ?? 'Display Name'}</h1>
                                            <p className="text-p2 italic">
                                                {authUserInfo?.profile?.pronouns ?? 'Pronouns'}
                                            </p>
                                        </hgroup>
                                        <p className="italic">{authUserInfo?.profile?.intro ?? 'Short Bio goes here'}</p>
                                        {(authUserInfo?.profile) && (
                                            <SocialLinks
                                                socialLinks={authUserInfo.profile.social_links}
                                                parentClassName="cs-stack-icons stack-row-wrap"
                                                childClassName=""
                                                removeATag={true}
                                            />
                                        )}
                                        {/*<div className="cs-stack-icons stack-row-wrap">*/}
                                        {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*	<i className="fa-brands fa-x-twitter"/>*/}
                                        {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*	<i className="fa-brands fa-instagram"/>*/}
                                        {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*	<i className="fa-brands fa-facebook-f"/>*/}
                                        {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*	<i className="fa-brands fa-tiktok"/>*/}
                                        {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*	<i className="fa-brands fa-linkedin-in"/>*/}
                                        {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*	<i className="fa-brands fa-twitch"/>*/}
                                        {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                        {/*	<i className="fa-regular fa-globe"/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </section>
                </main>
            )}
        </>
    );
}

export default memo(MembershipFeatured);