import React, { memo, useEffect, useState, useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import {
    createSpaceBenefit, deleteSpaceBenefit,
    getSpaceAccessGroups,
    updateSpaceBenefit,
    updateSpaceBenefitStatus
} from '../../../../services/space';
import { useDispatch, useSelector } from 'react-redux';
import AccessGroupModal from "../../../../components/commons/Modals/AccessGroupModal/AccessGroupModal";
import DropdownWithItems from "../../../../components/commons/DropdownWithItems"
import CreateAccessGroupModal
    from "../../../../components/commons/Modals/CreateAccessGroupModal/CreateAccessGroupModal";
import {getSitewideLink} from "../../../../utils/sitewideLinks";
import routes from "../../../../routes";
import {useNavigate} from "react-router-dom";
import {setEditBenefit} from "../../../../states/benefitsSlice";



const EditAccessBenefit = (props) => {
    const { benefitData } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [editBenefitForm] = Form.useForm();
    const [formHasError, setFormHasError] = useState(true);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [accessGroupList, setAccessGroupList] = useState([]);
    const [accessGroupModalOpen, setAccessGroupModalOpen] = useState(false);
    const [selectedAccessGroups, setSelectedAccessGroups] = useState([]);
    const [selectedAccessGroup, setSelectedAccessGroup] = useState([]);
    const [createAccessGroupModalOpen, setCreateAccessGroupModalOpen] = useState(false);
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [selectedButtonSetting, setSelectedButtonSetting] = useState()
    const [filteredAccessGroupList, setFilteredAccessGroupList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isSubmitClicked, setSubmitClicked] = useState(false);
    const [blurredField, setBlurredField] = useState(null);

    const [name, setName] = useState(null);
    const [details, setDetails] = useState(null);
    const [note, setNote] = useState(null);
    const [customUrl, setCustomUrl] = useState(null);

    const dummyButtonOptions = [
        { label: 'Default', value: 'default' },
        // { label: 'Benefits Post', value: 'benefits_post' },
        { label: 'Custom URL', value: 'custom_url' }
    ];

    const handleFormOnBlur = useCallback(async () => {
        await editBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const updateAccessGroup = (group) => {
        getSpaceAccessGroupsRequest().then(r => {});
        selectedAccessGroups.push({id: group.id, label: group.name, value: group.id});
    }

    const setFormData = async (data) => {
        if (data) {
            editBenefitForm.setFieldsValue({
                name: data.name,
                details: data.details,
                note: data.note,
                custom_url: data.custom_url,
                access_settings: data.access_settings,
                button_settings: data.button_settings
            });
            setName(data.name);
            setDetails(data.details);
            setNote(data.note);
            setCustomUrl(data.custom_url);

            if (data.access_settings) {
                setSelectedAccessGroup(data.access_settings);
            }

            if (data.button_settings) {
                setSelectedButtonSetting(data.button_settings);
            }

            await editBenefitForm
                .validateFields()
                .then(() => {
                    setFormHasError(false);
                })
                .catch((errors) => {
                    setFormHasError(true);
                });
        }
    };

    const handleSubmit = useCallback(
        async (values) => {
            if (formHasError) {
                return;
            }

            const data = { ...editBenefitForm.getFieldsValue() };
            data['benefit_id'] = benefitData.id;

            const response = await updateSpaceBenefit(data);
            if (response) {
                if (response.result) {
                    dispatch(setEditBenefit(response.benefit));
                    redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@');
                } else {
                    setAlert({ variant: 'danger', message: response.message });
                }
            }
        }, [spaceInfo, benefitData, formHasError]);

    const updateBenefitStatus = useCallback(
        async (status) => {
            const data = {
                benefit_id: benefitData.id,
                status
            };

            const response = await updateSpaceBenefitStatus(data);
            if (response) {
                if (!response.result) {
                    if (response.message) {
                        setAlert({ variant: 'danger', message: response.message });
                    } else {
                        setAlert({
                            variant: 'danger',
                            message: 'Something went wrong'
                        });
                    }
                }
            }

            redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@');
        }, [benefitData]);

    const deleteBenefit = useCallback(
        async () => {
            const data = {
                benefit_id: benefitData.id
            };

            const response = await deleteSpaceBenefit(data);
            if (response) {
                if (!response.result) {
                    if (response.message) {
                        setAlert({ variant: 'danger', message: response.message });
                    } else {
                        setAlert({
                            variant: 'danger',
                            message: 'Something went wrong'
                        });
                    }
                }
            }

            redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@');
        }, [benefitData]);

    const handleFormChanges = useCallback(async () => {
        setName(editBenefitForm.getFieldValue('name'));
        setDetails(editBenefitForm.getFieldValue('details'));
        setNote(editBenefitForm.getFieldValue('note'));
        setCustomUrl(editBenefitForm.getFieldValue('custom_url'));
        await editBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const getSpaceAccessGroupsRequest = useCallback(
        async () => {
            try {
                if (spaceInfo) {
                    const space_param = {}
                    space_param.space_id = spaceInfo.id;

                    const responseGetSpaceAccessGroups = await getSpaceAccessGroups(space_param);
                    if (responseGetSpaceAccessGroups && responseGetSpaceAccessGroups.result) {
                        let groupArray = [];
                        const groupList = responseGetSpaceAccessGroups.access_groups;
                        if (groupList && groupList.length > 0) {
                            groupList.map((group) => {
                                groupArray.push({id: group.id, label: group.name, value: group.id})
                            })
                        }
                        setAccessGroupList(groupArray);
                    }
                }
            } catch (error) {
            }
        },
        [spaceInfo]
    );

    const redirectToSpaceLink = (route, spaceUsername, prepend = '') => {
        setTimeout(()=>{
            navigate(route.replace(':space_username', prepend + spaceUsername));
        },[1500])
    };

    useEffect(() => {
        let groupList = [
            { label: 'Select Access Group', value: 'default', disabled: true },
            { label: 'Create New Access Group', value: 'create' }
        ];
        if (accessGroupList && accessGroupList.length > 0) {
            accessGroupList.map((accessGroup) => {
                groupList.push(accessGroup);
            })
        }
        // if (selectedAccessGroups && selectedAccessGroups.length > 0) {
        //     selectedAccessGroups.map((selectedAccessGroup) => {
        //         groupList.map((gList) => {
        //             if (selectedAccessGroup.value === gList.value)
        //                 groupList.shift(accessGroup);
        //         })
        //     })
        // }
        setFilteredAccessGroupList(groupList);
    }, [accessGroupList]);

    const filterDropdownChange = (value) => {
        if (value === 'create') {
            setCreateAccessGroupModalOpen(true);
            setModalOpen(false);
        } else {
            let selected = selectedAccessGroups;
            const selectedGroup = selectedAccessGroups.find((access) => access.value === value);
            const access = accessGroupList.find((access) => access.value === value);
            setSelectedAccessGroup(access.id)
            setSelectedAccessGroups(selected);
            editBenefitForm.setFieldsValue({ access_settings: access.id });
        }
    };

    const handleButtonSettingChange = (value) => {
        setSelectedButtonSetting(value)
        editBenefitForm.setFieldsValue({ button_settings: value });
    };

    const handleOnblurField = useCallback(async (field) => {
        if (!isSubmitClicked) {
            setBlurredField(field);
        }
        setSubmitClicked(false);
    }, [editBenefitForm, blurredField, isSubmitClicked]);

    useEffect(() =>{
        if (spaceInfo) {
            getSpaceAccessGroupsRequest().then(r => {});
        }
    },[spaceInfo]);

    useEffect(() =>{},[accessGroupList, editBenefitForm])

    useEffect(() => {
        if (benefitData) {
            setFormData(benefitData).then(r => {});
        }
    }, [benefitData]);


    return (
        <div className="cs-dashframe section-flex">
            <div className="cs-dash-card block-full card">
                <hgroup className="heading">
                    <h2>Edit Access Benefit</h2>
                    <p>An <a href="#link-via-admin" target="_blank">Access Benefit</a> is a type of Benefit which allows you to provide access to specific content on your Space. You can apply this Benefit to any Memberships or Items and Members with that specific Membership or Item will gain access to the specified content. This is different than standard Members Only access, which provides access to associated content to Members with <span className="italic">any</span> Membership. <a href="#link-via-admin" target="_blank">Learn more about the Access Benefit Type.</a></p>
                    {/*<p>An <a href="#link-via-admin" target="_blank">Access</a> Benefit is a type of Benefit which allows you to provide access to specific content on your Space. You can apply this Benefit to any Memberships or Items and Members with that specific Membership or Item will gain access to the specified content. This is different than standard Members Only access, which provides access to associated content to Members with any Membership. {getSitewideLink('learn_more_benefits')}</p>*/}
                </hgroup>
            </div>
            <div className="cs-dash-card block-full card">
                <Form
                    form={editBenefitForm}
                    name="editBenefitForm"
                    id="editBenefitForm"
                    validateMessages={VALIDATE_MESSAGES}
                    onFocus={() => {
                        setFormIsOnFocus(true);
                    }}
                    onBlur={() => {
                        // setTimeout(() => {
                        setFormIsOnFocus(false);
                        handleFormOnBlur().then(r => {});
                        // }, 200);
                    }}
                    onChange={handleFormChanges}
                    onFinish={handleSubmit}
                    className={formIsOnFocus ? 'hide-antd-error-messages stack-col' : 'stack-col'}
                >
                    <>
                        {alert && (
                            <div className="grid grid-cols-12 gap-8 mt-5">
                                <div className="col-span-8">
                                    <Alert
                                        key={alert.variant}
                                        variant={alert.variant}
                                        className={
                                            'custom-alert ' +
                                            (alert.variant === 'danger'
                                                ? 'custom-alert-danger'
                                                : 'custom-alert-success')
                                        }
                                    >
                                        {alert.message}
                                    </Alert>
                                </div>
                            </div>
                        )}
                    </>
                    {/* <form className="stack-col"> */}
                    <div className="formrow title">
                        <h3>Details</h3>
                        <p>Provide Benefit information</p>
                    </div>
                    <Form.Item
                        label="Name"
                        name="name"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable">
                                <span>
                                    <label htmlFor="name">Name</label>
                                    {/*<Input*/}
                                    {/*    maxLength={32}*/}
                                    {/*    onBlur={()=>handleOnblurField('name')}*/}
                                    {/*    placeholder="Up to 32 characters."*/}
                                    {/*    className="input-md body-text--small color-reg ps-4"*/}
                                    {/*/>*/}
                                    <textarea id="name"
                                           maxLength={32}
                                           value={name}
                                           onBlur={()=>handleOnblurField('name')}
                                           placeholder="Up to 32 characters."/>
                                </span>
                            </div>
                            <div>
                                <p className='text-sm text-color-secondary italic'>This will be listed on the Product page of Memberships or Items with this Benefit.</p>
                            </div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'name' || blurredField === 'all') && editBenefitForm.getFieldsError()[0]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{editBenefitForm.getFieldsError()[0]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <Form.Item
                        label="Summary"
                        name="details"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable">
                                <span>
                                    <label htmlFor="details">Summary</label>
                                    {/*<Input*/}
                                    {/*    maxLength={160}*/}
                                    {/*    onBlur={()=>handleOnblurField('details')}*/}
                                    {/*    placeholder="Up to 160 characters."*/}
                                    {/*    className="input-md body-text--small color-reg ps-4"*/}
                                    {/*/>*/}
                                    <textarea id="details"
                                           maxLength={160}
                                           value={details}
                                           onBlur={()=>handleOnblurField('details')}
                                           placeholder="Up to 160 characters."/>
                                </span>
                            </div>
                            <div>
                                <p className='text-sm text-color-secondary italic'>This Summary text with display next to the Benefit Name and should communicate what the Benefit provides.</p>
                            </div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'details' || blurredField === 'all') && editBenefitForm.getFieldsError()[1]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{editBenefitForm.getFieldsError()[1]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <Form.Item
                        label="Note"
                        name="note"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient expandable">
                                <span>
                                    <label htmlFor="note">Note</label>
                                    {/*<Input*/}
                                    {/*    maxLength={160}*/}
                                    {/*    onBlur={()=>handleOnblurField('note')}*/}
                                    {/*    placeholder="Optional, up to 160 characters."*/}
                                    {/*    className="input-md body-text--small color-reg ps-4"*/}
                                    {/*/>*/}
                                    <textarea id="note"
                                           maxLength={160}
                                           value={note}
                                           onBlur={()=>handleOnblurField('note')}
                                           placeholder="Optional, up to 160 characters."/>
                                </span>
                            </div>
                            <div><p className='text-sm text-color-secondary italic'>This private text is shown to Members as a interstitial, pop-up, or content before the Benefit is accessed.</p></div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'note' || blurredField === 'all') && editBenefitForm.getFieldsError()[2]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{editBenefitForm.getFieldsError()[2]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <div className="formrow title">
                        <h4>Settings</h4>
                        <p>Configure the functions for this Benefit type</p>
                    </div>
                    <Form.Item
                        label="Access Settings"
                        name="access_settings"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            {/* <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-key"></i><p>Select Access Group</p><i className="fa-solid fa-caret-down"></i></span></button> */}
                            <DropdownWithItems
                                title="Select Access Group"
                                customButtonClass="button gradient secondary dropdown w-full"
                                selectedValue={selectedAccessGroup}
                                listItems={filteredAccessGroupList}
                                onDropdownChange={(value) => { filterDropdownChange(value) }}
                            />
                            <div><p  className='text-sm text-color-secondary italic'>Memberships or Items with this Benefit will be added to the specified Benefit Group.</p></div>
                        </div>
                        {accessGroupModalOpen && (
                            <AccessGroupModal
                                modalOpen={accessGroupModalOpen}
                                setModalOpen={setAccessGroupModalOpen}
                                accessGroupList={accessGroupList}
                                selectedAccessGroups={selectedAccessGroups}
                                setSelectedAccessGroups={setSelectedAccessGroups}
                                setCreateAccessGroupModalOpen={setCreateAccessGroupModalOpen}
                                header_title={''}
                                spaceID={spaceInfo.id}
                                userID={authUserInfo.id}
                            />
                        )}
                        {createAccessGroupModalOpen && (
                            <CreateAccessGroupModal
                                modalOpen={createAccessGroupModalOpen}
                                setModalOpen={setCreateAccessGroupModalOpen}
                                spaceMembership={spaceInfo.memberships}
                                spaceItems={spaceInfo.items}
                                updateAccessGroupList={updateAccessGroup}
                                setAccessGroupModalOpen={setAccessGroupModalOpen}
                                spaceID={spaceInfo.id}
                                userID={authUserInfo.id}
                            />
                        )}
                    </Form.Item>

                    <div className="formrow title">
                        <h4>Button</h4>
                        <p>Set the button behavior for this Benefit</p>
                    </div>
                    <Form.Item
                        label="Button Settings"
                        name="button_settings"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            {/* <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-key"></i><p>Default</p><i className="fa-solid fa-caret-down"></i></span></button> */}
                            <DropdownWithItems
                                title="Select Button Setting"
                                customButtonClass="button gradient secondary dropdown w-full"
                                selectedValue={selectedButtonSetting}
                                listItems={dummyButtonOptions}
                                onDropdownChange={(value) => { handleButtonSettingChange(value) }}
                            />
                            <div><p className='text-sm text-color-secondary italic'>Default directly accesses the Benefit. Benefits Post links to a selected post. Custom URL opens a specified link.</p></div>
                        </div>
                    </Form.Item>

                    {selectedButtonSetting === 'custom_url' && (
                        <Form.Item
                            label="Custom URL"
                            name="custom_url"
                            className="cs-stack-controlrows stack-form-col"
                            validateTrigger="onBlur"
                            rules={[{ required: true }]}
                        >
                            <div className="formrow split">
                                <div className="form-input gradient">
                                    <span>
                                        <label htmlFor="name">Custom URL</label>
                                        {/*<input id="custom_url" name="text" type="text" required=""/>*/}
                                        <input id="custom_url"
                                               type="text"
                                               value={customUrl}
                                               onBlur={()=>handleOnblurField('custom_url')}/>
                                    </span>
                                </div>
                                <div><p className='text-sm text-color-secondary italic'>Redirect URL</p></div>
                            </div>
                        </Form.Item>
                    )}

                    {/* <Form.Item className="stack-buttons col">
                            <button
                                className="primary-gradient w-full"
                                onClick={handleSubmit}
                            >
                                <span><p>Create Benefit</p></span>
                            </button>
                            <a href="#"  onClick={()=>onCancelCreate(false)} className="button gradient secondary"><span><p>Cancel</p></span></a>
                        </Form.Item> */}
                    <div className="stack-buttons col">
                        <a onClick={handleSubmit} className="button gradient"><span><p>Save Benefit</p></span></a>
                        <a onClick={()=>{updateBenefitStatus(false).then(r => {})}} className="button gradient secondary"><span><p>Deactivate</p></span></a>
                        <p className="text-help text-center">Archiving a Benefit will remove it from use. Benefits with active stored values cannot be deleted. </p>
                        <a onClick={()=>{deleteBenefit().then(r => {})}} className="button gradient secondary"><span><p>Delete</p></span></a>
                    </div>
                </Form>
            </div>
        </div>
    )
};

export default memo(EditAccessBenefit);