import {memo, useEffect, useState} from 'react';
import moment from 'moment';
import parse from "html-react-parser";
import routes from '../../../routes';
import ReactPlayer from "react-player/lazy";
import default_bg from "../../../assets/images/form_background.png";
import {useTranslation} from "react-i18next";


const LatestUpdates = (parentProps) => {
	const { t, i18n } = useTranslation();
	const { data, user, updatesViewingUserIsMember, updateGlobalActiveTab, spaceData } = parentProps;
	const [spaceUsername, setSpaceUsername] = useState(null);

	let isMember = updatesViewingUserIsMember;
	const accessType = data?.access;

	if (accessType === 'custom' && data) {
		const ac_memberships = data?.access_groups_included_memberships ?? [];
		const ac_items = data?.access_groups_included_items ?? [];
		const owned_memberships = data?.owned_memberships_ids ? data?.owned_memberships_ids : [];
		const owned_items_ids = data?.owned_items_ids ? data?.owned_items_ids : [];

		// console.log('ac_memberships')
		// console.log(ac_memberships)
		//
		// console.log('ac_items')
		// console.log(ac_items)
		//
		// console.log('owned_memberships')
		// console.log(owned_memberships)
		//
		// console.log('owned_items_ids')
		// console.log(owned_items_ids)

		// Check if the user has any active memberships in the list of available memberships.
		// owned_memberships: Array of the user's owned memberships, where each membership is an array with an ID and a status.
		// ac_memberships: Array of available membership IDs.
		// The some() method returns true if at least one element in the array passes the test implemented by the provided function.
		const user_availed_membership = owned_memberships.some(
			membership => ac_memberships.includes(membership[0]) && membership[1] === 'active'
		);

		// Check if the user has any active items in the list of available items.
		// owned_items_ids: Array of the user's owned items, where each item is an array with an ID and a status.
		// ac_items: Array of available item IDs.
		// The some() method returns true if at least one element in the array passes the test implemented by the provided function.
		const user_availed_item = owned_items_ids.some(
			item => ac_items.includes(item[0]) && item[1] === 'active'
		);

		// let user_availed_membership = false;
		// if (ac_memberships.some(membership => owned_memberships.includes(membership))) {
		// 	user_availed_membership = true;
		// }

		// let user_availed_item = false;
		// if (ac_items.some(item => owned_items_ids.includes(item))) {
		// 	user_availed_item = true;
		// }

		// console.log('owned_memberships')
		// console.log(owned_memberships)
		// console.log('owned_items_ids')
		// console.log(owned_items_ids)
		// console.log('user_availed_membership')
		// console.log(user_availed_membership)
		// console.log('user_availed_item')
		// console.log(user_availed_item)

		isMember = user_availed_membership || user_availed_item;
	}

	if (spaceData?.viewing_user_is_owner) {
		isMember = true;
	}


	const handleActiveTabChange = () => {
		updateGlobalActiveTab('update_content');
	};

	const redirectLink = (spaceUsername, data) => {
		return routes
			.spaceViewSpaceUpdate()
			.replace(':space_username', '@' + spaceUsername)
			.replace(':update_id', data?.id);
	}

	const CategoryRow = (item) => {
		return (
			<>
				<p className="update-tag color-lightest">{item.category.name}</p>
			</>
		);
	};

	const renderContent = (title, previewText) => (
		<>
			<h4>
				<a onClick={() => {handleActiveTabChange()}}
				   href={redirectLink(spaceUsername, data)} target="_self"
				>
					{title || <br />}
				</a>
			</h4>
			<p>{previewText && parse(previewText)}</p>
		</>
	);

	let locked = false;
	if (data?.is_locked) {
		locked = data?.is_locked
	}

	const t_pt = renderContent(data?.title, data?.preview_text);
	const pt_pt = renderContent(data?.public_title, data?.preview_text);
	const p_ppt = renderContent(data?.title, data?.public_preview_text);
	const pt_ppt = renderContent(data?.public_title, data?.public_preview_text);


	let tpt_display = t_pt;
	if (data?.public_title && !data?.public_preview_text) {
		tpt_display = pt_pt;
	} else if (!data?.public_title && data?.public_preview_text) {
		tpt_display = p_ppt;
	} else {
		if (data?.public_title && data?.public_preview_text) {
			tpt_display = pt_ppt
		}
	}

	const titlePreviewTextView =
		data?.visibility === 'public'
			? t_pt
			: isMember
				? t_pt
				: pt_ppt;
	// : tpt_display;

	// const titlePreviewTextView =
	// 	data?.visibility === 'public'
	// 		? isMember
	// 			? t_pt : pt_ppt
	// 		: isMember
	// 			? locked
	// 				? pt_ppt : t_pt
	// 			: tpt_display;
	// 			// : tpt_display;

	const renderMedia = (videoUrl, photoUrl) => (
		videoUrl ? (
			<a onClick={() => {handleActiveTabChange();}}
			   href={redirectLink(spaceUsername, data)} target="_self"
			>
				<ReactPlayer
					playing
					loop
					controls={false}
					muted
					width="100%"
					height="100%"
					className="videoPreview"
					url={videoUrl}
				/>
			</a>
		) : (
			<a onClick={() => {handleActiveTabChange();}}
			   href={redirectLink(spaceUsername, data)} target="_self"
			>
				{/*<img className="card" alt="Description of image" src={photoUrl || default_bg} />*/}
				<img className="card" alt=" " src={photoUrl} />
			</a>
		)
	);

	const mediaContentView = data?.visibility === 'public'
		? renderMedia( data?.cover_video ?? '', data?.cover_photo_medium ?? '')
		: renderMedia(isMember ? data?.cover_video : data?.public_cover_video, isMember ? data?.cover_photo_medium : data?.public_cover_photo_medium);

	// console.log(data?.id);
	// console.log(isMember);
	// console.log(data?.visibility);
	// console.log(data?.public_cover_photo_medium ?? data?.cover_photo_medium);


	useEffect(() => {
		const path = window.location.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}
	}, [spaceUsername, data, isMember]);

	return (
		<article className={`cs-excerpt-update block-md stack-y`}>
			{data && data?.id ? (
				<>
					<a>
						{/* Render Media Content of updates */}
						{mediaContentView}


						{/*{!user ? (*/}
						{/*	<>*/}
						{/*		{data?.visibility === 'members' ? (*/}
						{/*			<>*/}
						{/*				{(data && data?.public_cover_video) ?*/}
						{/*					<ReactPlayer*/}
						{/*						playing={true}*/}
						{/*						loop={true}*/}
						{/*						controls={false}*/}
						{/*						muted={true}*/}
						{/*						width="100%"*/}
						{/*						height="100%"*/}
						{/*						className="videoPreview"*/}
						{/*						url={data?.public_cover_video}*/}
						{/*					/>*/}
						{/*					:*/}
						{/*					<img className="card " alt="Description of image" src={(data?.public_cover_photo_medium ? data?.public_cover_photo_medium : default_bg)} />*/}
						{/*				}*/}
						{/*			</>*/}
						{/*				) : (*/}
						{/*			<>*/}
						{/*				{(data && data?.cover_video) ?*/}
						{/*					<ReactPlayer*/}
						{/*						playing={true}*/}
						{/*						loop={true}*/}
						{/*						controls={false}*/}
						{/*						muted={true}*/}
						{/*						width="100%"*/}
						{/*						height="100%"*/}
						{/*						className="videoPreview"*/}
						{/*						url={data?.public_cover_video}*/}
						{/*					/>*/}
						{/*					:*/}
						{/*					<img className="card " alt="Description of image" src={(data?.cover_photo_medium ? data?.cover_photo_medium : default_bg)} />*/}
						{/*				}*/}
						{/*			</>*/}
						{/*		)}*/}
						{/*	</>*/}
						{/*) : (*/}
						{/*	<>*/}
						{/*		{data?.visibility === 'members' ? (*/}
						{/*			<>*/}
						{/*				{isMember ? (*/}
						{/*					<>*/}
						{/*						{(data && data?.cover_video) ?*/}
						{/*							<ReactPlayer*/}
						{/*								playing={true}*/}
						{/*								loop={true}*/}
						{/*								controls={false}*/}
						{/*								muted={true}*/}
						{/*								width="100%"*/}
						{/*								height="100%"*/}
						{/*								className="videoPreview"*/}
						{/*								url={data?.cover_video}*/}
						{/*							/>*/}
						{/*							:*/}
						{/*							<img className="card " alt="Description of image" src={(data?.cover_photo_medium ? data?.cover_photo_medium : default_bg)} />*/}
						{/*						}*/}
						{/*					</>*/}
						{/*				) : (*/}
						{/*					<>*/}
						{/*						{(data && data?.public_cover_video) ?*/}
						{/*							<ReactPlayer*/}
						{/*								playing={true}*/}
						{/*								loop={true}*/}
						{/*								controls={false}*/}
						{/*								muted={true}*/}
						{/*								width="100%"*/}
						{/*								height="100%"*/}
						{/*								className="videoPreview"*/}
						{/*								url={data?.public_cover_video}*/}
						{/*							/>*/}
						{/*							:*/}
						{/*							<img className="card " alt="Description of image" src={(data?.public_cover_photo_medium ? data?.public_cover_photo_medium : default_bg)} />*/}
						{/*						}*/}
						{/*					</>*/}
						{/*				)}*/}
						{/*			</>*/}
						{/*		) : (*/}
						{/*			<>*/}
						{/*				{(data && data?.public_cover_video) ?*/}
						{/*					<ReactPlayer*/}
						{/*						playing={true}*/}
						{/*						loop={true}*/}
						{/*						controls={false}*/}
						{/*						muted={true}*/}
						{/*						width="100%"*/}
						{/*						height="100%"*/}
						{/*						className="videoPreview"*/}
						{/*						url={data?.public_cover_video}*/}
						{/*					/>*/}
						{/*					:*/}
						{/*					<img className="card " alt="Description of image" src={(data?.public_cover_photo_medium ? data?.public_cover_photo_medium : default_bg)} />*/}
						{/*				}*/}
						{/*			</>*/}
						{/*		)}*/}
						{/*	</>*/}
						{/*)}*/}

					</a>

					<div className="cs-stack-subtitle-published stack-row ">
						<time>{moment(data?.created_on).format('LL')}</time>
						{data?.categories && data?.categories.map((category, index) => (
							<a key={index} href="#" className="chip-gradient">{category.name}</a>
						))}
					</div>
					<hgroup>
						{/* Title and preview text view */}
						{titlePreviewTextView}
						{/*{!isMember && data?.visibility === `Members Only` ? (*/}
						{/*	<>*/}
						{/*		<h4>*/}
						{/*			<a href="#" target="_self">*/}
						{/*				{((data?.public_title && data?.public_title !== '') ? data?.public_title : <br></br>)}*/}
						{/*			</a>*/}
						{/*		</h4>*/}
						{/*		<p>*/}
						{/*			{(data && data?.public_preview_text && data?.public_preview_text !== '') && (*/}
						{/*				parse(data?.public_preview_text)*/}
						{/*			)}*/}
						{/*		</p>*/}
						{/*	</>*/}
						{/*) : (*/}
						{/*	<>*/}
						{/*		<h4>*/}
						{/*			<a href="#" target="_self">*/}
						{/*				{((data?.title && data?.title !== '') ? data?.title : <br></br>)}*/}
						{/*			</a>*/}
						{/*		</h4>*/}
						{/*		<p>*/}
						{/*			{ (data && data?.preview_text && data?.preview_text !== '') && (*/}
						{/*				parse(data?.preview_text)*/}
						{/*			)}*/}
						{/*		</p>*/}
						{/*	</>*/}
						{/*)}*/}

						{/*{!user ? (*/}
						{/*	<>*/}
						{/*		{data?.visibility === 'members' ? (*/}
						{/*			<>*/}
						{/*				<h4>*/}
						{/*					<a href="#" target="_self">*/}
						{/*						{((data?.public_title && data?.public_title !== '') ? data?.public_title : <br></br>)}*/}
						{/*					</a>*/}
						{/*				</h4>*/}
						{/*				<p>*/}
						{/*					{ (data && data?.public_preview_text && data?.public_preview_text !== '') && (*/}
						{/*						parse(data?.public_preview_text)*/}
						{/*					)}*/}
						{/*				</p>*/}
						{/*			</>*/}
						{/*		) : (*/}
						{/*			<>*/}
						{/*				<h4>*/}
						{/*					<a href="#" target="_self">*/}
						{/*						{((data?.public_title && data?.public_title !== '') ? data?.public_title : <br></br>)}*/}
						{/*					</a>*/}
						{/*				</h4>*/}
						{/*				<p>*/}
						{/*					{ (data && data?.public_preview_text && data?.public_preview_text !== '') && (*/}
						{/*						parse(data?.public_preview_text)*/}
						{/*					)}*/}
						{/*				</p>*/}
						{/*			</>*/}
						{/*		)}*/}
						{/*	</>*/}
						{/*) : (*/}
						{/*	<>*/}
						{/*		{data?.visibility === 'members' ? (*/}
						{/*			<>*/}
						{/*				{isMember ? (*/}
						{/*					<>*/}
						{/*						<h4>*/}
						{/*							<a href="#" target="_self">*/}
						{/*								{((data?.title && data?.title !== '') ? data?.title : <br></br>)}*/}
						{/*							</a>*/}
						{/*						</h4>*/}
						{/*						<p>*/}
						{/*							{ (data && data?.preview_text && data?.preview_text !== '') && (*/}
						{/*								parse(data?.preview_text)*/}
						{/*							)}*/}
						{/*						</p>*/}
						{/*					</>*/}
						{/*				) : (*/}
						{/*					<>*/}
						{/*						<h4>*/}
						{/*							<a href="#" target="_self">*/}
						{/*								{((data?.public_title && data?.public_title !== '') ? data?.public_title : <br></br>)}*/}
						{/*							</a>*/}
						{/*						</h4>*/}
						{/*						<p>*/}
						{/*							{ (data && data?.public_preview_text && data?.public_preview_text !== '') && (*/}
						{/*								parse(data?.public_preview_text)*/}
						{/*							)}*/}
						{/*						</p>*/}
						{/*					</>*/}
						{/*				)}*/}
						{/*			</>*/}
						{/*		) : (*/}
						{/*			<>*/}
						{/*				<h4>*/}
						{/*					<a href="#" target="_self">*/}
						{/*						{((data?.title && data?.title !== '') ? data?.title : <br></br>)}*/}
						{/*					</a>*/}
						{/*				</h4>*/}
						{/*				<p>*/}
						{/*					{ (data && data?.preview_text && data?.preview_text !== '') && (*/}
						{/*						parse(data?.preview_text)*/}
						{/*					)}*/}
						{/*				</p>*/}
						{/*			</>*/}
						{/*		)}*/}
						{/*	</>*/}
						{/*)}*/}
					</hgroup>
					<div className="cs-stack-fill ">
						{!user ? (
							<>
								{(data?.visibility === 'public' && data?.access === 'all_members') ? (
									<a
										className="button-secondary-gradient"
										onClick={() => {
											handleActiveTabChange();
										}}
										href={redirectLink(spaceUsername, data)}
									>
												<span>
													<p>
														{t('button_updateread')}
													</p>
												</span>
									</a>
								) : (
									<a className="button-secondary-gradient" href={routes.login()}>
										<span>
											<p>
												<i className="fa-regular fa-lock"/>
												{t('button_updateunlock')}
											</p>
										</span>
									</a>
								)}
							</>
						) : (
							<>
								{data?.visibility === 'members' ? (
									<>
										{isMember ? (
											<a
												className="button-secondary-gradient"
												onClick={() => {
													handleActiveTabChange();
												}}
												href={redirectLink(spaceUsername, data)}
											>
												<span>
													<p>
														{t('button_updateread')}
													</p>
												</span>
											</a>
										) : (
											<a
												className="button-secondary-gradient"
												onClick={() => {
													handleActiveTabChange();
												}}
												href={redirectLink(spaceUsername, data)}
											>
												<span>
													<p>
														 {t('button_updateread')}
													</p>
												</span>
											</a>
										)}
									</>
								) : (
									<a
										className="button-secondary-gradient"
										onClick={() => {
											handleActiveTabChange();
										}}
										href={redirectLink(spaceUsername, data)}
									>
										<span>
											<p>
												{t('button_updateread')}
											</p>
										</span>
									</a>
								)}
							</>
						)}
					</div>
				</>
			) : (
				<>
					{spaceData && spacedata?.viewing_user_is_owner && (
						<div className="card-updates space-placeholder flex items-center justify-center">
							<h6 className="header-title text-center">
								Create Latest Updates <br />
								from your Dashboard
							</h6>
						</div>
					)}
				</>
			)
			}
		</article>
	);
};

export default memo(LatestUpdates);
