import { memo, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Form, Input } from 'antd';

import routes from '../../../routes';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import {GOOGLE_MAP_KEY, VALIDATE_MESSAGES} from '../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import {
	SPACE_PRIMARY_LANG,
	SPACE_PRIMARY_CURRENCY,
	SPACE_BUSINESS_INDUSTRY,
	SPACE_BUSINESS_TYPE,
	SPACE_BUSINESS_SIZE
} from '../../../constants/space';
import DropdownWithCountries from '../../../components/commons/DropdownWithCountryRegion/DropdownWithCountries';
import DropdownWithRegions from '../../../components/commons/DropdownWithCountryRegion/DropdownWithRegions';
import {
	createSpaceAddDetails, getSpaceBusinessInformation,
	setSpaceBusinessInformation,
	setSpaceInformation
} from '../../../services/space';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import FormFieldStacked from '../../../components/commons/FormFieldStacked';
import Autocomplete from "react-google-autocomplete";

const AddBusinessDetails = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [businessInformation, setBusinessInformation] = useState();
	const [initialLoad, setInitialLoad] = useState(true);
	const [addSpaceBusinessDetailsForm] = Form.useForm();
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [selectedPrimaryLang, setSelectedPrimaryLang] = useState('en');
	const [selectedPrimaryCurrency, setSelectedPrimaryCurrency] = useState('usd');
	const [selectedIndustry, setSelectedIndustry] = useState('default');
	const [selectedType, setSelectedType] = useState('default');
	const [selectedSize, setSelectedSize] = useState('default');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [verifyAuthorizedChecked, setVerifyAuthorizedChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [alert, setAlert] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [isBusinessInfoLoaded, setBusinessInfoLoaded] = useState(false);
	const [isLoadOnlyOnce, setLoadOnlyOnce] = useState(false);
	const [isDataLoaded, setDataLoaded] = useState(false);

	const [legalName, setLegalName] = useState('');
	const [streetAddress, setStreetAddress] = useState('');
	const [aptSteBldg,setAptSteBldg] = useState('');
	const [city,setCity] = useState('');
	const [zipCode,setZipCode] = useState('');
	const [contactPhone,setContactPhone] = useState('');
	const [authorized,setAuthorized] = useState('');
	const [contactEmail,setContactEmail] = useState('');
	const [primaryCity,setPrimaryCity] = useState('');

	const [blurredField, setBlurredField] = useState(null);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [isSubmitClicked, setSubmitClicked] = useState(false);

	const handleFinishFailed = useCallback(async (errorInfo) => {
		setSubmitClicked(true);
		// Set the blurred field to the first field with an error
		if (errorInfo.errorFields.length > 0) {
			setBlurredField('all');
		}
	}, [blurredField, isSubmitClicked]);

	const handleOnblurField = useCallback(async (field) => {
		if (!isSubmitClicked) {
			setBlurredField(field);
		}
		setSubmitClicked(false);
	}, [blurredField, isSubmitClicked]);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			setIsLoading(true);

			const data = values;
			if (authUserInfo && spaceInfo) {
				try {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;

					data.legal_name = addSpaceBusinessDetailsForm.getFieldValue('legal_name');
					data.country = selectedCountry;
					data.street_address =
						addSpaceBusinessDetailsForm.getFieldValue('street_address');
					data.apt_ste_bldg = addSpaceBusinessDetailsForm.getFieldValue('apt_ste_bldg');
					data.city = addSpaceBusinessDetailsForm.getFieldValue('city');
					data.state = selectedRegion;
					data.zip_code = addSpaceBusinessDetailsForm.getFieldValue('zip_code');
					data.contact_phone = addSpaceBusinessDetailsForm.getFieldValue('contact_phone');
					data.contact_email = addSpaceBusinessDetailsForm.getFieldValue('contact_email');
					data.primary_city = addSpaceBusinessDetailsForm.getFieldValue('primary_city');
					data.primary_language = selectedPrimaryLang;
					data.primary_currency = selectedPrimaryCurrency;

					data.industry = selectedIndustry;
					data.type = selectedType;
					data.size = selectedSize;
					data.is_authorized = verifyAuthorizedChecked;

					const url = routes.createSpaceAddPages();
					data.last_step_link = url.replace(':space_username', space_username);

					handleFormOnBlur().then(r => {});
					let hasError = false;

					if (selectedRegion === 'default' || selectedRegion === ''){
						setFormIsOnFocus(true);
						hasError = true;
					}

					if (selectedIndustry === 'default' || selectedIndustry === ''){
						setFormIsOnFocus(true);
						hasError = true;
					}

					if (selectedSize === 'default' || selectedSize === ''){
						setFormIsOnFocus(true);
						hasError = true;
					}

					if (verifyAuthorizedChecked === 'default' || verifyAuthorizedChecked === ''){
						setFormIsOnFocus(true);
						hasError = true;
					}

					if (hasError) {
						setIsLoading(false);
						setTimeout(() => {
							setBlurredField('all');
						}, 1000);
						return;
					}


					const response = await setSpaceBusinessInformation(data);
					if (response && response.result) {
						setIsLoading(false);
						//move to next page after saving
						const url = routes.createSpaceAddPages();
						navigate(url.replace(':space_username', space_username));
					} else {
						setIsLoading(false);
						setAlert({
							variant: 'danger',
							message: response.message
						});
						window.scrollTo(0, 0);
					}
				} catch (error) {
					// setIsLoading(false);
					setIsLoading(false);
					console.log(error);
				}
			} else {
				setIsLoading(false);
			}
		},
		[
			selectedCountry,
			selectedRegion,
			selectedPrimaryLang,
			selectedPrimaryCurrency,
			selectedIndustry,
			selectedType,
			selectedSize,
			verifyAuthorizedChecked,
			inputValue
		]
	);

	const fetchBusinessInfo = useCallback(
		async (values) => {
			setAlert(null);
			if (authUserInfo && spaceInfo) {
				try {

					const data = {
						// user_id: authUserInfo.id,
						space_id: spaceInfo.id
					};
					const response = await getSpaceBusinessInformation(data);

					if (response && response.result) {
						setLoadOnlyOnce(true);
						setDataLoaded(true);
						const businessInformation = response.business_info;

						setLegalName(businessInformation.legal_name);
						setSelectedCountry(businessInformation.country);
						setStreetAddress(businessInformation.street_address);
						setAptSteBldg(businessInformation.apt_ste_bldg);
						setCity(businessInformation.city);
						setSelectedRegion(businessInformation.state);
						setZipCode(businessInformation.zip_code);
						setContactPhone(businessInformation.contact_phone);
						setSelectedPrimaryLang(businessInformation.primary_language);
						setSelectedPrimaryCurrency(businessInformation.primary_currency);
						setSelectedIndustry(businessInformation.industry);
						setSelectedType(businessInformation.type);
						setSelectedSize(businessInformation.size);
						setAuthorized(businessInformation.is_authorized);
						setPrimaryCity(businessInformation.primary_city);
						setContactEmail(businessInformation.contact_email);


						setBusinessInfoLoaded(true);
						setIsLoading(false);
					} else {
						setBusinessInfoLoaded(false);
						setIsLoading(false);
						// setAlert({
						// 	variant: 'danger',
						// 	message: response.message
						// });
						// window.scrollTo(0, 0);0
					}
				} catch (error) {
					setBusinessInfoLoaded(false);
					// setIsLoading(false);
					setIsLoading(false);
					console.log(error);
				}
			} else {
				setBusinessInfoLoaded(false);
				setIsLoading(false);
			}
		},
	);

	const setFormSpaceData = async (createdSpace) => {
		// set space data to forms here
	};

	useEffect(()=>{
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},[])

	useEffect(() => {
		if (!isBusinessInfoLoaded && !isLoadOnlyOnce) {
			fetchBusinessInfo().then(r => {});
		}
	},[
		isBusinessInfoLoaded,
		isLoadOnlyOnce, spaceInfo
	]);

	useEffect(() => {
		if (isDataLoaded){
			setDataLoaded(false);
			addSpaceBusinessDetailsForm.setFieldValue("legal_name", legalName);
			addSpaceBusinessDetailsForm.setFieldValue("street_address", streetAddress);
			addSpaceBusinessDetailsForm.setFieldValue("apt_ste_bldg", aptSteBldg);
			addSpaceBusinessDetailsForm.setFieldValue("city", city);
			addSpaceBusinessDetailsForm.setFieldValue("zip_code", zipCode);
			addSpaceBusinessDetailsForm.setFieldValue("contact_phone", contactPhone);
			addSpaceBusinessDetailsForm.setFieldValue("contact_email", contactEmail);
			addSpaceBusinessDetailsForm.setFieldValue("primary_city", primaryCity);
			setVerifyAuthorizedChecked(authorized)
		}
	},[
		isDataLoaded
	]);

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}
		document.title = 'Commonspace Spaces';
	}, [
		spaceInfoLoading,
		initialLoad,
	]);

	useEffect(() => {},[
		authUserInfo,
		spaceInfo,
		space_username,
		selectedCountry,
		selectedRegion,
		selectedPrimaryLang,
		selectedPrimaryCurrency,
		selectedIndustry,
		selectedType,
		selectedSize,
		verifyAuthorizedChecked,
		inputValue,
		formIsOnFocus,
		legalName,
		streetAddress,
		aptSteBldg,
		city,
		zipCode,
		contactPhone,
		authorized,
		contactEmail,
		primaryCity
	]);

	const onChangeVerifyChecked = (e) => {
		setVerifyAuthorizedChecked(e.target.checked);
	};


	const handleFormChanges = useCallback(async () => {
		// setWelcomeTitle(addSpaceBusinessDetailsForm.getFieldValue('welcome_title'));
		// setWelcomeMessage(addSpaceBusinessDetailsForm.getFieldValue('welcome_message'));
		// setWelcomeYoutubeVideo(addSpaceBusinessDetailsForm.getFieldValue('welcome_youtube_video'));
		// setLinksTitleFirst(addSpaceBusinessDetailsForm.getFieldValue('links_title_first'));
		// setLinksTitleSecond(addSpaceBusinessDetailsForm.getFieldValue('links_title_second'));
		//
		// // TODO: change this implementation to be dynamic
		// setAddLinkLinkTitle1(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_title_0'));
		// setAddLinkLinkDescription1(
		// 	addSpaceBusinessDetailsForm.getFieldValue('add_link_link_description_0')
		// );
		// setAddLinkLinkUrl1(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_url_0'));
		//
		// setAddLinkLinkTitle2(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_title_1'));
		// setAddLinkLinkDescription2(
		// 	addSpaceBusinessDetailsForm.getFieldValue('add_link_link_description_1')
		// );
		// setAddLinkLinkUrl2(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_url_1'));
		//
		// setAddLinkLinkTitle3(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_title_2'));
		// setAddLinkLinkDescription3(
		// 	addSpaceBusinessDetailsForm.getFieldValue('add_link_link_description_2')
		// );
		// setAddLinkLinkUrl3(addSpaceBusinessDetailsForm.getFieldValue('add_link_link_url_2'));

		await addSpaceBusinessDetailsForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
				setDisplayDropDownError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
				setDisplayDropDownError(true);
			});
	}, []);

	const handleFormOnBlur = useCallback(async () => {
		await addSpaceBusinessDetailsForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
				setDisplayDropDownError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
				setDisplayDropDownError(true);
			});
	}, []);

	const handlePhoneInput = (e) => {
		// this is where we'll call our future formatPhoneNumber function that we haven't written yet.
		const formattedPhoneNumber = formatPhoneNumber(e.target.value);
		// we'll set the input value using our setInputValue
		setInputValue(formattedPhoneNumber);
		addSpaceBusinessDetailsForm.setFieldValue('contact_phone', formattedPhoneNumber);
	};

	function formatPhoneNumber(value) {
		// if input value is falsy eg if the user deletes the input, then just return
		if (!value) return value;

		// clean the input for any non-digit values.
		const phoneNumber = value.replace(/[^\d]/g, '');

		// phoneNumberLength is used to know when to apply our formatting for the phone number
		const phoneNumberLength = phoneNumber.length;

		// we need to return the value with no formatting if its less then four digits
		// this is to avoid weird behavior that occurs if you  format the area code to early

		if (phoneNumberLength < 4) return phoneNumber;

		// if phoneNumberLength is greater than 4 and less the 7 we start to return
		// the formatted number
		if (phoneNumberLength < 7) {
			return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
		}

		// finally, if the phoneNumberLength is greater then seven, we add the last
		// bit of formatting and return it.
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
			6,
			10
		)}`;
	}

	const onChangeCity = (e) => {
		addSpaceBusinessDetailsForm.setFieldValue('city', e.target.value);
		setCity(e.target.value);
		handleFormOnBlur().then(r => {});
	};

	const onCitySelected = (e) => {
		addSpaceBusinessDetailsForm.setFieldValue('city', e.formatted_address);
		setCity(e.formatted_address);
		const addressComponents = e.address_components;
		if (addressComponents && addressComponents?.length > 0) {
			addressComponents.map((addressComponent) => {
				if (addressComponent.types[0] === 'administrative_area_level_1') {
					setSelectedRegion(addressComponent.long_name);
				}
				if (addressComponent.types[0] === 'country') {
					setSelectedCountry(addressComponent.long_name);
				}
			})
		}
	};

	const onChangePrimaryCity = (e) => {
		addSpaceBusinessDetailsForm.setFieldValue('primary_city', e.target.value);
		setPrimaryCity(e.target.value);
		handleFormOnBlur().then(r => {});
	};

	const onPrimaryCitySelected = (e) => {
		addSpaceBusinessDetailsForm.setFieldValue('primary_city', e.formatted_address);
		setPrimaryCity(e.formatted_address);
	};

	return (
        <main id="cs-platform-main" className="cs-main main-theme">
            <section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
                <div className="centerframe-grid gap-2gutter">
                    <div className="block-full">
                        <div className="cs-stack-heading">
                            <div className="stack-row split">
                                <h1>Your Business Details</h1>
                                <h3>Step 1 of 5</h3>
                            </div>
                            <p>
                                Similar to an online e-commerce store, your Space is a website where
		 						your community can become members, interact, and transact. It is
		 						your{' '}
						
		 						{getSitewideLink(
		 							'owned_community',
		 							'gradient-color-txt body-txtitalic--reg'
		 						)}{' '}
		 						and you will have access to transactions, activity data, and opt-in
		 						contact information. For this reason, certain information is needed
		 						to comply with privacy laws and to provide services.
                            </p>
                        </div>
                        <div className="cs-stack-form-guided">
                            <div className="formrow title">
                                <div>
                                    <h2>Add Business Information</h2>
                                    <p>Required</p>
                                </div>
								{getSitewideLink('view_guide_business_details', 'button-secondary-gradient', true)}
                            </div>
                        </div>
                        <Form
                            form={addSpaceBusinessDetailsForm}
                            name="addSpaceBusinessDetails"
                            id="addSpaceBusinessDetails"
                            validateMessages={VALIDATE_MESSAGES}
                            onFinish={handleSubmit}
							onFinishFailed={handleFinishFailed}
                            onFocus={() => {
                                setFormIsOnFocus(true);
                            }}
                            onBlur={() => {
                                // setTimeout(() => {
                                    setFormIsOnFocus(false);
                                // }, 1000);
                            }}
                            onChange={handleFormChanges}
                            className='cs-stack-form-guided'
                        >
                            <Form.Item
                                label="Legal Name"
                                name="legal_name"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                               
                                <div className='formrow'>
                                    <div className="form-input air">
                                        <label htmlFor="text">Legal Name*</label>
                                        <input id="legal_name" name="legal_name" type="text" value={legalName}
											   onBlur={()=>handleOnblurField('legal_name')}
											   placeholder="Enter the legal name for the person, group, business, or other legal entity."
											   onChange={(e) => setLegalName(e.target.value)}
										/>
										{(blurredField === 'legal_name' || blurredField === 'all') && addSpaceBusinessDetailsForm.getFieldsError()[0]?.errors.length > 0 && (
											<div className="alert callout warning items-center !w-full">
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <h6>{addSpaceBusinessDetailsForm.getFieldsError()[0]?.errors}</h6>
                                                    </div>  
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="cs-formrow-helper-end">
                                        <p>Your Legal Name should be the person, business, or other legal
                                        entity that is responsible for operating the your Space.</p>
                                    </div>
                                </div>
                                
                            </Form.Item>
                            <Form.Item
                                label="Street Address"
                                name="street_address"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                       
                                <div className='formrow'>
                                    <div className="form-input air">
                                        <label htmlFor="text">Street Address*</label>
                                        <input id="street_address" name="street_address" type="text" value={streetAddress}
											   onBlur={()=>handleOnblurField('street_address')}
											   placeholder="Enter the legal address for the business."
											   onChange={(e) => setStreetAddress(e.target.value)}
										/>
										{(blurredField === 'street_address' || blurredField === 'all') && addSpaceBusinessDetailsForm.getFieldsError()[1]?.errors.length > 0 && (
											<div className="alert callout warning items-center !w-full">
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <h6>{addSpaceBusinessDetailsForm.getFieldsError()[1]?.errors}</h6>
                                                        
                                                    </div>  
                                                </div>
                                                
                                            </div>
                                        )}
                                    </div>
                                    <div className="cs-formrow-helper-end">
                                        <p>Enter the legal address for the person or business. This is kept
                                        private and will be only used by Commonspace.</p>
                                    </div>
                                </div>
                                    
                            </Form.Item>
                            <Form.Item
                                label="APT/STE/BLDG"
                                name="apt_ste_bldg"
                                validateTrigger="onBlur"
                            >
                                <div className='formrow'>
                                    <div className="form-input air">
                                        <label htmlFor="text">APT/STE/BLDG</label>
                                        <input id="apt_ste_bldg" name="apt_ste_bldg" type="text" value={aptSteBldg}
											   placeholder="Optional."
											   onChange={(e) => setAptSteBldg(e.target.value)}
										/>
                                    </div>
                                    <div className="cs-formrow-helper-none"></div>
                                </div>
                            </Form.Item>
							<div className="formrow">
								<div className="cs-stack-form-inputs stack-row cs-stack-form-input-quarters">
									<Form.Item
										label="City"
										name="city"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>
										<div className="form-input air">
											<label htmlFor="text">City*</label>
											<Autocomplete
												apiKey={GOOGLE_MAP_KEY}
												placeholder='Enter City'
												defaultValue={city}
												onBlur={()=>handleOnblurField('city')}
												onPlaceSelected={(place) => {
													onCitySelected(place);
												}}
												onChange={(e) => {
													onChangeCity(e);
												}}
											/>
											{(blurredField === 'city' || blurredField === 'all') && addSpaceBusinessDetailsForm.getFieldsError()[3]?.errors.length > 0 && (
												<div className="alert callout warning !w-auto">
													<div className="action !w-auto">
														<i className="fa-solid fa-triangle-exclamation"></i>
													</div>
													<div className="content">
														<div>
															<h6>{addSpaceBusinessDetailsForm.getFieldsError()[3]?.errors}</h6>
														</div>
													</div>
												</div>
											)}
										</div>
									</Form.Item>
									<div className="stack-row">
										<Form.Item
											label="ZIP"
											name="zip_code"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 5,
													message: 'Must not exceed 5 characters.'
												}
											]}
										>
											<div className="form-input air">
												<label htmlFor="text">ZIP*</label>
												<input id="zip_code" name="zip_code" type="text"
													   onBlur={()=>handleOnblurField('zip_code')}
													   value={zipCode} placeholder="5 digits"
													   onChange={(e) => setZipCode(e.target.value)}
												/>
												{(blurredField === 'zip_code' || blurredField === 'all') && addSpaceBusinessDetailsForm.getFieldsError()[4]?.errors.length > 0 && (
													<div className="alert callout warning !w-auto">
														<div className="action !w-auto">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{addSpaceBusinessDetailsForm.getFieldsError()[4]?.errors}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
										</Form.Item>
										<div className="form-input air">
											<label htmlFor="text">State*</label>
											<DropdownWithRegions
												title="State"
												country={selectedCountry}
												value={selectedRegion}
												onDropdownChange={setSelectedRegion}
											/>
											{(displayDropDownError && (selectedRegion === 'default' || selectedRegion === '')) && (
												<div className="alert callout warning !w-auto">
													<div className="action !w-auto">
														<i className="fa-solid fa-triangle-exclamation"></i>
													</div>
													<div className="content">
														<div>
															<h6>State is required.</h6>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="cs-formrow-helper-none"></div>
							</div>
							<div id="cscxl-country-select" className="formrow ">
								<div className="cs-stack-form-buttons form-input air">
									<label htmlFor="text"> Country*</label>
									<DropdownWithCountries
										title="Country"
										value={selectedCountry}
										onDropdownChange={setSelectedCountry}
									/>
									{(displayDropDownError &&
										(!selectedCountry || selectedCountry === '')) && (
										<div className="alert callout warning items-center !w-full">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>State is required.</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="cs-formrow-helper-none"></div>
							</div>
                            <Form.Item
                                label="Contact Phone"
                                name="contact_phone"
                                onChange={(e) => handlePhoneInput(e)}
                                value={inputValue}
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                                 <div className='formrow'>
                                    <div className="form-input air">
                                        <label htmlFor="text">Contact Phone*</label>
                                        <input id="contact_phone"  name="contact_phone" type="text" value={contactPhone}
											   onBlur={()=>handleOnblurField('contact_phone')}
											   placeholder="Enter the primary contact phone number for the business."
											   onChange={(e) => setContactPhone(e.target.value)}
										/>
										{(blurredField === 'contact_phone' || blurredField === 'all') && addSpaceBusinessDetailsForm.getFieldsError()[5]?.errors.length > 0 && (
											<div className="alert callout warning items-center !w-full">
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <h6>{addSpaceBusinessDetailsForm.getFieldsError()[5]?.errors}</h6>
                                                        
                                                    </div>  
                                                </div>
                                                
                                            </div>
                                        )}
                                    </div>
                                    <div className="cs-formrow-helper-end">
                                        <p>This phone number is kept private and will be only used by
                                        Commonspace.</p>
                                    </div>
                                </div> 
                              
                            </Form.Item>
                            <Form.Item
                                label="Contact Email"
                                name="contact_email"
                                validateTrigger="onBlur"
                                rules={[{ required: true, type: 'email' }]}
                            >

                                <div className='formrow'>
                                    <div className="form-input air">
                                        <label htmlFor="text">Contact Email*</label>
                                        <input id="contact_email" name="contact_email" type="text" value={contactEmail}
											   onBlur={()=>handleOnblurField('contact_email')}
											   placeholder="Enter the primary contact email for the business."
											   onChange={(e) => setContactEmail(e.target.value)}
										/>
										{(blurredField === 'contact_email' || blurredField === 'all') && addSpaceBusinessDetailsForm.getFieldsError()[6]?.errors.length > 0 && (
											<div className="alert callout warning items-center !w-full">
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <h6>{addSpaceBusinessDetailsForm.getFieldsError()[6]?.errors}</h6>
                                                        
                                                    </div>  
                                                </div>
                                                
                                            </div>
                                        )}
                                    </div>
                                    <div className="cs-formrow-helper-end">
                                        <p>This email is kept private and will be only used by Commonspace.</p>
                                    </div>
                                </div>                               
                            </Form.Item>
                            <Form.Item
                                label="Primary City"
                                name="primary_city"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                                <div className='formrow'>
                                    <div className="form-input air">
                                        <label htmlFor="text">Primary City*</label>
										<Autocomplete
											apiKey={GOOGLE_MAP_KEY}
											placeholder='Enter your primary city'
											defaultValue={primaryCity}
											onBlur={()=>handleOnblurField('primary_city')}
											onPlaceSelected={(place) => {
												onPrimaryCitySelected(place);
											}}
											onChange={(e) => {
												onChangePrimaryCity(e);
											}}
										/>
										{(blurredField === 'primary_city' || blurredField === 'all') && addSpaceBusinessDetailsForm.getFieldsError()[7]?.errors.length > 0 && (
                                            <div className="alert callout warning w-auto">
                                                <div className="action w-auto">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <h6>{addSpaceBusinessDetailsForm.getFieldsError()[7]?.errors}</h6>
                                                    </div>  
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="cs-formrow-helper-end">
                                        <p>Enter the primary or main location of the person or business.
		 							This can be different from the Legal Address. This city may be
		 							visible in certain areas.</p>
                                    </div>
                                </div>                                
                            </Form.Item>
                            <div id="cscxl-language-select" className="formrow ">
                                <div className="cs-stack-form-buttons w-full form-input air">                              
                                    <label htmlFor="text">Primary Language*</label>
                                    <DropdownWithItems
										customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
										displayError={blurredField === 'all' && (!selectedPrimaryLang || selectedPrimaryLang === 'default')}
										title="Image Position"
										selectedValue={selectedPrimaryLang}
										listItems={SPACE_PRIMARY_LANG}
										onDropdownChange={(value) => setSelectedPrimaryLang(value)}
									/>
                                    
                                </div>
                                <div className="cs-formrow-helper-end">
                                    <p>This is used to set the default language for your Space and to
                                    	provide services. Note that some features are only available in{' '}
										{getSitewideLink('supported_language')}
									</p>
                                </div>
                            </div>
                            <div id="cscxl-currency-select" className="formrow ">
                                <div className="cs-stack-form-buttons w-full form-input air">                              
                                    <label htmlFor="text">Primary Currency*</label>
                                    <DropdownWithItems
										customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
										title="Image Position"
										selectedValue={selectedPrimaryCurrency}
										listItems={SPACE_PRIMARY_CURRENCY}
										onDropdownChange={(value) => setSelectedPrimaryCurrency(value)}
									/>
                                    
                                </div>
                                <div className="cs-formrow-helper-end">
                                    <p>
										This is used to set the default currency for your Space and to
										provide services. Note that some features are only available in{' '}
										{getSitewideLink('supported_currency_crypto')}
									</p>
                                </div>
                            </div>
                            <div id="cscxl-industry-select" className="formrow ">
                                <div className="cs-stack-form-buttons w-full form-input air">                              
                                    <label htmlFor="text">Industry*</label>
                                    <DropdownWithItems
										customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
										displayError={blurredField === 'all' && (!selectedIndustry || selectedIndustry === 'default')}
										title="Industry"
										selectedValue={selectedIndustry}
										listItems={SPACE_BUSINESS_INDUSTRY}
										onDropdownChange={(value) => setSelectedIndustry(value)}
									/>
                                    
                                </div>
                                <div className="cs-formrow-helper-end">
                                    <p>Select an industry that best applies. This will be used to
                                        suggest features and provide services.</p>
                                </div>
                            </div>
                            <div id="cscxl-type-select" className="formrow ">
                                <div className="cs-stack-form-buttons w-full form-input air">                              
                                    <label htmlFor="text">Type*</label>
                                    <DropdownWithItems
										customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
										displayError={blurredField === 'all' && (!selectedType || selectedType === 'default')}
										title="Type"
										selectedValue={selectedType}
										listItems={SPACE_BUSINESS_TYPE}
										onDropdownChange={(value) => setSelectedType(value)}
									/>
                                    
                                </div>
                                <div className="cs-formrow-helper-end">
                                    <p>Select a type that best applies to your organization. This will
                                    be used to suggest features and provide services.</p>
                                </div>
                            </div>
                            <div id="cscxl-size-select" className="formrow ">
                                <div className="cs-stack-form-buttons w-full form-input air">                              
                                    <label htmlFor="text">Size*</label>
                                    <DropdownWithItems
										customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
										// displayError={
										// 	displayDropDownError && selectedSize === 'default'
										// }
										displayError={blurredField === 'all' && (!selectedSize || selectedSize === 'default')}
										title="Size"
										selectedValue={selectedSize}
										listItems={SPACE_BUSINESS_SIZE}
										onDropdownChange={(value) => setSelectedSize(value)}
									/>
                                    
                                </div>
                                <div className="cs-formrow-helper-end">
                                    <p>Select the size of your organization. This will be used to
                                    suggest features and provide services.</p>
                                </div>
                            </div>
                            <div className="formrow">
                                <div className="form-input-checkbox gradient md rounded row w-full">
                                    <div className="checkbox">
                                        <input id="checkbox-cs-agreement" aria-describedby="checkbox-cs-agreement-description" onChange={onChangeVerifyChecked} checked={verifyAuthorizedChecked} name="checkbox-cs-agreement" type="checkbox" required/>
                                    </div>
                                    <div>
                                        <label htmlFor="checkbox-cs-agreement" className="sr-only">Space Terms</label>
                                        <p id="checkbox-cs-agreement-description">
                                        I verify that I am an authorized representative of the
		 								organization of the Legal Name entered above and have the
		 								right to act on its behalf in the creation and management of
		 								this Space. The organization and I agree to{' '}
								
		 								{getSitewideLink(
		 									'terms_for_using',
		 									'gradient-color-txt body-txtitalic--small'
		 								)}
                                        </p>
                                    </div>
                                </div>
                                <div className="cs-formrow-helper-none">
                                    <p>Please certify that the responsible parties have read and agree
		 							to the terms, policies, agreements, and guidelines governing the
		 							use and management of Spaces.</p>
                                </div>
                                
                            </div>
                            <div className='formrow'>
                                <button type="submit" className="primary-gradient w-full"><span><p>
                                    {isLoading && <i className="fa-solid fa-spinner fa-spin"></i>}
                                    Save & Continue
                                </p></span></button>
                                <div className="cs-formrow-helper-none">
                                   
                                </div>

                            </div>
                            
                        </Form>
                        
                    </div>
                </div>
            </section>
        </main>
		// <>
		// 	<main className="dashboard-wrapper flex">
		// 		<div className="container !py-20">
		// 			<div className="grid grid-cols-12">
		// 				<div className="col-span-12 md:col-span-8 flex items-center">
		// 					<h1 className="mb-4 mt-5 header-large">Your Business Details</h1>
		// 				</div>
		// 				<div className="col-span-12 md:col-span-4 flex items-center justify-center md:justify-end">
		// 					<p className="font-['din2014Bold'] text-2xl">Step 1 of 5</p>
		// 				</div>
		// 				<div className="col-span-12">
		// 					<h5 className="body-text--reg">
		// 						Similar to an online e-commerce store, your Space is a website where
		// 						your community can become members, interact, and transact. It is
		// 						your{' '}
								
		// 						{getSitewideLink(
		// 							'owned_community',
		// 							'gradient-color-txt body-txtitalic--reg'
		// 						)}{' '}
		// 						and you will have access to transactions, activity data, and opt-in
		// 						contact information. For this reason, certain information is needed
		// 						to comply with privacy laws and to provide services.
		// 					</h5>
		// 				</div>
		// 			</div>
		// 			<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5">
		// 				<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
		// 					<h5 className="header-medium leading-10">Set Space Visibility</h5>
		// 					<span className="body-text--reg">Required</span>
		// 				</div>
		// 				<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">
							
		// 					{getSitewideLink(
		// 						'view_guide_business_details',
		// 						'btn btn-lg btn-secondary'
		// 					)}
		// 				</div>
		// 			</div>

		// 			<Form
		// 				form={addSpaceBusinessDetailsForm}
		// 				name="addSpaceBusinessDetails"
		// 				id="addSpaceBusinessDetails"
		// 				validateMessages={VALIDATE_MESSAGES}
		// 				onFinish={handleSubmit}
		// 				onFocus={() => {
		// 					setFormIsOnFocus(true);
		// 				}}
		// 				onBlur={() => {
		// 					setTimeout(() => {
		// 						setFormIsOnFocus(false);
		// 					}, 1000);
		// 				}}
		// 				onChange={handleFormChanges}
		// 				className={formIsOnFocus ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
		// 			>
		// 				<>
		// 					{alert && (
		// 						<div className="row mt-5">
		// 							<div className="col-8">
		// 								<Alert
		// 									key={alert.variant}
		// 									variant={alert.variant}
		// 									className={
		// 										'custom-alert ' +
		// 										(alert.variant === 'danger'
		// 											? 'custom-alert-danger'
		// 											: 'custom-alert-success')
		// 									}
		// 								>
		// 									{alert.message}
		// 								</Alert>
		// 							</div>
		// 						</div>
		// 					)}
		// 				</>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Legal Name*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 						<Form.Item
		// 								label="Legal Name"
		// 								name="legal_name"
		// 								className="mb-0 hidden-label"
		// 								validateTrigger="onBlur"
		// 								rules={[{ required: true }]}
		// 							>
		// 								<input
		// 									type="text"
		// 									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 									placeholder="Enter the legal name for the person, group, business, or other legal entity."
		// 									value={legalName}
		// 									/>
											
		// 							</Form.Item>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							Your Legal Name should be the person, business, or other legal
		// 							entity that is responsible for operating the your Space.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12 md: gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Country*
		// 						</label>
		// 						<div className="relative rounded-none text-gray-900">
		// 						<DropdownWithCountries
		// 								title="Country"
		// 								displayError={
		// 									displayDropDownError &&
		// 									(!selectedCountry || selectedCountry === '')
		// 								}
		// 								value={selectedCountry}
		// 								onDropdownChange={setSelectedCountry}
		// 							></DropdownWithCountries>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							Enter the legal address for the person or business. This is kept
		// 							private and will be only used by Commonspace.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Street Address*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 						<Form.Item
		// 								label="Street Address"
		// 								name="street_address"
		// 								className="mb-0 hidden-label"
		// 								validateTrigger="onBlur"
		// 								rules={[{ required: true }]}
		// 							>
		// 								<input
		// 									type="text"
		// 									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 									placeholder="Enter the primary contact email for the business."
		// 									value={streetAddress}
		// 									/>
		// 							</Form.Item>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							Enter the legal address for the person or business. This is kept
		// 							private and will be only used by Commonspace.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							APT/STE/BLDG*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<Form.Item
		// 								label="APT/STE/BLDG*"
		// 								name="apt_ste_bldg"
		// 								className="mb-0 hidden-label"
		// 								validateTrigger="onBlur"
		// 								rules={[{ required: true }]}
		// 							>
		// 								<input
		// 									type="text"
		// 									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 									placeholder="Enter the primary contact phone number for the business."
		// 									value={aptSteBldg}
		// 									/>
		// 							</Form.Item>
		// 						</div>							
		// 					</div>							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							Enter the legal address for the person or business. This is kept
		// 							private and will be only used by Commonspace.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-8 items-center">
		// 					<div className="md:col-span-3 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							City*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<Form.Item
		// 								label="City"
		// 								name="city"
		// 								className="mb-0 hidden-label"
		// 								validateTrigger="onBlur"
		// 								rules={[{ required: true }]}
		// 							>
		// 								<input
		// 									type="text"
		// 									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 									placeholder="Enter City"
		// 									value={city}
		// 									/>
		// 							</Form.Item>
		// 						</div>
								
		// 					</div>
		// 					<div className="md:col-span-3 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							State*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<DropdownWithRegions
		// 								title="State"
		// 								displayError={
		// 									selectedRegion === 'default' || selectedRegion === ''
										
		// 								}
		// 								country={selectedCountry}
		// 								value={selectedRegion}
		// 								onDropdownChange={setSelectedRegion}
		// 							></DropdownWithRegions>
		// 						</div>
								
		// 					</div>
		// 					<div className="md:col-span-2 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 						ZIP*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<Form.Item
		// 								label="ZIP"
		// 								name="zip_code"
		// 								className="mb-0 hidden-label"
		// 								validateTrigger="onBlur"
		// 								rules={[{ required: true }]}
		// 							>
										

		// 							<input
		// 									type="text"
		// 									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 									placeholder="5 digits "
		// 									value={city}
		// 									/>
		// 							</Form.Item>
		// 						</div>
								
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Contact Phone*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<Form.Item
		// 								label="Contact Phone"
		// 								name="contact_phone"
		// 								onChange={(e) => handlePhoneInput(e)}
		// 								value={inputValue}
		// 								className="mb-0 hidden-label"
		// 								validateTrigger="onBlur"
		// 								rules={[{ required: true }]}
		// 							>
		// 								<input
		// 									type="text"
		// 									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 									value={inputValue}
		// 									placeholder="Enter the primary contact phone number for the business."
		// 									/>
		// 							</Form.Item>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							This phone number is kept private and will be only used by
		// 							Commonspace.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Contact Email*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<Form.Item
		// 								label="Contact Email"
		// 								name="contact_email"
		// 								className="mb-0 hidden-label"
		// 								validateTrigger="onBlur"
		// 								rules={[{ required: true, type: 'email' }]}
		// 							>
		// 								<input
		// 									type="email"
		// 									placeholder="Enter the primary contact email for the business."
		// 									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 									value={contactEmail}
		// 									/>
		// 							</Form.Item>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							This email is kept private and will be only used by Commonspace.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Primary City*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<Form.Item
		// 								label="Primary City"
		// 								name="primary_city"
		// 								className="mb-0 hidden-label"
		// 								validateTrigger="onBlur"
		// 								rules={[{ required: true }]}
		// 							>
		// 								<input
		// 									type="text"
		// 									placeholder="Enter your primary city"
		// 									className="block h-8 w-full rounded-none border-0 py-1.5 pl-4 pr-20 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6"
		// 									value={primaryCity}
		// 									/>
		// 							</Form.Item>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							Enter the primary or main location of the person or business.
		// 							This can be different from the Legal Address. This city may be
		// 							visible in certain areas.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Primary Language*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<DropdownWithItems
		// 								customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
										
		// 								title="Image Position"
		// 								selectedValue={selectedPrimaryLang}
		// 								listItems={SPACE_PRIMARY_LANG}
		// 								onDropdownChange={(value) => setSelectedPrimaryLang(value)}
		// 							/>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							This is used to set the default language for your Space and to
		// 							provide services. Note that some features are only available in{' '}
									
		// 							{getSitewideLink('supported_language')}
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Primary Currency*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<DropdownWithItems
		// 								customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
		// 								title="Image Position"
		// 								selectedValue={selectedPrimaryCurrency}
		// 								listItems={SPACE_PRIMARY_CURRENCY}
		// 								onDropdownChange={(value) => setSelectedPrimaryCurrency(value)}
		// 							/>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg m-0">
		// 							This is used to set the default currency for your Space and to
		// 							provide services. Note that some features are only available in{' '}
									
		// 							{getSitewideLink('supported_currency_crypto')}
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Industry*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<DropdownWithItems
		// 								customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
		// 								displayError={selectedIndustry === 'default'
		// 								}
		// 								title="Industry"
		// 								selectedValue={selectedIndustry}
		// 								listItems={SPACE_BUSINESS_INDUSTRY}
		// 								onDropdownChange={(value) => setSelectedIndustry(value)}
		// 							/>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg">
		// 							Select an industry that best applies. This will be used to
		// 							suggest features and provide services.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center ">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Type*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<DropdownWithItems
		// 								customButtonClass="h-[40px] text-ellipsis overflow-hidden !bg-none bg-white w-full rounded-none border-0 py-1.5 text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 text-start"
		// 								displayError={
		// 									selectedType === 'default'
		// 								}
		// 								title="Type"
		// 								selectedValue={selectedType}
		// 								listItems={SPACE_BUSINESS_TYPE}
		// 								onDropdownChange={(value) => setSelectedType(value)}
		// 							/>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg ">
		// 							Select a type that best applies to your organization. This will
		// 							be used to suggest features and provide services.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 md:grid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-8 items-center">
		// 					<div className="md:col-span-8 my-auto !order-last md:!order-first">
		// 						<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
		// 							Size*
		// 						</label>
		// 						<div className="relative rounded-none ">
		// 							<DropdownWithItems
		// 								customButtonClass="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
		// 								displayError={
		// 									selectedSize === 'default'
		// 								}
		// 								title="Size"
		// 								selectedValue={selectedSize}
		// 								listItems={SPACE_BUSINESS_SIZE}
		// 								onDropdownChange={(value) => setSelectedSize(value)}
		// 							/>
		// 						</div>
								
		// 					</div>
							
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg">
		// 							Select the size of your organization. This will be used to
		// 							suggest features and provide services.
		// 						</p>
		// 					</div>
		// 				</div>

		// 				<div className="grid grid-cols-1 mdgrid-cols-12  gap-y-8 md:gap-y-0  md:gap-8 mt-5">
		// 					<div className="md:col-span-8 !order-last md:!order-first">
		// 						<div className="form-check">
		// 							<label className="form-check-label body-text--small">
		// 								<Checkbox
		// 									checked={verifyAuthorizedChecked}
		// 									onChange={onChangeVerifyChecked}
		// 									className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
		// 								></Checkbox>
		// 								I verify that I am an authorized representative of the
		// 								organization of the Legal Name entered above and have the
		// 								right to act on its behalf in the creation and management of
		// 								this Space. The organization and I agree to{' '}
										
		// 								{getSitewideLink(
		// 									'terms_for_using',
		// 									'gradient-color-txt body-txtitalic--small'
		// 								)}
		// 							</label>
		// 						</div>
		// 					</div>
		// 					<div className="md:col-span-4 !order-first md:!order-last">
		// 						<p className="body-txtitalic--smallest color-reg">
		// 							Please certify that the responsible parties have read and agree
		// 							to the terms, policies, agreements, and guidelines governing the
		// 							use and management of Spaces.
		// 						</p>
		// 					</div>
		// 				</div>
		// 				<div className="grid grid-cols-1 md:grid-cols-12 mt-5">
		// 					<div className="md:col-span-8 flex items-end">
		// 						<Button className="btn btn-lg btn-primary" type="submit"
		// 						disabled={displayDropDownError || !verifyAuthorizedChecked}>
		// 							{isLoading && <i className="fa-solid fa-spinner fa-spin"></i>}
		// 							Save & Continue
		// 						</Button>
		// 					</div>
		// 				</div>
		// 			</Form>
		// 		</div>
		// 	</main>
		// </>
	);
};

export default memo(AddBusinessDetails);
